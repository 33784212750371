import moment from 'moment';

const dateFormat = 'YYYYMMDD';
export const defaultTypes = {
  id: 0,
  name: '',
  status: '',
  type: '',
  description: '',
  subStatus: 'current',
  isOnBoardingProject: false,
  isSampleProject: false,
  isPrivate: false,
  companyId: 0,
  company: {
    id: 0,
    name: '',
  },
  isBillable: true,
  tagIds: [],
  tags: [],
  minMaxAvailableDates: {
    suggestedEndDate: moment.tz('', dateFormat, moment.tz.guess()),
    suggestedStartDate: moment.tz('', dateFormat, moment.tz.guess()),
  },
  activePages: {
    links: false,
    tasks: false,
    time: false,
    billing: false,
    notebooks: false,
    files: false,
    forms: false,
    comments: false,
    messages: false,
    milestones: false,
    riskRegister: false,
    list: false,
    board: false,
    table: false,
    gantt: false,
  },
  permissions: {
    canAddTaskLists: false,
    viewNotebook: false,
    canAccessSharepoint: false,
    canAccessOneDriveBusiness: false,
    receiveEmailNotifications: false,
    projectAdministrator: false,
    canAddLinks: false,
    viewMessagesAndFiles: false,
    canAddMessages: false,
    isObserving: false,
    canAccess: false,
    canEditAllTasks: false,
    canAccessMicrosoftConnectors: false,
    canAccessBox: false,
    viewTimeLog: false,
    canSetPrivacy: false,
    canAddNotebooks: false,
    viewLinks: false,
    isArchived: false,
    viewTasksAndMilestones: false,
    viewEstimatedTime: false,
    viewAllTimeLogs: false,
    canLogTime: false,
    canAddMilestones: false,
    canAccessDropbox: false,
    canAddFiles: false,
    canAccessGoogleDocs: false,
    canAccessInvoiceTracking: false,
    viewRiskRegister: false,
    canAddRisks: false,
    canAddTasks: false,
    active: false,
    canAccessOneDrive: false,
  },
  category: {
    id: 0,
    color: '',
  },
  categoryId: 0,
  customFields: null,
  customFieldValueIds: null,
  skipWeekends: false,
  starred: false,
  startPage: 'projectoverview',
  overviewStartPage: 'default',
  tasksStartPage: 'default',
  dateLastActive: null,
  createdAt: moment.tz('', dateFormat, moment.tz.guess()),
  createdBy: 0,
  updatedAt: moment.tz('', dateFormat, moment.tz.guess()),
  updatedBy: 0,
  logo: '',
  projectOwnerId: 0,
};

export const defaults = {
  ...defaultTypes,
  dateLastActive: null,
  updatedAt: null,
};
