import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';

import { initRouter } from '@/scaffolding/router';
import getUrlParam from '@/utils/helpers/getUrlParam';
import validate from './validate';

const metaConfig = {
  keyName: 'meta',
};

Vue.use(Router);
Vue.use(Meta, metaConfig);

// Scaffolding - with the legacy routes being added also, we don't
// instantiate the routes yet, we add them shortly in scaffolding code, once that
// is gone, they will return here.
// const router = new Router({ routes: enrichComponents(rawRoutes) });
const router = new Router();

// Support non-hashed initial routes
const initialPathName = window.location.pathname.replace(/\/+$/, '');
const queryString = window.location.search;
if (initialPathName) {
  window.history.replaceState(null, '', '/');
  router.replace(initialPathName + queryString);
}

// Provide an all meta data value for use in guards, this is NOT reactive
router.allMeta = {};
router.beforeEach((to, from, next) => {
  router.allMeta = to.matched.reduce((meta, rec) => ({ ...meta, ...rec.meta }), {});
  next();
});

// TODO Hybrid - scaffolding
initRouter(router);

router.registerStore = (store) => {
  // SharedFilter extracted early and only once during Application initialisation
  const sharedFilter = getUrlParam('sf');
  if (sharedFilter) {
    store.commit('filter/sharedFilterHash', sharedFilter);
  }
  validate(store, router);

  router.beforeEach((to, from, next) => {
    const currentProject = store.getters['project/current'];
    const { enforceProject, enforceProjectId } = store.state.layout;

    if (enforceProject && currentProject) {
      if (enforceProjectId && enforceProjectId !== -1 && enforceProjectId !== currentProject.id) {
        store.dispatch(
          'notifications/flashes/warn',
          Vue.t("Sorry, you're unable to switch between projects when on an embedded project"),
        );
      } else {
        next();
      }
    } else {
      next();
    }

    // The below is checked in two places in the case of serveral redirects
    if (from.query?.from?.includes('homepage')) {
      setTimeout(() => {
        store.dispatch('notifications/flashes/info', {
          title: Vue.t('You were automatically redirected to the app.'),
          opts: {
            timer: 8000,
            showCloseButton: true,
            showLink: true,
            buttonText: Vue.t('To visit our homepage'),
            linkText: Vue.t('click here'),
            onClickButton: () => {
              window.open('https://www.teamwork.com/?noredirect', '_blank').focus();
            },
            dataIdentifier: 'website-redirect-popup-click-here',
            closeButtonDataIdentifier: 'website-redirect-popup-x',
          },
        });
      }, 500);
    }
  });

  // As routes change, the store state is updated where necessary
  router.afterEach((to) => {
    const { allMeta } = router;

    // register current route meta to the layout store
    store.commit('layout/routeMeta', allMeta);
    // TODO Hybrid scaffolding
    if (window.app) {
      window.app.hybrid.meta(allMeta); // propagate meta data to TKO
    }

    // register current route components to the layout store
    store.commit(
      'layout/routeComponents',
      to.matched.reduce((comps, rec) => [...comps, ...Object.keys(rec.components)], []),
    );

    // Current Filters Key set, but filters can be feature gated
    if (allMeta.filtersGate) {
      store.when(
        (state, getters) => getters['account/isFullyReady'],
        () => {
          const enabled = store.getters['account/features'].includes(allMeta.filtersGate);
          store.commit('filter/currentFilterKey', (enabled && allMeta.filterKey) || '');
        },
      );
    } else {
      store.commit('filter/currentFilterKey', allMeta.filterKey || '');
    }

    // close any open modals
    store.dispatch('modals/close');

    if (to.query?.from?.includes('homepage')) {
      setTimeout(() => {
        store.dispatch('notifications/flashes/info', {
          title: Vue.t('You were automatically redirected to the app.'),
          opts: {
            timer: 8000,
            showCloseButton: true,
            showLink: true,
            buttonText: Vue.t('To visit our homepage'),
            linkText: Vue.t('click here'),
            onClickButton: () => {
              window.open('https://www.teamwork.com/?noredirect', '_blank').focus();
            },
            dataIdentifier: 'website-redirect-popup-click-here',
            closeButtonDataIdentifier: 'website-redirect-popup-x',
          },
        });
      }, 500);
    }
  });

  // Equivalent to app.utility.OpenInNewTab
  router.newTab = (...args) => {
    const newTab = window.open(router.resolve(...args).href, '_blank');
    if (newTab === null || newTab === undefined) {
      store.dispatch(
        'notifications/flashes/warn',
        Vue.t('A popup blocker has prevented the page from opening in a new tab'),
      );
    }
  };

  // Shortcut to manage redirecting the user to the landing page
  router.goHome = () => {
    router.push('/home/work');
  };
};

export default router;
