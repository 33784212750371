import api, { API_PREFIXES } from '@/services/api';

export default {
  namespaced: true,
  state: {
    productOutcomes: [],
  },

  actions: {
    fetchProductOutcomes({ commit }) {
      api.get(`${API_PREFIXES.v3}/productoutcomes.json`).then((result) => {
        commit('saveProductOutcomes', result.data.productOutcomes);
      });
    },
  },

  mutations: {
    saveProductOutcomes(state, response) {
      state.productOutcomes = response;
    },
  },

  getters: {
    getProductOutcomes: (state) => {
      return state.productOutcomes;
    },
  },
};
