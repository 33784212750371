import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  return response.data.customfields;
}

/**
 * Loads a list of custom fields from the Teamwork v3 API.
 */
export default function useCustomFieldsLoader({ params, count, pageSize = 50 }) {
  const { state, refresh } = useListLoader({
    url: v3Url('customfields'),
    params,
    count,
    responseToItems,
    pageSize,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'customfield') {
      refresh(event.customfieldId);
    }
  });

  return state;
}
