import { shallowRef, provide, inject } from 'vue-demi';
import useRouter from './useRouter';

const currentRouteSymbol = Symbol('currentRoute');

function CurrentRoute() {
  const router = useRouter();
  const currentRoute = shallowRef(router.currentRoute);
  router.afterEach((newRoute) => {
    currentRoute.value = newRoute;
  });
  return currentRoute;
}

export function provideCurrentRoute() {
  const currentRoute = CurrentRoute();
  provide(currentRouteSymbol, currentRoute);
  return currentRoute;
}

export default function useCurrentRoute() {
  return inject(currentRouteSymbol);
}
