import Vue from 'vue';
import { ACTIONS } from '@/store/utils/loader';
import typedDefaults from '@/utils/helpers/typed-defaults';
import all from './modules/all';
import filtered from './modules/filtered';
import { defaultTypes } from './recordDefault';

function groupByCategory(templates) {
  const groups = [];
  templates.forEach((template) => {
    let matchedGroup = groups.find((group) => group.id === template.categoryId);
    if (!matchedGroup) {
      matchedGroup = {
        id: template.categoryId,
        name: template.category.name,
        templates: [],
      };
      groups.push(matchedGroup);
    }
    matchedGroup.templates.push(template);
  });
  return groups;
}

export default {
  namespaced: true,

  modules: {
    all,
    filtered,
  },

  state: {
    records: {},
    searchTerm: '',
    currentCategoryId: null,
  },

  getters: {
    isFiltered({ searchTerm, currentCategoryId }) {
      return searchTerm.trim().length > 0 || currentCategoryId !== null;
    },
    allTemplates(state, getters, rootState) {
      const templates = getters['all/list'].map((id) => state.records[id]);
      return templates.map((template) => ({
        ...template,
        category: rootState.teamworkTemplateCategory.records[template.categoryId],
      }));
    },
    allTemplateGroups(state, getters) {
      return groupByCategory(getters.allTemplates);
    },

    filteredTemplates(state, getters, rootState) {
      const templates = getters['filtered/list'].map((id) => state.records[id]);
      return templates.map((template) => ({
        ...template,
        category: rootState.teamworkTemplateCategory.records[template.categoryId],
      }));
    },

    filteredTemplateGroups(state, getters) {
      return groupByCategory(getters.filteredTemplates);
    },
  },

  mutations: {
    currentCategory(state, id) {
      state.currentCategoryId = id;
    },
    search: (state, term) => {
      state.searchTerm = term;
    },
    records(state, templates) {
      const hash = {};
      templates.forEach((template) => {
        const defsApplied = typedDefaults(defaultTypes, template);
        // First time saving the record, add all the defaults too.
        hash[template.id] = {
          ...(state.records[template.id] || defaultTypes),
          ...defsApplied,
        };
      });
      state.records = { ...state.records, ...hash };
    },

    record(state, template) {
      const defsApplied = typedDefaults(defaultTypes, template);
      // First time saving the record, add all the defaults too.
      const newRec = {
        ...(state.records[template.id] || defaultTypes),
        ...defsApplied,
      };
      Vue.set(state.records, newRec.id, newRec);
    },
  },

  actions: {
    current({ commit, dispatch }, id) {
      const change = () => commit('current', id);
      return id ? dispatch(ACTIONS.ACCESS, id).then(change) : change();
    },
  },
};
