import state from './state';
import mutations from './mutations';
import getters from './getters';
import people from './modules/people';
import projects from './modules/projects';

export default {
  namespaced: true,
  modules: { people, projects },
  state,
  mutations,
  getters,
};
