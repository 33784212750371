import { inject, provide } from 'vue-demi';

const currentUserSymbol = Symbol('currentUser');

/**
 * Provides the currentUser ref which `useCurrentUser` will return.
 */
export function provideCurrentUser(currentUser) {
  provide(currentUserSymbol, currentUser);
}

/**
 * Returns the currentUser ref provided by `provideCurrentUser`.
 */
export function useCurrentUser() {
  return inject(currentUserSymbol);
}
