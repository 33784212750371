export default function isInputFocused() {
  const { activeElement } = document;
  if (activeElement) {
    const { nodeName } = activeElement;
    if (
      Boolean(activeElement.getAttribute('contenteditable')) === true ||
      nodeName === 'INPUT' ||
      nodeName === 'TEXTAREA' ||
      nodeName === 'SELECT'
    ) {
      return true;
    }
  }

  return false;
}
