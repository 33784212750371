export default ({ dispatch }, { itemId, actionType, projectId, subType }) => {
  // If the event is from a column trigger or a portfolio column, ignore it
  if (subType === 'columnTrigger' || !projectId) {
    return;
  }
  if (actionType === 'edited') {
    dispatch('boardColumn/changeNotification', Number(itemId));
  }
  if (actionType === 'deleted') {
    dispatch('project/tasks/changeNotification', Number(projectId));
  }
};
