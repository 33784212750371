import { createListLoader, API } from '@/store/utils/loader';

export default createListLoader({
  namespaced: true,
  config: {
    id: 'project/all',
    url: `${API.v2}/projects.json`,
    module: 'project',
    sort: false,
    pagination: { pageSize: 25 },
  },
});
