import { computed, shallowRef } from 'vue-demi';
import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  const { users = {} } = response.data.included;

  const budgetExpenses = response.data.budgetExpenses || [];

  /* eslint-disable no-param-reassign */
  budgetExpenses.forEach((budgetExpense) => {
    budgetExpense.cost = Number(budgetExpense.cost);

    if (users[budgetExpense.createdBy.id]) {
      budgetExpense.createdBy = users[budgetExpense.createdBy.id];
    }

    if (users[budgetExpense.updatedBy.id]) {
      budgetExpense.updatedBy = users[budgetExpense.updatedBy.id];
    }
    /* eslint-enable no-param-reassign */
  });

  return budgetExpenses;
}

function responseToMeta(response) {
  const { totalCost } = response.data.meta;
  return { totalCost };
}

/**
 * Loads a list of budgets from the Teamwork v3 API.
 */
export default function useProjectBudgetExpensesLoader({
  params,
  count,
  pageSize = 50,
  projectBudgetId: _projectBudgetId,
}) {
  const projectBudgetId = shallowRef(_projectBudgetId);
  const url = computed(() =>
    projectBudgetId.value ? v3Url(`projects/budgets/${projectBudgetId.value}/expenses`) : undefined,
  );

  const { state, update, refresh } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    responseToMeta,
    pageSize,
  });

  useOptimisticUpdates((event) => {
    if (event.type !== 'budgetExpense') {
      return;
    }
    update((budgetExpenses) => {
      if (event.action === 'create') {
        return budgetExpenses.concat(event.budgetExpense);
      }
      if (event.action === 'update') {
        return budgetExpenses.map((budgetExpense) =>
          budgetExpense.id === event.budgetExpense.id ? { ...budgetExpense, ...event.budgetExpense } : budgetExpense,
        );
      }
      if (event.action === 'delete') {
        return budgetExpenses.filter((budgetExpense) => budgetExpense.id !== event.budgetExpense.id);
      }
      return budgetExpenses;
    }, event.promise);
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'budgetExpense') {
      refresh(event.budgetExpenseId);
    }
  });

  return state;
}
