import { createRecordListLoader } from '@/store/utils/loader';

export default createRecordListLoader({
  namespaced: true,
  config: {
    url: (id) => `projects/${id}/teams.json`,
    module: 'team',
    mapResponse: (ctx, rs) => rs.data.teams,
    pagination: true,
    updater: true,
  },
});
