export default ({ dispatch, commit, state }, { subType, itemId, actionType, extraInfo }) => {
  const { ADDED, DELETED } = window.app.consts.ACTIONTYPE;

  dispatch('project/latest/changeNotification');
  if (actionType === ADDED) {
    dispatch('project/all/changeNotification');
    dispatch('project/filter/changeNotification');
    if (typeof extraInfo !== 'undefined' && extraInfo.data && extraInfo.data.isTemplate) {
      dispatch('customTemplate/all/changeNotification');
    }
  } else if (actionType === DELETED) {
    dispatch('project/all/changeNotification');
    dispatch('project/filter/changeNotification');
    dispatch('project/starred/changeNotification');
  } else if (['starred', 'unstarred', 'archive', 'active'].includes(subType)) {
    dispatch('project/starred/changeNotification');
    if (['starred', 'unstarred'].includes(subType)) {
      if (state.project.records[itemId]) {
        // We use the storeSync to make this change, to avoid sending
        // it back to TKO via a postbox update
        commit('storeSync', () => {
          state.project.records[itemId].starred = subType === 'starred';
        });
      }
    } else if (['archive', 'active'].includes(subType)) {
      dispatch('project/all/changeNotification');
      dispatch('project/filter/changeNotification');
    }
  }
};
