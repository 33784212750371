import i18next from 'i18next';
import Vue from 'vue';
import api from '@/services/api';
import { createSingletonLoader, MUTATIONS, ACTIONS } from '@/store/utils/loader';

const missingKeyHandler = (lang, ns, key) => {
  try {
    const iId = 0; // TODO - get installation ID from account store module
    throw new Error(`Translation XHR - installationID:${iId} - site:${document.location.href}`);
  } catch ({ stack }) {
    const saveStackTrace = {
      name: 'New/missing TL',
      lang,
      string: key,
      url: document.location.href,
      stack,
    };
    // Record that we have encountered this new string
    // TODO: Group multiple requests together
    api.put('translations.json', {
      ref: key,
      lang,
      app: 'projects',
      logAppUsage: true,
      saveStackTrace,
      appVersionId: window.appVersionId,
      authCode: 856,
      type: 'data',
    });
  }
};

const staticConfig = {
  fallbackLng: 'en',
  // Handy way to by default translate args to an array
  overloadTranslationOptionHandler: (args) => Array.from(args).slice(1),
  // Default interpolation {{}} conflicts with Vue interpolation when
  // used in templates, this alternative allows for:
  // {{ $t('Hello [0]', name) }}
  interpolation: {
    prefix: '[',
    suffix: ']',
    // according to i18next this is to sanitize values (XSS),
    // but this needs to be done elsewhere
    escapeValue: false,
  },
  // Turn off these special characters as key are English
  // strings and can contain special characters which might
  // be misinterpreted
  keySeparator: false,
  nsSeparator: false,
  // Handle 50 character key cutoff
  parseMissingKeyHandler: (key) =>
    key.length > 50 && i18next.exists(key.substr(0, 50)) ? i18next.t(key.substr(0, 50)) : key,
  // Missing Translations can be recorded
  saveMissing: false,
  saveMissingTo: 'current',
  missingKeyHandler: (langArray, ns, key) => {
    if (langArray[0] !== staticConfig.fallbackLng) {
      // default lang doesn't use translations
      // 50 character truncation will trigger a missing translate incorrectly
      if (staticConfig.parseMissingKeyHandler(key) === key) {
        missingKeyHandler(langArray[0], ns, key);
      }
    }
  },
};

export const watchers = [
  // TODO Hybrid - handled in TKO for now
  // {
  //   getter: (state, rootState) => rootState.user.localization.languageCode,
  //   callback: ({ dispatch }, lang) => lang && dispatch(lang.toLowerCase() ===
  //        staticConfig.fallbackLng ? 'i18n/defaultLang' : 'i18n/access'),
  // },
];

// Temporary fix to server-side language format, this should be performed server-side
const mapping = (v) => (typeof v === 'string' ? v.replace('[_s]', '[0]').replace(/\[_s([0-9])]/g, '[$1]') : v);

const serverSideFix = (trans) => {
  const fixed = {};
  if (trans !== undefined) {
    Object.keys(trans).forEach((key) => {
      fixed[mapping(key)] = mapping(trans[key]);
    });
  }
  return fixed;
};

export default {
  namespaced: true,
  modules: {
    loader: createSingletonLoader({
      config: {
        url: '/language.json',
        params: (state, getters) => ({
          lang: getters['i18n/lng'].toUpperCase(),
          product: 'projects',
        }),
      },
      actions: {
        [ACTIONS.LOADED_SUCCESS]({ commit, getters }, rs) {
          const raw = rs.data.translations;
          const translations = serverSideFix(raw);
          commit('translations', { lng: getters.lng, translations });
          return rs;
        },
      },
    }),
  },
  state: {
    lngOverride: '',
    resources: {
      en: {
        translation: {},
      },
    },
  },
  getters: {
    lng: (state, getters, rootState) => state.lngOverride || rootState.user.localization.languageCode.toLowerCase(),
  },
  mutations: {
    translations(state, { lng, translations: translation }) {
      if (!state.resources[lng]) {
        Vue.set(state.resources, lng, { translation });
      } else {
        Vue.set(state.resources[lng], 'translation', translation);
      }
    },
    addTranslations(state, { lng, translations: trans }) {
      Object.keys(trans).forEach((key) => {
        Vue.set(state.resources[lng].translation, key, trans[key]);
      });
    },
    overrideLng(state, lng) {
      state.lngOverride = lng;
    },
  },
  actions: {
    defaultLang({ dispatch, commit }) {
      commit(MUTATIONS.LOADED, {});
      return dispatch('libInit');
    },
    initLang({ dispatch, commit }, lng) {
      commit('overrideLng', lng);
      return dispatch('reload');
    },
    libInit({ state, getters }) {
      // Promisify the i18next callback
      return new Promise((resolve, reject) => {
        i18next.init({ ...staticConfig, ...state, lng: getters.lng }, (err) => (err ? reject(err) : resolve()));
      });
    },
  },
};
