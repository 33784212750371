import Vue from 'vue';
import typedDefaults from '@/utils/helpers/typed-defaults';
import { remove } from '@/utils/helpers/array';

const recordDefault = {
  dependentCant: '',
  id: 0,
  depTaskId: 0,
  projectId: 0,
};

const uniformat = (predecessor) => ({
  dependentCant: predecessor.type,
  ...predecessor,
});

export default {
  namespaced: true,
  state: {
    preds: {},
    deps: {},
    projects: {},
  },
  getters: {
    uniqueBidirectional:
      ({ preds, deps }) =>
      (idList) => [...new Set(idList.reduce((acc, id) => [...acc, ...(preds[id] || []), ...(deps[id] || [])], []))],
  },
  mutations: {
    records(state, list) {
      const deps = {};
      const preds = {};
      const projs = {};
      const recs = [];
      const toRemove = [];
      // Happy path is a single project in the list, but the logic
      // will adapt if there are multiples
      let projectId = (list.length && list[0].projectId) || 0;
      list.forEach((rec) => {
        const dep = typedDefaults(recordDefault, uniformat(rec));

        // Check for duplicate - this is pretty efficient as in most cases
        // no data will exist in the dependency module for the tasks in question
        const dup = (state.deps[dep.id] || []).find(({ depTaskId }) => depTaskId === dep.depTaskId);
        if (dup) {
          toRemove.push(dup);
        }

        if (!deps[dep.id]) {
          deps[dep.id] = [...(state.deps[dep.id] || [])];
        }
        deps[dep.id].push(dep);
        if (!preds[dep.depTaskId]) {
          preds[dep.depTaskId] = [...(state.preds[dep.depTaskId] || [])];
        }
        preds[dep.depTaskId].push(dep);
        if (projectId !== rec.projectId) {
          if (projectId) {
            projs[projectId] = [...(state.projects[projectId] || []), ...recs];
            projectId = 0;
          }
          if (rec.projectId) {
            if (!projs[rec.projectId]) {
              projs[rec.projectId] = [...(state.projects[rec.projectId] || [])];
            }
            projs[rec.projectId].push(dep);
          }
        }
        recs.push(dep);
      });

      const existingProjectRecs = projectId && (state.projects[projectId] || []).slice(0);

      // Handle duplicates
      toRemove.forEach((dep) => {
        if (deps[dep.id]) {
          remove(deps[dep.id], dep);
        }
        if (preds[dep.depTaskId]) {
          remove(preds[dep.depTaskId], dep);
        }
        if (projs[dep.projectId]) {
          remove(projs[dep.projectId], dep);
        } else if (existingProjectRecs) {
          remove(existingProjectRecs, dep);
        }
      });

      state.deps = { ...state.deps, ...deps };
      state.preds = { ...state.preds, ...preds };
      if (projectId) {
        Vue.set(state.projects, projectId, [...existingProjectRecs, ...recs]);
      } else {
        state.projects = { ...state.projects, ...projs };
      }
    },
    addPredecessor(state, rec) {
      const dep = typedDefaults(recordDefault, uniformat(rec));
      const { id, depTaskId, projectId } = dep;
      if (!state.deps[id]) {
        Vue.set(state.deps, id, []);
      }
      if (!state.preds[depTaskId]) {
        Vue.set(state.preds, depTaskId, []);
      }
      if (!state.projects[projectId]) {
        Vue.set(state.projects, projectId, []);
      }
      state.deps[id].push(dep);
      state.preds[depTaskId].push(dep);
      state.projects[projectId].push(dep);
    },
    removePredecessor(state, dep) {
      const { id, depTaskId, projectId } = dep;
      [state.deps[id], state.preds[depTaskId], state.projects[projectId]].forEach((arr) => arr && remove(arr, dep));
    },
    clearPredecessors(state, id) {
      (state.preds[id] || []).forEach((depToRemove) => {
        const predId = depToRemove.id;
        remove(state.deps[predId], depToRemove);
        const projId = depToRemove.projectId;
        if (projId) {
          remove(state.projects[projId], depToRemove);
        }
      });
      Vue.set(state.preds, id, []);
    },
    clearProject(state, id) {
      (state.projects[id] || []).forEach((depToRemove) => {
        const predId = depToRemove.id;
        const depId = depToRemove.depTaskId;
        remove(state.deps[predId], depToRemove);
        remove(state.preds[depId], depToRemove);
      });
      Vue.set(state.projects, id, []);
    },
  },
};
