import lscache from 'lscache';

export default [
  {
    path: '/projects/:projectId(\\d+)/finance',
    component: () => import('./RouteProjectsFinance.vue'),
    children: [
      {
        path: '',
        redirect() {
          // Default redirect to last section visited or budgets
          return lscache.get('twProjects-last-finance-section') || 'budgets';
        },
      },
      {
        path: 'billing/:invoiceType?',
        props: true,
        component: () => import('./RouteProjectsFinanceBilling.vue'),
        beforeEnter(to, from, next) {
          lscache.set('twProjects-last-finance-section', 'billing');
          next();
        },
      },
      {
        path: 'budgets',
        component: () => import('./RouteProjectsFinanceBudgets.vue'),
        beforeEnter(to, from, next) {
          lscache.set('twProjects-last-finance-section', 'budgets');
          next();
        },
      },
      {
        path: 'rates',
        component: () => import('./RouteProjectsFinanceRates.vue'),
        beforeEnter(to, from, next) {
          lscache.set('twProjects-last-finance-section', 'rates');
          next();
        },
      },
      {
        path: '*',
        redirect: '.',
      },
    ],
  },
  // Legacy billing path
  {
    path: '/projects/:projectId(\\d+)/billing',
    redirect(to) {
      return `/projects/${to.params.projectId}/finance/billing`;
    },
    children: [
      {
        path: '*',
        redirect: '.',
      },
    ],
  },
];
