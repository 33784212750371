/* eslint-disable no-undef */
import moment from 'moment';
import Vue from 'vue';
import {
  TRUSTED_INTERNAL,
  PRICEPLANS,
  EARLY_ACCESS,
  FREE_ACCOUNTS,
  PRICEPLANTYPES,
  STARTER_PLAN_IDS,
  DELIVER_PLAN_IDS,
} from '@tko/src/app/helpers/constants';
import typedDefaults from '@/utils/helpers/typed-defaults';
import { createSingletonLoader, ACTIONS } from '@/store/utils/loader';
import { lsSyncer } from '@/store/utils/localstorage';
import api from '@/services/api';
import Can from '@/services/can';
import GetCookie from '@/utils/helpers/getcookie';
// TODO Hybrid - these constants should live here in this module

const paymentCheckFrequency = 24 * 60 * 60 * 1000; // 1d

const BLOCK_TRUSTED_INTERNAL = GetCookie('TRUSTED') === 'BLOCK';

export const watchers = [
  // TODO Hybrid - triggers from TKO
  // {
  //   getter: (state, rootState) => rootState.user.status === 'ok',
  //   callback: ({ dispatch }, loggedIn) => loggedIn && dispatch('account/access'),
  // },
  {
    getter: (state) => state.id > 0, // better than isReady because it will only fire once
    callback: ({ dispatch }, accountLoadedOnce) => {
      if (accountLoadedOnce) {
        // After loading the Account record, perform a payment check if necessary,
        // and recheck at interval, runs once a day in case they don't refresh the app very often
        dispatch('account/paymentCheck');
        setInterval(() => dispatch('account/paymentCheck'), paymentCheckFrequency);
      }
    },
  },
  lsSyncer({
    prefix: 'account-',
    mutation: 'account/initLocalStorage',
    fields: {
      hideCardReminder: false,
      workloadPlannerOverhaulEnabled: true,
    },
    expiries: {
      hideCardReminder: 24 * 60 * 60 * 1000, // 1d
    },
  }),
];

function isTrialActive({ startDate, endDate }) {
  let isActive = true;
  // This function supports nullable dates
  if (startDate && startDate.isValid()) {
    isActive = isActive && startDate.isSameOrBefore();
  }
  if (endDate && endDate.isValid()) {
    isActive = isActive && endDate.isSameOrAfter();
  }
  return isActive;
}

const featureKeys = [
  'ganttfiltering',
  'ganttcriticalpath',
  'projectupdates',
  'customfieldsprojects',
  'customfieldstasks',
  'audittrail',
  'projecttemplates',
  'resourcescheduling',
  'reports',
  'utilizationreport',
  'plannedactualmilestonereport',
  'profitability',
  'timereport',
  'projecttimebudgets',
  'intakeforms',
];

const planFeaturesStruct = () => Object.assign(...featureKeys.map((f) => ({ [f]: 0 })));

const featureTrialsStruct = () =>
  Object.assign(
    ...featureKeys.map((f) => ({
      [f]: {
        startDate: moment('', 'YYYY-MM-DDTHH:mm:ssZ'),
        endDate: moment('', 'YYYY-MM-DDTHH:mm:ssZ'),
      },
    })),
  );

export default {
  namespaced: true,
  modules: {
    loader: createSingletonLoader({
      config: {
        url: '/account.json',
        params: () => ({ getPreferences: 1 }),
      },
      actions: {
        onLoad({ dispatch, commit }, { data: { account } }) {
          commit('response', account);
          commit('preferences/init', { prefs: account.preferences, scope: 'account' }, { root: true });
          dispatch('getIntegrations');
        },
        // No visible data check, always update the account if the data changes
        // TODO Hybrid
        [ACTIONS.DATA_CHANGE]: ({ dispatch }) => dispatch('hybrid-reload'),
        'hybrid-reload': () => app.UpdateAccountInfo(),
      },
    }),
  },
  state: {
    id: 0,
    name: '',
    accountHolderId: 0,
    companyid: 0,
    createdAt: '',
    currency: {
      code: '',
      decimalPoints: 0,
      id: 0,
      name: '',
      symbol: '',
    },
    environment: {
      launchDarklyClientId: '',
      rootDomain: '',
      serviceUrls: {
        billing: '',
        s3Bucket: {
          tempRef: '',
        },
        sslProxy: '',
        checkout: '',
        workhorse: '',
        collaborativeEditing: '',
        notificationServer: '',
        titanic: '',
      },
      name: '',
      mailSuffix: '',
    },
    priceplanData: {
      trials: featureTrialsStruct(),
      features: planFeaturesStruct(),
    },
    priceplanTrialData: {
      startDate: moment('', 'YYYY-MM-DDTHH:mm:ssZ'),
      endDate: moment('', 'YYYY-MM-DDTHH:mm:ssZ'),
      createdDate: moment('', 'YYYY-MM-DDTHH:mm:ssZ'),
      features: planFeaturesStruct(),
      name: '',
      user: {
        id: 0,
        firstname: '',
        lastname: '',
      },
    },
    code: '',
    canUploadFilesDirectly: false,
    isCardExpiryMessageShowing: false,
    chatEnabled: false,
    deskEnabled: false,
    CRMEnabled: false,
    spacesEnabled: false,
    documentEditorEnabled: false,
    paidForUsers: 0,
    paidUntilDateTime: moment('', 'YYYY-MM-DDTHH:mm:ssZ'),
    datesignedup: moment('', 'YYYY-MM-DDTHH:mm:ssZ'),
    pdfServerLink: '',
    pricePlan: '',
    pricePlanId: 0,
    pricePlanMaxUsers: 0,
    pricePlanName: '',
    priceplanType: '',
    paymentStatus: '',
    premiumSupportEnabled: false,
    trialDaysRemaining: 0,
    priceplanCanTurnOffTWBranding: false,
    installationTrackingEnabled: false,
    industryCategoryId: 0,
    industryCategoryName: '',
    billingAmount: 0,
    billingPeriod: '',
    realTimeNotifications: true,
    logo: '//cdn.teamwork.com/tko/public/favicon/ms-icon-144x144.png',
    favIcon: '',
    touchIcon: '',
    strictBranding: false,
    isPaid: false,
    isStaging: false,
    inGracePeriod: false,
    hasFailedPayment: false,
    projectsNextBillingDate: moment('', 'YYYY-MM-DDTHH:mm:ssZ'),
    sessionTimeoutMinutes: 0,
    customCSSEnabled: false,
    SSO: {
      enabled: false,
    },
    betaFeaturesEnabled: false,
    betaFeatures: {
      myBoards: 0,
      teams: 0,
      clockInOut: 0,
      newgantt: 0,
      reporting: 0,
      viewmanager: 0,
      schedule: 0,
    },
    accounts: [],
    allowTeamworkProjectsBrand: true,
    shortcutsAvailable: false,
    features: [],
    integrations: {
      clockInOut: { enabled: false },
      hubSpot: { enabled: false, portalId: '' }, // not sync'd - does it need to be?
      // scaffold before adding more integrations
      // see https://digitalcrew.teamwork.com/#messages/670909?scrollTo=2132554
    },
    blockTrustedInternal: GetCookie('TRUSTED') === 'BLOCK',
    awsRegion: '',
    markdownEnabled: true,
    tagsEnabled: false,
    tagsLockedToAdmins: true,
    isPlanTrialAvailable: false,
    isLiveChatEnabled: false,
    installationDateFirstPayment: moment('', 'YYYY-MM-DDTHH:mm:ssZ'),
    URL: '',
    skipWeekends: false,
  },
  getters: {
    showPlanTrialBanner: (state, getters, { user }) =>
      // Identifying and disabling the header banner for users on the November Grow Preview
      // priceplanTrialData.id is not passed through the API so used the startDate to workaround
      state.priceplanTrialData.startDate &&
      !state.priceplanTrialData.startDate.diff('2021-11-01', 'days') === 0 &&
      state.priceplanTrialData.endDate &&
      user.administrator &&
      user.inOwnerCompany &&
      getters.planTrialRemainingDays >= -1 &&
      getters.planTrialRemainingDays <= 7 &&
      // If planFeatures >= trialFeatures, they already upgraded since
      getters.pricePlanFeatures.length < getters.planTrialFeatures.length,
    planTrialRemainingDays: (state) => {
      const endDateAsMoment = moment(state.priceplanTrialData.endDate);

      if (endDateAsMoment.isValid()) {
        return endDateAsMoment.diff(moment(), 'days');
      }

      return null;
    },
    pricePlanTrialName: (state) => state.priceplanTrialData.name,
    trialDaysRemaining: (state) => state.trialDaysRemaining,
    isEU: (state) => state.awsRegion.toLowerCase() === 'eu',
    isUS: (state) => state.awsRegion.toLowerCase() === 'us',
    isFreeAccount: (state) => FREE_ACCOUNTS.includes(state.pricePlanId),
    pricePlanCode: (state) => state.pricePlan.toLowerCase(),
    isEnterprise: (state) =>
      [PRICEPLANS.ENTERPRISECUSTOM, PRICEPLANS.PERUSER_ENTERPRISE, PRICEPLANS.PERUSER_CORPORATE].includes(
        state.pricePlanId,
      ) || state.pricePlan.toLowerCase().includes('enterprise'),
    paymentOverdue: (state) => state.paymentStatus === 'paymentDue' && state.inGracePeriod,
    shouldWarnTrialExpiry: (state, getters, { user }) =>
      user.administrator &&
      state.paymentStatus !== '' &&
      !['free', 'paid'].includes(state.paymentStatus) &&
      state.trialDaysRemaining < 0 &&
      state.trialDaysRemaining > -31 &&
      // paymentOverdue is its own action
      !getters.paymentOverdue,
    shouldWarnTrialEndingSoon: (state, getters, { user }) =>
      user.administrator &&
      state.paymentStatus !== '' &&
      !['free', 'paid'].includes(state.paymentStatus) &&
      state.trialDaysRemaining >= 0 &&
      state.trialDaysRemaining < 11 &&
      // make sure to treat paymentOverdue as its own state
      !getters.paymentOverdue,
    shouldCheckCardExpiry: (state, getters, { user }) =>
      user.administrator &&
      user.inOwnerCompany &&
      !getters.isFreeAccount &&
      (!state.isPaid || !state.projectsNextBillingDate.isValid()) &&
      (state.paymentStatus !== 'trial' || state.trialDaysRemaining < 1) &&
      state.inGracePeriod &&
      !state.hideCardReminder,

    // Ensure the 2 warnings never fire together
    shouldWarnCardExpiry: (state, getters) =>
      getters.shouldCheckCardExpiry && state.hasFailedPayment && !getters.shouldWarnTrialExpiry,

    isChatAvailable: (state) => state.chatEnabled && Can('access', 'chat'),

    isPremiumSupportEnabled: (state) => state.premiumSupportEnabled,

    isDeskAvailable: (state, getters, { user }) =>
      state.deskEnabled && user.hasDeskAccount && (user.inOwnerCompany || !state.strictBranding),

    isCRMAvailable: (state, getters, rootState, rootGetters) => state.CRMEnabled && rootGetters['user/canAccessCRM'],

    isSpacesAvailable: (state, getters, rootState, rootGetters) =>
      state.spacesEnabled && rootGetters['user/canAccessSpaces'],

    suiteProducts: (state, getters) =>
      [
        {
          id: 'desk',
          href: '/desk',
          label: 'Desk',
          check: 'isDeskAvailable',
          icon: 'logo-desk',
        },
        {
          id: 'chat',
          href: '/chat',
          label: 'Chat',
          check: 'isChatAvailable',
          icon: 'logo-chat',
        },
        {
          id: 'spaces',
          href: '/spaces',
          label: 'Spaces',
          check: 'isSpacesAvailable',
          icon: 'logo-spaces',
        },
        {
          id: 'crm',
          href: '/crm',
          label: 'CRM',
          check: 'isCRMAvailable',
          icon: 'logo-crm',
        },
      ].filter((product) => getters[product.check]),

    activeBetaFeatures: ({ betaFeatures: feats, betaFeaturesEnabled }, { isFreeAccount }, { user }) =>
      betaFeaturesEnabled && !isFreeAccount
        ? Object.keys(feats).filter((key) => feats[key] === 2 || (feats[key] === 1 && user.inOwnerCompany))
        : [],

    accountAgeInDays: ({ createdAt }) => moment().diff(moment(createdAt), 'days'),

    // there's almost never a reason for this to be called outside this module
    pricePlanFeatures: ({ priceplanData: { features } }) => Object.keys(features).filter((key) => features[key]),

    // these are the features allowed by the current active plan trial
    // there's almost never a reason for this to be called outside this module
    planTrialFeatures: ({ priceplanTrialData: trial }) =>
      isTrialActive(trial) ? Object.keys(trial.features).filter((key) => trial.features[key]) : [],
    isPlanTrialActive: ({ priceplanTrialData: trial }) => isTrialActive(trial),

    // these are standalone feature trials' features
    // there's almost never a reason for this to be called outside this module
    featureTrialFeatures: ({ priceplanData: { trials = {} } }) =>
      Object.keys(trials).filter((key) => isTrialActive(trials[key])),

    // this is the one you should use for checking features
    features: (state, getters, { user }) =>
      state.features
        .filter((f) => {
          const now = moment();
          return (
            (!f.startAt.isValid() || f.startAt.isBefore(now)) &&
            (!f.endAt.isValid() || f.endAt.isAfter(now)) &&
            (f.scope !== 'ownercompany' || user.inOwnerCompany) &&
            // scope=='nobody' generally means "disabled in beta, but potentially togglable"
            f.scope !== 'nobody'
          );
        })
        .map((f) => f.key)
        .concat(['teams']),

    pricePlanType: (state) => state.priceplanType,

    isTWInternal: ({ id }) => TRUSTED_INTERNAL.includes(id) && !BLOCK_TRUSTED_INTERNAL,

    isEarlyAccess: (vm, { isTWInternal }) => isTWInternal || EARLY_ACCESS.includes(vm.id),

    // means branding is allowed in PDF exports, etc. but not everywhere
    isSomeBrandingAllowed: (state) => !state.strictBranding,

    // means branding is allowed everywhere, e.g. in header, footer, PDF exports
    isAllBrandingAllowed: (state, { isSomeBrandingAllowed }) =>
      isSomeBrandingAllowed && (!state.priceplanCanTurnOffTWBranding || state.allowTeamworkProjectsBrand),

    isFreeTrialActive: ({ isPaid, paymentStatus, trialDaysRemaining }) =>
      !isPaid && paymentStatus === 'trial' && trialDaysRemaining > 0,
    priceplanTrialData: (state) => state.priceplanTrialData,

    isPlanTypeMaxProjects: (state) => state.priceplanType === PRICEPLANTYPES.MAXPROJECTS,
    isPlanTypePerUser: (state) => state.pricePlanType === PRICEPLANTYPES.PERUSER,
    isPlanDeliver: (state) => DELIVER_PLAN_IDS.includes(state.pricePlanId),
    isPlanStarter: (state) => STARTER_PLAN_IDS.includes(state.pricePlanId),

    shouldShowPlanTrialUpgradeSlates: (state, getters) =>
      !(getters.isFreeAccount || getters.isPlanStarter || getters.isPlanDeliver),
    isInPlanTrialUpgradeSegment: (state, getters) => {
      const minFreeForeverTimeBeforeTriallingInDays = 60;
      return (
        getters.shouldShowPlanTrialUpgradeSlates &&
        !(
          app.features.hideUpgradePathsForMaxpSmallAccountsEnabled() ||
          (!getters.isPlanTypeMaxProjects &&
            getters.isFreeAccount &&
            moment().diff(moment(state.datesignedup), 'days') < minFreeForeverTimeBeforeTriallingInDays)
        )
      );
    },
    shouldShowNewUpgradePath: (state, getters) =>
      !getters.shouldShowPlanTrialUpgradeSlates || app.features.projectsFeatureTrialScale(),
    isFullyReady: (_state, getters) => getters.isReady && getters.features.length,
    isCardExpiryMessageShowing: (state) => state.isCardExpiryMessageShowing,
    isSchedulerInScaleUpgradeSegment: () => {
      return app.features.projectsPlantrialUpgradeSlatesEnabled() && app.features.projectsScaleUpgradeSlatesEnabled();
    },
  },
  mutations: {
    toggleWorkloadPlannerOverhaulEnabled(state) {
      state.workloadPlannerOverhaulEnabled = !state.workloadPlannerOverhaulEnabled;
    },
    dismissTrialPlanAlert(state) {
      state.priceplanTrialData = {};
    },
    response(state, account) {
      Object.assign(state, typedDefaults(state, account));
      Object.assign(state.integrations, account.integrations);
    },
    initLocalStorage(state, lsdata) {
      Vue.assign(state, lsdata);
    },
    awsRegion(state, payload) {
      state.awsRegion = payload;
    },
    priceplanType(state, payload) {
      state.priceplanType = payload;
    },
    betaFeature(state, { feature, value }) {
      state.betaFeatures[feature] = value;
    },
    setFeatures(state, features) {
      state.features = features.map((feature) => ({
        key: feature.key,
        value: feature.value,
        startAt: moment(feature.startAt),
        endAt: moment(feature.endAt),
        isBeta: feature.isBeta,
        scope: feature.scope,
      }));
    },
    setIntegration(state, integration) {
      state.integrations = {
        ...state.integrations,
        [integration.code]: {
          id: integration.id,
          enabled: integration.enabled,
          image: integration.image,
          connectImage: integration.connectImage,
          displayName: integration.displayName,
        },
      };
    },
    id(state, id) {
      state.id = Number(id);
    },
    setIndustryCategory(state, industryCategoryId) {
      state.industryCategoryId = Number(industryCategoryId);
    },
    setIsCardExpiryMessageShowing(state, payload) {
      state.isCardExpiryMessageShowing = payload;
    },
  },
  actions: {
    ssr({ commit }) {
      const { STATUS, account } = window.initialData['account.json'] || {};
      if (STATUS === 'OK') {
        commit('id', account.id);
      }
    },
    checkCardExpiry({ commit }) {
      return api.put('/subscription/paymentstatus.json').then((rs) => {
        commit(rs.data.hasFailedPayment ? 'failPayment' : 'clearFailedPayment');
      });
    },
    accessLinkedAccount(context, { id, userId }) {
      return api
        .get(`/linkedSites/${id}/accessURL.json${userId ? `?userId=${userId}` : ''}`)
        .then((rs) => (rs.data && rs.data.accessURL) || false);
    },
    getFeatures({ commit }) {
      return api.get('/projects/api/v3/features.json').then(({ data }) => {
        commit('setFeatures', data.features);
      });
    },
    getIntegrations({ commit }) {
      return api.get('/synthesis/api/v2/integrations.json').then(({ data }) => {
        const { integrations } = data;

        if (!integrations || !integrations.length) {
          return;
        }

        integrations.forEach((integration) => {
          commit('setIntegration', integration);
        });
      });
    },
    hideCardReminder: ({ commit }) => commit('hideCardReminder'),
    paymentCheck: async ({ getters, dispatch }) => {
      if (getters.shouldCheckCardExpiry) {
        await dispatch('checkCardExpiry');
        if (getters.shouldWarnCardExpiry) {
          await dispatch('notifications/banners/add', { id: 'creditcard' }, { root: true });
        }
      }
    },
    toggleClockInEnabled({ commit }, enabled) {
      return api.put('/account.json', { clockInOutEnabled: enabled ? 1 : 0 }).then(() => {
        commit('toggleClockInEnabled', !!enabled);
      });
    },
  },
};
