export default [
  {
    path: '/everything',
    component: () => import('./RouteEverything'),
    children: [
      {
        path: 'notebooks',
        component: () => import('./RouteEverythingNotebooks'),
        name: 'page-everything-notebooks', // TODO find references and remove
        meta: {
          noSpacing: true,
        },
      },
      {
        path: 'time',
        redirect: '/time/all',
      },
    ],
  },
];
