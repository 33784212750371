import ko from 'knockout';
import { computed, shallowRef } from 'vue-demi';

const localTkoSocket = ko.observable();
const localVueSocket = shallowRef();

export function setSocket(socket) {
  localTkoSocket(socket);
  localVueSocket.value = socket;
}

export const tkoSocket = ko.pureComputed(() => localTkoSocket());
export const tkoSocketId = ko.pureComputed(() => tkoSocket()?.io.engine.id);

export const vueSocket = computed(() => localVueSocket.value);
export const vueSocketId = computed(() => vueSocket.value?.io.engine.id);

const localTkoUnreadCount = ko.observable();
const localVueUnreadCount = shallowRef();

export function setUnreadCount(unreadCount) {
  localTkoUnreadCount(unreadCount);
  localVueUnreadCount.value = unreadCount;
}

export const tkoUnreadCount = ko.pureComputed(() => localTkoUnreadCount());
export const vueUnreadCount = computed(() => localVueUnreadCount.value);

// Inbox
const localTkoInboxUnreadCount = ko.observable();

export const tkoInboxUnreadCount = ko.pureComputed(() => localTkoInboxUnreadCount());

export function setInboxUnreadCount(count) {
  localTkoInboxUnreadCount(count);
}

// Retrieves the correct socket URL based from the account object.
export function getSocketUrl(account) {
  return account?.environment.serviceUrls.notificationServer;
}
