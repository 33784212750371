import { mapState, mapActions, mapGetters } from 'vuex';
import Modals from '@sections/Modals';
import GlobalEvents from 'vue-global-events';
import { getModalZIndex, DEFAULT_MODAL_Z_INDEX } from '@/utils/helpers/modalhelper';

// @vue/component
export default {
  components: {
    ...Modals,
    GlobalEvents,
  },
  data() {
    return {
      zIndex: DEFAULT_MODAL_Z_INDEX,
      getModalZIndex,
    };
  },
  mounted() {
    this.zIndex = getModalZIndex();
  },
  computed: {
    ...mapState('modals', ['modals']),
    ...mapGetters('modals', ['active']),
  },
  methods: {
    ...mapActions('modals', ['close']),
    escToClose() {
      if (!this.modals.map((m) => m.props).some((s) => s.escToClose === false)) {
        this.closeTopModal();
      }
    },
    clickOutsideToClose({ clickOutsideToClose }) {
      if (clickOutsideToClose !== false) {
        this.closeTopModal();
      }
    },
    closeTopModal() {
      const { ref } = this.active;
      const { $children } = this.$refs[ref];

      // Trigger the close event on the top most modal
      if ($children?.[0]) {
        // FModal
        if (typeof $children[0].closeModal === 'function') {
          $children[0].closeModal();
        }

        // ModalDialog
        if (typeof $children[0].close === 'function') {
          $children[0].close();
        }
      } else {
        this.close();
      }
    },
    calculateModalZIndex(index) {
      // The first modal in the stack gets the default z-index calculated on mount.
      if (index === 0) {
        return this.zIndex;
      }
      // The rest of modals from the stack need to recalculate their z-index based on the existing stack
      return getModalZIndex();
    },
  },
};
