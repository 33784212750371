const listeners = [];

export const addPostboxEventListener = (handler) => {
  listeners.push(handler);
};

export const removePostboxEventListener = (handler) => {
  const index = listeners.indexOf(handler);
  if (index > -1) {
    listeners.splice(index, 1);
  }
};

export const onPostboxEvent = (event) => {
  listeners.forEach((handler) => handler(event));
};
