import * as LDClient from 'launchdarkly-js-client-sdk';
import { inject, onScopeDispose, provide, shallowRef, watch, unref } from 'vue-demi';
import { useCurrentAccount, useCurrentUser } from '@/api';
import useTrackingDebugQuery from './useTrackingDebugQuery';

function getClientKey(account) {
  return account.environment.launchDarklyClientId;
}

function createClient(account, user) {
  const clientId = getClientKey(account);

  const ldUser = {
    key: `${account.id}-${user.id}`,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.emailAddress,
    custom: {
      region: account.awsRegion,
      shardNo: account.shardNo,
      projectsPlanId: account.pricePlanId,
      projectsPricePlanCode: account.pricePlan,
      projectsPricePlanType: account.pricePlanType,
      installationId: account.id,
      installationIsTestAccount: account.installationIsTestAccount,
      industryCategoryId: Number(account.industryCategoryId),
      isICP: account.isICP,
      projectsStartDate: account.dateSignedUp.clone().utc().format(),
      projectsIsPaid: account.isPaid,
      projectsPaymentStatus: account.paymentStatus,
      projectsTrialDaysRemaining: Number(account.trialDaysRemaining) || undefined,
      projectsPaymentMethod: account.paymentMethod,
      projectsInOwnerCompany: user.inOwnerCompany,
      projectsSiteOwner: user.siteOwner,
      projectAdministrator: user.administrator,
      projectsIsClientUser: user.isClientUser,
      projectsIsCollaborator: user.isCollaborator,
      projectsCompanyRoleId: user.companyRoleId,
      projectsFirstLogin: user.loginCount <= 1,
    },
  };

  const options = {
    hash: user.ldHash,
    bootstrap: 'localStorage',
    evaluationReasons: true,
    sendEventsOnlyForVariation: true,
  };

  return LDClient.initialize(clientId, ldUser, options);
}

const launchDarklySymbol = Symbol('useLaunchDarkly');

/**
 * Initializes a LaunchDarkly client once useCurrentAccount and useCurrentUser are initialized.
 * It currently does not reinitialize when the current account and current user change.
 */
export function provideLaunchDarkly() {
  const account = useCurrentAccount();
  const user = useCurrentUser();
  const { isBypassExperimentsQueryEnabled, isLogTrackEventsEnabled } = useTrackingDebugQuery();
  const client = shallowRef();
  const ready = shallowRef(false);
  const initialized = shallowRef(false);

  const TRACK_MAX_RETRIES = 10;
  const TRACK_RETRY_TIME_IN_MILLIS = 500;

  /**
   * Logs LaunchDarkly Event name and metadata to the console.
   *
   * @param {string} eventName Name of the LaunchDarkly key
   * @param {Object} data Object to be passed to ldClient.track
   */
  function logDebugEvent(eventName, data) {
    if (Object.entries(data).length > 0) {
      console.info(`=== LaunchDarkly: ${eventName}`, data);
    } else {
      console.info(`=== LaunchDarkly: ${eventName}`);
    }
  }

  /**
   * Internal method for tracking event in LaunchDarkly, with retry mechanism.
   * @param {string} eventName Event name
   * @param {*} [data] Additional information to associate with the event.
   * @param {number} [metricValue] An optional numeric value that can be used by the LaunchDarkly experimentation feature in numeric custom metrics.
   */
  function track(eventName, data, metricValue, retryCount = 0) {
    if (ready.value) {
      if (isLogTrackEventsEnabled.value) {
        logDebugEvent(eventName, { data, metricValue });
        return;
      }

      client.value.track(eventName, data, metricValue);
      return;
    }

    if (retryCount >= TRACK_MAX_RETRIES) {
      return;
    }

    setTimeout(() => {
      track(eventName, data, metricValue, retryCount + 1);
    }, TRACK_RETRY_TIME_IN_MILLIS);
  }

  /**
   * Method for tracking event in LaunchDarkly.
   * @param {Object} dataObject LaunchDarkly data object
   *
   * `dataObject.eventName` Event name
   *
   * `dataObject.data` Additional information to associate with the event.
   *
   * `dataObject.metricsValue` An optional numeric value that can be used by the LaunchDarkly experimentation feature in numeric custom metrics.
   *
   * `dataObject.launchDarklyFlagKey` LaunchDarkly flag key. When the key is passed, the event track action will only be sent if current user evaluation of this flag is in experiment or if it is bypassed by the `bypassExperiments` query parameter.
   *
   * `options.appLevelTargeting` Optional boolean parameter to check if user matches app level targeting. Defaults to `true` therefore if not passed, the check will be skipped.
   *
   * @param {string} dataObject.eventName
   * @param {*} [dataObject.data]
   * @param {number} [dataObject.metricsValue]
   * @param {string} [dataObject.launchDarklyFlagKey]
   * @param {boolean} [options.appLevelTargeting]
   */
  function trackLaunchDarklyEvent({ eventName, data, metricsValue, launchDarklyFlagKey, appLevelTargeting = true }) {
    if (launchDarklyFlagKey) {
      const expVariationDetail = client.value.variationDetail(launchDarklyFlagKey);
      const matchesAppLevelTargeting = unref(appLevelTargeting);

      if (
        !matchesAppLevelTargeting &&
        !expVariationDetail?.reason?.inExperiment &&
        !isBypassExperimentsQueryEnabled.value
      ) {
        return;
      }
    }

    track(eventName, data, metricsValue);
  }

  // keep as a backwards compatible method
  function ldTrackCustomEvent(eventName, data, metricsValue, launchDarklyFlagKey) {
    trackLaunchDarklyEvent({
      launchDarklyFlagKey,
      data,
      metricsValue,
      eventName,
    });
  }

  watch(
    [account, user],
    () => {
      if (!client.value && account.value && user.value) {
        client.value = createClient(account.value, user.value);
        client.value.on('ready', () => {
          ready.value = true;
        });
        client.value.on('initialized', () => {
          initialized.value = true;
        });
      }
    },
    { immediate: true },
  );

  onScopeDispose(() => {
    if (client.value) {
      client.value.close();
    }
  });

  provide(launchDarklySymbol, {
    client,
    ready,
    initialized,
    ldTrackCustomEvent,
    trackLaunchDarklyEvent,
  });
}

export function useLaunchDarkly() {
  return inject(launchDarklySymbol);
}
