import { inject, provide } from 'vue-demi';
import { providePermissions } from '@/platform/composables/usePermissions';

const symbol = Symbol('currentProject');

/**
 * Provides the current project state which `useCurrentProjectState` will return.
 */
export function provideCurrentProjectState(state) {
  provide(symbol, state);

  // Some permissions depend on the current project,
  // so we must always provide them right after providing the current project.
  // It is especially important considering that the current project might change
  // in some contexts, for example in quick views.
  //
  // An alternative design would be to never provide permissions and prepare them
  // anew every time `usePermissions` is called, however, that would not scale well,
  // as more permissions are added and the composable is used in more places.
  providePermissions();
}

/**
 * Returns the current project state provided by `provideCurrentProjectState`.
 */
export function useCurrentProjectState() {
  return inject(symbol);
}

/**
 * Returns the current project ref from `useCurrentProjectState`.
 */
export function useCurrentProject() {
  return useCurrentProjectState().item;
}
