import { v3Url } from '@/utils/fetcher';
import useItemLoader from '../base/useItemLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

const responseToItem = (response) => {
  return response.data.budgetCounts;
};

/**
 * Loads the budget counts.
 */
export default function useProjectBudgetCountsLoader() {
  const { state, refresh } = useItemLoader({
    url: v3Url('projects/budgets/counts'),
    responseToItem,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'budget') {
      refresh();
    }
  });

  return state;
}
