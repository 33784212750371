import { v1Url, v3Url } from '@/utils/fetcher';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

export default function useFileActions() {
  const api = useAxios();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId.value,
        'Triggered-By': 'user',
        'Sent-By': 'composable',
      },
    };
  }

  return {
    deleteFile(file) {
      const promise = api.delete(v3Url(`files/${file.id}`), config()).then(() =>
        emitRealTimeUpdate({
          type: 'file',
          action: 'updated',
          projectId: file.projectId,
          fileId: file.id,
        }),
      );
      emitOptimisticUpdate({
        promise,
        type: 'file',
        action: 'update',
        file,
      });
      return promise;
    },
    restoreFile(file) {
      const promise = api.put(v1Url(`trashcan/files/${file.id}/restore`), null, config()).then(() =>
        emitRealTimeUpdate({
          type: 'file',
          action: 'reopened',
          projectId: file.projectId,
          fileId: file.id,
        }),
      );
      return promise;
    },
  };
}
