import { remove as removeArr } from '@/utils/helpers/array';

/* eslint-disable consistent-return */
/**
 * The Annex is an additional storage mechanism intended for use
 * with Vuex. A reactive store is a bad place to keep promises or
 * functions. So the idea here is to use a WeakMap to link with
 * state within the store and allow for (non-persisted/volatile)
 * storage of runtime promises and functions.
 *
 * Each context object will be linked to a hash of key -> array
 *
 * Best practice is to pass the module state, or record mapped state, as the context.
 */
let annex = new WeakMap();

/**
 * @param state
 * @param keyOrHash - 'key' to get all, { key: value } to save one
 * @return {*}
 */
const funcs = (state, keyOrHash) => {
  if (typeof keyOrHash === 'string') {
    return (annex.get(state) || {})[keyOrHash] || [];
  }
  if (!annex.has(state)) {
    annex.set(state, {});
  }
  const key = Object.keys(keyOrHash)[0];
  const record = annex.get(state);
  record[key] = record[key] || [];
  record[key].push(keyOrHash[key]);
  return keyOrHash[key];
};

/**
 * @param state
 * @param keyOrHash - 'key' to remove all, { key: value } to remove one
 */
const remove = (state, keyOrHash) => {
  if (typeof keyOrHash === 'string') {
    delete (annex.get(state) || {})[keyOrHash];
  } else {
    if (!annex.has(state)) {
      return;
    }
    const key = Object.keys(keyOrHash)[0];
    const record = annex.get(state);
    record[key] = record[key] || [];
    removeArr(record[key], keyOrHash[key]);
  }
};

/**
 * @param state
 * @param keyOrHash - 'key' to get Promise.all, { key: promise }
 *                    to save one and remove it on resolve/reject
 * @return {*}
 */
const promises = (state, keyOrHash) => {
  if (typeof keyOrHash === 'string') {
    return Promise.all(funcs(state, keyOrHash));
  }
  return funcs(state, keyOrHash).finally(() => remove(state, keyOrHash));
};

const count = (state, key) => funcs(state, key).length;

// Only used for unit testing
const clear = () => {
  annex = new WeakMap();
};

export default { funcs, remove, promises, count, clear };
