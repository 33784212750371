import typedDefaults from '@/utils/helpers/typed-defaults';

const recordDefault = {
  id: 0,
  color: '',
  name: '',
  count: 0,
};

export default {
  namespaced: true,
  state: {
    records: {},
  },
  mutations: {
    records(state, categories) {
      const hash = {};
      categories.forEach((rec) => {
        const existing = state.records[rec.id];
        const category = typedDefaults(recordDefault, rec);
        hash[category.id] = existing ? { ...existing, ...category } : category;
      });
      state.records = { ...state.records, ...hash };
    },
  },
};
