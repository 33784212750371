/* eslint-disable no-param-reassign,no-return-assign */
import Vue from 'vue';
import { createRecordLoader } from '@/store/utils/loader';
import typedDefaults from '@/utils/helpers/typed-defaults';

const defaultTypes = {
  id: 0,
  name: '',
  color: '',
};

export default {
  namespaced: true,
  modules: {
    loader: createRecordLoader({
      // no id, always update if initialised
      config: {
        url: (id) => `/boards/columns/${id}.json`,
      },
    }),
  },
  state: {
    records: {},
  },
  mutations: {
    records(state, columns) {
      const newCols = {};
      columns.forEach((column) => {
        newCols[column.id] = typedDefaults(defaultTypes, column);
      });
      state.records = { ...state.records, ...newCols };
    },
    record(state, column) {
      const newCol = typedDefaults(defaultTypes, column);
      Vue.set(state.records, newCol.id, newCol);
    },
  },
};
