/* eslint-disable no-param-reassign */
import { provide, inject, ref, set, computed, watch } from 'vue-demi';
import lscache from 'lscache';
import useSavedFilters from '@sections/GenericFilter/composables/useSavedFilters';
import { useCurrentUser } from '@/api';

const FiltersSymbol = Symbol('useFilters');

function provideFilters(
  { filter, hasSavedFilters = true, oldLocalStorageKey = 'oldLocalStorageKey' },
  symbol = FiltersSymbol,
) {
  const user = useCurrentUser();
  const localStorageKey = `twProjects-filters-${filter.filterMeta.section}-userId-${user.value.id}`;
  if (!hasSavedFilters) {
    const localStorageFilter = lscache.get(localStorageKey) || lscache.get(oldLocalStorageKey);
    if (localStorageFilter) {
      const result = { ...localStorageFilter };
      const newFields = Object.keys(filter.params.value);
      const oldFields = Object.keys(localStorageFilter);

      newFields.forEach((nf) => {
        if (!oldFields.includes(nf)) {
          result[nf] = filter.params.value[nf];
        }
      });

      filter.params.value = result;
    }

    watch(
      filter.params,
      (newVal) => {
        lscache.set(localStorageKey, newVal);
      },
      { deep: true },
    );
  }

  function clearAdvanced() {
    filter.clearAdvanced();
    if (!hasSavedFilters) {
      lscache.set(localStorageKey, filter.params.value);
    }
  }

  function clearAll() {
    filter.params.value = { ...filter.defaultParams };
    if (!hasSavedFilters) {
      lscache.set(localStorageKey, filter.params.value);
    }
  }

  const savedFilters = hasSavedFilters
    ? useSavedFilters(
        { ...filter.defaultParams },
        { ...filter.filterMeta },
        filter.params,
        filter.paramNameMapping,
        filter.defaultFiltersNameMapping,
        filter.fulldataParamMapping,
        clearAdvanced,
      )
    : {};

  const removeChip = (id, type) => {
    filter.params.value[type] = filter.params.value[type].filter((item) => item.id !== id);
  };

  provide(symbol, {
    ...filter,
    hasSavedFilters,
    ...savedFilters,
    isLoadingSavedFilters: computed(() => {
      return savedFilters.loading?.value || false;
    }),
    params: filter.params || ref({}),
    activeNumber: filter.activeNumber || computed(() => 0),
    clearAdvanced: hasSavedFilters ? savedFilters.clearSavedFilter : clearAdvanced,
    clearAll,
    suggestedFilters: filter.suggestedFilters || [],
    chips: filter.chips || [],
    removeChip: filter.removeChip || removeChip,
    learnMore: filter.learnMore,
  });
  return {
    savedFilters,
  };
}

function useFilters(symbol) {
  return inject(symbol || FiltersSymbol);
}

function useFilterParam(key, defaultValue, symbol = FiltersSymbol) {
  const { params } = useFilters(symbol);
  // this is needed because of how vue 2 reactive
  // system works, but not required with vue 3
  if (typeof params.value[key] === 'undefined') {
    set(params.value, key, defaultValue);
  }

  if (defaultValue) {
    params.value[key] = defaultValue;
  }

  return computed({
    get: () => params.value[key],

    set(value) {
      params.value[key] = value;
    },
  });
}

export { FiltersSymbol, provideFilters, useFilters, useFilterParam };

// Documentation for creating filters:
// https://digitalcrew.teamwork.com/spaces/teamwork-projects-front-end-infrastructure/page/27783-creating-a-new-filter-in-pwa-vue
