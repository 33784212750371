import moment from 'moment-timezone';
import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  const { milestones, included } = response.data;

  return milestones.map((milestone) => {
    const assignedTo = milestone.responsibleParties.map(({ id, type }) => included[type][id]);

    return {
      ...milestone,
      deadline: moment(milestone.deadline),
      assignedTo,
      canEdit: false,
    };
  });
}

/**
 * Loads a list of milestones from the Teamwork v3 API.
 */
export default function useProjectMilestonesLoader({
  /**
   * The id of project to loader the project milestones from.
   */
  projectId,
  params,
}) {
  const { state, refresh } = useListLoader({
    url: v3Url(`projects/${projectId}/milestones`),
    params: {
      ...params,
      include: 'users',
    },
    count: 50,
    pageSize: 50,
    responseToItems,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'milestone') {
      refresh();
    }
  });

  return state;
}
