/* eslint-disable no-param-reassign */
// Similar to the v-focus directive from the Vue docs,
// this behaviour matches the knockout hasFocus binding though
export default {
  inserted(el, { modifiers, expression, value }, { context }) {
    if (modifiers.first) {
      if (!expression || value) {
        el.focus();
      }
    }
    const setVal = (val) => {
      if (context && context[expression] !== undefined) {
        context[expression] = val;
      }
    };

    if (modifiers.listen) {
      el.addEventListener('focus', () => setVal(true));
      el.addEventListener('blur', () => setVal(false));
    }
  },
  updated(el, { value, oldValue, modifiers }) {
    if (modifiers.first) {
      return;
    }
    if (oldValue !== value) {
      el[value ? 'focus' : 'blur']();
    }
  },
};
