export default {
  namespaced: true,
  state: {
    tkoCurrentRoute: {},
  },
  mutations: {
    // Maps tko app.currentRoute data to Vue store
    // if we transfer this meta over to vue router it will not work as expected.
    // due to the delay in KO rendering,
    // Vue meta will update before the TKO route has fully changed
    tkoCurrentRoute(state, payload) {
      state.tkoCurrentRoute = { ...payload };
    },
  },
};
