import tkoModal from '@/scaffolding/tko-modal';

export default {
  namespaced: true,
  modules: {
    ...tkoModal,
  },
  state: {
    modals: [],
  },
  getters: {
    active: (state) => state.modals.slice(-1)[0],
  },
  mutations: {
    open(state, { name, props = {} } = {}) {
      state.modals = [...state.modals, { name, props, ref: `${name}-${state.modals.length}` }];
    },
    close(state) {
      // Close the topmost modal
      state.modals.splice(-1, 1);
    },
    reset(state) {
      // Reset modal array to empty
      state.modals = [];
    },
  },
  actions: {
    open({ commit, getters: { active } }, { name, props } = {}) {
      if (!name || (active && active.name === name)) {
        return;
      }
      commit('open', { name, props });
    },
    close({ commit }) {
      commit('close');
    },
    reset: ({ commit }) => commit('reset'),
  },
};
