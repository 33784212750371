import task from './task';
import tasklist from './tasklist';
import tasklists from './tasklists';
import milestone from './milestone';
import project from './project';
import person from './person';
import clockin from './clockin';
import gapps from './gapps';
import integration from './integration';
import column from './column';
import card from './card';
import tags from './tags';
import board from './board';
import importer from './importer';
import dataview from './dataview';

export default {
  task,
  tasklist,
  tasklists,
  milestone,
  project,
  person,
  clockin,
  gapps,
  integration,
  column,
  card,
  tags,
  board,
  finished: importer,
  dataview,
};
