import { passive } from '@/utils/helpers/promise';

// Convenience watcher which waits for a check to be true and fires callback,
// then the watch is removed, i.e. it only fires once.
export default function when(check, callback) {
  const promise = passive();

  let unwatch = null;
  unwatch = this.watch(
    check,
    (checkPasses) => {
      if (checkPasses) {
        if (callback) {
          callback();
        }
        promise.resolve();
        // defer the unwatch in case it is called during immediate
        Promise.resolve().then(() => unwatch());
      }
    },
    { immediate: true },
  );
  return promise;
}
