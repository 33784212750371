import { inject, provide } from 'vue-demi';

const currentAccountSymbol = Symbol('currentAccount');

/**
 * Provides the currentAccount ref which `useCurrentAccount` will return.
 */
export function provideCurrentAccount(currentAccount) {
  provide(currentAccountSymbol, currentAccount);
}

/**
 * Returns the currentAccount ref provided by `provideCurrentAccount`.
 */
export function useCurrentAccount() {
  return inject(currentAccountSymbol);
}
