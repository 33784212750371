import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  const items =
    response.data.budgetUsages?.map(({ budgetSummary, usageTimeline = [] }) => ({
      id: Number(budgetSummary.id),
      projectId: Number(budgetSummary.projectId),
      timeline: usageTimeline,
      usages: { targetProfit: budgetSummary.budgetExpectedProfit, targetCost: budgetSummary.budgetExpectedCost },
    })) ?? [];
  return items;
}

export default function useProjectBudgetUsagesLoader({ params, count, pageSize = 50 }) {
  const { state, refresh } = useListLoader({
    url: v3Url('projects/budgets/usages'),
    params,
    count,
    responseToItems,
    pageSize,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'budget') {
      refresh(event.budgetId);
      return;
    }
    if (event.type === 'tasklistBudget' || event.type === 'budgetExpense') {
      refresh(event.projectBudgetId);
      return;
    }
    if (event.type === 'time' || (event.type === 'project' && event.action === 'rate-set')) {
      const budget = state.items.value.find(({ projectId }) => projectId === event.projectId);
      if (budget) {
        refresh(budget.id);
      }
      return;
    }
    if (event.type === 'installation' && event.action === 'rate-set') {
      refresh();
    }
  });

  return state;
}
