import api from './helpers/api';

function paginate({ headers }) {
  return {
    page: parseInt(headers['x-page'], 10),
    totalRecords: parseInt(headers['x-records'], 10),
    hasMore: parseInt(headers['x-page'], 10) < parseInt(headers['x-pages'], 10),
  };
}

export default async (endpoint, params, normalize = (data) => data, options = {}) => {
  const resp = await api.get(`/${endpoint}.json`, { params, ...options });
  if (resp.data.STATUS === 'OK') {
    return {
      ...normalize(resp),
      pagination: paginate(resp),
    };
  }

  return undefined;
};
