import { useAxiosResponseInterceptor } from '@/api';
import { report401 } from '@/scaffolding/tko-lightspeed';

export default function useErrorHandler() {
  useAxiosResponseInterceptor(null, (error) => {
    const url = error.request?.url || '';

    if (
      url.includes('synthesis/') ||
      url.includes('integrations/') ||
      url.includes('chat/') ||
      url.includes('spaces/')
    ) {
      return Promise.reject(error);
    }

    // Ignore cached responses
    if (error.config?.cache === 'only-if-cached') {
      return Promise.reject(error);
    }

    if (error.response?.status === 401) {
      report401();
    }

    return Promise.reject(error);
  });
}
