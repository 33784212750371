/* eslint-disable no-param-reassign */
import lscache from 'lscache';
import { typecast } from '@/utils/helpers/typed-defaults';

const fixedPrefix = 'twProjects-';

const defOpts = {
  record: {
    records: (state) => state.records,
    mutation: 'record',
    id: 'id',
    prefix: (id) => `replaceme-${id}`,
    formatter: null, // (id, field) => `replaceme-${id}-${field}`,
    fields: {},
    expiries: {},
  },
  singleton: {
    record: (state) => state,
    mutation: 'record',
    prefix: '',
    fields: {},
    expiries: {},
  },
};

/**
 * Create a localStorage record from all the configured fields
 * using defaults and casting/mapping rules.
 */
const buildRecord = (record, fields, cacheMap) =>
  Object.keys(fields).reduce((rec, field) => {
    const def = typeof fields[field] === 'object' ? fields[field].default : fields[field];
    const name = (fields[field] && fields[field].name) || field;
    const valStr = lscache.get(cacheMap(field));
    const val = valStr != null ? typecast(valStr, def) : def;
    return { ...rec, [name]: val };
  }, {});

/**
 * Enable reactivity to persist localStorage fields
 */
const watchMutations = (store, record, fields, expiries, cacheMap) => {
  Object.keys(fields).forEach((field) => {
    const storeField = (fields[field] && fields[field].name) || field;
    // Black magic! Reactivity works off registering an access,
    // as we already have a reference to this record object,
    // rather than figuring out how to access it from the root state,
    // we can access it directly within the watch getter. Same thing.
    store.watch(
      () => record[storeField],
      () => {
        lscache.set(cacheMap(field), record[storeField], expiries[field]);
      },
    );
  });
};

/**
 * Generates a Watcher which will enrich localStorage data in the
 * context of an Record Loader
 * @param opts
 * @returns {{getter, callback: (function(*=, *=))}}
 */
export const lsRecordsSyncer = (opts) => {
  opts = { ...defOpts.record, ...opts };
  const first = Object.keys(opts.fields)[0];
  const storeFirst = (opts.fields[first] && opts.fields[first].name) || first;
  return {
    getter: opts.records,
    callback: (store, records) => {
      Object.keys(records).forEach((id) => {
        const record = records[id];
        // is it missing the first field? a sign it is uninitialised
        if (record[storeFirst] === undefined) {
          // Create the localStorage record
          const cacheMap = (field) =>
            fixedPrefix + (opts.formatter ? opts.formatter(id, field) : opts.prefix(id) + field);
          const lsrecord = buildRecord(record, opts.fields, cacheMap);
          // Save it to the record
          store.commit(opts.mutation, {
            [opts.id]: parseInt(id, 10),
            payload: lsrecord,
          });
          watchMutations(store, record, opts.fields, opts.expiries, cacheMap);
        }
      });
    },
    // a synchronous watch will avoid waiting for nextTick,
    // means we avoid a double update in the UI. The negative
    // here is that without queueing, this may fire multiple
    // times within a single tick.
    options: { sync: true },
  };
};

/**
 * Generates a Watcher to enrich localStorage data for any
 * Store module (singleton)
 * @param opts
 * @returns {{getter: (function(*): *), callback: (function(*=, *=)), options: (object)}}
 */
export const lsSyncer = (opts) => {
  opts = { ...defOpts.singleton, ...opts };
  const first = Object.keys(opts.fields)[0];
  const storeFirst = (opts.fields[first] && opts.fields[first].name) || first;
  return {
    getter: opts.record,
    callback: (store, record) => {
      // is it missing the first field? a sign it is uninitialised
      if (record[storeFirst] === undefined) {
        // Create the localStorage record
        const cacheMap = (field) => fixedPrefix + opts.prefix + field;
        const lsrecord = buildRecord(record, opts.fields, cacheMap);
        // Save it to the record
        store.commit(opts.mutation, lsrecord);
        watchMutations(store, record, opts.fields, opts.expiries, cacheMap);
      }
    },
    options: { immediate: true, once: true },
  };
};
