// Proxies a real promise, once the real promise
// resolves, this object provides synchronous inspection
// (like http://bluebirdjs.com/docs/api/synchronous-inspection.html)
export const sync = (promise) => {
  let resolved;
  let rejected;
  let finished = false;

  const wrapped = new Promise((resolver, rejector) =>
    // TODO ask Greg about this
    // eslint-disable-next-line no-promise-executor-return
    promise.then(
      (output) => {
        resolved = output;
        finished = true;
        resolver(output);
      },
      (reason) => {
        rejected = reason;
        finished = true;
        rejector(reason);
      },
    ),
  );
  wrapped.isFinished = () => finished;
  wrapped.resolved = () => resolved;
  wrapped.rejected = () => rejected;
  return wrapped;
};

// A passive promise allows callbacks to be added
// long before the resolution condition has been set,
// then at some future time, the surrogate can be
// placed after another promise, or resolved/rejected
// directly (like Promise static functions) to fire
// the callbacks.
export const passive = () => {
  let resolver;
  let rejector;

  const promise = new Promise((resolve, reject) => {
    resolver = resolve;
    rejector = reject;
  });
  promise.after = (otherPromise) => {
    otherPromise.then(resolver, rejector);
  };
  // Note these are chainable functions
  promise.resolve = (output) => {
    resolver(output);
    return promise;
  };
  promise.reject = (reason) => {
    rejector(reason);
    return promise;
  };
  return promise;
};

// Async function version of https://lodash.com/docs/4.17.4#debounce
// Don't forget https://css-tricks.com/debouncing-throttling-explained-examples/
export const debounce = (func, wait = 0, options = { leading: true, trailing: true }) => {
  let trailing = null;
  let promise = null;
  let timeout = null;
  let passve = null;
  let args = [];

  const fire = () => sync(func(...args));

  const later = () => {
    // Not resolved yet, keep waiting
    if (promise && !promise.isFinished()) {
      timeout = setTimeout(later, wait);
    } else if (trailing) {
      // resolved, but need a trailing execution
      trailing = null;
      promise = fire();
      timeout = setTimeout(later, wait);
    } else {
      passve.after(promise);
      trailing = null;
      promise = null;
      passve = null;
    }
  };

  return (...params) => {
    args = params;
    if (!promise && options.leading) {
      promise = fire();
    } else {
      trailing = options.trailing;
      if (timeout) {
        clearTimeout(timeout);
      }
    }
    timeout = setTimeout(later, wait);
    if (!passve) {
      passve = passive();
    }
    return passve;
  };
};
