import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    activeModal: null,
    loading: false,
  },
  mutations: {
    open(state, { name, props = {} } = {}) {
      Vue.set(state, 'activeModal', { name, props });
    },
    close(state) {
      Vue.set(state, 'activeModal', null);
    },
    isLoading(state, status = false) {
      Vue.set(state, 'loading', status);
    },
  },
  actions: {
    open({ commit, state: { activeModal } }, { name, props } = {}) {
      if (!name || (activeModal && activeModal.name === name)) {
        return;
      }
      commit('isLoading', true);
      commit('open', { name, props });
    },
    close({ commit }) {
      commit('isLoading', false);
      commit('close');
    },
    isLoading({ commit }, status) {
      commit('isLoading', status);
    },
  },
};
