export default [
  {
    path: '/home',
    component: () => import('./RouteHome'),
    children: [
      {
        path: '',
        redirect() {
          return `/home/${window.localStorage.getItem('lscache-twProjects-last-home-section') || 'work'}`;
        },
      },
      {
        path: 'work',
        component: () => import('./RouteHomeWork'),
        meta: {
          noSpacing: true,
        },
      },
      {
        path: 'calendar',
        component: () => import('@sections/Idle'),
      },
      {
        path: 'projects',
        component: () => import('./RouteHomeProjects'),
      },
      {
        path: 'activity',
        component: () => import('./RouteHomeActivity'),
      },
      {
        path: 'events',
        component: () => import('./RouteHomeEvents'),
      },
      {
        path: 'dashboards',
        component: () => import('./RouteHomeDashboards'),
      },
      {
        path: 'inbox',
        component: () => {
          return import('./RouteHomeInbox');
        },
        meta: {
          noSpacing: true,
        },
      },
      {
        path: 'planner',
        component: 'planning-workload',
        meta: {
          permission: {
            id: 'canAccessWorkloadPlannerTab',
            redirect: '/planning/chart',
          },
          noSpacing: true,
          name: 'workload',
          pageName: 'Workload',
          isPlanningPage: true, // temporary TKO hack
          canViewFullScreen: true,
          menuPermission: 'canAccessWorkloadPlannerTab',
        },
      },
      {
        path: 'messages',
        component: () => import('./RouteHomeMessages'),
      },
      {
        path: 'comments',
        component: () => import('./RouteHomeComments'),
      },
      {
        path: 'shortcuts',
        component: () => import('./RouteHomeShortcuts'),
      },
      {
        path: '*',
        redirect: '/home',
      },
    ],
  },
];
