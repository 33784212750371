// Constants flagged as public are expected to be called without
// the constant, therefore the value is more human-readable.
export const STATUS = {
  uninit: 0,
  loading: 1,
  loaded: 2,
  stale: 3,
  updating: 4,
  error: 5,
};

export const GETTERS = {
  STATUS_READY: 'isReady', // public
  UNINIT: 'loader-uninit',
  UPDATING: 'loader-updating',
  WORKING: 'isWorking', // public
  LIST: 'list', // public
  PAGINATION: {
    URL_PARAMS: 'pagination-url-params',
    HAS_MORE: 'hasMore', // public
    PAGES: 'pages', // public
    LOADED_PERC: 'percentLoaded', // public
    TOTAL_RECORDS: 'totalRecords', // public
  },
};

export const MUTATIONS = {
  LOADING: 'loader-loading',
  UPDATING: 'loader-updating',
  HEADERS: 'loader-headers',
  LOADED: 'loader-loaded',
  LOADED_ERROR: 'loader-loaded-error',
  INIT: 'loader-initialize',
  STALE: 'loader-stale',
  PARAMS: 'loader-params',
  ADDITIVE: 'loader-additive',
  LIST: 'loader-list',
  PAGINATION: {
    LOADED: 'loader-pagination',
    INIT: 'pagination-initialize',
    NEXT_PAGE: 'pagination-next-page',
    NEXT_TOKEN: 'pagination-next-token',
  },
};

export const ACTIONS = {
  ACCESS: 'access', // public
  RELEASE: 'release', // public
  BEFORE_LOAD: 'loader-before-load',
  LOAD: 'loader-load',
  LOAD_WAIT: 'loader-load-wait',
  RELOAD: 'reload', // public
  RESET: 'loadReset', // public
  BEFORE_LOAD_UPDATES: 'loader-before-load-updates',
  LOAD_UPDATES: 'loader-load-updates',
  LOAD_CANCEL: 'cancelRequests', // public
  LOADED_ERROR: 'loader-loaded-error',
  LOADED_SUCCESS: 'loader-loaded-success',
  LOADED_RECORD: 'loader-loaded-record',
  LOADED_LIST: 'loader-loaded-list',
  LOADED_INCLUDED: 'loader-loaded-included',
  LOADED_HEADERS: 'loader-loaded-headers',
  DATA_CHANGE: 'changeNotification', // public
  PARAM_CHANGE: 'loader-param-change',
  LOADED_PAGINATION: 'loader-loaded-pagination',
  PAGINATION: {
    LOAD_NEXT: 'loadMore', // public
    LOAD_ALL: 'pagination-load-all',
  },
  FILTERED: {
    SWITCH: 'loader-filtered-switch',
    INIT: 'loader-filtered-init',
  },
};
