export default [
  {
    path: '/people/:userId(\\d+)',
    component: () => import('./RouteUser.vue'),
    children: [
      {
        path: 'integrations/:category(.+)?',
        component: () => import('./RouteUserIntegrations.vue'),
      },
      {
        path: '*',
        component: () => import('@sections/TkoComponent/TkoComponent.vue'),
        props: {
          name: 'page-person',
        },
      },
    ],
  },
];
