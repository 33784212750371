import Vue from 'vue';
import { ACTIONS } from '@/store/utils/loader';
import api from '@/services/api';
import annex from '@/services/vuex-annex';
import router from '@/router';
import { mapActions } from '@/store/utils/record-mapper';

export default {
  current({ commit, dispatch }, id) {
    const change = () => commit('current', id);
    return id ? dispatch(ACTIONS.ACCESS, id).then(change) : change();
  },
  skipWeekends: async ({ commit, state }) => {
    const currentProjectId = state.currentProjectId;
    const skipWeekendsValue = !state.records[currentProjectId].skipWeekends;
    commit('skipWeekends', skipWeekendsValue);
    await api.put(`projects/${state.currentProjectId}.json`, {
      project: {
        skipWeekends: skipWeekendsValue,
      },
    });
    // TODO Hybrid - note the TKO process here will in turn trigger Vue to update
    // as part of the flow. Therefore when this is removed, it should be replaced with a
    // Vue call to update the project record
    window.app.projectInfo.update(currentProjectId);
  },
  ...mapActions({
    unarchive({ commit, dispatch, rootGetters, id }) {
      return (
        api
          .put(`/projects/${id}/archive.json`, { status: 'active' })
          // eslint-disable-next-line consistent-return
          .then((rs) => {
            // Temporarily check for both STATUS and status until https://github.com/Teamwork/project-manager/pull/12369 is merged
            if (rs.data.STATUS === 'OK' || rs.data.status === 'OK') {
              commit('activate');
              if (rs.data.usersNotAdded) {
                if (rs.data.usersNotAdded.length === 1) {
                  dispatch(
                    'notifications/flashes/info',
                    {
                      title: Vue.t('A client user was removed as they exceeded their project limit'),
                    },
                    { root: true, recordMap: false },
                  );
                } else if (rs.data.usersNotAdded.length > 1) {
                  dispatch(
                    'notifications/flashes/info',
                    {
                      title: Vue.t(
                        '[0] client users were removed as they exceeded their project limits',
                        rs.data.usersNotAdded.length,
                      ),
                    },
                    { root: true, recordMap: false },
                  );
                }
              }
              return dispatch(ACTIONS.DATA_CHANGE); // app.UpdateProjectAndPermissions
            }
            if (rs.data.STATUS === 'UPGRADE_REQUIRED') {
              if (rootGetters['user/isSiteAdmin']) {
                dispatch(
                  'notifications/flashes/error',
                  {
                    title: Vue.t('Oops, upgrade required!'),
                    msg: Vue.t(
                      'You have already reached the maximum number of projects allowed with your current price plan',
                    ),
                  },
                  { root: true, recordMap: false },
                );
                router.push('/settings/subscription');
              } else {
                dispatch(
                  'notifications/flashes/error',
                  {
                    title: Vue.t('Project limit reached'),
                    msg: Vue.t('Please contact the site owner'),
                  },
                  { root: true, recordMap: false },
                );
              }
            }
          })
      );
    },
    archive({ dispatch, commit, id }) {
      const title = Vue.t('Archive this Project?');
      const text = Vue.t('Are you sure you want to archive this project?');
      return dispatch(
        'modals/tko/open',
        {
          id: 'confirm',
          args: {
            title,
            text,
            callback: (result) => {
              if (result.OK) {
                api
                  .put(`/projects/${id}/archive.json`, { status: 'inactive' })
                  // eslint-disable-next-line consistent-return
                  .then((rs) => {
                    // Temporarily check for both STATUS and status until https://github.com/Teamwork/project-manager/pull/12369 is merged
                    if (rs.data.STATUS === 'OK' || rs.data.status === 'OK') {
                      commit('archive');
                      return dispatch(ACTIONS.DATA_CHANGE); // app.UpdateProjectAndPermissions
                    }
                  });
              }
            },
          },
        },
        { root: true, recordMap: false },
      );
    },
    star({ state, commit, dispatch, id }) {
      commit('star');
      // We tracking 'working' API requests, as there are
      // watchers of the above mutation that should wait for
      // working to finish too :)
      const working = api
        .put(`/projects/${id}/star.json`, {})
        // eslint-disable-next-line consistent-return
        .then((rs) => {
          if (rs.data.STATUS !== 'OK') {
            commit('unstar');
            return dispatch('notifications/flashes/errorExclaim', {}, { root: true, recordMap: false });
          }
        });
      return annex.promises(state, { working });
    },
    unstar({ state, commit, dispatch, id }) {
      commit('unstar');
      // We tracking 'working' API requests, as there are
      // watchers of the above mutation that should wait for
      // working to finish too :)
      const working = api
        .put(`/projects/${id}/unstar.json`, {})
        // eslint-disable-next-line consistent-return
        .then((rs) => {
          if (rs.data.STATUS !== 'OK') {
            commit('star');
            return dispatch('notifications/flashes/errorExclaim', {}, { root: true, recordMap: false });
          }
        });
      return annex.promises(state, { working });
    },
    complete({ commit, dispatch, id }) {
      return api.put(`/projects/${id}/complete.json`, {}).then(() => {
        dispatch('notifications/flashes/success', Vue.t('This project has been marked complete'), {
          root: true,
          recordMap: false,
        });
        commit('completed');
      });
    },
    uncomplete({ dispatch, commit, getters, id }) {
      return api.put(`/projects/${id}/uncomplete.json`, {}).then(() => {
        dispatch('notifications/flashes/success', Vue.t('This project has been marked incomplete'), {
          root: true,
          recordMap: false,
        });
        commit('uncompleted', getters.subStatusFromDates(id));
      });
    },
    delete({ dispatch, commit, id, rootState }, payload) {
      const currentProject = rootState.project.currentProjectId;
      const title = Vue.t('Confirm Delete?');
      const text = Vue.t(
        'This project will be moved to the trash can. After [0] days this project and all attached files will be completely removed.',
        '30',
      );
      return dispatch(
        'modals/tko/open',
        {
          id: 'confirm',
          args: {
            title,
            text,
            callback: (result) => {
              if (result.OK) {
                api.delete(`/projects/${id}.json`).then(() => {
                  commit('deleted');
                  dispatch('notifications/flashes/success', Vue.t('Project Deleted'), {
                    root: true,
                    recordMap: false,
                  }).then(() => {
                    commit('deleteSuccessful');
                  });
                  if (currentProject > 0) {
                    window.app.GoTo('#');
                  }
                  if (payload && payload.updateSampleProjectPref) {
                    const { updateSampleProjectPref } = payload;
                    updateSampleProjectPref();
                  }
                });
              }
            },
          },
        },
        { root: true, recordMap: false },
      );
    },
  }),
};
