/* eslint-disable no-param-reassign */
/**
 * Can service - permissions checking
 *
 * eg.
 * rule = [{
 *   type: 'project',
 *   action: ['delete'],
 *   rule: (item, { getters, state }) => getters.conditions && state.values,
 * }];
 * <div v-if="$can('delete', 'project')">Example</div>
 */
const formatRules = (options) =>
  options.reduce(
    (ruleSet, { type, action, rule }) => ({
      ...ruleSet,
      [type]: {
        ...ruleSet[type],
        ...action.reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: rule,
          }),
          {},
        ),
      },
    }),
    {},
  );

let store;
let ruleSet = {};

const loadRules = (rules) => {
  ruleSet = formatRules(rules);
};

const registerStore = (storeToRegister) => {
  store = storeToRegister;
};

const can = (access, type, item) =>
  ruleSet[type] && ruleSet[type][access] ? ruleSet[type][access](item, store) : true; // return true as default if rule not set.

const plugin = {
  install(Vue, options) {
    loadRules(options);
    Vue.prototype.$can = can;
  },
};

Object.assign(can, { loadRules, registerStore, plugin });
export default can;
