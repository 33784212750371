import { v3Url } from '@/utils/fetcher';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

const { hasOwnProperty } = Object.prototype;

function toApiTimelog(timelogPayload) {
  const timelog = {};
  const tags = [];
  const timelogOptions = {};

  if (hasOwnProperty.call(timelogPayload, 'date')) {
    timelog.date = String(timelogPayload.date);
  }

  if (hasOwnProperty.call(timelogPayload, 'description')) {
    timelog.description = timelogPayload.description;
  }

  if (hasOwnProperty.call(timelogPayload, 'hasStartTime')) {
    timelog.hasStartTime = Boolean(timelogPayload.hasStartTime);
  }

  if (hasOwnProperty.call(timelogPayload, 'hours')) {
    timelog.hours = Number(timelogPayload.hours);
  }

  if (hasOwnProperty.call(timelogPayload, 'isBillable')) {
    timelog.isBillable = Boolean(timelogPayload.isBillable);
  } else if (hasOwnProperty.call(timelogPayload, 'billable')) {
    timelog.isBillable = Boolean(timelogPayload.billable);
  }

  if (hasOwnProperty.call(timelogPayload, 'isUtc')) {
    timelog.isUtc = Boolean(timelogPayload.isUtc);
  }

  if (hasOwnProperty.call(timelogPayload, 'minutes')) {
    timelog.minutes = Number(timelogPayload.minutes);
  }

  if (hasOwnProperty.call(timelogPayload, 'projectId')) {
    timelog.projectId = Number(timelogPayload.projectId);
  }

  if (hasOwnProperty.call(timelogPayload, 'taskId')) {
    timelog.taskId = Number(timelogPayload.taskId);
  }

  if (hasOwnProperty.call(timelogPayload, 'time')) {
    timelog.time = String(timelogPayload.time);
  }

  if (hasOwnProperty.call(timelogPayload, 'userId')) {
    timelog.userId = Number(timelogPayload.userId);
  }

  // tags
  if (hasOwnProperty.call(timelogPayload, 'tags')) {
    const tagIds = [];
    timelogPayload.tags.forEach((tag) => {
      tagIds.push(tag.id);
      tags.push({
        color: tag.color,
        name: tag.name,
        projectId: tag.projectId,
      });
    });
    timelog.tagIds = tagIds;
  }

  // timelogOptions
  if (hasOwnProperty.call(timelogPayload, 'fireWebhook')) {
    timelogOptions.fireWebhook = Boolean(timelogPayload.fireWebhook);
  }

  if (hasOwnProperty.call(timelogPayload, 'logActivity')) {
    timelogOptions.logActivity = Boolean(timelogPayload.logActivity);
  }

  if (hasOwnProperty.call(timelogPayload, 'markTaskComplete')) {
    timelogOptions.markTaskComplete = Boolean(timelogPayload.markTaskComplete);
  }

  if (hasOwnProperty.call(timelogPayload, 'parseInlineTags')) {
    timelogOptions.parseInlineTags = Boolean(timelogPayload.parseInlineTags);
  }

  if (hasOwnProperty.call(timelogPayload, 'useNotifyViaTWIM')) {
    timelogOptions.useNotifyViaTWIM = Boolean(timelogPayload.useNotifyViaTWIM);
  }

  return {
    timelog,
    tags,
    timelogOptions,
  };
}

export default function useTimelogActions() {
  const api = useAxios();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: _emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  function emitOptimisticUpdate(promise, action, timelog) {
    _emitOptimisticUpdate({ promise, type: 'time', action, timelog });
  }

  function emitRealTimeUpdate(action, newTimelog, oldTimelog) {
    _emitRealTimeUpdate({
      type: 'time',
      action,
      timeId: newTimelog.id ?? oldTimelog.id,
      projectId: newTimelog.projectId ?? oldTimelog.projectId,
      taskId: newTimelog.taskId ?? oldTimelog.taskId,
    });
  }

  function config() {
    return {
      headers: {
        'Socket-ID': socketId.value,
        'Triggered-By': 'user',
        'Sent-By': 'composable',
      },
    };
  }

  return {
    createTimelog(timelog) {
      let url;
      if (timelog.taskId) {
        url = v3Url(`tasks/${timelog.taskId}/time`);
      } else if (timelog.projectId) {
        url = v3Url(`projects/${timelog.projectId}/time`);
      } else {
        throw new Error('Missing taskId or projectId on timelog');
      }

      const promise = api.post(url, toApiTimelog(timelog), config()).then((response) => {
        const createdTimelog = response.data.timelog;
        emitRealTimeUpdate('created', createdTimelog);
        return createdTimelog;
      });

      emitOptimisticUpdate(promise, 'create', timelog);

      return promise;
    },
    updateTimelog(timelog, oldTimelog) {
      const url = v3Url(`/time/${timelog.id}`);
      const promise = api.patch(url, toApiTimelog(timelog), config()).then((response) => {
        const updatedTimelog = response.data.timelog;
        emitRealTimeUpdate('updated', updatedTimelog, oldTimelog);
        return updatedTimelog;
      });

      emitOptimisticUpdate(promise, 'update', timelog);

      return promise;
    },
    deleteTimelog(timelog) {
      const url = v3Url(`/time/${timelog.id}`);
      const promise = api.delete(url, config()).then(() => {
        emitRealTimeUpdate('delete', timelog);
      });

      emitOptimisticUpdate(promise, 'delete', timelog);

      return promise;
    },
  };
}
