const initialState = () => ({
  plan: {},
  paidUsers: [],
  clientUsers: [],
  companies: [],
  freeUsersCount: 0,
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    plan(state, x) {
      state.plan = x;
    },
    paidUsers(state, x) {
      state.paidUsers = x;
    },
    clientUsers(state, x) {
      state.clientUsers = x;
    },
    companies(state, x) {
      state.companies = x;
    },
    freeUsersCount(state, x) {
      state.freeUsersCount = x;
    },
    init(state) {
      const s = initialState();
      Object.assign(state, s);
    },
  },
};
