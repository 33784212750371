import Vue from 'vue';
import typedDefaults from '@/utils/helpers/typed-defaults';
import { defaultTypes } from './recordDefault';

export default {
  namespaced: true,
  modules: {},
  state: {
    records: {},
  },
  mutations: {
    records(state, countries) {
      const hash = {};
      countries.forEach((country) => {
        const defsApplied = typedDefaults(defaultTypes, country);
        // First time saving the record, add all the defaults too.
        hash[country.code] = {
          ...(state.records[country.code] || defaultTypes),
          ...defsApplied,
        };
      });
      state.records = { ...state.records, ...hash };
    },
    record(state, country) {
      const defsApplied = typedDefaults(defaultTypes, country);
      // First time saving the record, add all the defaults too.
      const newRec = {
        ...(state.records[country.code] || defaultTypes),
        ...defsApplied,
      };
      Vue.set(state.records, newRec.code, newRec);
    },
  },
};
