export default [
  {
    path: '/teams/:teamId(\\d+)',
    component: () => import('./RouteTeam.vue'),
    children: [
      {
        path: '*',
        component: () => import('@sections/TkoComponent/TkoComponent.vue'),
        props: {
          name: 'page-team',
        },
      },
    ],
  },
];
