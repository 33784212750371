/* eslint-disable consistent-return */
import Vue from 'vue';
import moment from 'moment';
import { mapActions } from '@/store/utils/record-mapper';
import TaskListsAPI from '@/platform/data/tasklists';
import api from '@/services/api';

export default {
  collapseAllForProject({ state, commit }, pid) {
    Object.values(state.records)
      .filter(({ projectId, collapsed }) => projectId === pid && !collapsed)
      .forEach(({ id }) => commit('toggleCollapsed', id));
  },
  expandAllForProject({ state, commit }, pid) {
    Object.values(state.records)
      .filter(({ projectId, collapsed }) => projectId === pid && collapsed)
      .forEach(({ id }) => commit('toggleCollapsed', id));
  },
  async getTaskLists({ commit }, id) {
    const params = {
      status: 'all',
    };
    try {
      const { taskLists } = await TaskListsAPI.fetchAllByProject(id, params);
      commit('setAllTaskLists', taskLists);
    } catch (error) {
      throw new Error('There was an unexpected error', error);
    }
  },
  ...mapActions({
    toggleCollapsed({ commit }) {
      commit('toggleCollapsed');
    },
    changeName({ commit, dispatch, state, id }, newName) {
      if (!newName) {
        dispatch('notifications/flashes/error', Vue.t('The tasklist name cannot be blank'), {
          root: true,
          recordMap: false,
        });
        return;
      }
      const oldName = state.name;
      commit('changeName', newName);
      return api
        .put(`/tasklists/${id}.json`, {
          'todo-list': { name: newName },
        })
        .catch((e) => {
          commit('changeName', oldName);
          throw e;
        });
    },
    // Local reset of the 'order' property to match the list here. Useful when
    // order needs to be interpreted from task records directly.
    addTask: ({ state, getters, commit }, { id, positionAfterTask }) => {
      const list = getters
        .tasks(state.id)
        .filter((t) => t.id !== id)
        .map((t) => t.id);
      list.splice(list.indexOf(positionAfterTask) + 1, 0, id);
      list.forEach((tid, index) => {
        commit('task/orderTask', { id: tid, payload: index + 1 }, { recordMap: false, root: true });
      });
    },
    reposition({ commit, dispatch, getters, id }, position) {
      if (!position) {
        dispatch('notifications/flashes/error', Vue.t('The tasklist position cannot be 0 or blank'), {
          root: true,
          recordMap: false,
        });
        return;
      }
      const oldPosition = getters.position(id);
      commit('reposition', { id, position }, { recordMap: false });
      return api
        .put(`/tasklists/${id}/reposition.json`, {
          position,
        })
        .catch((e) => {
          commit('reposition', { id, position: oldPosition }, { recordMap: false });
          throw e;
        });
    },
    newTaskPlaceholder({ dispatch, getters, id }, task = {}) {
      const newTask = {
        ...task,
        parentTaskId: 0,
        taskListId: id,
        order: getters.highestOrder(id) + 1,
      };
      return dispatch('task/newPlaceholder', newTask, {
        root: true,
        recordMap: false,
      });
    },
    delete({ state, commit, id }) {
      if (state.status === 'deleted') {
        return;
      }
      commit('delete');
      return api.delete(`/tasklists/${id}.json`).catch((e) => {
        commit('undelete');
        throw e;
      });
    },
    undelete({ state, commit, id }) {
      if (state.status !== 'deleted') {
        return;
      }
      commit('busy');
      commit('undelete');
      return api
        .put(`/tasklists/${id}/undelete.json`)
        .catch((e) => {
          commit('delete');
          throw e;
        })
        .finally(() => commit('notBusy'));
    },
    unlinkMilestone({ state, commit, dispatch }, milestoneId) {
      if (state.milestoneId !== milestoneId) {
        return;
      }
      commit('unlinkMilestone');
      dispatch('checkTasksForMilestone');
      return api
        .put(`/tasklists/${state.id}.json`, {
          'todo-list': {
            'milestone-id': 0,
          },
        })
        .catch((e) => {
          commit('linkMilestone', milestoneId);
          dispatch('checkTasksForMilestone');
          throw e;
        });
    },
    linkMilestone({ state, commit, dispatch }, milestoneId) {
      if (state.milestoneId === milestoneId) {
        return;
      }
      if (!milestoneId) {
        return dispatch('unlinkMilestone', state.milestoneId);
      }
      commit('linkMilestone', milestoneId);
      dispatch('checkTasksForMilestone');
      return api
        .put(`/tasklists/${state.id}.json`, {
          'todo-list': {
            'milestone-id': milestoneId,
          },
        })
        .catch((e) => {
          commit('unlinkMilestone');
          dispatch('checkTasksForMilestone');
          throw e;
        });
    },
    checkTasksForMilestone({ commit, state, rootState }) {
      if (!state) {
        return; // ignore this check if the tasklist isn't loaded (twim probably)
      }
      const tasks = Object.values(rootState.task.records)
        .filter((t) => t.taskListId === state.id)
        .filter(({ status }) => !['deleted', 'completed'].includes(status))
        .filter((t) => !t.dueDate.isValid() || t.dueDateFromMilestone);
      if (state.milestoneId && rootState.milestone.records[state.milestoneId]) {
        const deadline = rootState.milestone.records[state.milestoneId].deadline;
        tasks.forEach((task) => {
          if (!task.dueDateFromMilestone) {
            commit(
              'task/dueDate',
              {
                id: task.id,
                payload: {
                  dueDate: deadline.clone(),
                  dueDateFromMilestone: true,
                },
              },
              { root: true, recordMap: false },
            );
          }
        });
      } else {
        tasks.forEach((task) => {
          if (task.dueDate.isValid()) {
            commit(
              'task/dueDate',
              {
                id: task.id,
                payload: {
                  dueDate: moment(''),
                  dueDateFromMilestone: false,
                },
              },
              { root: true, recordMap: false },
            );
          }
        });
      }
    },
  }),
};
