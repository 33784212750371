import { computed, shallowRef } from 'vue-demi';
import useListLoader from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems({ data }) {
  const { stages } = data;

  return stages;
}

export default function useWorkflowStagesV3Loader({ workflowId: _workflowId, params = {}, count, pageSize }) {
  const workflowId = shallowRef(_workflowId);
  const url = computed(
    () => Number.isInteger(workflowId.value) && `/projects/api/v3/workflows/${workflowId.value}/stages.json`,
  );

  const { state, refresh } = useListLoader({
    url,
    params,
    responseToItems,
    count,
    pageSize,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'stage') {
      refresh();
    }
  });

  return state;
}
