const debug = window.devMode;
let logging = false;

// If in debug mode, we wrap each function for logging, which
// is off by default, but can be turned on.
const debugWrap = (name, fnc) => {
  if (!debug) {
    return fnc;
  }
  return (...args) => {
    if (logging) {
      console.debug(`Vuex Visible Data: ${name}: ${JSON.stringify(args)}`);
    }
    return fnc(...args);
  };
};
const toggleLogging = (on) => {
  logging = on;
};

/**
 * Note:
 * - This service does not provide reactive data nor does leverage any reactive watchers
 */
const hier = {
  'tasklist/tasks': {
    exists: ({ tasklist }, id) => tasklist.records[id],
    parent: (rec) => ['project/tasks', rec.projectId],
  },
  'task/subtasks': {
    exists: ({ task }, id) => task.records[id],
    parent: (rec) => ['project/tasks', rec.projectId],
  },
  'task/predecessors': {
    exists: ({ task, dependencies }, id) => (dependencies.deps[id] || dependencies.preds[id]) && task.records[id],
    parent: (rec) => ['project/dependencies', rec.projectId],
  },
};

let store;
// Data Loader map will track how many components are using the data
const dlMap = {};

/**
 * Add an access for this dataloader (and record ID)
 * @param String dlId identifier for this Data loader
 * @param Number id
 */
export const access = debugWrap('access', (dlId, id = 0) => {
  dlMap[dlId] = dlMap[dlId] || {};
  dlMap[dlId][id] = dlMap[dlId][id] || 0;
  dlMap[dlId][id] += 1;
});

/**
 * Release an access for this dataloader (and record ID)
 * @param String dlId identifier for this Data loader
 * @param Number id
 */
export const release = debugWrap('release', (dlId, id = 0) => {
  if (!dlMap[dlId] || !dlMap[dlId][id]) {
    if (debug) {
      throw new Error('Vuex Visible Data: Released a resource without a matching access.');
    }
    return;
  }
  dlMap[dlId][id] -= 1;
});

let checkLocal;
const checkHier = (dlId, id) => {
  if (!hier[dlId]) {
    return false;
  }
  const { exists, parent } = hier[dlId];
  const rec = exists && exists(store.state, id);
  return (rec && parent && checkLocal(...parent(rec), 'up')) || false;
};
checkLocal = (dlId, id = 0) => (dlMap[dlId] && dlMap[dlId][id]) || checkHier(dlId, id);

/**
 * Check open accesses for this dataloader (and record ID)
 * @param String dlId identifier for this Data loader
 * @param Number id
 */
export const check = debugWrap('check', checkLocal);

export const registerStore = (st) => {
  store = st;
  if (debug) {
    // Global access to the service if we might need to debug
    window.vuexVisibleData = { check, dlMap, toggleLogging };
  }
};

// unit test util
export const reset = () => {
  Object.keys(dlMap).forEach((k) => {
    delete dlMap[k];
  });
};
