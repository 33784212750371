import { createListLoader } from '@/store/utils/loader';

export default createListLoader({
  namespaced: true,
  config: {
    url: '/projects/api/v3/companies.json',
    module: 'company',
    sort: false,
    pagination: { pageSize: 25 },
  },
});
