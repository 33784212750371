export default [
  {
    path: '/projects/:project(\\d+)',
    component: 'project-page',
    children: [
      {
        path: 'milestones',
        meta: {
          productTourLaunch: true,
        },
      },
      {
        path: 'files',
        name: 'project-files',
      },
      {
        path: 'forms',
        name: 'project-forms',
        meta: {
          pageName: 'Forms',
          permission: 'canViewForms',
          noSpacing: true,
        },
        component: 'page-next',
        children: [
          {
            name: 'project-forms-form',
            path: ':formId',
            props: true,
            component: 'page-next',
            meta: {
              noSpacing: true,
            },
          },
        ],
      },
      {
        path: '*',
      },
    ],
  },
];
