import buildFullPath from 'axios/lib/core/buildFullPath';
import createError from 'axios/lib/core/createError';
import settle from 'axios/lib/core/settle';
import buildURL from 'axios/lib/helpers/buildURL';
import cookies from 'axios/lib/helpers/cookies';
import isURLSameOrigin from 'axios/lib/helpers/isURLSameOrigin';
import utils from 'axios/lib/utils';
import Cancel from 'axios/lib/cancel/Cancel';
import isCancel from 'axios/lib/cancel/isCancel';

// TODO support progress events

const domParser = new DOMParser();

function getDocument(response) {
  // The Content-Type header value is often like "text/xml; charset=utf-8",
  // so we need to discard what comes after ";".
  const contentTypeHeader = response.headers.get('content-type') || '';
  const contentType = contentTypeHeader.replace(/;.*/, '');
  return response.text().then((text) => domParser.parseFromString(text, contentType));
}

function getResponseData(response, responseType) {
  switch (responseType) {
    case 'arraybuffer':
      return response.arrayBuffer();
    case 'blob':
      return response.blob();
    case 'document':
      return getDocument(response);
    case 'json':
      return response.json();
    case 'text':
      return response.text();
    case 'stream':
      return Promise.resolve(response.body);
    default:
      return response.text();
  }
}

export default function fetchAdapter(config) {
  const aborter = new AbortController();
  const { cancelToken, signal } = config;
  let onCanceled;

  return new Promise(function dispatchFetchRequest(resolve, reject) {
    // Handle cancellation
    onCanceled = (cancel) => {
      reject(isCancel(cancel) ? cancel : new Cancel('canceled'));
      aborter.abort();
    };
    if (cancelToken) {
      cancelToken.subscribe(onCanceled);
    }
    if (signal) {
      // eslint-disable-next-line no-unused-expressions
      signal.aborted ? onCanceled() : signal.addEventListener('abort', onCanceled);
    }

    // Handle timeout
    if (config.timeout) {
      setTimeout(() => aborter.abort(), config.timeout);
    }

    // Prepare a url
    const fullPath = buildFullPath(config.baseURL, config.url);
    const url = buildURL(fullPath, config.params, config.paramsSerializer);

    // Prepare headers
    const requestHeaders = new Headers();
    utils.forEach(config.headers, (value, key) => {
      if (
        key.toLowerCase() === 'content-type' &&
        (typeof config.data === 'undefined' || utils.isFormData(config.data))
      ) {
        // Let the browser set the content-type header.
        return;
      }
      requestHeaders.set(key, value);
    });

    // HTTP basic authentication
    if (config.auth) {
      const username = config.auth.username || '';
      const password = config.auth.password ? unescape(encodeURIComponent(config.auth.password)) : '';
      requestHeaders.set('Authorization', `Basic ${btoa(`${username}:${password}`)}`);
    }

    // Add xsrf header
    // This is only done if running in a standard browser environment.
    // Specifically not if we're in a web worker, or react-native.
    if (utils.isStandardBrowserEnv()) {
      // Add xsrf header
      const xsrfValue =
        (config.withCredentials || isURLSameOrigin(fullPath)) && config.xsrfCookieName
          ? cookies.read(config.xsrfCookieName)
          : undefined;

      if (xsrfValue) {
        requestHeaders.set(config.xsrfHeaderName, xsrfValue);
      }
    }

    // Prepare a request
    const request = new Request(url, {
      method: config.method.toUpperCase(),
      headers: requestHeaders,
      body: config.data,
      mode: config.mode,
      credentials: config.withCredentials ? 'include' : 'same-origin',
      cache: config.cache || 'default',
      signal: aborter.signal,
    });

    // Send the request
    fetch(request).then(
      function handleFetchResponse(response) {
        // Prepare the response headers
        const responseHeaders = {};
        response.headers.forEach((value, key) => {
          responseHeaders[key] = value;
        });

        // Prepare the response
        getResponseData(response, config.responseType).then(
          function handleDataOk(data) {
            settle(resolve, reject, {
              data,
              status: response.status,
              statusText: response.statusText,
              headers: responseHeaders,
              config,
              request,
            });
          },
          function handleDataError() {
            reject(createError('Failed to get response data', config, null, request, response));
          },
        );
      },
      function handleFetchError(error) {
        // The browser hides the error details from JS code.
        if (error.name === 'AbortError') {
          reject(createError('Request aborted', config, 'ECONNABORTED', request));
        } else {
          reject(createError('Request failed', config, null, request));
        }
      },
    );
  }).finally(() => {
    if (cancelToken) {
      cancelToken.unsubscribe(onCanceled);
    }
    if (signal) {
      signal.removeEventListener('abort', onCanceled);
    }
  });
}
