import { v3Url } from '@/utils/fetcher';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import useTranslations from '@/platform/composables/useTranslations';
import useStore from '@/platform/composables/useStore';

const { hasOwnProperty } = Object.prototype;

/**
 * Generates temporary IDs for new budgets for the purpose of optimistic updates.
 */
const newProjectBudgetExpenseId = (() => {
  let id = 0;
  return () => {
    id -= 1;
    return id;
  };
})();

function createProjectBudgetExpensePayload(budgetExpense) {
  const budgetExpensePayload = {};

  // if (hasOwnProperty.call(budgetExpense, 'id')) {
  //   budgetExpensePayload.id = Number(budgetExpense.id);
  // }

  if (hasOwnProperty.call(budgetExpense, 'projectBugdetId')) {
    budgetExpensePayload.projectBudgetId = Number(budgetExpense.projectBudgetId);
  }

  if (hasOwnProperty.call(budgetExpense, 'cost')) {
    budgetExpensePayload.cost = Number(budgetExpense.cost);
  }

  if (hasOwnProperty.call(budgetExpense, 'description')) {
    budgetExpensePayload.description = budgetExpense.description;
  }

  return budgetExpensePayload;
}

export default function useProjectBudgetExpenseActions() {
  const api = useAxios();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: _emitRealTimeUpdate, socketId } = useRealTimeUpdates();
  const $t = useTranslations();
  const store = useStore();

  const config = () => ({
    headers: {
      'Socket-ID': socketId.value,
      'Triggered-By': 'user',
      'Sent-By': 'composable',
    },
  });

  /**
   *
   * @param {Promise} promise
   * @param {'create'|'update'|'delete'} action
   * @param {object} budgetExpense
   */
  function emitOptimisticUpdate(promise, action, budgetExpense) {
    _emitOptimisticUpdate({
      promise,
      type: 'budgetExpense',
      action,
      budgetExpense,
    });
  }

  /**
   *
   * @param {'new'|'edited'|'deleted'} action
   * @param {object} budgetExpense
   * @param {object} oldBudgetExpense
   */
  function emitRealTimeUpdate(action, budgetExpense, oldBudgetExpense) {
    _emitRealTimeUpdate({
      type: 'budgetExpense',
      action,
      budgetExpenseId: budgetExpense.id ?? oldBudgetExpense.id,
      projectBudgetId: budgetExpense.projectBudgetId ?? oldBudgetExpense.projectBudgetId,
    });
  }

  /**
   * Creates new project budget  against an existing project budget.
   * @param {number} projectBudgetId
   * @param {Object} budgetExpense
   */
  function createProjectBudgetExpense(projectBudgetId, budgetExpense) {
    const url = v3Url(`projects/budgets/${projectBudgetId}/expenses`);
    const promise = api
      .post(
        url,
        {
          budgetExpense: createProjectBudgetExpensePayload(budgetExpense),
        },
        config(),
      )
      .then(({ data: { budgetExpense: createdBudgetExpense } }) => {
        emitRealTimeUpdate('new', createdBudgetExpense);
        return createdBudgetExpense;
      })
      .catch(() => {
        store.dispatch('notifications/flashes/error', $t('There was an error creating the expense'));
      });

    emitOptimisticUpdate(promise, 'create', {
      ...budgetExpense,
      id: newProjectBudgetExpenseId(),
    });
    return promise;
  }

  /**
   * Update an existing project budget expense.
   * @param {Object} budgetExpense
   */
  function updateProjectBudgetExpense(budgetExpense, oldBudgetExpense) {
    const url = v3Url(`projects/budgets/expenses/${budgetExpense.id}`);
    const promise = api
      .patch(url, { budgetExpense: createProjectBudgetExpensePayload(budgetExpense) }, config())
      .then(({ data: { budgetExpense: updatedBudgetExpense } }) => {
        emitRealTimeUpdate('edited', updatedBudgetExpense, oldBudgetExpense);
        return updatedBudgetExpense;
      })
      .catch(() => {
        store.dispatch('notifications/flashes/error', $t('There was an error updating the expense'));
      });

    emitOptimisticUpdate(promise, 'update', budgetExpense);
    return promise;
  }
  /**
   * Deletes a single project budget expense.
   * @param {object} budgetExpense
   */
  function deleteProjectBudgetExpense(budgetExpense) {
    const url = v3Url(`projects/budgets/expenses/${budgetExpense.id}`);
    const promise = api
      .delete(url, null, config())
      .then(() => {
        emitRealTimeUpdate('deleted', budgetExpense);
      })
      .catch(() => {
        store.dispatch('notifications/flashes/error', $t('There was an error deleting the expense'));
      });
    emitOptimisticUpdate(promise, 'delete', budgetExpense);
    return promise;
  }

  return {
    createProjectBudgetExpense,
    updateProjectBudgetExpense,
    deleteProjectBudgetExpense,
  };
}
