/* eslint-disable no-param-reassign */
import ResizeSensor from 'resize-sensor';

const sensors = [];

function getHeight(selector) {
  const element = document.querySelector(selector);
  return element ? element.offsetHeight : 0;
}

function reset(el) {
  el.style.position = '';
  el.style.top = '';
  el.style.bottom = '';
}

// scroll handler to reposition sidebar using fixed positioning
function scroll(el) {
  const sidebarOffset = 32;
  const windowHeight = window.innerHeight; // Height of the window, doesn't change unless resized
  const windowPos = window.scrollY; // Position of the top of the window => start scrolling = 0, 1, 2....
  const mainContentHeight = getHeight('#mainContent'); // Height of the main content, may change when data is loaded
  const sidebarHeight = el.offsetHeight; // Height of the sidebar, may change when data is loaded

  // No need to stick if content is smaller than window or sidebar
  if (mainContentHeight <= windowHeight || mainContentHeight <= sidebarHeight) {
    reset(el);
    return;
  }

  const shouldLockTop = sidebarHeight + sidebarOffset <= windowHeight;
  const shouldLockBottom = windowPos + windowHeight >= sidebarHeight + sidebarOffset;
  if (shouldLockTop) {
    el.style.position = 'fixed';
    el.style.top = `${sidebarOffset}px`;
    return;
  }
  if (shouldLockBottom) {
    if (sidebarHeight + sidebarOffset <= mainContentHeight) {
      el.style.position = 'fixed';
      el.style.bottom = '0px';
    }

    return;
  }

  reset(el);
}

export default {
  inserted(el) {
    const appBody = document.querySelector('.app-body');
    if (appBody) {
      sensors.push(new ResizeSensor(appBody, scroll.bind(null, el)));
      sensors.push(new ResizeSensor(el, scroll.bind(null, el)));
      window.addEventListener('scroll', scroll.bind(null, el));
      window.addEventListener('resize', scroll.bind(null, el));
      scroll(el);
    }
  },
  unbind() {
    window.removeEventListener('scroll', scroll);
    window.removeEventListener('resize', scroll);
    sensors.forEach((sensor) => sensor.detach());
  },
};
