import { createListLoader, API } from '@/store/utils/loader';

export default createListLoader({
  namespaced: true,
  config: {
    id: 'project/starred',
    url: `${API.v2}/projects/starred.json?dataset=basic`,
    module: 'project',
    mapResponse: (ctx, rs) => rs.data.projects,
    sort: false,
    pagination: { pageSize: 25 },
  },
});
