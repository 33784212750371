import { provide, inject } from 'vue-demi';
import api from '@/utils/fetcher';

const AxiosSymbol = Symbol('Axios instance');

export function provideAxios(axios) {
  provide(AxiosSymbol, axios);
}

export function useAxios() {
  return inject(AxiosSymbol, api);
}
