/* eslint-disable no-underscore-dangle,no-param-reassign */
import moment from 'moment';

const rehydrateMoments = (obj) => {
  const queue = [obj];
  while (queue.length) {
    const item = queue.shift();
    if (typeof item === 'object' && item !== null) {
      if (Array.isArray(item)) {
        queue.push(...item);
      } else if (item._isAMomentObject) {
        // Found a Moment instance
        Object.setPrototypeOf(item, moment.prototype);
        // Re-read the TZ and Locale shared objects (only the identifier fields are in the snapshot)
        item.tz(item.tz());
        item.locale(item.locale());
      } else {
        queue.push(...Object.values(item));
      }
    }
  }
};

/**
 * Should only be used in development!
 *
 * Catches any time-travel debugging and rehydrates momentjs objects, this isn't a problem
 * in production, but can be frustrating when developing, principally as the application errors
 * without any clear indication why.
 *
 * Also when adding breakpoints in the browser within 'composed' actions,
 * you can add a condition/watch like `arguments[arguments.length - 1].includes('moduleName')`
 * @param store
 */
export default (store) => {
  const oldReplace = store.replaceState;
  store.replaceState = function replaceState(state) {
    rehydrateMoments(state);
    return oldReplace.call(this, state);
  };

  // Wrap each action/mutation handler (proxy pattern) and provide an additional argument (name)
  ['_actions', '_mutations'].forEach((collection) =>
    Object.entries(store[collection]).forEach(([name, handlers]) => {
      store[collection][name] = handlers.map(
        (handler) =>
          (...args) =>
            handler(...args, name),
      );
    }),
  );
};
