import { mapState } from 'vuex';

// @vue/component
export default {
  name: 'HybridPopoverContainer',
  components: {
    ProjectsDropdown: () => import(/* webpackChunkName: "custom-popover" */ '@widgets/ProjectsDropdown'),
  },
  computed: {
    ...mapState({
      tippedToDisplay: ({ hybridPopover }) => hybridPopover.component,
      tippedProperties: ({ hybridPopover }) => hybridPopover.properties,
      tippedOptions: ({ hybridPopover }) => hybridPopover.tippedOptions,
      tippedSelector: ({ hybridPopover }) => hybridPopover.selector,
    }),
  },
  methods: {
    hide() {
      this.$store.dispatch('hybridPopover/hide');
    },
    show() {
      // Hacky way to set the target of the tooltip: https://github.com/Akryum/v-tooltip/issues/60#issuecomment-347893624
      this.$refs.popover.$refs.trigger = document.querySelector(this.tippedSelector);
    },
  },
};
