import { createRecordListLoader, API } from '@/store/utils/loader';

export default createRecordListLoader({
  namespaced: true,
  config: {
    id: 'task/subtasks',
    url: (id) => `${API.v2}/tasks/${id}/subtasks.json`,
    params: () => ({
      getFiles: true,
      includeCompletedTasks: true,
      useAllProjects: 1,
    }),
    module: 'task',
    listMutation: 'task/subtasks',
    pagination: { loadAll: true },
    updater: true,
  },
});
