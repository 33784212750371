import { computed, shallowRef } from 'vue-demi';
import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';

function responseToItems(response) {
  return response.data.companies;
}

/**
 * Loads companies from Teamwork v3 endpoints.
 */
export default function useCompaniesLoader({
  /**
   * If `false` (default), companies are loaded from the `/companies.json` endpoint.
   * If `true`, companies are loaded from the `/projects/companies.json` endpoint.
   */
  projectsCompanies: _projectsCompanies = false,
  params,
  count,
  pageSize,
}) {
  const projectsCompanies = shallowRef(_projectsCompanies);
  const url = computed(() => v3Url(projectsCompanies.value ? 'projects/companies' : 'companies'));
  const { state } = useListLoader({
    url,
    params,
    count,
    pageSize,
    responseToItems,
  });
  return state;
}
