import projectsUpdateStats from './projects-update-stats';
import project from './project';
import projectsUpdated from './projects-updated';

export const events = {
  'projects-update-stats': projectsUpdateStats,
  'projects-updated': projectsUpdated,
};

export const activity = {
  project,
};
