export default [
  {
    path: '/projects/:projectId(\\d+)/tasks/list',
    component: () => import('./RouteProjectsTasksList'),
    meta: {
      sidebar: 'portal',
      productTourLaunch: true,
    },
  },
  {
    path: '/tasklists/:tasklistId(\\d+)/list',
    props: (to) => ({
      tasklistId: Number(to.params.tasklistId),
    }),
    component: () => import('./RouteProjectsTasksList'),
    meta: {
      sidebar: 'portal',
    },
  },
  {
    path: '/projects/:projectId(\\d+)/tasks/table',
    component: () => import('./RouteProjectsTasksTable'),
    meta: {
      sidebar: 'portal',
      productTourLaunch: true,
    },
  },
  {
    path: '/tasklists/:tasklistId(\\d+)/table',
    props: (to) => ({
      tasklistId: Number(to.params.tasklistId),
    }),
    component: () => import('./RouteProjectsTasksTable'),
    meta: {
      sidebar: 'portal',
    },
  },
  {
    path: '/projects/:projectId(\\d+)/tasks/board',
    component: () => import('./RouteProjectsTasksBoard'),
    meta: {
      canViewFullScreen: true,
      productTourLaunch: true,
    },
  },
  {
    path: '/projects/:projectId(\\d+)/tasks/gantt',
    component: () => import('./RouteProjectsTasksGantt'),
    meta: {
      canViewFullScreen: true,
      filters: 'section-tasks-filter',
      filterKey: 'projectTasks',
      filtersScroll: true,
      filtersGate: 'ganttfiltering',
    },
  },
  {
    path: '/projects/:projectId(\\d+)/tasks/:taskId(\\d+)',
    redirect(to) {
      return `/tasks/${to.params.taskId}`;
    },
  },
  {
    path: '/projects/:projectId(\\d+)/tasks/(.*)?',
    component: () => import('./RouteProjectsTasks'),
  },
  {
    path: '/tasklists/:tasklistId(\\d+)/(.*)?',
    props: (to) => ({
      tasklistId: Number(to.params.tasklistId),
    }),
    component: () => import('./RouteProjectsTasks'),
  },
  {
    path: '/projects/:projectId(\\d+)/gantt',
    redirect: (to) => `/projects/${to.params.projectId}/tasks/gantt`,
  },
];
