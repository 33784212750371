import { createListLoader, API } from '@/store/utils/loader';

export default createListLoader({
  namespaced: true,
  config: {
    id: 'project/filtered',
    url: `${API.v2}/projects.json`,
    params: ({ project }) => ({
      dataset: 'basic',
      searchCompany: true,
      searchTerm: project.filtered.searchTerm,
    }),
    module: 'project',
  },
  state: {
    searchTerm: '',
  },
  mutations: {
    search: (state, term) => {
      state.searchTerm = term;
    },
  },
});
