import typedDefaults from '@/utils/helpers/typed-defaults';

const recordDefault = {
  id: 0,
  customfieldId: 0,
  value: '',
};

export default {
  namespaced: true,
  state: {
    records: {},
  },
  mutations: {
    records(state, customFields) {
      const hash = {};
      customFields.forEach((rec) => {
        const existing = state.records[rec.id];
        const customField = typedDefaults(recordDefault, rec);
        hash[customField.id] = existing ? { ...existing, ...customField } : customField;
      });
      state.records = { ...state.records, ...hash };
    },
  },
};
