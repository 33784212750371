import moment from 'moment';
import { computed, shallowRef } from 'vue-demi';
import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  const { users = {}, projects = {}, tasks = {}, tags = {}, tasklists = {} } = response.data.included;

  const { permissions = {} } = response.data.meta;

  const timelogs = response.data.timelogs || [];

  /* eslint-disable no-param-reassign */
  timelogs.forEach((timelog) => {
    timelog.minutes = Number(timelog.minutes);
    timelog.createdAt = timelog.createdAt ? moment(timelog.createdAt) : null;
    timelog.timeLogged = timelog.timeLogged ? moment(timelog.timeLogged) : null;

    // Calculated properties
    timelog.timeLoggedEnd = timelog.timeLogged ? moment(timelog.timeLogged).add(timelog.minutes, 'minutes') : null;

    timelog.isBilled = Boolean(timelog.projectBillingInvoice?.id);

    // Includes
    if (permissions[timelog.id]) {
      timelog.permissions = permissions[timelog.id];
    }

    if (projects[timelog.project?.id]) {
      timelog.project = projects[timelog.project.id];
    }

    if (timelog.tags) {
      timelog.tags.forEach((tag, index) => {
        if (tags[tag.id]) {
          timelog.tags[index] = tags[tag.id];
        }
      });
    }

    if (tasks[timelog.task?.id]) {
      timelog.task = tasks[timelog.task.id];

      if (tasklists[timelog.task.tasklist?.id]) {
        timelog.task.tasklist = tasklists[timelog.task.tasklist.id];
      }
    }

    if (users[timelog.user?.id]) {
      timelog.user = users[timelog.user.id];
    }
  });
  /* eslint-enable no-param-reassign */

  return timelogs;
}

/**
 * Loads a list of time logs from the Teamwork v3 API.
 */
export default function useTimelogsLoader({ projectId: _projectId, taskId: _taskId, params, count, pageSize } = {}) {
  const taskId = shallowRef(_taskId);
  const projectId = shallowRef(_projectId);

  const url = computed(() => {
    if (taskId.value) {
      return v3Url(`tasks/${taskId.value}/time`);
    }
    if (projectId.value) {
      return v3Url(`projects/${projectId.value}/time`);
    }
    return v3Url('time');
  });

  const { state, update, refresh } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    pageSize,
  });

  useOptimisticUpdates((event) => {
    if (event.type !== 'time') {
      return;
    }
    update((timelogs) => {
      if (event.action === 'create') {
        return timelogs.concat(event.timelog);
      }
      if (event.action === 'update') {
        return timelogs.map((timelog) =>
          timelog.id === event.timelog.id ? { ...timelog, ...event.timelog } : timelog,
        );
      }
      if (event.action === 'delete') {
        return timelogs.filter((timelog) => timelog.id !== event.timelog.id);
      }
      return timelogs;
    }, event.promise);
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'time') {
      refresh(event.timeId);
    }
  });

  return state;
}
