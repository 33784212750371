import Vue from 'vue';
import typedDefaults from '@/utils/helpers/typed-defaults';

const defaultTypes = {
  id: 0,
  startTime: '',
  endTime: '',
  taskHours: 0,
  timezone: '',
  weekday: '',
};

export default {
  namespaced: true,
  state: {
    records: {},
  },
  mutations: {
    records(state, entries) {
      const hash = {};
      entries.forEach((wh) => {
        const defsApplied = typedDefaults(defaultTypes, wh);
        hash[wh.id] = {
          ...(state.records[wh.id] || defaultTypes),
          ...defsApplied,
        };
      });
      state.records = { ...state.records, ...hash };
    },
    record(state, entry) {
      const defsApplied = typedDefaults(defaultTypes, entry);
      const newRec = {
        ...(state.records[entry.id] || defaultTypes),
        ...defsApplied,
      };
      Vue.set(state.records, newRec.id, newRec);
    },
  },
};
