export const STATUSES = {
  BADRQ: 400,
  UNAUTH: 401,
  FORBIDDEN: 403,
  NOTFOUND: 404,
  CANCELLED: 420, // /me.json response code
  BADGATEWAY: 502,
  UNAVAILABLE: 503,
};

export const DROPBOX_503 = "We can't retrieve account details from Dropbox at the moment";
export const DROPBOX_URL = './integrations/dropbox.json?getInfo=true';
export const DEV_MODE = window.devMode;

export const API_PREFIXES = {
  v1: '/projects/api/v1',
  v2: '/projects/api/v2',
  v3: '/projects/api/v3',
};
