import moment from 'moment';
import typedDefaults from '@/utils/helpers/typed-defaults';

const dateFormat = 'YYYY-MM-DD';
const recordDefault = {
  id: 0,
  billable: false,
  dateCreated: moment.tz('', dateFormat, moment.tz.guess()),
  dateDeleted: moment.tz('', dateFormat, moment.tz.guess()),
  dateEdited: moment.tz('', dateFormat, moment.tz.guess()),
  timeLogged: moment.tz('', moment.tz.guess()),
  deleted: false,
  deletedByUserId: 0,
  description: '',
  deskTicketId: 0,
  editedByUserId: 0,
  hasStartTime: false,
  loggedByUserId: 0,
  minutes: 0,
  projectId: 0,
  userId: 0,
  taskId: 0,
  taskIdPreMove: 0,
};

export default {
  namespaced: true,
  state: {
    records: {},
  },
  mutations: {
    records(state, timelogs) {
      const hash = {};
      timelogs.forEach((rec) => {
        const existing = state.records[rec.id];
        const timelog = typedDefaults(recordDefault, rec);
        hash[timelog.id] = existing ? { ...existing, ...timelog } : timelog;
      });
      state.records = { ...state.records, ...hash };
    },
  },
};
