export default {
  defaultHtml: false,
  defaultClass: 'w-tooltip',
  defaultTemplate: `
  <div class="tooltip" role="tooltip">
    <div class="w-tooltip__arrow"></div>
    <div class="w-tooltip__inner"></div>
  </div>
  `,
  defaultArrowSelector: '.w-tooltip__arrow',
  defaultInnerSelector: '.w-tooltip__inner',
  defaultBoundariesElement: 'window',
  popover: {
    defaultClass: '',
    defaultOffset: 10,
    defaultBaseClass: 'w-popover',
    defaultInnerClass: 'w-popover__inner',
    defaultArrowClass: 'w-tooltip__arrow w-popover__arrow',
  },
};
