import { useLightspeedBridge } from '@/platform/composables/useLightspeedBridge';

let nextId = 0;
export const callbacks = new Map();

export function useLsToast() {
  const { postMessageToLightspeed } = useLightspeedBridge();

  function showToast(level, options) {
    // eslint-disable-next-line no-plusplus
    const id = nextId++;
    function action() {
      options.action?.();
    }
    function onRemove() {
      callbacks.delete(id);
      options.onRemove?.();
    }
    callbacks.set(id, { action, onRemove });
    const { action: toastAction, onRemove: toastOnRemove, ...lsOptions } = options;
    postMessageToLightspeed('twa:toast-open', {
      ...lsOptions,
      level,
      callbackId: id,
    });
  }
  return {
    success: showToast.bind(null, 'success'),
    info: showToast.bind(null, 'info'),
    warning: showToast.bind(null, 'warning'),
    critical: showToast.bind(null, 'critical'),
  };
}
