import { groupBy, fromPairs, max, get } from 'lodash-es';
import { createSingletonLoader, API, ACTIONS } from '@/store/utils/loader';
import typedDefaults from '@/utils/helpers/typed-defaults';

const defaultTypes = {
  id: 0,
  selected: false,
  extra: '',
  completed: false,
};

export default {
  namespaced: true,
  modules: {
    loader: createSingletonLoader({
      config: {
        url: `${API.v2}/quickSearch.json`,
        params: (state) => ({
          searchTerm: state.quickSearch.searchTerm,
          returnExtras: true,
          projectId: (state.preferences.user.quickSearchUseCurrentProjectOnly && state.project.currentProjectId) || 0,
          searchAllProjects: !state.project.currentProjectId,
          skipES: false,
        }),
        mapResponse: (ctx, rs) => rs.data.searchResults,
        commit: 'results',
      },
    }),
  },
  getters: {
    fileResultsById: ({ results }) =>
      groupBy(
        results.filter((r) => r.extra && r.extra.fileVersionNo),
        (r) => r.id,
      ),

    // Build a map of file ID to the its highest version, where there are multiples
    maxVersions: (state, { fileResultsById }) =>
      fromPairs(
        Object.keys(fileResultsById)
          .filter((id) => fileResultsById[id].length > 1)
          .map((id) => [id, max(fileResultsById[id].map((r) => r.extra.fileVersionNo))]),
      ),
  },
  state: {
    isSearchOpen: false,
    results: [],
    searchTerm: '',
  },
  mutations: {
    results: (state, results) => {
      state.results =
        results &&
        results.map((result) => {
          const record = {
            ...result,
            ...defaultTypes,
            ...typedDefaults(defaultTypes, result),
          };
          if (record.type === 'comment_file' && record.extra.version && !record.url.includes('?')) {
            record.url = `${record.url}?v=${record.extra.version}&c=${record.id}`;
          }

          // Set up default properties for when a quickSearch result is of type 'team'
          if (record.type === 'team') {
            const { extra } = record;
            record.teamLogoInfo = {
              name: record.title,
              logoUrl: extra.teamLogo || '',
              logoColor: extra.teamLogoColor || '',
              logoIcon: extra.teamLogoIcon || '',
              defaultImageUrl: '//twa-prod.teamwork.com/tko/public/assets/svg/team-regular.svg',
              logoIconSVG: '',
            };

            record.details = `@${extra.teamHandle}`;

            if (get(extra, 'companyName.length')) {
              record.details = `${extra.companyName} / @${extra.teamHandle}`;
            } else if (get(extra, 'parentTeamName.length')) {
              record.details = `${extra.parentTeamName} / @${extra.teamHandle}`;
            }
          }

          return record;
        });
    },

    search: (state, searchTerm) => {
      state.searchTerm = searchTerm;
    },

    clear: (state) => {
      state.searchTerm = '';
    },

    toggleSearchOpen: (state) => {
      state.isSearchOpen = !state.isSearchOpen;
    },

    setSearchOpen: (state, isSearchOpen) => {
      state.isSearchOpen = isSearchOpen;
    },
  },
  actions: {
    clear({ commit, dispatch }) {
      dispatch(ACTIONS.RESET); // reset the loader, stop it automatically reloading
      commit('clear');
    },
  },
};
