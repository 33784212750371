import api from './helpers/api';

function paginate({ headers }) {
  return {
    page: parseInt(headers['x-page'], 10),
    totalRecords: parseInt(headers['x-records'], 10),
    hasMore: parseInt(headers['x-page'], 10) < parseInt(headers['x-pages'], 10),
  };
}

export default async (endpoint, params, normalize = (data) => data, options = {}) => {
  const resp = await api.get(`/projects/api/v2/${endpoint}.json`, {
    params,
    ...options,
    headers: { Twprojectsver: window.appVersionId ?? '' },
  });
  return {
    ...normalize(resp),
    pagination: paginate(resp),
  };
};
