import { computed, shallowRef } from 'vue-demi';
import { v3Url } from '@/utils/fetcher';
import useItemLoader from '../base/useItemLoader';

function responseToItem({ data }) {
  const project = data.project;
  const meta = data.meta || {};
  // const included = data.included || {};
  const { permissions = {} } = meta;

  if (project.id && permissions[project.id]) {
    project.permissions = permissions[project.id];
  }

  return project;
}

export default function useProjectLoader({ projectId: _projectId, params }) {
  const projectId = shallowRef(_projectId);
  const url = computed(() => Number.isInteger(projectId.value) && v3Url(`projects/${projectId.value}`));
  const { state } = useItemLoader({
    url,
    params,
    responseToItem,
  });
  return state;
}
