import { v1Url, v3Url } from '@/utils/fetcher';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

const { hasOwnProperty } = Object.prototype;

function toApiProject(project) {
  const apiProject = {};
  apiProject.id = project.id;
  if (hasOwnProperty.call(project, 'description')) {
    apiProject.description = project.description;
  }
  if (hasOwnProperty.call(project, 'categoryId')) {
    apiProject['category-id'] = Number(project.categoryId);
  } else if (hasOwnProperty.call(project, 'category')) {
    apiProject['category-id'] = Number(project.category.id);
  }
  if (hasOwnProperty.call(project, 'customFieldValues')) {
    apiProject.customFields = project.customFieldValues.map(({ customFieldId, value, urlTextToDisplay }) => ({
      customFieldId,
      value,
      urlTextToDisplay,
    }));
  }
  if (hasOwnProperty.call(project, 'tags')) {
    apiProject.tagIds = project.tags.map((tag) => tag.id).join(',');
  }
  return apiProject;
}

function hasCategoryChange(updatedProject) {
  return hasOwnProperty.call(updatedProject, 'categoryId') || hasOwnProperty.call(updatedProject, 'category');
}

export default function useProjectActions() {
  const api = useAxios();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId.value,
        'Triggered-By': 'user',
        'Sent-By': 'composable',
      },
    };
  }

  return {
    starProject(project) {
      const updatedProject = { id: project.id, isStarred: true };
      const promise = api.put(v1Url(`projects/${updatedProject.id}/star`), null, config()).then(() =>
        emitRealTimeUpdate({
          type: 'project',
          action: 'starred',
          projectId: updatedProject.id,
          categoryChanged: false,
        }),
      );
      emitOptimisticUpdate({
        promise,
        type: 'project',
        action: 'update',
        project: updatedProject,
      });
      return promise;
    },

    unstarProject(project) {
      const updatedProject = { id: project.id, isStarred: false };
      const promise = api.put(v1Url(`projects/${updatedProject.id}/unstar`), null, config()).then(() =>
        emitRealTimeUpdate({
          type: 'project',
          action: 'unstarred',
          projectId: updatedProject.id,
          categoryChanged: false,
        }),
      );
      emitOptimisticUpdate({
        promise,
        type: 'project',
        action: 'update',
        project: updatedProject,
      });
      return promise;
    },

    updateProject(updatedProject) {
      const promise = api
        .put(v1Url(`projects/${updatedProject.id}`), { project: toApiProject(updatedProject) }, config())
        .then(() =>
          emitRealTimeUpdate({
            type: 'project',
            action: 'edited',
            projectId: updatedProject.id,
            categoryChanged: hasCategoryChange(updatedProject),
          }),
        );
      emitOptimisticUpdate({
        promise,
        type: 'project',
        action: 'update',
        project: updatedProject,
      });
      return promise;
    },

    addCustomfieldProject({ project, customfieldProject }) {
      const promise = api
        .post(v3Url(`projects/${project.id}/customfields`), { customfieldProject }, config())
        .then(() =>
          emitRealTimeUpdate({
            type: 'project',
            action: 'edited',
            projectId: project.id,
            categoryChanged: false,
          }),
        );
      emitOptimisticUpdate({
        promise,
        type: 'project',
        action: 'update',
        project: {
          ...project,
          customFieldValues: [...project.customFieldValues, customfieldProject],
        },
      });
      return promise;
    },

    updateCustomfieldProject({ project, customfieldProject }) {
      const promise = api
        .patch(v3Url(`projects/${project.id}/customfields/${customfieldProject.id}`), { customfieldProject }, config())
        .then(() =>
          emitRealTimeUpdate({
            type: 'project',
            action: 'edited',
            projectId: project.id,
            categoryChanged: false,
          }),
        );
      emitOptimisticUpdate({
        promise,
        type: 'project',
        action: 'update',
        project: {
          ...project,
          customFieldValues: project.customFieldValues.map((field) =>
            field.id === customfieldProject.id ? customfieldProject : field,
          ),
        },
      });
      return promise;
    },

    deleteCustomfieldProject({ project, customfieldProject }) {
      const promise = api
        .delete(v3Url(`projects/${project.id}/customfields/${customfieldProject.id}`), config())
        .then(() =>
          emitRealTimeUpdate({
            type: 'project',
            action: 'edited',
            projectId: project.id,
            categoryChanged: false,
          }),
        );
      emitOptimisticUpdate({
        promise,
        type: 'project',
        action: 'update',
        project: {
          ...project,
          customFieldValues: project.customFieldValues.filter(({ id }) => id !== customfieldProject.id),
        },
      });
      return promise;
    },
  };
}
