/* eslint-disable no-param-reassign,no-return-assign */
import { createRecordLoader, mutations } from '@/store/utils/loader';

export default {
  namespaced: true,
  modules: {
    loader: createRecordLoader({
      config: {
        url: (id) => `/projects/api/v3/lockdowns/${id}.json`,
        params: () => ({ include: 'users.companies,companies,teams' }),
        mapResponse: ({ id }, rs) => ({ items: rs.data, id }),
      },
    }),
  },
  state: {
    records: {},
  },
  mutations: {
    record(state, { items, id }) {
      mutations.record(state, items, id);
    },
  },
};
