import { h } from 'vue-demi';

export default [
  {
    path: '/projects/:projectId(\\d+)/overview',
    component: { render: () => h('RouterView') },
    children: [
      {
        path: '',
        component: () => import('./RouteProjectsOverview'),
      },
      {
        path: 'activity',
        component: () => import('./RouteProjectsOverviewActivity'),
      },
      {
        path: 'summary',
        component: () => import('./RouteProjectsOverviewSummary'),
      },
      {
        path: '*',
        redirect: (to) => `/projects/${to.params.projectId}/overview`,
      },
    ],
  },
];
