import { createListLoader, API } from '@/store/utils/loader';

export default createListLoader({
  namespaced: true,
  config: {
    id: 'project/latest',
    url: `${API.v2}/projects/me/latest.json`,
    module: 'project',
    sort: false,
  },
});
