// this pairs with the @widgets/Popover component
// to purpose is to keep track of popovers so we can controls things like
// closing all other when one is opened
export default {
  namespaced: true,
  state: {
    component: '',
    properties: {},
    tippedOptions: {},
    selector: '',
  },
  mutations: {
    show(state, { component, properties, tippedOptions = {}, selector }) {
      state.component = component;
      state.properties = properties;
      state.tippedOptions = tippedOptions;
      state.selector = selector;
    },

    hide(state) {
      state.component = '';
      state.properties = {};
      state.tippedOptions = {};
      state.selector = '';
    },
  },
  actions: {
    show({ commit }, { component, properties, tippedOptions, selector }) {
      commit('show', { component, properties, tippedOptions, selector });
    },
    hide({ commit }) {
      commit('hide');
    },
  },
};
