/**
 * Gets pagination data from an axios response.
 */
export default function pagination(response) {
  const data = response.data;
  const pageMeta = data && data.meta && data.meta.page;

  // handle v3
  if (pageMeta) {
    const page = pageMeta.pageOffset + 1;
    const pageSize = pageMeta.pageSize;
    const records = pageMeta.count;
    const pages = Math.ceil(records / pageSize);
    return { page, pages, pageSize, records };
  }

  // handle v2 & v1
  const {
    headers,
    config: { params },
  } = response;
  const page = Number(headers['x-page']);
  const pages = Number(headers['x-pages']);
  const records = Number(headers['x-records']);
  // v1 and v2 responses do not contain pageSize, so we try to get it from params and fall back to 0.
  const pageSize = params && typeof params.pageSize === 'number' ? params.pageSize : 0;
  if (Number.isInteger(page) && Number.isInteger(pages) && Number.isInteger(pageSize) && Number.isInteger(records)) {
    return { page, pages, pageSize, records };
  }

  // fallback
  return { page: 0, pages: 0, pageSize: 0, records: 0 };
}
