import { inject, onUnmounted, provide } from 'vue-demi';
import assertHasScope from '@/utils/other/assertHasScope';

const useOptimisticUpdatesSymbol = Symbol('optimisticUpdates');

/**
 * Provides a new optimistic updates instance which can be later obtained using `useOptimisticUpdates`.
 */
export function provideOptimisticUpdates() {
  let listeners = [];
  let emitting = 0;

  /**
   * Registers `listener` to be called on optimistic updates events.
   * Must be called within a component.
   * Unregisters the listener automatically when the component unmounts.
   */
  function on(listener) {
    assertHasScope();

    // copy on write, if currently emitting
    if (emitting > 0) {
      listeners = listeners.slice();
    }

    listeners.push(listener);

    onUnmounted(() => {
      // copy on write, if currently emitting
      if (emitting > 0) {
        listeners = listeners.slice();
      }

      listeners.splice(listeners.lastIndexOf(listener), 1);
    });
  }

  /**
   * Emits the specified optimistic updates event.
   */
  function emit(event) {
    try {
      emitting += 1;
      // thanks to "copy on write" above, fixedListeners will not change while iterating
      const fixedListeners = listeners;
      for (let i = 0; i < fixedListeners.length; i += 1) {
        try {
          fixedListeners[i](event);
        } catch (error) {
          console.error('useOptimisticUpdates: Error in an event listener', error);
        }
      }
    } finally {
      emitting -= 1;
    }
  }

  provide(useOptimisticUpdatesSymbol, { emit, on });
}

/**
 * Returns the optimistic updates instance provided by `provideRealTimeUpdates`.
 * If `listener` is specified, it is registered to listen for optimistic updates events.
 */
export function useOptimisticUpdates(listener) {
  const optimisticUpdates = inject(useOptimisticUpdatesSymbol);
  if (listener) {
    optimisticUpdates.on(listener);
  }

  return optimisticUpdates;
}
