/* eslint-disable no-param-reassign,no-return-assign */
import Vue from 'vue';
import typedDefaults from '@/utils/helpers/typed-defaults';
import api from '@/services/api';

const defaultTypes = {
  id: 0,
  name: '',
  color: '',
  projectId: 0,
};

export default {
  namespaced: true,
  state: {
    records: {},
    specialTags: [],
    specialTagsLoaded: false,
  },
  mutations: {
    records(state, columns) {
      const newCols = {};
      columns.forEach((column) => {
        newCols[column.id] = typedDefaults(defaultTypes, column);
      });
      state.records = { ...state.records, ...newCols };
    },
    record(state, column) {
      const newCol = typedDefaults(defaultTypes, column);
      Vue.set(state.records, newCol.id, newCol);
    },
    specialTags(state, tags) {
      Vue.set(state, 'specialTags', tags);
      state.specialTagsLoaded = true;
    },
  },
  actions: {
    'special/access': async ({ state, commit }) => {
      if (!state.specialTagsLoaded) {
        const { data } = await api.get('tags/special.json');
        commit('records', data.tags);
        commit(
          'specialTags',
          data.tags.map((tag) => tag.id),
        );
      }
    },
  },
};
