/* eslint-disable no-undef */
import { onPostboxEvent } from '@/utils/helpers/postboxEventListener';
import { events, activity } from './postbox-handlers';

const subscriptions = [];

const handler = (handlers, store) => (originalEvent) => {
  // ignore any events originating from the server, this is just for internal postbox events
  if (originalEvent.twimEventId || originalEvent.sendTo || originalEvent.socketId) {
    return;
  }

  // Shallow copy to avoid modifying by reference
  const event = { ...originalEvent };

  // Normalize the event for the handlers
  if (event.actionType === 'updated') {
    event.actionType =
      {
        'task-completed': 'completed',
        'task-reopened': 'reopened',
      }[event.subType] || 'edited';
  }

  if (event.itemType) {
    let type = event.itemType.toLowerCase();
    // Normalize the itemType
    type = { taskcard: 'card' }[type] || type;

    // send postbox event to the event listener
    onPostboxEvent(event);

    if (handlers[type]) {
      handlers[type](store, event);
    }
    if (activity[type]) {
      activity[type](store, event);
    }
  }
};

// watch out for Vue originating events too.
const filterVue = (handlr) => (event) => !event.vue && handlr(event);

export default (handlers, store) => {
  if (!subscriptions.length && ko && ko.postbox) {
    subscriptions.push(ko.postbox.subscribe('allTWEvents', filterVue(handler(handlers, store))));
    Object.keys(events).forEach((event) => {
      subscriptions.push(ko.postbox.subscribe(event, filterVue(events[event](store))));
    });
  }
};
