export default [
  {
    path: '/clients/:companyId(\\d+)',
    component: () => import('./RouteCompany.vue'),
    children: [
      {
        path: '*',
        component: () => import('@sections/TkoComponent/TkoComponent.vue'),
        props(route) {
          return {
            name: 'page-company',
            params: {
              ...route.params,
            },
          };
        },
      },
    ],
  },
  {
    path: '/companies/:companyId(\\d+)',
    component: () => import('./RouteCompany.vue'),
    children: [
      {
        path: '*',
        component: () => import('@sections/TkoComponent/TkoComponent.vue'),
        props(route) {
          return {
            name: 'page-company',
            params: {
              ...route.params,
            },
          };
        },
      },
    ],
  },
];
