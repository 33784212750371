import Vue from 'vue';
import typedDefaults from '@/utils/helpers/typed-defaults';
import { createRecordLoader } from '@/store/utils/loader';
import all from './all';

const defaultTypes = {
  id: 0,
  columns: [],
  displayOrder: '',
  description: '',
  projectIds: [],
  deletedDate: '',
  stats: {},
  boardType: '',
  canEdit: false,
  userIds: [],
  isUserSpecific: true,
  dateCreated: '',
  deleted: false,
  userId: 0,
  name: '',
  color: '',
};

export default {
  namespaced: true,
  modules: {
    loader: createRecordLoader({
      config: {
        url: (id) => `/boards/${id}.json`,
      },
    }),
    all,
  },
  state: {
    records: {},
  },
  mutations: {
    records(state, boards) {
      const newBoards = {};
      boards.forEach((board) => {
        newBoards[board.id] = typedDefaults(defaultTypes, board);
      });
      state.records = { ...state.records, ...newBoards };
    },
    record(state, board) {
      const newBoard = typedDefaults(defaultTypes, board);
      Vue.set(state.records, newBoard.id, newBoard);
    },
  },
};
