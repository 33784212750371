export default {
  type(state, type) {
    state.type = type;
  },

  dates(state, { startDate, endDate }) {
    state.startDate = startDate;
    state.endDate = endDate;
  },
};
