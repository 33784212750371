/* eslint-disable consistent-return */
import { isEmpty } from 'lodash-es';
import api from '@/services/api';
import typedDefaults from '@/utils/helpers/typed-defaults';

export const watchers = [
  // TODO Hybrid - triggers from TKO
  // {
  //   getter: (state) => JSON.stringify({ ...state.user, lastSnapshot: undefined }),
  //   callback: ({ dispatch }) => dispatch('preferences/persistChanges', 'user'),
  // },
  // {
  //   getter: (state) => JSON.stringify({ ...state.account, lastSnapshot: undefined }),
  //   callback: ({ dispatch }) => dispatch('preferences/persistChanges', 'account'),
  // },
];

const scopeUrl = {
  user: '/me',
  account: '/account',
};

const responseFieldMappings = {
  user: {},
  account: {},
};

const copyProjectStruct = () => ({
  items: {
    tasks: true,
    milestones: true,
    messages: true,
    files: true,
    links: true,
    notebooks: true,
    risks: true,
    people: true,
    followers: true,
    expenses: true,
    budgets: true,
    comments: true,
  },
  keepOffWeekends: 0,
  createActivity: true,
  makeMeCreator: true,
  uncompleteItems: false,
});

export default {
  namespaced: true,
  state: {
    // Last Snapshot will keep track of the last persisted state of
    // preferences
    user: {
      lastSnapshot: {},
      showOnlyStarredProjects: false,
      useStartDatesForTodaysTasks: false,
      showOnlyMyEvents: false,
      showOnlyMyItems: false,
      dashboardEventsRange: 7,
      webBNotifsOn: true,
      useDesktopNotifications: true,
      ganttShowItemAssignees: true,
      ganttShowItemDuration: true,
      ganttShowItemName: true,
      ganttShowItemBoardColumn: false,
      ganttShowItemTags: false,
      ganttAutoSave: true,
      ganttDefaultDependencyDateAdjustmentMode: 'lazy',
      quickSearchUseCurrentProjectOnly: false,
      searchSelectedProject: { id: 0, name: '' },
      projectTasksViewMode: 'list',
      filterSidebarVisible: false,
      teamsEnabled: false,
      useAdvancedMarkdownEditor: true,
      copyProject: copyProjectStruct(),
      saveAsTemplate: copyProjectStruct(),
      taskPushSubTasks: true,
      taskPushDependentTasks: true,
      pendingMaxPUpgrade: false,
      dismissedPlanTrialExpiredModals: [],
      importOnboardingApp: [],
      integrateOnboardingApp: [],
      shouldShowPersonalizedExperience: true,
    },
    account: {
      lastSnapshot: {},
      projectHealthGreenLabel: 'Good',
      projectHealthAmberLabel: 'At Risk',
      projectHealthRedLabel: 'Needs Attention',
      hasSeenOnboarding: false,
      hideAutoProvisioningPrompt: false,
      switchAllUsersToRedesignedSidebar: false,
    },
  },
  mutations: {
    init(state, { prefs, scope }) {
      Object.assign(state[scope], typedDefaults(state[scope], prefs, responseFieldMappings[scope]));
    },
    addToSnapshot(state, { data, scope }) {
      Object.assign(state[scope].lastSnapshot, data);
    },
    // Request from a Vue component to change - no typedDefaults
    change(state, { prefs, scope }) {
      Object.assign(state[scope], prefs);
    },
  },
  actions: {
    persistChanges({ dispatch, commit, state }, scope) {
      const [scopedState, mappings] = [state[scope], responseFieldMappings[scope]];
      // No Mappings? Preferences haven't been initialised yet
      if (isEmpty(mappings)) {
        return;
      }
      // Build API version of state
      const data = Object.keys(state[scope]).reduce((apiData, field) => {
        const rsfield = mappings[field] || field;
        if (scopedState[field] !== undefined && scopedState.lastSnapshot[rsfield] !== scopedState[field]) {
          // eslint-disable-next-line no-param-reassign
          apiData[rsfield] = scopedState[field];
        }
        return apiData;
      }, {});

      // If the lastSnapshot is empty, it means this is the first time the data
      // is being set (i.e. we just read the API response), so don't bother persisting it
      if (!isEmpty(scopedState.lastSnapshot)) {
        commit('addToSnapshot', { data, scope });
        return dispatch('persist', { prefs: data, scope });
      }
      commit('addToSnapshot', { data, scope });
    },
    persist: (ctx, { prefs, scope }) => api.put(`${scopeUrl[scope]}/prefs.json`, { prefs }),

    // TODO Hybrid - special action for TKO to trigger which will sync to Vue at the same time
    'hybrid-persist': ({ commit, dispatch }, { prefs, scope }) => {
      commit('init', { prefs, scope }); // sync TKO changes to Vue
      return dispatch('persist', { prefs, scope });
    },

    change: ({ commit }, { prefs, scope }) => commit('change', { prefs, scope }),
  },
};
