import Vue from 'vue';
import { lsSyncer } from '@/store/utils/localstorage';

export const watchers = [
  lsSyncer({
    mutation: 'layout/initLocalStorage',
    fields: {
      sidebarManuallyClosed: {
        name: 'isSidebarCollapsed',
        default: null,
      },
    },
  }),
];

export default {
  namespaced: true,
  state: {
    bodyId: 'preloader',
    essentialDataIsLoaded: false,
    isBoardsView: false,
    isPlannerView: false,
    isFiltersCollapsed: false,
    isFullScreen: false,
    isStandalonePage: false,
    customCSS: '',
    pageComponentToShow: '',
    shouldHideFooter: false,
    routeComponents: [],
    isNewVersionAvailable: false,
    isTrialExpired: false,
    hasOutageErrorMessage: false,
    hasOpenQuickView: false,
    shouldShowPrimaryNav: false,
    showBacklog: false,
    routeMeta: {},
    isPaymentOverDue: false,
    isQuickAddOpen: false,
    quickAddProject: null,
    areTkoModalsOpen: false,
    isShowingQuickJump: false,
    enforceProject: false,
    enforceProjectId: null,
    isHoveringOnTask: false,
    shouldUseAppShell: false,
  },
  actions: {
    toggleSidebar({ state, commit }) {
      commit('toggleSidebarCollapsed', !state.isSidebarCollapsed);
    },
  },
  mutations: {
    enforceProject(state, payload) {
      state.enforceProject = payload;
    },
    enforceProjectId(state, payload) {
      state.enforceProjectId = payload;
    },
    isShowingQuickJump(state, payload) {
      state.isShowingQuickJump = payload;
    },
    areTkoModalsOpen(state, payload) {
      state.areTkoModalsOpen = !!payload.length;
    },
    quickAddProject(state, payload) {
      state.quickAddProject = payload;
    },
    isQuickAddOpen(state, payload) {
      state.isQuickAddOpen = payload;
      window.app.isQuickAddOpen(payload);
    },
    bodyId(state, payload) {
      state.bodyId = payload;
    },
    essentialDataIsLoaded(state, payload) {
      state.essentialDataIsLoaded = payload;
    },
    isBoardsView(state, payload) {
      state.isBoardsView = !!payload;
    },
    isPlannerView(state, payload) {
      state.isPlannerView = !!payload;
    },
    isPaymentOverDue(state, payload) {
      state.isPaymentOverDue = payload;
    },
    isSidebarCollapsed(state, payload) {
      state.isSidebarCollapsed = !!payload;
    },
    filterSidebarVisible(state, payload) {
      state.isFiltersCollapsed = !payload;
    },
    customCSS(state, payload) {
      state.customCSS = payload;
    },
    pageComponentToShow(state, payload) {
      state.pageComponentToShow = payload;
    },
    shouldHideFooter(state, payload) {
      state.shouldHideFooter = payload;
    },
    shouldShowPrimaryNav(state, payload) {
      state.shouldShowPrimaryNav = payload;
    },
    showBacklog(state, payload) {
      state.showBacklog = !!payload;
    },
    isShowingFullScreenMode(state, payload) {
      state.isFullScreen = !!payload;
    },
    isNewVersionAvailable(state, payload) {
      state.isNewVersionAvailable = !!payload;
    },
    hasOutageErrorMessage(state, payload) {
      state.hasOutageErrorMessage = !!payload;
    },
    isTrialExpired(state, payload) {
      state.isTrialExpired = !!payload;
    },
    routeMeta(state, payload) {
      state.routeMeta = payload;
    },
    hasOpenQuickView(state, payload) {
      state.hasOpenQuickView = !!payload.length;
    },
    routeComponents(state, payload) {
      state.routeComponents = payload;
    },
    isStandalonePage(state, payload) {
      state.isStandalonePage = payload;
    },
    toggleSidebarCollapsed(state, payload) {
      state.isSidebarCollapsed = payload;
    },
    initLocalStorage(state, lsdata) {
      Vue.assign(state, lsdata);
    },
    isHoveringOnTask(state, payload) {
      state.isHoveringOnTask = payload;
    },
    shouldUseAppShell(state, payload) {
      state.shouldUseAppShell = payload;
    },
  },
  getters: {
    windowWidth: (state, getters, { responsive }) => responsive.width,
    pageComponentToShow: (state) => state.pageComponentToShow,
    isSidebarCollapsed(state, getters) {
      const minSidebarDisplayWidth = 990;
      if (state.isSidebarCollapsed != null) {
        return state.isSidebarCollapsed && getters.hasSidebar;
      }
      return getters.windowWidth < minSidebarDisplayWidth;
    },
    isAlertBannerActive(state) {
      return state.isTrialExpired || state.isNewVersionAvailable || state.hasOutageErrorMessage;
    },
    isBacklogVisible(state) {
      return state.isBoardsView && state.showBacklog;
    },
    isHoveringOnTask(state) {
      return state.isHoveringOnTask;
    },
    hasSidebar: (state, getters, { routes }) => {
      return state.routeMeta.sidebar != null || routes.tkoCurrentRoute.hasSidebar;
    },
    hasHeader: (state) => state.routeMeta.hasHeader !== false,
    shouldShowPrimaryNav: (state, getters) => getters.hasHeader && state.shouldShowPrimaryNav,
    shouldShowSecondNav: (state, getters, { project }) => getters.hasHeader && project.currentProjectId > 0,
    hasSecondaryMenu: (state, getters, { routes, project }) =>
      project.currentProjectId > 0 ||
      [
        'project',
        'everything',
        'projects',
        'planning',
        'schedule-page',
        'projects-schedule',
        'people-schedule',
      ].includes(routes.tkoCurrentRoute.page),
  },
};
