import isPositiveInteger from '@/utils/helpers/isPositiveInteger';

export default (_id) => {
  const id = parseInt(_id, 10);

  if (Number.isNaN(id)) {
    return false;
  }

  return isPositiveInteger(id);
};
