/* eslint-disable consistent-return */
import { MUTATIONS, ACTIONS, GETTERS, STATUS } from '../constants';

// Shorthand for recordMap getters
const recordMapped = (getter, id) => (id ? getter(id) : getter);

export default (cfg) => ({
  [ACTIONS.BEFORE_LOAD]({ commit, getters, id }) {
    const urlParams = recordMapped(getters[GETTERS.PAGINATION.URL_PARAMS], id);

    commit(MUTATIONS.PARAMS, urlParams);
  },
  [ACTIONS.LOADED_SUCCESS]({ dispatch, state }) {
    const triggerLoadAll = cfg.loadAll || cfg.initialPages || state.paginator === STATUS.updating;
    if (state[state.paginator].page === 1 && triggerLoadAll) {
      return dispatch(ACTIONS.PAGINATION.LOAD_ALL, cfg.initialPages);
    }
  },
  [ACTIONS.PAGINATION.LOAD_NEXT]({ dispatch, commit, state, getters, id }) {
    if (state[state.paginator].totalRecords === undefined) {
      throw new Error('LOAD_NEXT triggered before first LOAD');
    }
    if (recordMapped(getters[GETTERS.PAGINATION.HAS_MORE], id)) {
      commit(MUTATIONS.PAGINATION.NEXT_PAGE);
      return dispatch(ACTIONS.LOAD_WAIT);
    }
  },
  [ACTIONS.PAGINATION.LOAD_ALL]({ dispatch, commit, state, getters, id }, initialPages) {
    if (state[state.paginator].totalRecords === undefined) {
      throw new Error('LOAD_ALL triggered before first LOAD');
    }
    let pages = recordMapped(getters[GETTERS.PAGINATION.PAGES], id);

    pages = initialPages !== undefined ? Math.min(pages, initialPages) : pages;

    const pending = [];
    while (state[state.paginator].page < pages) {
      commit(MUTATIONS.PAGINATION.NEXT_PAGE);
      pending.push(dispatch(ACTIONS.LOAD));
    }
    return Promise.all(pending);
  },
});
