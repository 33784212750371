import { inject, provide, ref, watch } from 'vue-demi';
import useRedundantRealtimeUpdateGuard from '@sections/Notifications/useRedundantRealtimeUpdateGuard';
import { debounce } from 'lodash-es';
import { useAxios, useCurrentAccount, useCurrentUser, useOptimisticUpdates, useRealTimeUpdates } from '@/api';
import { setUnreadCount } from '@/scaffolding/socket';
import { v3Url } from '@/utils/fetcher';
import { shouldAddNewNotificationFromRealTimeUpdate } from '@/utils/helpers/notifications';

const NotificationsUnreadCountSymbol = Symbol('NotificationsUnreadCount');

export function provideNotificationsUnreadCount() {
  const api = useAxios();
  const currentUser = useCurrentUser();
  const currentAccount = useCurrentAccount();

  const count = ref(0);

  const { setRedundantRealtimeUpdateGuard, isRedundantRealTimeUpdate, unsetRedundantRealtimeUpdateGuard } =
    useRedundantRealtimeUpdateGuard();

  function decrement() {
    const result = count.value - 1;
    count.value = result < 0 ? 0 : result;
  }

  function increment() {
    count.value += 1;
  }

  function set(_count) {
    count.value = _count;
  }

  function clear() {
    set(0);
  }

  async function fetch() {
    try {
      const response = await api.get(v3Url(`notifications/unreadcount`));
      set(response.data.notifications.unread);
    } catch (e) {
      console.error(e);
    }
  }

  const debouncedFetch = debounce(fetch, 500);

  useRealTimeUpdates(async (event, rawEvent) => {
    if (!currentAccount.value?.realTimeNotifications) {
      return;
    }
    if (!rawEvent) {
      return;
    }

    if (event.detail === 'notification-mark-all-read') {
      clear();
      return;
    }

    // Note: the 'notification-markread' event is received when a comment or
    // message entity is marked as read. In this case we must also
    // mark the corresponding notification item as read
    if (event.detail === 'notification-markread') {
      // debounce fetch because marking a message a read send a 'notification-markread'
      // RTU for every item in that message thread
      debouncedFetch();
    }

    if (event.detail === 'notification-updated-read') {
      if (
        isRedundantRealTimeUpdate({
          notificationId: rawEvent.eventInfo.twimEventId,
          wasRead: true,
        })
      ) {
        unsetRedundantRealtimeUpdateGuard();
        return;
      }

      decrement();
      return;
    }

    if (event.detail === 'notification-updated-unread') {
      if (
        isRedundantRealTimeUpdate({
          notificationId: rawEvent.eventInfo.twimEventId,
          wasRead: false,
        })
      ) {
        unsetRedundantRealtimeUpdateGuard();
        return;
      }

      increment();
      return;
    }

    if (!shouldAddNewNotificationFromRealTimeUpdate(currentUser, currentAccount, event, rawEvent)) {
      return;
    }

    increment();
  });

  function optimisticIncrement(promise) {
    increment();
    promise.catch(decrement);
  }

  function optimisticDecrement(promise) {
    decrement();
    promise.catch(increment);
  }

  function optimisticClear(promise) {
    const oldCount = count.value;
    clear();
    const rollback = () => set(oldCount);
    promise.catch(rollback);
  }

  useOptimisticUpdates((event) => {
    if (event.type !== 'notification') {
      return;
    }

    const actions = ['update', 'markAllRead'];
    if (!actions.includes(event.action)) {
      return;
    }

    if (event.action === 'update') {
      setRedundantRealtimeUpdateGuard({
        notificationId: event.notification.id,
        wasRead: event.notification.read,
      });
      if (event.notification.read) {
        optimisticDecrement(event.promise);
      } else {
        optimisticIncrement(event.promise);
      }
    } else if (event.action === 'markAllRead') {
      optimisticClear(event.promise);
    }
  });

  // Sync unread count with TKO and desktop app
  watch(count, () => {
    setUnreadCount(count.value);

    if (window.desktopInterop) {
      window.desktopInterop.setNotificationCount(count.value);
    }
  });

  const notificationsUnreadCount = {
    count,
    increment,
    decrement,
    set,
    clear,
    fetch,
  };

  provide(NotificationsUnreadCountSymbol, notificationsUnreadCount);
  return notificationsUnreadCount;
}

export function useNotificationsUnreadCount() {
  return inject(NotificationsUnreadCountSymbol);
}
