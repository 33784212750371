// eslint-disable-next-line import/prefer-default-export
export const defaultTypes = {
  id: 0,
  name: '',
  logoUrl: '',
  addressOne: '',
  addressTwo: '',
  city: '',
  state: '',
  zip: '',
  countryCode: '',
  phone: '',
  fax: '',
  website: '',
  emailOne: '',
};
