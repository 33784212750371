import Vue from 'vue';
import annex from '@/services/vuex-annex';

export default async ({ type, payload }, state) => {
  if (['project/star', 'project/unstar'].includes(type)) {
    const id = payload;
    await Vue.nextTick();
    await annex.promises(state.project.records[id], 'working');

    const app = window.app;
    app.ko.postbox.publish('projects-update-stats', { vue: true });
    app.ko.postbox.publish('allTWEvents', {
      vue: true,
      eventTime: new Date(),
      itemType: 'project',
      actionType: app.consts.ACTIONTYPE.UPDATED,
      itemId: id,
      subType: type === 'project/unstar' ? 'unstarred' : 'starred',
    });
  } else if (type === 'task/delete') {
    const id = payload;
    // Defer the check, this way if there are working promises
    // to wait on, they will be available in the annex.
    await Vue.nextTick();
    await annex.promises(state.task.records[id], 'working');

    const { projectId, taskListId, parentTaskId } = state.task.records[id];
    const app = window.app;
    app.ko.postbox.publish('allTWEvents', {
      vue: true,
      eventTime: new Date(),
      itemType: 'task',
      actionType: app.consts.ACTIONTYPE.DELETED,
      itemId: id,
      projectId,
      taskListId,
      parentTaskId,
    });
  } else if (['task/progress'].includes(type)) {
    const { id } = payload;
    // Defer the check, this way if there are working promises
    // to wait on, they will be available in the annex.
    await Vue.nextTick();
    await annex.promises(state.task.records[id], 'working');

    const { projectId, taskListId, parentTaskId } = state.task.records[id];
    const app = window.app;
    app.ko.postbox.publish('allTWEvents', {
      vue: true,
      eventTime: new Date(),
      itemType: 'task',
      actionType: app.consts.ACTIONTYPE.UPDATED,
      itemId: id,
      projectId,
      taskListId,
      parentTaskId,
    });
  } else if (type === 'task/placeIn') {
    const { id } = payload;
    const { parentTaskId: newParentTaskId, taskListId: newTaskListId } = payload.payload;
    const { parentTaskId: oldParentTaskId, taskListId: oldTaskListId } = state.task.records[id];
    // Defer the check, this way if there are working promises
    // to wait on, they will be available in the annex.
    await Vue.nextTick();
    await annex.promises(state.task.records[id], 'working');

    const { projectId } = state.task.records[id];
    const app = window.app;
    app.ko.postbox.publish('allTWEvents', {
      vue: true,
      eventTime: new Date(),
      itemType: 'task',
      actionType: app.consts.ACTIONTYPE.UPDATED,
      subType: 'task-reorder',
      itemId: id,
      projectId,
      oldTaskListId,
      oldParentTaskId,
      newTaskListId,
      newParentTaskId,
    });
  } else if (type === 'people/moveCompany') {
    const app = window.app;
    const { userIds } = payload;
    app.ko.postbox.publish('allTWEvents', {
      vue: true,
      eventTime: new Date(),
      itemType: 'person',
      subType: 'person-moveCompany',
      actionType: app.consts.ACTIONTYPE.UPDATED,
      itemId: userIds.length === 1 ? userIds[0] : 0,
    });
  } else if (type === 'people/invite') {
    const app = window.app;
    app.ko.postbox.publish('allTWEvents', {
      vue: true,
      eventTime: new Date(),
      itemType: 'person',
      actionType: app.consts.ACTIONTYPE.ADDED,
      itemId: 0,
      companyId: state.user.companyId,
    });
  } else if (type === 'project/tasklists/sort') {
    const { id } = payload;
    // Defer the check, this way if there are working promises
    // to wait on, they will be available in the annex.
    await Vue.nextTick();
    await annex.promises(state.project.tasklists.records[id], 'working');

    const app = window.app;
    app.ko.postbox.publish('allTWEvents', {
      vue: true,
      eventTime: new Date(),
      itemType: 'taskLists',
      actionType: app.consts.ACTIONTYPE.UPDATED,
      itemId: id,
    });
  } else if (type === 'user/cancel') {
    const app = window.app;
    // Tidy all all account related info - can't call app.logout as
    // it will return a 420 error as account is already cancelled
    app.loggedInUser = null;
    app.isLoggedIn(false);
    app.cachedProjects = {};
    // moment.tz.setDefault null
    if (typeof app.heartBeatInterval !== 'undefined') {
      clearInterval(app.heartBeatInterval);
    }
    app.status('cancelled');
  }
};
