import { createListLoader, API } from '@/store/utils/loader';
import { createPagination } from '@/store/utils/loader/pagination/v3';

export default createListLoader({
  id: 'teamworkTemplate/all',
  namespaced: true,
  config: {
    module: 'teamworkTemplate',
    url: `${API.v3}/projects/teamwork/samples.json`,
    sort: false,
    mapResponse: (ctx, rs) => rs.data.projects,
    params: () => ({
      include: 'categories',
      'fields[sampleprojects]': 'id,name,categoryId,description,icon,imagePreview,code',
    }),
    includedConfig: {
      projectCategories: 'teamworkTemplateCategory',
    },
  },
  modules: {
    pagination: createPagination({
      config: { loadAll: true },
    }),
  },
});
