import { validateHex, isColorLight, darken } from '@/utils/helpers/color';

export default {
  namespaced: true,
  state: {
    theme: { id: '0', color: '' },
  },
  mutations: {
    theme(state, payload) {
      state.theme = payload;
    },
  },
  getters: {
    rawColor: (state) => state.theme.color.replace('#', ''),
    validColor: (state, getters) => validateHex(getters.rawColor),
    isLight: (state, getters) => getters.validColor && isColorLight(getters.rawColor),
    themeClass: (state, getters) => [getters.isLight ? 'color-mode--light' : 'color-mode--dark'],
    darkTint: (state, getters) => darken(getters.rawColor, 0.75),
    themeCSS: (state, getters) => `
      .w-data-grid th, .w-time-grid th, table.timeGrid th, table.dataGrid th{
        background-color: ${getters.darkTint} !important;
      }
    `,
  },
  actions: {
    ssr({ commit }) {
      const { STATUS, branding } = window.initialData['v/1/settings/branding.json'] || {};

      if (STATUS === 'OK') {
        commit('theme', branding.theme);
      }
    },
  },
};
