/* eslint-disable consistent-return */
import Vue from 'vue';
import moment from 'moment';
import { last } from 'lodash-es';
import lscache from 'lscache';
import annex from '@/services/vuex-annex';
import api, { API_PREFIXES as API } from '@/services/api';
import { mapActions } from '@/store/utils/record-mapper';
import { convertAssignedTo } from '@/utils/helpers/responsible';
import recordDefault from './recordDefault';

const today = () => moment.tz(moment.tz.guess()).startOf('day');
const dateFormat = 'YYYYMMDD';

const dateToAPI = (date) => (date.isValid() ? date.format(dateFormat) : '');
const resolvedDate = (date) => (date.isValid() ? date : today());

export default mapActions({
  toggleCollapsed({ commit, state, id }) {
    commit('toggleCollapsed');
    lscache.set(`twProjects-task-${id}-displaying-subtasks`, state.expanded);
  },
  changeName({ commit, dispatch, state, id }, newName) {
    if (!newName) {
      dispatch('notifications/flashes/error', Vue.t('The task name cannot be blank'), { root: true, recordMap: false });
      return;
    }
    const oldName = state.name;
    commit('changeName', newName);
    return api
      .put(`/tasks/${id}.json`, {
        'todo-item': { content: newName },
      })
      .catch((e) => {
        commit('changeName', oldName);
        throw e;
      });
  },
  progress({ commit, dispatch, state, id }, newProgress) {
    if (typeof newProgress !== 'number') {
      dispatch('notifications/flashes/error', Vue.t('The task progress must be numeric'), {
        root: true,
        recordMap: false,
      });
      return;
    }
    const oldProgress = state.progress;
    if (newProgress === 100) {
      commit('complete', true);
    } else {
      commit('progress', newProgress);
    }
    const working = api
      .put(`/tasks/${id}/progress.json`, {
        progress: newProgress,
      })
      .catch((e) => {
        commit(newProgress === 100 ? 'uncomplete' : 'progress', oldProgress);
        throw e;
      });
    return annex.promises(state, { working });
  },
  move({ dispatch, getters, rootGetters, id, state }, startDate) {
    const { diff, add } = rootGetters['project/workingWeek'](state.projectId);
    const resolved = getters.resolveRange(id);
    const newStart = resolvedDate(startDate);
    const deltaDays = diff(resolved.startDate.toDate(), newStart.toDate());
    let dueDate = moment(add(resolved.dueDate.toDate(), -deltaDays));

    // start date is removed, check if due date should be too
    if (!startDate.isValid() && dueDate.isSame(newStart)) {
      dueDate = recordDefault.dueDate.clone();
    }
    return dispatch('range', { startDate, dueDate });
  },
  range({ commit, state, id }, { startDate, dueDate, dueDateFromMilestone }) {
    const oldStart = state.startDate;
    const oldDue = state.dueDate;
    const oldDueDateFromMilestone = state.dueDateFromMilestone;
    const item = {
      'push-dependents': false,
      'push-external-only': false,
      'use-defaults': false,
    };
    if (startDate) {
      commit('startDate', startDate);
      item['start-date'] = dateToAPI(startDate);
    }
    if (dueDate) {
      commit('dueDate', { dueDate, dueDateFromMilestone });
      item['due-date'] = dueDateFromMilestone ? '' : dateToAPI(dueDate);
    }
    return api.put(`/tasks/${id}.json`, { 'todo-item': item }).catch((e) => {
      if (startDate) {
        commit('startDate', oldStart);
      }
      if (dueDate) {
        commit('dueDate', {
          dueDate: oldDue,
          dueDateFromMilestone: oldDueDateFromMilestone,
        });
      }
      throw e;
    });
  },
  addSubTask({ state, commit, id: depTaskId }, { id, positionAfterTask }) {
    commit('addSubTask', { id, positionAfterTask });
    commit('resetOrderSubtasks');
    commit(
      'dependencies/addPredecessor',
      { depTaskId, id, projectId: state.projectId, type: 'complete' },
      { recordMap: false, root: true },
    );
  },
  localPlaceIn({ dispatch, commit, state, id }, { from, to }) {
    if (from) {
      commit('removeSubTask', { id: from, payload: id }, { recordMap: false });
      dispatch('removeLocalPredecessor', { id: from, payload: id }, { recordMap: false });
    } else {
      commit('removeFromTasklist', state.taskListId);
    }
    if (!to.parent) {
      commit('addToTasklist', to.tasklist);
    }
    commit('placeIn', { parentTaskId: to.parent, taskListId: to.tasklist });
    if (to.parent) {
      dispatch('addSubTask', { id: to.parent, payload: { id, positionAfterTask: to.posAfter } }, { recordMap: false });
    } else {
      dispatch(
        'tasklist/addTask',
        { id: to.tasklist, payload: { id, positionAfterTask: to.posAfter } },
        { recordMap: false, root: true },
      );
    }
  },
  placeIn(
    {
      dispatch,
      state,
      id,
      rootState: {
        task: { records, subtasks },
      },
      rootGetters,
    },
    { parentTaskId, positionAfterTask, taskListId },
  ) {
    let posAfter = positionAfterTask;
    if (posAfter === 'last') {
      posAfter = parentTaskId
        ? last(subtasks[parentTaskId].filter((sibId) => sibId !== id))
        : last(rootGetters['tasklist/tasks'](taskListId)).id;
    }

    // Destination data
    const to = {
      parent: parentTaskId,
      tasklist: parentTaskId ? records[parentTaskId].taskListId : taskListId,
      posAfter,
    };

    // Revert data for rollback
    const parent = state.parentTaskId;
    const siblings = parent ? subtasks[parent] : rootGetters['tasklist/tasks'](state.taskListId).map((t) => t.id);
    const revert = {
      parent,
      tasklist: state.taskListId,
      posAfter: siblings[siblings.indexOf(id) - 1] || -1,
    };

    dispatch('localPlaceIn', { from: revert.parent, to });
    const working = api
      .put(`/tasks/${id}.json`, {
        'todo-item': {
          'notify-followers': false,
          parentTaskId: to.parent,
          positionAfterTask: to.posAfter,
          taskListId: to.tasklist,
        },
      })
      .catch((e) => {
        dispatch('localPlaceIn', { from: to.parent, to: revert });
        throw e;
      });
    return annex.promises(state, { working });
  },
  placeAfter({ dispatch, rootState, state }, sibling) {
    const { parentTaskId, taskListId } = sibling > -1 ? rootState.task.records[sibling] : state;
    return dispatch('placeIn', {
      parentTaskId,
      taskListId,
      positionAfterTask: sibling,
    });
  },
  placeBefore({ dispatch, rootState, rootGetters, state }, sibling) {
    const { parentTaskId, taskListId } = sibling > -1 ? rootState.task.records[sibling] : state;
    const positionAfterTask = sibling > -1 ? rootGetters['task/prevSiblingId'](sibling) : 'last';
    return dispatch('placeIn', { parentTaskId, taskListId, positionAfterTask });
  },
  complete({ state, commit, id }) {
    if (state.status === 'completed') {
      return;
    }
    const oldProgress = state.progress;
    commit('complete', true);
    return api.put(`/tasks/${id}/complete.json`).catch((e) => {
      commit('uncomplete', oldProgress);
      throw e;
    });
  },
  uncomplete({ state, commit, id }, progress) {
    if (state.status !== 'completed') {
      return;
    }
    commit('uncomplete', progress);
    return api.put(`/tasks/${id}/uncomplete.json`).catch((e) => {
      commit('complete', true);
      throw e;
    });
  },
  delete({ state, commit, dispatch, id }) {
    if (state.status === 'deleted') {
      return;
    }
    commit('delete');
    const { parentTaskId, projectId, taskListId } = state;
    if (!parentTaskId) {
      commit('removeFromTasklist', taskListId);
    } else {
      commit('removeSubTask', { id: parentTaskId, payload: id }, { recordMap: false });
      dispatch('removeLocalPredecessor', { id: parentTaskId, payload: id }, { recordMap: false });
    }
    // We tracking 'working' API requests, as there are
    // watchers of the above mutation that should wait for
    // working to finish too :)
    const working = api.delete(`${API.v3}/tasks/${id}.json`).catch((e) => {
      commit('undelete');
      if (!parentTaskId) {
        commit('addToTasklist', taskListId);
      } else {
        commit('addSubTask', { id: parentTaskId, payload: { id } }, { recordMap: false });
        commit(
          'dependencies/addPredecessor',
          {
            depTaskId: parentTaskId,
            id,
            projectId,
            type: 'complete',
          },
          { recordMap: false, root: true },
        );
      }
      throw e;
    });
    return annex.promises(state, { working });
  },
  undelete({ state, commit, dispatch, id }) {
    if (state.status !== 'deleted') {
      return;
    }
    commit('busy');
    commit('undelete');
    const { parentTaskId, projectId, taskListId } = state;
    if (!parentTaskId) {
      commit('addToTasklist', taskListId);
    } else {
      commit('addSubTask', { id: parentTaskId, payload: { id } }, { recordMap: false });
      commit(
        'dependencies/addPredecessor',
        {
          depTaskId: parentTaskId,
          id,
          projectId,
          type: 'complete',
        },
        { recordMap: false, root: true },
      );
    }
    return api
      .put(`/trashcan/tasks/${id}/restore.json`)
      .catch((e) => {
        commit('delete');
        if (!parentTaskId) {
          commit('removeFromTasklist', taskListId);
        } else {
          commit('removeSubTask', { id: parentTaskId, payload: id }, { recordMap: false });
          dispatch('removeLocalPredecessor', { id: parentTaskId, payload: id }, { recordMap: false });
        }
        throw e;
      })
      .finally(() => commit('notBusy'));
  },
  newSubTaskPlaceholder({ dispatch, commit, state }, task = {}) {
    const newTask = {
      ...task,
      parentTaskId: state.id,
      taskListId: state.taskListId,
    };
    // In reality, this is synchronous and we need to avoid a tick inbetween adding
    // and reseting order, otherwise we'll lose UI focus and cancel the placeholder :(
    const promise = dispatch('newPlaceholder', newTask, { recordMap: false });
    commit('resetOrderSubtasks');
    return promise; // need to return the ID
  },
  addPredecessor({ state, commit, rootState, id: depTaskId }, { id, dependentCant = 'start' }) {
    commit(
      'dependencies/addPredecessor',
      { id, depTaskId, projectId: state.projectId, type: dependentCant },
      { root: true, recordMap: false },
    );
    const predecessors = rootState.dependencies.preds[depTaskId].map((p) => ({
      id: p.id,
      type: p.dependentCant || p.type,
    }));
    return api
      .put(`/tasks/${state.id}.json`, {
        'todo-item': {
          predecessors,
        },
      })
      .catch((e) => {
        commit('dependencies/removePredecessor', predecessors[predecessors.length - 1], {
          root: true,
          recordMap: false,
        });
        throw e;
      });
  },
  removeLocalPredecessor({ rootState, commit, id: depTaskId }, id) {
    const original = rootState.dependencies.preds[depTaskId];
    if (original) {
      const dep = original.find((p) => p.id === id);
      if (dep) {
        commit('dependencies/removePredecessor', dep, {
          root: true,
          recordMap: false,
        });
      }
      return dep;
    }
  },
  async removePredecessor({ rootState, commit, dispatch, id: depTaskId }, id) {
    const dep = await dispatch('removeLocalPredecessor', id);
    if (!dep) {
      return;
    }
    const predecessors = rootState.dependencies.preds[depTaskId]
      .filter((p) => p !== dep)
      .map((p) => ({ id: p.id, type: p.dependentCant || p.type }));

    return api
      .put(`/tasks/${depTaskId}.json`, {
        'todo-item': {
          predecessors,
        },
      })
      .catch((e) => {
        commit('dependencies/addPredecessor', dep, {
          root: true,
          recordMap: false,
        });
        throw e;
      });
  },
  assign({ state, commit }, assignedTo) {
    const original = state.assignedTo;
    const responsiblePartyId = convertAssignedTo(assignedTo).join(',');
    commit('assignedTo', assignedTo);
    return api
      .put(`/tasks/${state.id}.json`, {
        'todo-item': {
          'responsible-party-id': responsiblePartyId,
        },
      })
      .catch((e) => {
        commit('assignedTo', original);
        throw e;
      });
  },
});
