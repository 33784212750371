const componentChunkMap = require('./component-chunks.js');

module.exports = function (name) {
		var component = null;

	if (componentChunkMap[name] === 'hybrid'){
		component = import(/* webpackChunkName: "hybrid" */ './chunks/hybrid').then(m => m.default);
	}
	if (componentChunkMap[name] === 'core'){
		component = import(/* webpackChunkName: "core" */ './chunks/core').then(m => m.default);
	}
	if (componentChunkMap[name] === 'peoplepicker'){
		component = import(/* webpackChunkName: "peoplepicker" */ './chunks/peoplepicker').then(m => m.default);
	}
	if (componentChunkMap[name] === 'dashboardnew'){
		component = import(/* webpackChunkName: "dashboardnew" */ './chunks/dashboardnew').then(m => m.default);
	}
	if (componentChunkMap[name] === 'views'){
		component = import(/* webpackChunkName: "views" */ './chunks/views').then(m => m.default);
	}
	if (componentChunkMap[name] === 'summary'){
		component = import(/* webpackChunkName: "summary" */ './chunks/summary').then(m => m.default);
	}
	if (componentChunkMap[name] === 'customFields'){
		component = import(/* webpackChunkName: "customFields" */ './chunks/customFields').then(m => m.default);
	}
	if (componentChunkMap[name] === 'teams'){
		component = import(/* webpackChunkName: "teams" */ './chunks/teams').then(m => m.default);
	}
	if (componentChunkMap[name] === 'projectUpdates'){
		component = import(/* webpackChunkName: "projectUpdates" */ './chunks/projectUpdates').then(m => m.default);
	}
	if (componentChunkMap[name] === 'activity'){
		component = import(/* webpackChunkName: "activity" */ './chunks/activity').then(m => m.default);
	}
	if (componentChunkMap[name] === 'audit'){
		component = import(/* webpackChunkName: "audit" */ './chunks/audit').then(m => m.default);
	}
	if (componentChunkMap[name] === 'tags'){
		component = import(/* webpackChunkName: "tags" */ './chunks/tags').then(m => m.default);
	}
	if (componentChunkMap[name] === 'reactions'){
		component = import(/* webpackChunkName: "reactions" */ './chunks/reactions').then(m => m.default);
	}
	if (componentChunkMap[name] === 'dash'){
		component = import(/* webpackChunkName: "dash" */ './chunks/dash').then(m => m.default);
	}
	if (componentChunkMap[name] === 'welcome'){
		component = import(/* webpackChunkName: "welcome" */ './chunks/welcome').then(m => m.default);
	}
	if (componentChunkMap[name] === 'projects'){
		component = import(/* webpackChunkName: "projects" */ './chunks/projects').then(m => m.default);
	}
	if (componentChunkMap[name] === 'overview'){
		component = import(/* webpackChunkName: "overview" */ './chunks/overview').then(m => m.default);
	}
	if (componentChunkMap[name] === 'import'){
		component = import(/* webpackChunkName: "import" */ './chunks/import').then(m => m.default);
	}
	if (componentChunkMap[name] === 'comments'){
		component = import(/* webpackChunkName: "comments" */ './chunks/comments').then(m => m.default);
	}
	if (componentChunkMap[name] === 'notebooks'){
		component = import(/* webpackChunkName: "notebooks" */ './chunks/notebooks').then(m => m.default);
	}
	if (componentChunkMap[name] === 'links'){
		component = import(/* webpackChunkName: "links" */ './chunks/links').then(m => m.default);
	}
	if (componentChunkMap[name] === 'messages'){
		component = import(/* webpackChunkName: "messages" */ './chunks/messages').then(m => m.default);
	}
	if (componentChunkMap[name] === 'everythingTasks'){
		component = import(/* webpackChunkName: "everythingTasks" */ './chunks/everythingTasks').then(m => m.default);
	}
	if (componentChunkMap[name] === 'reports'){
		component = import(/* webpackChunkName: "reports" */ './chunks/reports').then(m => m.default);
	}
	if (componentChunkMap[name] === 'filters'){
		component = import(/* webpackChunkName: "filters" */ './chunks/filters').then(m => m.default);
	}
	if (componentChunkMap[name] === 'risks'){
		component = import(/* webpackChunkName: "risks" */ './chunks/risks').then(m => m.default);
	}
	if (componentChunkMap[name] === 'everything'){
		component = import(/* webpackChunkName: "everything" */ './chunks/everything').then(m => m.default);
	}
	if (componentChunkMap[name] === 'planning'){
		component = import(/* webpackChunkName: "planning" */ './chunks/planning').then(m => m.default);
	}
	if (componentChunkMap[name] === 'embed'){
		component = import(/* webpackChunkName: "embed" */ './chunks/embed').then(m => m.default);
	}
	if (componentChunkMap[name] === 'projectTasks'){
		component = import(/* webpackChunkName: "projectTasks" */ './chunks/projectTasks').then(m => m.default);
	}
	if (componentChunkMap[name] === 'sharedBoardComponents'){
		component = import(/* webpackChunkName: "sharedBoardComponents" */ './chunks/sharedBoardComponents').then(m => m.default);
	}
	if (componentChunkMap[name] === 'taskCards'){
		component = import(/* webpackChunkName: "taskCards" */ './chunks/taskCards').then(m => m.default);
	}
	if (componentChunkMap[name] === 'projectsPortfolio'){
		component = import(/* webpackChunkName: "projectsPortfolio" */ './chunks/projectsPortfolio').then(m => m.default);
	}
	if (componentChunkMap[name] === 'projectsTemplates'){
		component = import(/* webpackChunkName: "projectsTemplates" */ './chunks/projectsTemplates').then(m => m.default);
	}
	if (componentChunkMap[name] === 'taskTemplates'){
		component = import(/* webpackChunkName: "taskTemplates" */ './chunks/taskTemplates').then(m => m.default);
	}
	if (componentChunkMap[name] === 'tasksShared'){
		component = import(/* webpackChunkName: "tasksShared" */ './chunks/tasksShared').then(m => m.default);
	}
	if (componentChunkMap[name] === 'tasksSecondary'){
		component = import(/* webpackChunkName: "tasksSecondary" */ './chunks/tasksSecondary').then(m => m.default);
	}
	if (componentChunkMap[name] === 'taskModals'){
		component = import(/* webpackChunkName: "taskModals" */ './chunks/taskModals').then(m => m.default);
	}
	if (componentChunkMap[name] === 'gantt'){
		component = import(/* webpackChunkName: "gantt" */ './chunks/gantt').then(m => m.default);
	}
	if (componentChunkMap[name] === 'milestones'){
		component = import(/* webpackChunkName: "milestones" */ './chunks/milestones').then(m => m.default);
	}
	if (componentChunkMap[name] === 'milestoneModals'){
		component = import(/* webpackChunkName: "milestoneModals" */ './chunks/milestoneModals').then(m => m.default);
	}
	if (componentChunkMap[name] === 'billing'){
		component = import(/* webpackChunkName: "billing" */ './chunks/billing').then(m => m.default);
	}
	if (componentChunkMap[name] === 'billingModals'){
		component = import(/* webpackChunkName: "billingModals" */ './chunks/billingModals').then(m => m.default);
	}
	if (componentChunkMap[name] === 'calendar'){
		component = import(/* webpackChunkName: "calendar" */ './chunks/calendar').then(m => m.default);
	}
	if (componentChunkMap[name] === 'statuses'){
		component = import(/* webpackChunkName: "statuses" */ './chunks/statuses').then(m => m.default);
	}
	if (componentChunkMap[name] === 'projectPeople'){
		component = import(/* webpackChunkName: "projectPeople" */ './chunks/projectPeople').then(m => m.default);
	}
	if (componentChunkMap[name] === 'people'){
		component = import(/* webpackChunkName: "people" */ './chunks/people').then(m => m.default);
	}
	if (componentChunkMap[name] === 'peopleSecondary'){
		component = import(/* webpackChunkName: "peopleSecondary" */ './chunks/peopleSecondary').then(m => m.default);
	}
	if (componentChunkMap[name] === 'addUser'){
		component = import(/* webpackChunkName: "addUser" */ './chunks/addUser').then(m => m.default);
	}
	if (componentChunkMap[name] === 'peopleModals'){
		component = import(/* webpackChunkName: "peopleModals" */ './chunks/peopleModals').then(m => m.default);
	}
	if (componentChunkMap[name] === 'search'){
		component = import(/* webpackChunkName: "search" */ './chunks/search').then(m => m.default);
	}
	if (componentChunkMap[name] === 'settings'){
		component = import(/* webpackChunkName: "settings" */ './chunks/settings').then(m => m.default);
	}
	if (componentChunkMap[name] === 'integrations'){
		component = import(/* webpackChunkName: "integrations" */ './chunks/integrations').then(m => m.default);
	}
	if (componentChunkMap[name] === 'project'){
		component = import(/* webpackChunkName: "project" */ './chunks/project').then(m => m.default);
	}
	if (componentChunkMap[name] === 'projectSettings'){
		component = import(/* webpackChunkName: "projectSettings" */ './chunks/projectSettings').then(m => m.default);
	}
	if (componentChunkMap[name] === 'projectBudget'){
		component = import(/* webpackChunkName: "projectBudget" */ './chunks/projectBudget').then(m => m.default);
	}
	if (componentChunkMap[name] === 'files'){
		component = import(/* webpackChunkName: "files" */ './chunks/files').then(m => m.default);
	}
	if (componentChunkMap[name] === 'filesModals'){
		component = import(/* webpackChunkName: "filesModals" */ './chunks/filesModals').then(m => m.default);
	}
	if (componentChunkMap[name] === 'time'){
		component = import(/* webpackChunkName: "time" */ './chunks/time').then(m => m.default);
	}
	if (componentChunkMap[name] === 'timeModals'){
		component = import(/* webpackChunkName: "timeModals" */ './chunks/timeModals').then(m => m.default);
	}
	if (componentChunkMap[name] === 'shortcuts'){
		component = import(/* webpackChunkName: "shortcuts" */ './chunks/shortcuts').then(m => m.default);
	}
	if (componentChunkMap[name] === 'workload'){
		component = import(/* webpackChunkName: "workload" */ './chunks/workload').then(m => m.default);
	}
	if (componentChunkMap[name] === 'widgets'){
		component = import(/* webpackChunkName: "widgets" */ './chunks/widgets').then(m => m.default);
	}
	if (componentChunkMap[name] === 'frequentModals'){
		component = import(/* webpackChunkName: "frequentModals" */ './chunks/frequentModals').then(m => m.default);
	}
	if (componentChunkMap[name] === 'projectsModals'){
		component = import(/* webpackChunkName: "projectsModals" */ './chunks/projectsModals').then(m => m.default);
	}
	if (componentChunkMap[name] === 'settingsModals'){
		component = import(/* webpackChunkName: "settingsModals" */ './chunks/settingsModals').then(m => m.default);
	}
	if (componentChunkMap[name] === 'lazyModals'){
		component = import(/* webpackChunkName: "lazyModals" */ './chunks/lazyModals').then(m => m.default);
	}
	if (componentChunkMap[name] === 'rare1'){
		component = import(/* webpackChunkName: "rare1" */ './chunks/rare1').then(m => m.default);
	}
	if (componentChunkMap[name] === 'linkedAccounts'){
		component = import(/* webpackChunkName: "linkedAccounts" */ './chunks/linkedAccounts').then(m => m.default);
	}
	if (componentChunkMap[name] === 'chat'){
		component = import(/* webpackChunkName: "chat" */ './chunks/chat').then(m => m.default);
	}
	if (componentChunkMap[name] === 'hubSpot'){
		component = import(/* webpackChunkName: "hubSpot" */ './chunks/hubSpot').then(m => m.default);
	}
	if (componentChunkMap[name] === 'reassign'){
		component = import(/* webpackChunkName: "reassign" */ './chunks/reassign').then(m => m.default);
	}
	if (componentChunkMap[name] === '2fa'){
		component = import(/* webpackChunkName: "2fa" */ './chunks/2fa').then(m => m.default);
	}
	if (componentChunkMap[name] === 'referral'){
		component = import(/* webpackChunkName: "referral" */ './chunks/referral').then(m => m.default);
	}
	if (componentChunkMap[name] === 'pageTest'){
		component = import(/* webpackChunkName: "pageTest" */ './chunks/pageTest').then(m => m.default);
	}
	if (componentChunkMap[name] === 'inputs'){
		component = import(/* webpackChunkName: "inputs" */ './chunks/inputs').then(m => m.default);
	}
	if (componentChunkMap[name] === 'attachments'){
		component = import(/* webpackChunkName: "attachments" */ './chunks/attachments').then(m => m.default);
	}
	if (componentChunkMap[name] === 'tips1'){
		component = import(/* webpackChunkName: "tips1" */ './chunks/tips1').then(m => m.default);
	}
	if (componentChunkMap[name] === 'clockInOut'){
		component = import(/* webpackChunkName: "clockInOut" */ './chunks/clockInOut').then(m => m.default);
	}
	if (componentChunkMap[name] === 'ot'){
		component = import(/* webpackChunkName: "ot" */ './chunks/ot').then(m => m.default);
	}

	return component; 
}