import { computed, shallowRef } from 'vue-demi';
import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  const items = response.data.budgets?.map((budget) => budget) ?? [];
  return items;
}

function responseToMeta(response) {
  return response.data.meta;
}

export default function useProjectBudgetRetainerHistoryLoader({
  originatorBudgetId: _originatorBudgetId,
  params,
  count,
  pageSize = 50,
}) {
  const originatorBudgetId = shallowRef(_originatorBudgetId);
  const url = computed(() => v3Url(`projects/budgets/${originatorBudgetId.value}/cycle`));

  const { state, refresh } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    responseToMeta,
    pageSize,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'budget') {
      // Only call the history endpoint for retainer budgets
      if (!event.isRetainer) {
        return;
      }
      // if the budget was edited,
      // refresh
      if (event.action === 'edited') {
        refresh();
      }
      return;
    }
    if (event.type === 'budgetExpense') {
      refresh();
      return;
    }
    if (event.type === 'time' || (event.type === 'project' && event.action === 'rate-set')) {
      const budget = state.items.value.find(({ projectId }) => projectId === event.projectId);
      if (budget) {
        refresh();
      }
      return;
    }
    if (event.type === 'installation' && event.action === 'rate-set') {
      refresh();
    }
  });

  return state;
}
