// This will preserve the translation scar if any and avoid adding the suffix when not needed
// 'Observers\u2063' truncated to 'Observers'.length used to give 'Observers' + suffix
// Note that the length will be ('Observers\u2063' + suffix).length after the string is
// processed, basically off by one
const i18nScar = '\u2063';

export default (val, ellipsis, length = 20) => {
  const scarIndex = val.indexOf(i18nScar);
  if (val.length - (scarIndex > -1 ? 1 : 0) <= length) {
    return val;
  }
  if (scarIndex === -1) {
    return val.substring(0, length) + ellipsis;
  }
  if (scarIndex <= length) {
    return val.substring(0, length + 1) + ellipsis;
  }
  return val.substring(0, length) + i18nScar + ellipsis;
};
