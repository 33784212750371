import api from '@/services/api';
import { createFilteredListLoader } from '@/store/utils/loader/filtered';

export default {
  namespaced: true,
  modules: {
    loader: createFilteredListLoader({
      config: {
        url: '/statuses.json',
        params: () => ({ showEveryone: true }),
        filterParams: (state) => ({
          filterText: state.statuses.searchTerm,
        }),
        isFiltered: (state) => !!state.statuses.searchTerm,
        module: 'status',
        commit: 'status/updateOrAddStatuses',
        toId: (ent) => ent.userId,
        isDeleted: () => false, // no such thing as a deleted status
        mapResponse: (ctx, rs) => rs.data.userStatuses,
        pagination: { loadAll: true },
        updater: true,
      },
    }),
  },
  state: {
    searchTerm: '',
  },
  getters: {
    searchTerm: (state) => state.searchTerm,
    statuses: (state, getters, rootState) => getters.list.map((id) => rootState.status.records[id]),
  },
  mutations: {
    filter(state, searchTerm) {
      state.searchTerm = searchTerm;
    },
  },
  actions: {
    update(context, status) {
      return api
        .post('/me/status.json', {
          userStatus: { status, notify: false, notifyIds: [] },
        })
        .catch(() => {
          // TODO
        });
    },
  },
};
