/* eslint-disable no-underscore-dangle,no-param-reassign */
import moment from 'moment';
import 'moment-timezone';
import '@/scaffolding/tko-moment';

// Make all fields on `object` hidden (not enumerable)
// except any in the whitelist.
const hideFields = (object, whitelist) =>
  Object.keys(object).forEach((field) => {
    if (!whitelist.includes(field)) {
      const val = object[field];
      Object.defineProperty(object, field, {
        value: val,
        configurable: true,
        writable: true,
        enumerable: false,
      });
    }
  });

/**
 * Modify Moment locales and timezones so that only the identifier field
 * is enumerable, this way the other data will be hidden from reactivity.
 *
 * Individual Moment instances can be reactive and if they have Locale or TZ
 * changed, it will trigger reactivity, but changes to the Locale or TZ
 * shared objects won't cause problems.
 *
 * See the Debug plugin for the code to rehydrate these Moment objects
 * when serialized, and restore the full locale and TZ objects.
 */
moment.locales().forEach((code) => {
  const locale = moment.localeData(code);
  hideFields(locale, ['_abbr']);
});
moment.tz.names().forEach((tz) => {
  const zone = moment.tz.zone(tz);
  hideFields(zone, ['name']);
});
