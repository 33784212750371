import { v1Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';

function responseToItems({ data: { dataViews } }) {
  for (let i = 0; i < dataViews.length; i += 1) {
    const dataView = dataViews[i];
    dataView.id = Number(dataView.id);
    dataView.userId = Number(dataView.userId);
    dataView.projectId = Number(dataView.projectId);
  }

  return dataViews;
}

export default function useDataViewsLoader({ count, params, pageSize = 50 }) {
  const { state } = useListLoader({
    url: v1Url('me/dataviews'),
    params,
    count,
    pageSize,
    responseToItems,
  });

  return state;
}
