import api from './helpers/api';

export default async (endpoint, params, options = {}) => {
  const resp = await api.get(`${endpoint}`, { params, ...options });

  const tickets = resp.data.tickets;
  const included = resp.data.included;
  const mappedTickets = tickets.map((ticketItem) => {
    const ticket = ticketItem;
    ticket.customer = included?.customers.find((customer) => customer.id === ticket.customer.id);
    ticket.status = included?.ticketstatuses.find((status) => status.id === ticket.status.id);
    if (ticket.agent) {
      ticket.agent = included?.users.find((user) => user.id === ticket.agent.id);
    } else {
      ticket.agent = null;
    }
    ticket.hasAttachments = ticket?.files != null;
    return ticket;
  });

  return {
    tickets: mappedTickets,
  };
};
