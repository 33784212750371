export const peopleIncluded =
  'allocations,projects,assignee,projects.companies,assignee.timelogs,assignee.calendarEvents,assignee.workingHours,assignee.workingHours.workingHoursEntry';
export const peopleIncludedConfig = {
  allocations: 'allocations',
  users: 'people',
  projects: 'project',
  companies: 'company',
  timelogs: 'timelog',
  calendarEvents: 'calendarEvent',
  workingHours: 'workingHours',
  workingHourEntries: 'workingHours/entries',
};
