const allDays = [0, 1, 2, 3, 4, 5, 6];
const noOfDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

// Factors for millis
const factor = { seconds: 1000 };
factor.minutes = factor.seconds * 60;
factor.hours = factor.minutes * 60;
factor.days = factor.hours * 24;
factor.months = factor.days * 30;
factor.years = factor.months * 12;

let lastBlacklist;
let lastWorkingWeek;

// DST Aware add/diff day functions

const addDays = (date, qty) => {
  const clone = new Date(date.getTime());
  clone.setDate(date.getDate() + qty);
  return clone;
};

const dayDiff = (later, earlier) => {
  const milliseconds = later - earlier;
  if (milliseconds % factor.days) {
    const dstOff = (earlier.getTimezoneOffset() - later.getTimezoneOffset()) * factor.minutes;
    return Math.floor((milliseconds + dstOff) / factor.days);
  }
  return Math.floor(milliseconds / factor.days);
};

// Date utils with a Weekday Blacklist built-in
//
// The prepared blacklist data ensures date operations
// are high performance :) We also hold onto the
// last set of utils (cached) in case this is called
// repeatedly with the same input. Again, avoids duplicate
// effort.
//
// @param blacklist Array of days (0 - sun, 6 - sat)
export default (blacklist) => {
  if (blacklist === lastBlacklist) {
    // object equality!
    return lastWorkingWeek;
  }

  if (!blacklist.length) {
    return {
      diff: dayDiff,
      add: addDays,
      offset: (dayFrom, dayTo) => dayTo - dayFrom + (dayFrom > dayTo ? 7 : 0),
      getWorkingDaysInMonth(date) {
        const month = date.getMonth();
        if (month !== 1) {
          return noOfDays[month];
        }
        // Feb
        const year = date.getFullYear();
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
        return leapYear ? 29 : 28;
      },
      correct: (date) => date,
      length: 7,
    };
  }

  const blHash = blacklist.reduce((hash, day) => ({ ...hash, [day]: true }), {});
  const whitelist = allDays.filter((d) => !blHash[d]);
  // Work out days needed for a blacklisted day to get to a valid one
  const blValid = blacklist.reduce((hash, day) => {
    let toAdd;
    // eslint-disable-next-line no-empty
    for (toAdd = 1; blHash[(day + toAdd) % 7] && toAdd < 7; toAdd += 1) {}
    return { ...hash, [day]: toAdd };
  }, {});
  // Work out 'jumps' from every day to every other
  const blGrid = whitelist.reduce((hash, day) => ({ ...hash, [day]: {} }), {});
  whitelist.forEach((start) =>
    whitelist
      .filter((d) => d !== start)
      .forEach((end) => {
        const crossedDays = blacklist.filter(
          start < end ? (b) => b > start && b < end : (b) => !(b > end && b < start),
        ).length;
        // Day diff, plus a week if they are inverted
        const actual = end - start + (start > end ? 7 : 0);
        // Minus the crossed blacklisted days,
        const jumps = actual - crossedDays;
        // Add 1000 to jumps to avoid key conflict
        Object.assign(blGrid[start], { [end]: jumps, [jumps + 1000]: actual });
        // Add negative jumps to end end day hash
        Object.assign(blGrid[end], { [-(jumps + 1000)]: actual });
      }),
  );

  // Move any date off a blacklisted day if it is on one
  const correct = (date) => {
    const day = date.getDay();
    return blHash[day] ? addDays(date, blValid[day]) : date;
  };

  const offset = (dayFrom, dayTo) => (blGrid[dayFrom] && blGrid[dayTo] ? blGrid[dayFrom][dayTo] || 0 : null);

  const diff = (a, b) => {
    const later = a > b ? a : b;
    const earlier = a > b ? b : a;
    const multiplier = a > b ? 1 : -1;
    const days = dayDiff(later, earlier);
    let weeks = Math.floor(days / 7);
    const remainder = days % 7;
    if (!remainder) {
      return weeks * (7 - blacklist.length) * multiplier;
    }
    let start = earlier.getDay();
    const startAdd = blHash[start] ? blValid[start] : 0;
    if (startAdd) {
      start = (start + startAdd) % 7;
    }
    let end = later.getDay();
    const endAdd = blHash[end] ? blValid[end] : 0;
    if (endAdd) {
      end = (end + endAdd) % 7;
    }
    // With dates shifted forward, possible another week is added between the dates
    weeks += endAdd - startAdd + remainder >= 7 ? 1 : 0;
    const jumps = start === end ? 0 : blGrid[start][end];
    return (weeks * (7 - blacklist.length) + jumps) * multiplier;
  };

  const add = (date, qty) => {
    const multiplier = qty < 0 ? -1 : 1;
    const aQty = Math.abs(qty);
    const starting = correct(date);
    const weeks = Math.floor(aQty / (7 - blacklist.length));
    const remainder = aQty % (7 - blacklist.length);
    if (!remainder) {
      return addDays(starting, weeks * 7 * multiplier);
    }
    const actual = blGrid[starting.getDay()][(remainder + 1000) * multiplier];
    return addDays(starting, multiplier * (weeks * 7 + actual));
  };

  const getWorkingDaysInMonth = (date) =>
    diff(
      correct(new Date(date.getFullYear(), date.getMonth() + 1)),
      correct(new Date(date.getFullYear(), date.getMonth())),
    );

  lastWorkingWeek = {
    diff,
    add,
    offset,
    correct,
    getWorkingDaysInMonth,
    length: 7 - blacklist.length,
  };
  lastBlacklist = blacklist;
  return lastWorkingWeek;
};
