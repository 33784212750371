import { computed, unref } from 'vue-demi';
import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems({ data: { files, included: { users } = {} } }) {
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];

    file.uploadedBy = (users && users[file.uploadedBy]) || {
      id: file.uploadedBy,
    };

    if (file.changeFollowers.userIds) {
      file.changeFollowers.users = file.changeFollowers.userIds.map((id) => (users && users[id]) || { id });
    }

    if (file.commentFollowers.userIds) {
      file.commentFollowers.users = file.commentFollowers.userIds.map((id) => (users && users[id]) || { id });
    }
  }

  return files;
}

export default function useFilesLoader({ params, count, pageSize }) {
  const { state, refresh, update } = useListLoader({
    url: v3Url('files'),
    params,
    count,
    pageSize,
    responseToItems,
  });

  const taskId = computed(() => unref(params)?.taskId);

  useRealTimeUpdates((event) => {
    if (event.type === 'task') {
      if (
        // If changing the list of files attached to a task
        event.detail === 'task-fileattached' &&
        // and filtering by the ID of that task,
        taskId.value === event.taskId
      ) {
        // refresh all files because file IDs are not included in this event.
        refresh();
      }
    } else if (event.type === 'file') {
      refresh(event.fileId);
    } else if (event.type === 'comment') {
      if (
        // We're interested only in file comments.
        event.fileId > 0 &&
        // Adding and deleting comments modifies the comment count.
        (event.action === 'new' || event.action === 'deleted')
      ) {
        refresh(event.fileId);
      }
    }
  });

  useOptimisticUpdates((event) => {
    if (event.type === 'file') {
      update((files) => {
        if (event.action === 'update') {
          return files.map((file) => (file.id === event.file.id ? { ...file, ...event.file } : file));
        }
        if (event.action === 'delete') {
          return files.filter((file) => file.id !== event.file.id);
        }
        return files;
      }, event.promise);
    }
  });

  return state;
}
