import { mapValues } from 'lodash-es';

// Most project permission checks consist of an activePage check and a permission check
const projectPerms = mapValues(
  {
    canAddMilestones: ['milestones', 'canAddMilestones'],
    canViewMilestones: ['milestones', 'viewTasksAndMilestones'],
    canAddTasks: ['tasks', 'canAddTasks'],
    canAddTaskLists: ['tasks', 'canAddTaskLists'],
    canViewTasks: ['tasks', 'viewTasksAndMilestones'],
    canAddMessages: ['messages', 'canAddMessages'],
    canViewMessages: ['messages', 'viewMessagesAndFiles'],
    canViewFiles: ['files', 'viewMessagesAndFiles'],
    canLogTime: ['time', 'canLogTime'],
    canViewTimeLog: ['time', 'viewTimeLog'],
    canViewNotebooks: ['notebooks', 'viewNotebook'],
    canViewRisks: ['riskRegister', 'viewRiskRegister'],
    canViewLinks: ['links', 'viewLinks'],
    canViewBilling: ['billing', 'canAccessInvoiceTracking'],
    canAccessInvoiceTracking: ['billing', 'canAccessInvoiceTracking'],
  },
  ([activePage, permission]) =>
    (record) =>
      record.activePages[activePage] && record.permissions[permission],
);

const projectItem = (item, store, recordArrow) =>
  recordArrow(
    (!item && store.getters['project/current']) ||
      (typeof item === 'number' && store.state.project.records[item]) ||
      item,
  );

export default [
  {
    type: 'chat',
    action: ['access'],
    rule: (item, { state }) => state.permissions.canAccessChat,
  },
  {
    type: 'projectPrivacy',
    action: ['edit'],
    rule: (projectId, { getters }) =>
      projectId && getters['project/isActive'](projectId) && getters['project/isAdmin'](projectId),
  },
  {
    type: 'projectSettings',
    action: ['view'],
    rule: (projectId, { getters }) => projectId && getters['project/isAdmin'](projectId),
  },
  {
    type: 'templates',
    action: ['access'],
    rule: (item, { state }) => state.permissions.canAccessTemplates,
  },
  {
    type: 'tasks',
    action: ['add'],
    rule: (item, store) => projectItem(item, store, projectPerms.canAddTasks),
  },
  {
    type: 'tasklists',
    action: ['add'],
    rule: (item, store) => projectItem(item, store, projectPerms.canAddTaskLists),
  },
  {
    type: 'milestones',
    action: ['add'],
    rule: (item, store) => projectItem(item, store, projectPerms.canAddMilestones),
  },
];
