import typedDefaults from '@/utils/helpers/typed-defaults';
import { createSingletonLoader, ACTIONS, GETTERS } from '@/store/utils/loader';
import annex from '@/services/vuex-annex';

export const watchers = [
  // TODO Hybrid - triggers from TKO
  // {
  //   getter: (state, rootState) => rootState.user.status === 'ok',
  //   callback: ({ dispatch }, loggedIn) => loggedIn && dispatch('stats/access'),
  // },
  {
    mutations: ['project/star', 'project/unstar'],
    callback: ({ dispatch, getters, state }, type, id) => {
      if (!getters[`stats/${GETTERS.UNINIT}`]) {
        // wait for any pending actions
        annex.promises(state.project.records[id], 'working').then(() => {
          // Invalidate the following data
          dispatch('stats/changeNotification');
        });
      }
    },
  },
];

export default {
  namespaced: true,
  modules: {
    loader: createSingletonLoader({
      config: {
        params: ({ preferences, user }) => ({
          forStarredProjects: preferences.user.showOnlyStarredProjects ? 1 : undefined,
          'responsible-party-ids': preferences.user.showOnlyMyItems ? user.id : undefined,
          onlymyprojects: 1,
          set: 'dashboard',
          useStartDatesForTodaysTasks: preferences.user.useStartDatesForTodaysTasks,
          onlyMyEvents: preferences.user.showOnlyMyEvents,
          eventsInNext: preferences.user.dashboardEventsRange,
        }),
      },
      actions: {
        onLoad({ commit }, rs) {
          commit('init', rs.data.stats);
          if (rs.data.stats.permissions) {
            commit('loadedPermissions');
            commit('permissions/init', rs.data.stats.permissions, {
              root: true,
            });
          }
        },
        // No visible data check by default, update the stats if the data changes
        // TODO Hybrid
        [ACTIONS.DATA_CHANGE]: ({ dispatch }) => dispatch('hybrid-reload'),
        [ACTIONS.PARAM_CHANGE]: ({ dispatch }) => dispatch('hybrid-reload'),
        // eslint-disable-next-line no-undef
        'hybrid-reload': () => app.loggedInUser.GetStats(),
      },
      getters: {
        // Permissions should only be loaded once, the reason it is added to the URL is because
        // the params are reactive and we don't want this to trigger further requests
        url: (state, getters, rootState) => `/stats.json?getPermissions=${!rootState.stats.loadedPermissions}`,
      },
    }),
  },
  state: {
    events: {
      count: 0,
      hasEvents: false,
    },
    milestones: {
      active: 0,
      complete: 0,
      late: 0,
      today: 0,
      upcoming: 0,
    },
    projects: {
      archived: 0,
      completed: 0,
      count: 0,
      current: 0,
      late: 0,
      starred: 0,
      upcoming: 0,
    },
    tasks: {
      late: 0,
      today: 0,
      upcoming: 0,
    },
    users: {
      count: 0,
      clockedIn: 0,
    },
    loadedPermissions: false,
  },
  mutations: {
    init(state, stats) {
      Object.assign(state, typedDefaults(state, stats));
    },
    loadedPermissions(state) {
      state.loadedPermissions = true;
    },
    resetPermissions(state) {
      state.loadedPermissions = false;
    },
  },
};
