/**
 * Creates a v1 URL
 * by prefixing `/` and appending `.json` to `endpoint`.
 */
export function v1Url(endpoint) {
  return `/${endpoint}.json`;
}

/**
 * Creates a v2 URL
 * by prefixing `/projects/api/v2/` and appending `.json` to `endpoint`.
 */
export function v2Url(endpoint) {
  return `/projects/api/v2/${endpoint}.json`;
}

/**
 * Creates a v3 URL
 * by prefixing `/projects/api/v3/` and appending `.json` to `endpoint`.
 */
export function v3Url(endpoint) {
  return `/projects/api/v3/${endpoint}.json`;
}
