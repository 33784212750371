import { inject, provide } from 'vue-demi';

const brandingSymbol = Symbol('branding');

/**
 * Provides the branding ref which `useBranding` will return.
 */
export function provideBranding(branding) {
  provide(brandingSymbol, branding);
}

/**
 * Returns the branding ref provided by `provideBranding`.
 */
export function useBranding() {
  return inject(brandingSymbol);
}
