import Vue from 'vue';
import moment from 'moment';

function formatDate(str) {
  return moment(str).format('dddd Do, MMMM');
}

function formatMinutes(mins) {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;

  return minutes === 0 ? `${hours}h` : `${hours}h ${minutes}m`;
}

export const responseErrors = {
  UNAVAILABLE: 'user is unavailable',
  NOT_ON_PROJECT: "doesn't exist",
};

export function generateMessages(errors) {
  return errors
    .map((err) => {
      if (err.detail === responseErrors.UNAVAILABLE) {
        const { availableMinutes, date } = err.meta;
        const formattedDate = formatDate(date);

        if (availableMinutes) {
          return Vue.t('User is only available on [0] for [1]', formattedDate, formatMinutes(availableMinutes));
        }

        return Vue.t('User is unavailable on [0]', formattedDate);
      }

      return false;
    })
    .filter(Boolean);
}
