import moment from 'moment';
import { v1Url } from '@/utils/fetcher';
import useItemLoader from '../base/useItemLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItem({ data: { person: user } }) {
  const normalizedUser = {};
  Object.keys(user).forEach((key) => {
    const normalizedKey = key.replace(/-./g, (match) => match.slice(1).toUpperCase());
    normalizedUser[normalizedKey] = user[key];
  });

  const { permissions, notifications } = user;

  if (permissions) {
    const normalizedPermissions = {};
    Object.keys(permissions).forEach((key) => {
      const normalizedKey = key.replace(/-./g, (match) => match.slice(1).toUpperCase());
      normalizedPermissions[normalizedKey] = permissions[key];
    });
    normalizedUser.permissions = normalizedPermissions;
  }

  if (notifications) {
    const normalizedNotifications = {};
    Object.keys(notifications).forEach((key) => {
      const normalizedKey = key.replace(/-./g, (match) => match.slice(1).toUpperCase());
      normalizedNotifications[normalizedKey] = notifications[key];
    });
    normalizedUser.notifications = normalizedNotifications;
  }

  normalizedUser.id = Number(normalizedUser.id);
  normalizedUser.companyId = Number(normalizedUser.companyId);
  normalizedUser.loginCount = Number(normalizedUser.loginCount);
  normalizedUser.inOwnerCompany = normalizedUser.inOwnerCompany === '1';
  normalizedUser.userInvited = normalizedUser.userInvited === '1';
  normalizedUser.lastChangedOn = moment(normalizedUser.lastChangedOn);
  normalizedUser.lastLogin = moment(normalizedUser.lastLogin);
  normalizedUser.companyRoleId = Number(normalizedUser.companyRoleId);

  return normalizedUser;
}

/**
 * Loads the current user.
 */
export default function useCurrentUserLoader({ params } = {}) {
  const { state, refresh, update } = useItemLoader({
    url: v1Url('me'),
    params,
    responseToItem,
  });

  useOptimisticUpdates((event) => {
    if (event.type === 'person') {
      if (event.action === 'update') {
        update((person) => {
          return person?.id === event.person?.id ? { ...person, ...event.person } : person;
        }, event.promise);
      }
    }
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'person' && state.item.value && state.item.value.id === event.userId) {
      refresh();
      return;
    }

    if (event.type === 'userPreference') {
      refresh();
      // return;
    }
  });

  return state;
}
