import axios from 'axios';
import moment from 'moment';
import { computed, onUnmounted, shallowRef, triggerRef, watch } from 'vue-demi';
import useBackOff from '@/utils/backOff/useBackOff';
import { pagination } from '@/utils/fetcher';
import assertHasScope from '@/utils/other/assertHasScope';
import { useAxios } from './useAxios';
import { useRealTimeUpdates } from './useRealTimeUpdates';

/**
 * Loads a list of items from the Teamwork API.
 * Supports pagination, retrying with customizable delay, optimistic updates and real-time updates.
 */
export default function useListLoader({
  /**
   * The request url.
   */
  url: _url,
  /**
   * Filtering and sorting params for the request.
   */
  params: _params,
  /**
   * The number of items to load.
   * If it is less then 0, then nothing will be loaded.
   */
  count: _count = 0,
  /**
   * Gets a list of items from the server response.
   */
  responseToItems: _responseToItems = () => [],
  /**
   * Gets metadata from the server response.
   * It's used only when a page of items is loaded and
   * not when only recently modified items are refreshed.
   */
  responseToMeta: _responseToMeta = () => null,
  /**
   * A function for sorting items using `Array#sort`.
   */
  order: _order,
  /**
   * The number of items to load per request when loading new items.
   */
  pageSize: _pageSize = 10,
  /**
   * If `true`, process real-time updates immediately.
   * If `false`, queue up real-time updates until this param becomes `true`.
   */
  canRefresh: _canRefresh = true,
}) {
  assertHasScope();
  const axiosInstance = useAxios();
  const { handlingEventFromSocket } = useRealTimeUpdates();
  const url = shallowRef(_url);
  const params = shallowRef(_params);
  const count = shallowRef(_count);
  const responseToItems = shallowRef(_responseToItems);
  const responseToMeta = shallowRef(_responseToMeta);
  const order = shallowRef(_order);
  const pageSize = shallowRef(_pageSize);
  const canRefresh = shallowRef(_canRefresh);
  const cursor = shallowRef(undefined);
  // The number of consecutive items which are guaranteed to be in sync with the server
  // counting from the beginning of the list. The actual number of loaded items might
  // be more than `loadedCount`. The guarantee is based on correctness and reliability
  // of real-time updates, which is sufficient in practice but not 100% reliable.
  const loadedCount = shallowRef(0);
  const totalCount = shallowRef(undefined);
  const lastUpdated = shallowRef(undefined);
  const itemsMap = shallowRef(new Map());
  const updatedItemIds = shallowRef(new Set());
  const optimisticUpdates = shallowRef(new Set());
  const cancel = shallowRef(undefined);
  const backOff = useBackOff();
  const meta = shallowRef(undefined);
  const response = shallowRef(undefined);
  const error = shallowRef(undefined);
  const loading = computed(() => Boolean(cancel.value));
  const outdatedItems = shallowRef(undefined);
  const needsBuffer = shallowRef(false);
  const bufferSize = computed(() => (needsBuffer.value ? Math.ceil(pageSize.value * 0.2) : 0));
  const needsRefresh = computed(() => updatedItemIds.value.size > 0 || Boolean(outdatedItems.value));
  const inSync = computed(
    () =>
      // no real-time updates
      !needsRefresh.value &&
      // no optimistic updates
      optimisticUpdates.value.size === 0 &&
      // something loaded or nothing requested
      (lastUpdated.value !== undefined || count.value < 0) &&
      // loaded as much as possible, needed or available
      (pageSize.value === 0 || loadedCount.value >= count.value || loadedCount.value >= totalCount.value),
  );
  // We force using the browser cache for the initial request in order to show the cached data immediately.
  // After that request completes, we immediately call `refresh` to load fresh data from the server.
  const shouldUseCache = computed(() => response.value === undefined && error.value === undefined);
  let cachedItems = [];
  function applyOptimisticUpdates(loadedItems) {
    let processedItems = loadedItems;
    optimisticUpdates.value.forEach((optimisticUpdate) => {
      processedItems = optimisticUpdate.apply(processedItems);
    });
    return processedItems;
  }
  const allItems = computed(() => {
    const newItems = applyOptimisticUpdates(outdatedItems.value || Array.from(itemsMap.value.values()));
    if (typeof order.value === 'function') {
      newItems.sort(order.value);
    }
    return newItems;
  });
  const items = computed(() => {
    const newItems = allItems.value.slice(0, Math.max(0, count.value));
    // If `newItems` contains the same items as `cachedItems`,
    // then we return `cachedItems` to avoid unnecessary change notifications and processing,
    // which affects performance especially when the items are rendered as Vue components.
    if (newItems.length !== cachedItems.length || newItems.some((newItem, index) => newItem !== cachedItems[index])) {
      cachedItems = newItems;
    }
    return cachedItems;
  });
  let triggeredBy = 'user';

  const forceRefresh = Symbol('useListLoader/forceRefresh');
  const refreshLater = new Set();
  watch(canRefresh, () => {
    if (canRefresh.value && refreshLater.size > 0) {
      // eslint-disable-next-line no-use-before-define
      refreshLater.forEach(refresh);
      refreshLater.clear();
    }
  });

  function reset() {
    cursor.value = undefined;
    needsBuffer.value = false;
    loadedCount.value = 0;
    totalCount.value = undefined;
    lastUpdated.value = undefined;
    response.value = undefined;
    error.value = undefined;
    meta.value = undefined;
    itemsMap.value.clear();
    triggerRef(itemsMap);
    updatedItemIds.value.clear();
    triggerRef(updatedItemIds);
    if (cancel.value) {
      cancel.value();
    }
    backOff.reset();
    triggeredBy = 'user';
    refreshLater.clear();
  }

  function refresh(itemId, force) {
    if (force !== forceRefresh && !canRefresh.value) {
      refreshLater.add(itemId);
      return;
    }

    if (typeof itemId === 'undefined') {
      // reload all
      outdatedItems.value ??= Array.from(itemsMap.value.values()); // temporarily return old items
      cursor.value = undefined;
      loadedCount.value = 0;
      lastUpdated.value = undefined;
      itemsMap.value.clear();
      triggerRef(itemsMap);
      updatedItemIds.value.clear();
      triggerRef(updatedItemIds);
    } else {
      // reload one
      needsBuffer.value = true;
      updatedItemIds.value.add(itemId);
      triggerRef(updatedItemIds);
    }
    if (cancel.value) {
      cancel.value();
    }
    backOff.reset();
    triggeredBy = handlingEventFromSocket.value ? 'event/ws' : 'event/local';
  }

  function getLastUpdated({ headers }) {
    // Subtract 10s from the response date for extra safety.
    // Round to the start of the minute to make backend cache hits more likely.
    return moment.utc(headers.date).subtract(10000).startOf('minute');
  }

  async function loadUpdates() {
    // no items loaded yet, so discard updatedItemIds
    if (lastUpdated.value === undefined) {
      updatedItemIds.value.clear();
      triggerRef(updatedItemIds);
      return;
    }

    // refresh all if too many items changed
    if (updatedItemIds.value.size > pageSize.value) {
      refresh();
      return;
    }

    try {
      response.value = await new Promise((resolve, reject) => {
        const requestParams = {
          ...params.value,
          cursor: '',
          limit: pageSize.value,
          pageSize: pageSize.value,
        };

        if (url.value.startsWith('/projects/api/v3/')) {
          requestParams.updatedAfter = lastUpdated.value.format();
        } else {
          requestParams.updatedAfterDate = lastUpdated.value.format('YYYYMMDDHHmmss');
        }

        // Marks the request as canceled but allows it to complete,
        // so that it could be cached by the browser.
        cancel.value = () => reject(new axios.Cancel());
        axiosInstance
          .get(url.value, {
            params: requestParams,
            headers: {
              'Triggered-By': triggeredBy,
              'Sent-By': 'composable',
              Accept: 'application/json',
              'Retry-Attempt': backOff.retryAttempt.value + 1,
            },
          })
          .then(resolve, reject);
      });
      error.value = undefined;

      const responseMeta = response.value.data?.meta;
      const hasCursorPagination = typeof responseMeta?.limit === 'number';

      // refresh all if too many items changed
      if ((hasCursorPagination && responseMeta.nextCursor != null) || pagination(response.value).pages > 1) {
        refresh();
        return;
      }

      // When items are removed or reordered, the offsets of all subsequent items
      // are affected, which can lead to skipping some results when loading more pages.
      // We avoid that situation by adjusting `loadedCount`, which may force reloading of
      // some pages to ensure that no items are omitted in the result. In order to
      // minimize the frequency of such reloads, `loadMore` maintains a buffer of extra items
      // at the end of the list. Those items are normally hidden from the client code, so we can
      // simply reveal them instead of forcing an immediate reload. The last page(s) needs to be
      // reloaded only when we run out of the buffered items.
      // An added benefit of maintaining the buffered items is that we can make them available to
      // the client code immediately when `count` is increased, and then load more items in the background.
      let newLoadedCount = loadedCount.value;
      const loadedItemsSet = new Set();
      meta.value = responseToMeta.value(response.value);
      const loadedItems = responseToItems.value(response.value);
      loadedItems.forEach((item) => {
        const oldItem = itemsMap.value.get(item.id);
        if (oldItem && (typeof order.value !== 'function' || order.value(item, oldItem) !== 0)) {
          newLoadedCount -= 1; // item reordered
        }
        itemsMap.value.set(item.id, item);
        loadedItemsSet.add(item.id);
      });
      updatedItemIds.value.forEach((id) => {
        if (itemsMap.value.has(id) && !loadedItemsSet.has(id)) {
          itemsMap.value.delete(id);
          newLoadedCount -= 1; // item removed
        }
      });
      updatedItemIds.value.clear();
      triggerRef(itemsMap);
      triggerRef(updatedItemIds);

      if (loadedCount.value < totalCount.value) {
        loadedCount.value = Math.max(newLoadedCount, 0);
        totalCount.value = Math.max(totalCount.value, itemsMap.value.size);
      } else {
        loadedCount.value = itemsMap.value.size;
        totalCount.value = itemsMap.value.size;
      }

      lastUpdated.value = getLastUpdated(response.value);

      backOff.reset();
    } catch (axiosError) {
      if (axios.isCancel(axiosError)) {
        return;
      }

      if (!axiosError.config || axiosError.config.cache !== 'only-if-cached') {
        console.error('Error in useListLoader:', axiosError);
      }

      response.value = undefined;
      error.value = axiosError;

      backOff.start();
    } finally {
      cancel.value = undefined;
    }
  }

  async function loadMore() {
    if (count.value < 0) {
      if (outdatedItems.value) {
        outdatedItems.value = undefined;
      }
      return; // no items are needed
    }

    // If we have something already loaded.
    if (lastUpdated.value !== undefined) {
      if (pageSize.value <= 0) {
        if (outdatedItems.value) {
          outdatedItems.value = undefined;
        }
        return; // cannot load anything more
      }

      if (loadedCount.value >= totalCount.value) {
        if (outdatedItems.value) {
          outdatedItems.value = undefined;
        }
        return; // loaded as much as available
      }

      if (loadedCount.value >= count.value) {
        if (outdatedItems.value) {
          outdatedItems.value = undefined;
        }

        // With cursor-based pagination we can always request the next batch of items
        // without worrying about item offsets, so buffering is not needed.
        if (cursor.value !== undefined) {
          return; // loaded as much as needed
        }

        // We enable buffering only when items are refreshed for the first time
        // to minimize unnecessary server requests.
        if (bufferSize.value <= 0) {
          return; // loaded as much as needed
        }

        // We use `loadedCount` to track how many items are in sync with the server.
        // When items are removed or reordered, the offsets of all following items are changed,
        // so we decrement `loadedCount` to ensure that no items are missed when paginating.
        // When `loadedCount` falls below `count`, we must load a full page of data.
        // In order to avoid loading a full page of data every time we decrement `loadedCount`,
        // we maintain a buffer of extra items.
        if (loadedCount.value > Math.floor((count.value + bufferSize.value - 1) / pageSize.value) * pageSize.value) {
          return; // loaded as much as needed including a buffer
        }
      }
    }

    try {
      response.value = await new Promise((resolve, reject) => {
        const requestParams = {
          ...params.value,
          // For some reason the same limit and pageSize must be provided
          // in all requests to make cursor-based pagination work in Teamwork API.
          limit: pageSize.value,
          pageSize: pageSize.value,
        };

        if (lastUpdated.value === undefined) {
          // Opt in to cursor-based pagination on the first request, if supported by the API.
          requestParams.cursor = '';
        } else if (cursor.value !== undefined) {
          // Use cursor-based pagination, if available.
          requestParams.cursor = cursor.value;
        } else if (loadedCount.value >= pageSize.value) {
          // Fall back to offset-based pagination.
          // eslint-disable-next-line no-bitwise
          requestParams.page = ((loadedCount.value / pageSize.value) | 0) + 1;
        }

        // Marks the request as canceled but allows it to complete,
        // so that it could be cached by the browser.
        cancel.value = () => reject(new axios.Cancel());
        axiosInstance
          .get(url.value, {
            params: requestParams,
            headers: {
              'Triggered-By': triggeredBy,
              'Sent-By': 'composable',
              Accept: 'application/json',
              'Retry-Attempt': backOff.retryAttempt.value + 1,
            },
            // See https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
            cache: shouldUseCache.value ? 'only-if-cached' : undefined,
            mode: shouldUseCache.value ? 'same-origin' : undefined,
          })
          .then(resolve, reject);
      });
      error.value = undefined;

      const responseMeta = response.value.data?.meta;
      const hasCursorPagination = typeof responseMeta?.limit === 'number';

      meta.value = responseToMeta.value(response.value);
      const loadedItems = responseToItems.value(response.value);
      loadedItems.forEach((item) => itemsMap.value.set(item.id, item));
      triggerRef(itemsMap);

      if (hasCursorPagination) {
        cursor.value = responseMeta.nextCursor ?? '';

        if (responseMeta.limit > 0 && responseMeta.nextCursor == null) {
          // All items have been already loaded.
          loadedCount.value = itemsMap.value.size;
          totalCount.value = itemsMap.value.size;
        } else {
          // More items can be loaded.
          loadedCount.value = Math.min(itemsMap.value.size, loadedCount.value + loadedItems.length);
          totalCount.value = typeof responseMeta.page?.count === 'number' ? Number(responseMeta.page.count) : Infinity;
        }
      } else {
        const pageMeta = pagination(response.value);
        loadedCount.value = pageMeta.page * pageMeta.pageSize;
        totalCount.value = pageMeta.records;
      }

      if (lastUpdated.value === undefined) {
        lastUpdated.value = getLastUpdated(response.value);
      }

      backOff.reset();
    } catch (axiosError) {
      if (axios.isCancel(axiosError)) {
        return;
      }

      if (!axiosError.config || axiosError.config.cache !== 'only-if-cached') {
        console.error('Error in useListLoader:', axiosError);
      }

      response.value = undefined;
      error.value = axiosError;

      backOff.start();
    } finally {
      cancel.value = undefined;
    }
  }

  function load() {
    if (cancel.value) {
      return;
    } // loading in progress
    if (backOff.active.value) {
      return;
    } // back-off active
    if (typeof url.value !== 'string') {
      return;
    } // invalid url
    if (updatedItemIds.value.size > 0) {
      loadUpdates();
    } else {
      loadMore();
    }
  }

  function itemInSync(itemToCheck) {
    return (
      Boolean(itemToCheck) &&
      itemsMap.value.get(itemToCheck.id) === itemToCheck &&
      !updatedItemIds.value.has(itemToCheck.id)
    );
  }

  function update(apply, promise) {
    const optimisticUpdate = { apply, promise };
    promise.then(
      () => {
        if (needsRefresh.value) {
          // Keep the update until the data is refreshed.
          optimisticUpdate.promise = undefined;
        } else {
          // Discard the update, as it did not affect this loader.
          optimisticUpdates.value.delete(optimisticUpdate);
          triggerRef(optimisticUpdates);
        }
      },
      () => {
        // Discard the update, as it failed.
        optimisticUpdates.value.delete(optimisticUpdate);
        triggerRef(optimisticUpdates);
      },
    );
    // Apply the update optimistically.
    optimisticUpdates.value.add(optimisticUpdate);
    triggerRef(optimisticUpdates);
  }

  watch(needsRefresh, () => {
    if (!needsRefresh.value) {
      // Prune the optimistic updates which have been saved and read back from the server.
      optimisticUpdates.value.forEach((optimisticUpdate) => {
        if (!optimisticUpdate.promise) {
          optimisticUpdates.value.delete(optimisticUpdate);
          triggerRef(optimisticUpdates);
        }
      });
    }
  });

  watch(url, reset);
  let oldParamsJson = JSON.stringify(params.value ?? null);
  watch(
    params,
    () => {
      const newParamsJson = JSON.stringify(params.value ?? null);
      // We compare JSON strings to account for deep reactive objects.
      if (newParamsJson !== oldParamsJson) {
        oldParamsJson = newParamsJson;
        reset();
      }
    },
    { deep: true },
  );
  watch(responseToItems, reset);
  watch(responseToMeta, reset);
  watch(order, reset);

  watch(cancel, load);
  watch(backOff.active, load);
  watch(updatedItemIds, load);
  watch(pageSize, load);
  watch(count, load);
  watch(cursor, load);
  watch(loadedCount, load);
  watch(totalCount, load);
  watch(shouldUseCache, () => {
    if (!shouldUseCache.value) {
      refresh(undefined, forceRefresh);
      triggeredBy = 'user';
    }
  });

  onUnmounted(reset);
  load();

  return {
    state: {
      /**
       * The loaded items.
       */
      items,
      /**
       * The total number of available items.
       * It is undefined until the first request completes successfully.
       */
      totalCount: computed(() => {
        if (typeof totalCount.value !== 'number') {
          return totalCount.value;
        }

        // If all items have been already loaded from the server.
        if (loadedCount.value >= totalCount.value) {
          // Return `allItems.value.length` which accounts for optimistic updates.
          return allItems.value.length;
        }

        // `allItems.value.length` can be greater than `totalCount.value`
        // as a result of applying optimistic updates.
        return Math.max(totalCount.value, allItems.value.length);
      }),
      /**
       * Indicates if the items are in sync with the server.
       */
      inSync,
      /**
       * Determines if the given item is in sync with the server.
       * @param item An item to check.
       * @returns `true`, if the `item` is in sync with the server, otherwise `false`.
       */
      itemInSync,
      /**
       * Indicates if items are being loaded.
       */
      loading,
      /**
       * The loaded metadata.
       */
      meta,
      /**
       * The response produced by the last axios request.
       */
      response,
      /**
       * The error produced by the last axios request.
       */
      error,
    },
    /**
     * Refreshes the specific item or all items by reloading them from the server.
     * @param id Item ID. If specified, only one item is refreshed, otherwise all items are refreshed.
     */
    refresh,
    /**
     * Updates the items locally, while waiting for the same change to be saved on the server.
     * @param apply(Item[]): Item[]
     *   Gets an Array of items and returns a new Array of items with modifications.
     *   It MUST NOT modify the original Array nor items.
     * @param promise A Promise tracking the request which makes the corresponding change on the server.
     */
    update,
  };
}
