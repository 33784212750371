import { computed, shallowRef } from 'vue-demi';
import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  const { notifications = {}, companies = {}, tasklists = {}, teams = {}, users = {} } = response.data.included;

  const tasklistBudgets = response.data.tasklistBudgets || [];

  tasklistBudgets.forEach((_tasklistBudget) => {
    const tasklistBudget = _tasklistBudget;
    tasklistBudget.type = tasklistBudget.type.toLowerCase();

    if (tasklists[tasklistBudget.tasklistId]) {
      tasklistBudget.tasklist = tasklists[tasklistBudget.tasklistId];
    }

    const budgetNotifications = [];
    tasklistBudget.notifications?.forEach((_notification) => {
      if (notifications[_notification.id]) {
        const notification = notifications[_notification.id];
        const { capacityThreshold, notificationMedium } = notification;
        // Find other notification with the same capacity threshold
        const existingIndex = budgetNotifications.findIndex(
          (n) => n.capacityThreshold === capacityThreshold && n.notificationMedium === notificationMedium.toLowerCase(),
        );

        notification.notificationMedium = notification.notificationMedium.toLowerCase();
        const notificationUsers = notification.userId ? [users[notification.userId]] : [];
        const notificationTeams = notification.teamId ? [teams[notification.teamId]] : [];
        const notificationCompanies = notification.companyId ? [companies[notification.companyId]] : [];

        if (existingIndex > -1) {
          // There is already a notification with this capacity and medium
          // We need to merge it
          budgetNotifications[existingIndex].users.push(...notificationUsers);
          budgetNotifications[existingIndex].companies.push(...notificationCompanies);
          budgetNotifications[existingIndex].teams.push(...notificationTeams);
        } else {
          notification.users = notificationUsers;
          notification.companies = notificationCompanies;
          notification.teams = notificationTeams;
          budgetNotifications.push(notification);
        }
      }
    });
    tasklistBudget.notifications = budgetNotifications;
  });

  return tasklistBudgets;
}

// Gets projects metadata from an axios response.
function responseToMeta(response) {
  const { totalCapacity } = response.data.meta;
  return { totalCapacity };
}

function order(tasklistBudget1, tasklistBudget2) {
  return tasklistBudget1.tasklist.displayOrder - tasklistBudget2.tasklist.displayOrder;
}

/**
 * Loads a list of budgets from the Teamwork v3 API.
 */
export default function useTasklistBudgetsLoader({ params, projectBudgetId: _projectBudgetId, count, pageSize = 50 }) {
  const projectBudgetId = shallowRef(_projectBudgetId);
  const url = computed(() =>
    projectBudgetId.value ? v3Url(`projects/budgets/${projectBudgetId.value}/tasklists/budgets`) : undefined,
  );
  const { state, update, refresh } = useListLoader({
    url,
    params,
    count,
    pageSize,
    responseToItems,
    responseToMeta,
    order,
  });

  useOptimisticUpdates((event) => {
    if (event.type !== 'tasklistBudget') {
      return;
    }
    update((tasklistBudgets) => {
      if (event.action === 'create') {
        return tasklistBudgets.concat(event.tasklistBudget);
      }
      if (event.action === 'bulkCreated') {
        return event.tasklistBudgets;
      }
      if (event.action === 'update') {
        return tasklistBudgets.map((tasklistBudget) => {
          return tasklistBudget.id === event.tasklistBudget.id
            ? { ...tasklistBudget, ...event.tasklistBudget }
            : tasklistBudget;
        });
      }
      if (event.action === 'delete') {
        return tasklistBudgets.filter((tasklistBudget) => tasklistBudget.id !== event.tasklistBudget.id);
      }
      return tasklistBudgets;
    }, event.promise);
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'tasklistBudget' || event.type === 'budget') {
      refresh();
    } else if (event.type === 'time' || (event.type === 'project' && event.action === 'rate-set')) {
      refresh();
    } else if ((event.type === 'installation' && event.action === 'rate-set') || event.type === 'taskList') {
      refresh();
    } else if (event.detail === 'tasklistbudget-edited' || event.detail === 'tasklistbudget-new') {
      refresh();
    }
  });

  return state;
}
