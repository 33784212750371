import moment from 'moment';
import Vue from 'vue';
import { CUSTOM_FIELD_TYPES as CF_TYPES } from '@/views/projects-list/sections/constants';

export const formatFieldsForSubmit = (customFields) => {
  if (!customFields) {
    return [];
  }
  return customFields.map((customField) => ({
    customFieldId: customField.customfieldId,
    type: customField.type,
    value:
      customField.type === 'date' && customField.value
        ? moment().format(customField.value, 'YYYY-MM-DD')
        : customField.value,
    urlTextToDisplay: customField.urlTextToDisplay,
  }));
};

// Validates the input of various types of custom field
export const validateFields = (customFields) => {
  const errors = [];
  customFields.forEach((customField) => {
    if (customField.type !== 'number-integer') {
      return;
    }
    if (customField.value && !/^[0-9 -]+$/.test(customField.value)) {
      errors.push(`${Vue.t('Custom Field [_s] must be a whole number', customField.name)}`);
    }
  });
  return errors;
};

export const isMultipleOption = (type) => [CF_TYPES.DROPDOWN, CF_TYPES.STATUS].includes(type);

export function getCustomFieldIconByType(customFieldType) {
  return (
    {
      checkbox: 'check-square-light',
      date: 'calendar-light',
      dropdown: 'circle-chevron-down-light',
      'number-integer': 'hashtag-light',
      status: 'exclamation-triangle',
      'text-short': 'text',
      url: 'link-light',
    }[customFieldType] || 'text'
  );
}

export function isTypeCustomField(type) {
  return ['checkbox', 'date', 'dropdown', 'number-integer', 'status', 'text-short', 'url'].includes(type);
}
