/* eslint-disable consistent-return */
import Vue from 'vue';
import typedDefaults from '@/utils/helpers/typed-defaults';
import api from '@/services/api';
import { createBatchLoader } from '@/store/utils/loader/batch';
import { API, createRecordLoader } from '@/store/utils/loader';

import { mapGetters } from '../utils/record-mapper';

const recordDefault = {
  id: 0,
  firstName: '',
  lastName: '',
  emailAddress: '',
  companyId: 0,
  companyName: '',
  avatarUrl: '',
  title: '',
  lengthOfDay: 0,
  administrator: false,
  isClientUser: false,
  workingHours: {
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
  },
  workingHour: null,
};

const personFromEmail = (email, companyId) => ({
  'email-address': email,
  'user-name': email,
  'first-name': '',
  'last-name': '',
  isTempName: true,
  'company-id': companyId,
  receiveDailyReports: true,
  sendInvite: true,
  getUserDetails: true,
  adminOnTrialProjects: true,
  canAccessTemplates: true,
  canManageProjectTemplates: true,
  canViewProjectTemplates: true,
  canManageSchedule: true,
  canViewSchedule: true,
  canAccessPortfolio: true,
  canManagePortfolio: true,
  canManageCustomFields: true,
  canAddProjects: true,
  canAccessCalendar: false,
  canAccessAllProjects: false,
});
export default {
  namespaced: true,
  modules: {
    loader: createRecordLoader({
      config: {
        recordMutation: 'people/record',
        mapToRecords: ({ rootState }, list) => list.map((id) => rootState.people.records[id]),
        findVisibleData: ({ state, id }) => state.people.records[id],
      },
      getters: {
        url: () => (id) => `${API.v2}/people/${id}.json?showDeleted=true`,
      },
    }),
    batch: createBatchLoader({
      namespaced: true,
      config: {
        url: `${API.v2}/people.json`,
        param: 'userIds',
        module: 'people',
      },
    }),
  },
  state: {
    records: {},
  },
  getters: {
    scheduleWorkingHours: (state, getters, rootState, rootGetters) => (id) => {
      const workingHourEntries = getters.workingHourEntries(id);
      const daysInRange = rootGetters['schedule/daysInRange'];
      let totalWorkingHours = 0;

      daysInRange.forEach((day) => {
        const workingHourEntry = workingHourEntries.find((entry) => entry.weekday === day);
        if (workingHourEntry) {
          totalWorkingHours += workingHourEntry.taskHours;
        }
      });

      return totalWorkingHours;
    },
    workingHourEntries: (state, getters, rootState) => (id) => {
      const user = state.records[id];
      const { workingHour } = user;

      if (!workingHour) {
        return [];
      }

      const workingHours = rootState.workingHours.records[workingHour.id] || {};
      const entryIds = workingHours.entryIds || [];
      return entryIds.map((entryId) => rootState.workingHours.entries.records[entryId]);
    },
    ...mapGetters({
      avatar: ({ firstName, lastName, avatarUrl } = {}) =>
        firstName ? { label: `${firstName} ${lastName}`, avatarUrl } : null,
    }),
  },
  actions: {
    invite({ dispatch, rootGetters }, { emails, projectId, inviteType, administrator, canAccessAllProjects } = {}) {
      if (!emails) {
        throw new Error('No user invitation list provided');
      }

      return Promise.all(
        emails.map((user, index) => {
          const data = {
            person: personFromEmail(user, rootGetters['account/companyid']),
            createCollaboratorIfNoSeats: true,
          };
          if (projectId > 0 && !canAccessAllProjects) {
            data.person.accessProjectIds = projectId;
          } else {
            data.person.canAccessAllProjects = true;
          }
          if (inviteType) {
            data.person.inviteType = inviteType;
          }
          data.person.administrator = administrator?.[index];
          return api
            .post('people.json', data)
            .then((r) => ({
              email: user,
              id: r?.data?.id,
            }))
            .catch((err) => {
              const responseMessage = err.response?.data.MESSAGE || '';
              if (
                responseMessage.includes('in use') ||
                responseMessage.includes('Email address already used by another user')
              ) {
                dispatch('notifications/flashes/warn', Vue.t('[0] is already invited', user), {
                  root: true,
                  recordMap: false,
                });
              } else {
                dispatch('notifications/flashes/error', Vue.t('Failed to Send Invite to [0]', user), {
                  root: true,
                  recordMap: false,
                });
              }
            });
        }),
      );
    },
    moveCompany(state, { userIds, companyId } = {}) {
      if (!userIds.length) {
        return;
      }
      return api.put('people/move.json', {
        userIdList: userIds.join(','),
        companyId,
      });
    },
    addPeopleToList({ commit, state }, newRecords) {
      const currentRecords = { ...state.records };

      newRecords.forEach((person) => {
        const defsApplied = typedDefaults(recordDefault, person);
        const newRec = { ...state.records[person.id], ...defsApplied };
        currentRecords[person.id] = newRec;
      });

      commit('records', Object.values(currentRecords));
    },
  },
  mutations: {
    record(state, person) {
      const defsApplied = typedDefaults(recordDefault, person);
      const newRec = { ...state.records[person.id], ...defsApplied };
      Vue.set(state.records, newRec.id, newRec);
    },
    records(state, list) {
      const hash = {};

      list.forEach((rec) => {
        const existing = state.records[rec.id];
        const person = typedDefaults(recordDefault, rec);
        hash[person.id] = existing ? { ...existing, ...person } : person;
      });
      state.records = { ...state.records, ...hash };
    },
  },
};
