import { computed } from 'vue-demi';
import { PRICEPLANS, PRICEPLANTYPES } from '@tko/src/app/helpers/constants';
import { useCurrentAccount, useCurrentUser } from '@/api';

export default function usePricePlan() {
  const account = useCurrentAccount();
  const user = useCurrentUser();

  const STARTER_PLAN_IDS = [67];
  const DELIVER_PLAN_IDS = [21, 41, 47, 64, 82];
  const GROW_PLAN_IDS = [48, 65, 69]; // sometimes [22, 42, 48, 65, 69]
  const SCALE_PLAN_IDS = [51, 66];

  const id = computed(() => account.value.pricePlanData.id);
  const code = computed(() => account.value.pricePlanData.code);
  const maxFinancialBudgets = computed(() => account.value.pricePlanData.maxFinancialBudgets);
  const maxUsers = computed(() => account.value.pricePlanData.maxUsers);
  const name = computed(() => account.value.pricePlanData.name);
  const type = computed(() => account.value.pricePlanData.type);
  const isEnterprise = computed(
    () =>
      [PRICEPLANS.ENTERPRISECUSTOM, PRICEPLANS.PERUSER_ENTERPRISE, PRICEPLANS.PERUSER_CORPORATE].includes(id.value) ||
      code.value.toLowerCase().includes('enterprise'),
  );
  const pricePlanType = computed(() => account.value?.pricePlanType);
  const isPlanMaxProjects = computed(() => Boolean(pricePlanType.value === PRICEPLANTYPES.MAXPROJECTS));
  const isPlanPerUser = computed(() => Boolean(pricePlanType.value === PRICEPLANTYPES.PERUSER));
  const paymentStatus = computed(() => account.value.paymentStatus);
  const inGracePeriod = computed(() => account.value.inGracePeriod);
  const isPaymentOverdue = computed(() => paymentStatus.value === 'paymentDue' && inGracePeriod.value);
  const pricePlanId = computed(() => account.value?.pricePlanId);
  const isPlanFree = computed(() => Boolean([PRICEPLANS.FREE, PRICEPLANS.PERUSER_FREE].includes(pricePlanId.value)));
  const isPaid = computed(() => account.value.isPaid);
  const isPlanStarter = computed(() => STARTER_PLAN_IDS.includes(pricePlanId.value));
  const isPlanDeliver = computed(() => DELIVER_PLAN_IDS.includes(pricePlanId.value));
  const isPlanGrow = computed(() => GROW_PLAN_IDS.includes(pricePlanId.value));
  const isPlanScale = computed(() => SCALE_PLAN_IDS.includes(pricePlanId.value));

  const isTrialAccount = computed(
    () =>
      !isPaid.value && Number(account.value.trialDaysRemaining || 0) > 0 && account.value?.paymentStatus === 'trial',
  );

  const canMaxpUpgradeAccount = computed(() =>
    Boolean(
      isPlanMaxProjects.value &&
        user.value?.administrator &&
        user.value?.inOwnerCompany &&
        !isEnterprise.value &&
        !isPaymentOverdue.value,
    ),
  );

  return {
    id,
    code,
    name,
    type,
    maxFinancialBudgets,
    maxUsers,
    isEnterprise,
    paymentStatus,
    inGracePeriod,
    isPaymentOverdue,
    isTrialAccount,
    isPlanMaxProjects,
    canMaxpUpgradeAccount,
    isPlanFree,
    pricePlanId,
    isPlanPerUser,
    isPaid,
    isPlanStarter,
    isPlanDeliver,
    isPlanGrow,
    isPlanScale,
  };
}
