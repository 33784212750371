import routes from './routes';
import i18n, { watchers as i18nWatchers } from './i18n';
import user, { watchers as userWatchers } from './user';
import account, { watchers as accWatchers } from './account';
import status from './status';
import statuses from './statuses';
import project, { watchers as projectWatchers } from './project';
import stats, { watchers as statsWatchers } from './stats';
import permissions, { watchers as permWatchers } from './permissions';
import preferences, { watchers as prefWatchers } from './preferences';
import tasklist, { watchers as tasklistWatchers } from './tasklist';
import task from './task';
import dependencies from './dependencies';
import milestone from './milestone';
import responsive, { watchers as responsiveWatchers } from './responsive';
import modals from './modals';
import nextModals from './next-modals';
import notifications, { watchers as notifWatchers } from './notifications';
import branding from './branding';
import quickSearch from './quick-search';
import lockdown from './lockdown';
import layout, { watchers as layoutWatchers } from './layout';
import people from './people';
import team from './team';
import sampleProjects from './sample-projects';
import copyProject from './steps/copy-project';
import addEditProjectWizard from './steps/add-edit-project-wizard';
import config, { watchers as configWatchers } from './config';
import boardColumn from './board-column';
import tag from './tag';
import filter from './filter';
import company from './company';
import category from './category';
import board from './board';
import customTemplate from './customTemplate';
import teamworkTemplate from './teamworkTemplate';
import checkout from './checkout';
import quickViews from './quick-views';
import popover from './popover';
import allocations from './allocations';
import workloadTasks from './workload/tasks';
import schedule from './schedule';
import scheduleWatchers from './schedule/watchers';
import timelog from './timelog';
import teamworkTemplateCategory from './teamworkTemplateCategory';
import customTemplateCustomFields from './customTemplateCustomFields';
import hybridPopover from './hybrid-popover';
import profilePopoverSingleton from './profile-popover-singleton';
import dataview from './dataview';
import calendarEvent from './calendar-event';
import eventType from './event-type';
import reviewUsers from './migrate/review-users';
import country from './country';
import workingHours from './working-hours';
import productoutcomes from './productoutcomes';

const subscriptions = {};
const actionSubscriptions = {};

const setupSubscriptions = (store) => {
  store.subscribe(({ type, payload }) =>
    (subscriptions[type] || []).forEach((callback) => callback(store, type, payload)),
  );
  store.subscribeAction({
    after: ({ type, payload }) =>
      (actionSubscriptions[type] || []).forEach((callback) => callback(store, type, payload)),
  });
};

// Takes a function, returns a function, which when run, will defer the execution
const defer =
  (fnc) =>
  (...args) =>
    setTimeout(() => fnc(...args), 0);

/**
 * The concept of a Module Watcher is to facilitate
 * data->data logic, where the trigger is state and the
 * resulting action will alter state.
 * Added to normal watch options, we've added `once` here
 * too allow the watcher to be unregistered after firing once.
 * ---
 * A watcher can be with a getter/callback, or a list of
 * mutations with a callback (store, type, payload).
 * ---
 * @param moduleName - module context for state
 * @param watcher - each instance of a watcher definition (from modules)
 * @param store - will be passed in for each mapped watcher
 */
export const watcherMap = (moduleName) => (watcher) => (store) => {
  const options = watcher.options || {};

  if (watcher.getter) {
    let unwatch = null;

    unwatch = store.watch(
      (state, getters) => watcher.getter(state[moduleName], state, getters),
      (newVal, oldVal) => {
        watcher.callback(store, newVal, oldVal);
        if (options.once) {
          if (unwatch) {
            unwatch();
          } else {
            setTimeout(() => unwatch(), 0);
          }
        }
      },
      options,
    );
  } else if (watcher.mutations) {
    watcher.mutations.forEach((mutation) => {
      subscriptions[mutation] = subscriptions[mutation] || [];
      // Defer by default
      const shouldDefer = options.nextTick !== false;
      subscriptions[mutation].push(shouldDefer ? defer(watcher.callback) : watcher.callback);
    });
  } else if (watcher.actions) {
    watcher.actions.forEach((action) => {
      actionSubscriptions[action] = actionSubscriptions[action] || [];
      actionSubscriptions[action].push(watcher.callback);
    });
  }
};

export const watchers = [
  ...userWatchers.map(watcherMap('user')),
  ...accWatchers.map(watcherMap('account')),
  ...i18nWatchers.map(watcherMap('i18n')),
  ...statsWatchers.map(watcherMap('stats')),
  ...permWatchers.map(watcherMap('permissions')),
  ...prefWatchers.map(watcherMap('preferences')),
  ...notifWatchers.map(watcherMap('notifications')),
  ...projectWatchers.map(watcherMap('project')),
  ...configWatchers.map(watcherMap('config')),
  ...tasklistWatchers.map(watcherMap('tasklist')),
  ...responsiveWatchers.map(watcherMap('responsive')),
  ...scheduleWatchers.map(watcherMap('schedule')),
  ...layoutWatchers.map(watcherMap('layout')),
  setupSubscriptions,
];

export default {
  routes,
  i18n,
  user,
  account,
  status,
  statuses,
  project,
  stats,
  preferences,
  permissions,
  tasklist,
  task,
  dependencies,
  milestone,
  sampleProjects,
  copyProject,
  addEditProjectWizard,
  responsive,
  modals,
  nextModals,
  notifications,
  branding,
  quickSearch,
  lockdown,
  layout,
  config,
  people,
  boardColumn,
  tag,
  filter,
  company,
  category,
  board,
  customTemplate,
  teamworkTemplate,
  checkout,
  quickViews,
  popover,
  allocations,
  workloadTasks,
  schedule,
  hybridPopover,
  profilePopoverSingleton,
  team,
  timelog,
  teamworkTemplateCategory,
  customTemplateCustomFields,
  dataview,
  calendarEvent,
  eventType,
  reviewUsers,
  country,
  workingHours,
  productoutcomes,
};
