import moment from 'moment';
import { v1Url } from '@/utils/fetcher';
import useItemLoader from '../base/useItemLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

const keyMapping = new Map([
  ['companyid', 'companyId'],
  ['companyname', 'companyName'],
  ['datesignedup', 'dateSignedUp'],
  ['priceplanAllowSSL', 'pricePlanAllowSSL'],
  ['priceplanCanTurnOffTWBranding', 'pricePlanCanTurnOffTWBranding'],
  ['priceplanData', 'pricePlanData'],
  ['priceplanMaxUsers', 'pricePlanMaxUsers'],
  ['priceplanType', 'pricePlanType'],
]);

function responseToItem({ data: { account } }) {
  const normalizedAccount = {};
  Object.keys(account).forEach((key) => {
    const normalizedKey = keyMapping.get(key) || key.replace(/-./g, (match) => match.slice(1).toUpperCase());
    normalizedAccount[normalizedKey] = account[key];
  });

  normalizedAccount.accountHolderId = Number(normalizedAccount.accountHolderId);
  normalizedAccount.companyId = Number(normalizedAccount.companyId);
  normalizedAccount.id = Number(normalizedAccount.id);
  normalizedAccount.isPaid = normalizedAccount.isPaid === '1';
  normalizedAccount.memberOfOwnerCompany = normalizedAccount.memberOfOwnerCompany === 'YES';
  normalizedAccount.paidForUsers = Number(normalizedAccount.paidForUsers);
  normalizedAccount.pricePlanId = Number(normalizedAccount.pricePlanId);
  normalizedAccount.pricePlanMaxUsers = Number(normalizedAccount.pricePlanMaxUsers);
  normalizedAccount.shardNo = Number(normalizedAccount.shardNo);
  normalizedAccount.tasklistTemplateProjectId = Number(normalizedAccount.tasklistTemplateProjectId);
  normalizedAccount.installationTeamsCount = Number(normalizedAccount.installationTeamsCount);
  normalizedAccount.createdAt = moment(normalizedAccount.createdAt);
  normalizedAccount.dateSignedUp = moment(normalizedAccount.dateSignedUp);
  normalizedAccount.inGracePeriod = Boolean(normalizedAccount.inGracePeriod);

  const { pricePlanData, currency } = normalizedAccount;
  if (pricePlanData) {
    const normalizedPricePlanData = {};
    Object.keys(pricePlanData).forEach((key) => {
      normalizedPricePlanData[key] = pricePlanData[key];
    });
    normalizedPricePlanData.id = Number(normalizedPricePlanData.id);
    normalizedPricePlanData.maxFinancialBudgets = Number(normalizedPricePlanData.maxFinancialBudgets);
    normalizedPricePlanData.maxUsers = Number(normalizedPricePlanData.maxUsers);
    normalizedAccount.pricePlanData = normalizedPricePlanData;
  }

  if (currency) {
    const normalizedCurrency = {};
    normalizedCurrency.id = Number(currency.id);
    normalizedCurrency.decimalPoints = Number(currency.decimalPoints);
    normalizedCurrency.code = currency.code;
    normalizedCurrency.symbol = currency.symbol;
    normalizedCurrency.name = currency.name;

    normalizedAccount.currency = normalizedCurrency;
  }

  return normalizedAccount;
}

/**
 * Loads the current account.
 */
export default function useCurrentAccountLoader({ params } = {}) {
  const { state, refresh, update } = useItemLoader({
    url: v1Url('account'),
    params,
    responseToItem,
  });

  useOptimisticUpdates((event) => {
    if (event.type === 'account') {
      update((account) => {
        if (event.action === 'update') {
          return {
            ...account,
            ...event.account,
          };
        }
        return account;
      }, event.promise);
    }
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'account') {
      refresh();
      return;
    }

    if (event.type === 'accountPreference') {
      refresh();
      // return;
    }
  });

  return state;
}
