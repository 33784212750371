import Vue from 'vue';
import lscache from 'lscache';
import { ACTIONS, API, createRecordLoader } from '@/store/utils/loader';
import typedDefaults from '@/utils/helpers/typed-defaults';
import all from './modules/all';
import filtered from './modules/filtered';
import { defaultTypes } from './recordDefault';

export default {
  namespaced: true,

  modules: {
    loader: createRecordLoader({
      config: {
        url: (id) => `${API.v3}/projects/templates/${id}.json`,
        params: () => ({
          include: 'companies,customfieldprojects,updatedby,tags',
        }),
        mapResponse: (ctx, rs) => rs.data.project,
        includedConfig: {
          customfieldProjects: 'customTemplateCustomFields',
          companies: 'company',
          tags: 'tag',
        },
      },
    }),
    all,
    filtered,
  },

  state: {
    records: {},
    searchTerm: '',
    orderBy: lscache.get('twProjects-projectTemplates-sortBy') || 'name',
    orderMode: lscache.get('twProjects-projectTemplates-sortOrder') || 'asc',
    currentTemplateId: null,
  },

  getters: {
    isFiltered: ({ searchTerm }) => searchTerm.trim().length > 0,

    allTemplates(state, getters, { customTemplateCustomFields, company, tag, people }) {
      const templates = getters['all/list'].map((id) => state.records[id]);
      return templates.map((template) => ({
        ...template,
        company: company.records[template.companyId],
        customFields: getters.customFields(template, customTemplateCustomFields),
        tags: getters.tags(template, tag),
        creator: people.records[template.createdBy],
      }));
    },

    filteredTemplates(state, getters, { customTemplateCustomFields, company, tag, people }) {
      const templates = getters['filtered/list'].map((id) => state.records[id]);
      return templates.map((template) => ({
        ...template,
        company: company.records[template.companyId],
        customFields: getters.customFields(template, customTemplateCustomFields),
        tags: getters.tags(template, tag),
        creator: people.records[template.createdBy],
      }));
    },

    current: (state, getters) =>
      state.currentTemplateId ? getters.allTemplates.find((template) => template.id === state.currentTemplateId) : null,

    customFields: () => (template, customTemplateCustomFields) => {
      const customFields = [];
      if (template.customFieldValueIds) {
        template.customFieldValueIds.forEach((id) => {
          const cf = customTemplateCustomFields.records[id];
          customFields.push(cf);
        });
        return customFields;
      }
      return [];
    },

    tags: () => (template, tag) => {
      if (!template.tagIds) {
        return [];
      }
      return template.tagIds.map((tagId) => tag.records[tagId]);
    },
  },

  mutations: {
    search(state, term) {
      state.searchTerm = term;
    },
    records(state, templates) {
      const hash = {};
      templates.forEach((template) => {
        const defsApplied = typedDefaults(defaultTypes, template);
        // First time saving the record, add all the defaults too.
        hash[template.id] = {
          ...(state.records[template.id] || defaultTypes),
          ...defsApplied,
        };
      });
      state.records = { ...state.records, ...hash };
    },
    record(state, template) {
      const defsApplied = typedDefaults(defaultTypes, template);
      // First time saving the record, add all the defaults too.
      const newRec = {
        ...(state.records[template.id] || defaultTypes),
        ...defsApplied,
      };
      Vue.set(state.records, newRec.id, newRec);
    },
    orderBy(state, val) {
      state.orderBy = val;
    },
    orderMode(state, val) {
      state.orderMode = val;
    },
    current(state, id) {
      state.currentTemplateId = id;
    },
  },

  actions: {
    current({ commit, dispatch }, id) {
      const change = () => commit('current', id);
      return id ? dispatch(ACTIONS.ACCESS, id).then(change) : change();
    },
  },
};
