import company from './company';
import everything from './everything';
import home from './home';
import people from './people';
import planning from './planning';
import project from './project';
import projects from './projects';
import reports from './reports';
import settings from './settings';
import team from './team';
import time from './time';
import user from './user';
import welcome from './welcome';

export default [
  {
    path: '/idle/:projectId?',
    component: () => import('@sections/Idle'),
  },
  {
    path: '/json-to-project',
    component: 'json-to-project',
    props: {
      name: 'json-to-project',
    },
  },
  {
    path: '/playground',
    component: 'playground',
    props: {
      name: 'playground',
    },
  },
  {
    path: '/budgets', // TODO: check if this is all good
    component: () => import('@pages/Budgets'),
  },
  {
    path: '/getting-started',
    component: 'sample-projects-container',
    props: {
      name: 'sample-projects-container',
    },
  },
  {
    path: '/onboarding/step/:step?',
    component: 'onboarding-project-container',
    props: (route) => ({
      name: 'onboarding-project-container',
      step: route.params.step,
    }),
  },
  {
    path: '/embed',
    component: 'tko-component',
    props: {
      name: 'page-embed',
    },
  },
  ...welcome,
  {
    path: '/(.*)',
    component: 'app-layout',
    children: [
      ...company,
      ...everything,
      ...home,
      ...user,
      ...people,
      ...projects,
      ...settings,
      ...time,
      ...team,
      {
        path: '/projects',
        meta: {
          pageName: 'Projects',
        },
      },
      planning,
      reports,
      {
        path: '/calendar',
        meta: {
          pageName: 'Calendar',
          menuPermission: 'canAccessCalendar',
        },
      },
      {
        path: '/legacytogrow',
        component: 'growvember',
        meta: {
          inOwnerCompany: true,
          isAccount: true,
          menuPermission: 'administrator',
          pageName: 'Grow',
        },
      },
      ...project,
      {
        path: '/search/:searchTerm',
        // Going to the search page means the quick search is cleared
        beforeEnter: ({ dispatch }, to, from, next) => {
          dispatch('quickSearch/clear');
          next();
        },
      },
      {
        path: '/cancel',
        component: 'cancellation',
        props: {
          name: 'cancellation',
          mode: 'cancel',
        },
        meta: {},
      },
      {
        path: '/downgrade',
        component: 'cancellation',
        props: {
          name: 'cancellation',
          mode: 'downgrade',
        },
        meta: {},
      },
      {
        path: '/trialstarted/:feature',
        component: 'trialstarted',
        props: (route) => ({
          pageName: 'Trial Started',
          feature: route.params.feature,
          enableTrial: !!route.query.enableTrial,
        }),
        meta: {},
      },
      {
        path: '/migrate/',
        component: 'migrate',
        props: {
          name: 'migrate',
        },
        meta: {
          disableGating: true,
        },
      },
      {
        path: '/launchmodal/:modal',
        beforeEnter({ dispatch, state }, to, from, next) {
          if (to.params.modal === 'junediscount') {
            dispatch('modals/tko/open', {
              id: 'enterpriseForm',
              args: {
                isSalesContact: true,
              },
            });
          } else if (to.params.modal === 'profilePhoto') {
            dispatch('modals/tko/open', {
              id: 'addOrEditPerson',
              args: {
                personId: state.user.id,
                uploadPhoto: true,
              },
            });
          }
          next(from);
        },
      },
      {
        path: '/(email|checkout)gateway/plans',
        component: 'emailgateway',
        props: (route) => ({
          entryPointName: route.query.entryPointName,
        }),
        meta: {
          hasHeader: false,
        },
      },
      {
        path: '/(email|checkout)gateway/:planId/:discount/:campaign',
        component: 'emailgateway',
        props: (route) => ({
          campaign: route.params.campaign,
          planId: Number(route.params.planId),
          discount: route.params.discount,
          entryPointName: route.query.entryPointName,
        }),
        meta: {
          hasHeader: false,
        },
      },
      {
        path: '/sdr/call',
        component: 'sdrcall',
        props: (route) => ({
          name: 'Call',
          entryPoint: route.query.entryPoint,
        }),
        meta: {
          hasHeader: false,
        },
      },
      {
        path: '/contact/:type',
        component: 'sdrcall',
        props: (route) => ({
          type: route.params.type,
        }),
        meta: {
          hasHeader: false,
        },
      },
      {
        path: '*',
      },
    ],
  },
];
