import { mapActions } from 'vuex';
import { debounce } from 'lodash-es';

const visibility = [
  { field: 'hidden', event: 'visibilitychange' },
  { field: 'msHidden', event: 'msvisibilitychange' },
  { field: 'webkitHidden', event: 'webkitvisibilitychange' },
];

// @vue/component
export default {
  name: 'WindowEvents',
  data: () => ({
    vizOpt: 0,
  }),
  created() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    // eslint-disable-next-line eqeqeq
    this.vizOpt = visibility.find((opt) => document[opt.field] != undefined);
    document.addEventListener(this.vizOpt.event, this.handleVisibilityChange);
    window.addEventListener('blur', this.blur);
    window.addEventListener('focus', this.focus);
    this.handleVisibilityChange();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener(this.vizOpt.event, this.handleVisibilityChange);
    window.removeEventListener('blur', this.blur);
    window.removeEventListener('focus', this.focus);
  },
  render: (createElement) => createElement(),
  methods: {
    // Avoid flood of events when dragging the resize handle
    handleResize: debounce(function handleResize() {
      this.width(document.documentElement.clientWidth);
      this.height(document.documentElement.clientHeight);
    }, 100),
    handleVisibilityChange(state) {
      const hidden = document[this.vizOpt.field] && (typeof state !== 'string' || state !== 'focus');
      if (hidden) {
        this.hidden();
      } else {
        this.visible();
      }
    },
    blur() {
      this.handleVisibilityChange('blur');
    },
    focus() {
      this.handleVisibilityChange('focus');
    },
    ...mapActions('responsive', ['width', 'height', 'visible', 'hidden']),
  },
};
