/* eslint-disable import/prefer-default-export */
import { base } from '@/store/utils/loader/actions';
import { ACTIONS, STATUS } from '@/store/utils/loader/constants';
import { mapActions } from '@/store/utils/record-mapper';

// Note, we can only use actions that don't leverage config
// Fix Jest tests by making this a default parameter
const baseActions = base({});

// Used currently by Projects record loader, extra default parameter for Jest Spec
export const rewireRecordAccess = (tkoAction, actions = baseActions) =>
  mapActions({
    'hybrid-original-access': actions[ACTIONS.ACCESS],
    [ACTIONS.ACCESS]({ dispatch, state, id }) {
      if (!state || state.status !== STATUS.loaded) {
        tkoAction(id); // important this run reload
      }
      // If the action ran, the call to access should
      // return the promise currently waiting.
      return dispatch('hybrid-original-access');
    },
  });
