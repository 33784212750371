import api, { v1Url, v3Url } from '@/utils/fetcher';

const save = (payload, id) => {
  if (id) {
    return api.put(v1Url(`me/savedfilters/${id}`), payload);
  }
  return api.post(v1Url('me/savedfilters'), payload);
};

const setAsDefault = (id, isTemporary) =>
  api.put(v1Url(`me/savedfilters/${id}`), {
    updateDefault: true,
    savedFilter: {
      isDefault: true,
      isTemporary,
      isProjectSpecific: false,
      displayOrder: 0,
      includesSort: false,
      section: 'projectsList',
      projectId: 0,
      parameters: {},
    },
  });

const deleteItem = (id) => api.delete(v1Url(`me/savedfilters/${id}`));

const reposition = (payload, id) => api.put(v1Url(`me/savedFilters/${id}/reposition`), payload);

const saveV3 = (payload, id) => api.post(v3Url(`me/savedfilters${id ? `/${id}` : ''}`), payload);

const editV3 = (payload, id) => api.patch(v3Url(`me/savedfilters${id ? `/${id}` : ''}`), payload);

const setAsDefaultV3 = (id, isTemporary, section, defaultParams) =>
  api.put(v3Url(`me/savedfilters/${id}`), {
    updateDefaults: true,
    savedFilter: {
      isDefault: true,
      isTemporary,
      isProjectSpecific: false,
      includesSort: false,
      section,
      projectId: 0,
      parameters: defaultParams,
    },
  });

const deleteItemV3 = (id) => api.delete(v3Url(`me/savedfilters/${id}`));

const repositionV3 = (payload, id) => api.put(v3Url(`me/savedFilters/${id}/reposition`), payload);

export default {
  save,
  deleteItem,
  reposition,
  setAsDefault,
  saveV3,
  editV3,
  deleteItemV3,
  repositionV3,
  setAsDefaultV3,
};
