import moment from 'moment';
import typedDefaults from '@/utils/helpers/typed-defaults';
import api from '@/services/api';

export const watchers = [
  {
    getter: (state, { account }) => account.chatEnabled,
    callback: ({ dispatch }, chatEnabled) => chatEnabled && dispatch('permissions/checkIfUserCanChat'),
  },
];

const projectAwarePermission =
  (permission, noCollaborators = false) =>
  (state, getters, { user }, rootGetters) =>
    (!noCollaborators || !user.isCollaborator) &&
    (rootGetters['project/current']
      ? rootGetters[`project/current/${permission}`]
      : state.administrator || state[permission]);

export default {
  namespaced: true,
  state: {
    administrator: false,
    canAccessPortfolio: false,
    canAccessTemplates: false,
    canAccessChat: false,
    canAddMessages: false,
    canAddMilestones: false,
    canAddProjects: false,
    canAddEvents: false,
    canAddUpdate: false,
    canAddRisks: false,
    canAddTasks: false,
    canAddTaskLists: false,
    canLogTime: false,
    canViewTimeLog: false,
    canManagePeople: false,
    canManagePortfolio: false,
    canManageCustomFields: false,
    canManageTimeReminders: false,
    canApproveTime: false,
    canAddCustomReports: false,
    canEditWorkflows: false,
    canAccessCustomReports: false,
    canManageProjectTemplates: false,
    canViewProjectTemplates: false,
    canManageSchedule: false,
    hasAccessToNewProjects: false,
    canAddNotebooks: false,
    canViewEvents: false,
    canViewRisks: false,
    canViewSchedule: false,
    canViewReports: false,
    canViewUtilizationReport: false,
    canViewProfitabilityReport: false,
    canViewPlannedActualMilestoneReport: false,
    canViewForms: false,
    canAddForms: false,
    canAccessInvoiceTracking: false,
    canViewProjectBudget: false,
    canManageProjectBudget: false,
  },
  getters: {
    administrator: (state) => state.administrator,
    canAddProjects: (state) => state.administrator || state.canAddProjects,
    canAddCustomReports: (state) => state.canAddCustomReports,
    canAccessCustomReports: (state) => state.canAccessCustomReports,
    canManagePeople: (state, getters, { user }) => state.administrator || user.inOwnerCompany || state.canManagePeople,
    canAddMilestones: projectAwarePermission('canAddMilestones', true),
    canAddUpdate: projectAwarePermission('canAddUpdate'),
    canViewUpdate: projectAwarePermission('canViewUpdate'),
    canEditWorkflows: projectAwarePermission('canEditWorkflows'),
    canViewMilestones: projectAwarePermission('canViewMilestones'),
    canAddTasks: projectAwarePermission('canAddTasks'),
    canAddTaskLists: projectAwarePermission('canAddTaskLists'),
    canViewTasks: projectAwarePermission('canViewTasks'),
    canAddMessages: projectAwarePermission('canAddMessages'),
    canViewMessages: projectAwarePermission('canViewMessages'),
    canViewFiles: projectAwarePermission('canViewFiles'),
    canAddFiles: projectAwarePermission('canAddFiles'),
    canViewNotebooks: projectAwarePermission('canViewNotebooks'),
    canAddEvents: projectAwarePermission('canAddEvents'),
    canLogTime: projectAwarePermission('canLogTime'),
    canViewTimeLog: projectAwarePermission('canViewTimeLog'),
    canInviteUsers: (state) => state.administrator || state.canManagePeople,
    hasAccessToNewProjects: (state) => state.hasAccessToNewProjects,
    canViewRisks: projectAwarePermission('canViewRisks'),
    canViewLinks: projectAwarePermission('canViewLinks'),
    canAddLinks: projectAwarePermission('canAddLinks'),
    canAccessInvoiceTracking: projectAwarePermission('canAccessInvoiceTracking'),
    canViewProjectBudget: projectAwarePermission('canViewProjectBudget'),
    canManageProjectBudget: projectAwarePermission('canManageProjectBudget'),
    canViewProjectMembers: projectAwarePermission('canViewPeople'),
    canViewProjectUpdate: projectAwarePermission('canViewProjectUpdate'),
    canAddNotebooks: projectAwarePermission('canAddNotebooks'),
    canAddRisks: projectAwarePermission('canAddRisks'),
    canViewEvents: projectAwarePermission('canViewEvents'),
    isProjectAdmin: (state, getters, rootState, rootGetters) =>
      rootGetters['project/current'] && rootGetters['project/current/isAdmin'],
    canAccessCalendar: (state, getters, rootState) => rootState.user.permissions.canAccessCalendar,
    canViewSchedule: (state, getters, rootState, rootGetters) =>
      (state.administrator || state.canViewSchedule) &&
      rootState.user.permissions.canViewSchedule &&
      rootGetters['account/features'].includes('resourcescheduling'),
    canViewSchedulePeople: (state, getters, rootState, rootGetters) =>
      getters.canViewSchedule && rootGetters['account/features'].includes('resourceschedulingpeople'),
    canAccessSchedulePeopleCta: (state, getters, rootState, rootGetters) =>
      rootGetters['account/isSchedulerInScaleUpgradeSegment'] &&
      rootGetters['user/isOwnerAdmin'] &&
      !rootGetters['account/features'].includes('resourceschedulingpeople'),
    canAccessSchedulePeopleTab: (state, getters) => getters.canViewSchedulePeople || getters.canAccessSchedulePeopleCta,
    canManageSchedule: (state, getters, rootState, rootGetters) =>
      (state.administrator || state.canManageSchedule) &&
      rootState.user.permissions.canManageSchedule &&
      rootGetters['account/features'].includes('resourcescheduling'),
    canManageProjectTemplates: (state) => state.administrator || state.canManageProjectTemplates,
    canViewProjectTemplates: (state, getters, rootState, rootGetters) =>
      (state.administrator || state.canViewProjectTemplates) &&
      rootGetters['account/features'].includes('projecttemplates'),
    canViewProjectTemplatesUpgradeCta: (state, getters, { user }, rootGetters) =>
      rootGetters['account/isInPlanTrialUpgradeSegment'] &&
      !rootGetters['account/features'].includes('projecttemplates') &&
      (state.administrator || state.canAddProjects) &&
      user.inOwnerCompany,
    canAccessProjectTemplatesTab: (state, getters) =>
      getters.canViewProjectTemplates || getters.canViewProjectTemplatesUpgradeCta,
    canViewReports: (state, getters, rootState, rootGetters) =>
      (state.administrator || rootState.user.permissions.isAdminOnAProject) &&
      rootGetters['account/features'].includes('reports'),
    canViewReportsUpgradeCta: (state, getters, { user }, rootGetters) =>
      (rootGetters['account/isInPlanTrialUpgradeSegment'] || rootGetters['account/shouldShowNewUpgradePath']) &&
      (state.administrator || user.permissions.isAdminOnAProject) &&
      user.inOwnerCompany,
    canAccessReportsTab: (state, getters) =>
      getters.canViewReports || getters.canViewReportsUpgradeCta || getters.canAccessCustomReports,
    canViewUtilizationReport: (state, getters, rootState, rootGetters) =>
      state.administrator && rootGetters['account/features'].includes('utilizationreport'),
    canViewUtilizationReportUpgradeCta: (state, getters, { user }, rootGetters) =>
      (rootGetters['account/isInPlanTrialUpgradeSegment'] || rootGetters['account/shouldShowNewUpgradePath']) &&
      (state.administrator || user.permissions.isAdminOnAProject) &&
      user.inOwnerCompany,
    canAccessUtilizationReportTab: (state, getters) =>
      getters.canViewUtilizationReportUpgradeCta || getters.canViewUtilizationReport,
    canViewPlannedActualMilestoneReport: (state, getters, rootState, rootGetters) =>
      (state.administrator || rootState.user.permissions.isAdminOnAProject) &&
      rootGetters['account/features'].includes('plannedactualmilestonereport'),
    canAccessPlannedActualMilestoneReportUpgradeCta: (state, getters, { user }) => user.inOwnerCompany,
    canAccessPlannedActualMilestoneReportTab: (state, getters) =>
      getters.canViewPlannedActualMilestoneReport || getters.canAccessPlannedActualMilestoneReportUpgradeCta,
    canViewProfitabilityReport: (state, getters, rootState, rootGetters) =>
      rootGetters['user/isOwnerAdmin'] && rootGetters['account/features'].includes('profitability'),
    canAccessTimeReportCta: (state, getters, rootState, rootGetters) =>
      rootGetters['account/shouldShowNewUpgradePath'] &&
      rootGetters['user/isOwnerAdmin'] &&
      !rootGetters['account/features'].includes('timereport'),
    canAccessTimeReport: (state, getters, rootState, rootGetters) =>
      rootGetters['account/features'].includes('timereport') || getters.canAccessTimeReportCta,
    canAccessProfitabilityReportCta: (state, getters, rootState, rootGetters) =>
      rootGetters['user/isOwnerAdmin'] && !rootGetters['account/features'].includes('profitability'),
    canAccessProfitabilityReportTab: (state, getters) =>
      getters.canViewProfitabilityReport || getters.canAccessProfitabilityReportCta,
    canViewWorkloadPlanner: (state, getters, rootState, rootGetters) =>
      !rootGetters['user/isClientUser'] &&
      rootGetters['user/isAccount'] &&
      rootGetters['account/features'].includes('workloadplanner'),
    canAccessWorkloadPlannerUpgradeCta: (state, getters, { user }, rootGetters) =>
      rootGetters['user/isAccount'] && rootGetters['account/isInPlanTrialUpgradeSegment'] && user.inOwnerCompany,
    canAccessWorkloadPlannerTab: (state, getters) =>
      getters.canViewWorkloadPlanner || getters.canAccessWorkloadPlannerUpgradeCta,
    canViewForms: (state, getters, rootState, rootGetters) =>
      !rootGetters['user/isClientUser'] &&
      projectAwarePermission('canViewForms') &&
      rootGetters['account/features'].includes('intakeforms'),
    canAddForms: (state, getters, rootState, rootGetters) =>
      !rootGetters['user/isClientUser'] &&
      projectAwarePermission('canAddForms') &&
      rootGetters['account/features'].includes('intakeforms'),
    canAccessAutomations: (state, getters, rootState, rootGetters) =>
      rootGetters['user/inOwnerCompany'] && rootGetters['user/isAccount'],
    canAccessWorkloadPlannerOverhaul: (state, getters, rootState, rootGetters) =>
      rootGetters['account/features'].includes('workloadplanneroverhaul'),
    canToggleWorkloadPlannerOverhaul: (state, getters, rootState) => {
      const dateOfRelease = moment('2021-10-28');
      const isRecentSignup = moment(rootState.account.createdAt).isSameOrAfter(dateOfRelease);
      return !isRecentSignup;
    },
  },
  mutations: {
    init(state, permissions) {
      Object.assign(state, typedDefaults(state, permissions));
    },
    canAccessChat(state) {
      state.canAccessChat = true;
    },
  },
  actions: {
    checkIfUserCanChat({ commit, rootState }) {
      api.get(`/chat/v7/people/${rootState.user.id}.json`).then((rs) => {
        if (rs && rs.data && rs.data.person && rs.data.person.isCurrentUserAllowedToChatDirectly) {
          commit('canAccessChat');
        }
      });
    },
  },
};
