"use strict";

define(['moment'], function (moment) {
  return {
    // Defines the relative order of different auto-complete data sources.
    // All values should be at least 1,000,000 apart.
    // Used to initialize the `AutoCompleteDataSource.order` property.
    AUTO_COMPLETE_ORDER: {
      PROJECT: -20e6,
      USER: -10e6,
      TEAM: 10e6,
      TAG: 10e6,
      PRIORITY: 20e6,
      DATE: 30e6,
      DURATION: 40e6,
      TASK_LIST: 50e6,
      BOARD_COLUMN: 60e6,
      COMPANY: 70e6,
      CATEGORY: 80e6,
      EVENT_TYPE: 90e6,
      PROJECT_HEALTH: 100e6,
      CUSTOM_FIELD_DROPDOWN: 101e6,
      STAGE: 102e6
    },
    PROJECT_TEMPLATE: 'projects-template',
    PROJECT_NORMAL: 'normal',
    ZINDICES: {
      TIMERS: 130,
      TINYMCE: 95,
      // reference only, TinyMCE notifications (.mce-notification) implemented in widget-wysiwyg-editor.less
      QUICKSEARCH: 97,
      NOTIFICATIONS: 110,
      FILTERS_SIDEBAR: 115,
      QUICKVIEW: 120,
      // base value, incremented by 1 as qvs are added/nested
      LIGHTBOX: 130,
      // modal-XYX etc, image preview slideshow etc => see lightbox.coffee
      SELECT2: 129,
      FROALA_POPUP: 140,
      TOASTER: 145,
      // Used for toast messages (app.flash)
      TIPPED: 100000000 // for reference only
    },

    OFFSET: {
      SIDEBAR: 32,
      FILTER: 20
    },
    SERVER: {
      TIME_LOG_MAX_DURATION: 1000
    },
    CHECKOUT: {
      NEW_REF_IN_MINUTES: 30,
      SUBSCRIPTION_URL: '/launchpad/v1/checkoutref/projects.json'
    },
    LOADINGMODE: {
      ACTIVE: 1,
      UPDATES: 2,
      FILTER: 3
    },
    ACTIONTYPE: {
      ADDED: 'added',
      UPDATED: 'updated',
      DELETED: 'deleted',
      ARCHIVED: 'archived',
      INVALID: 'invalid'
    },
    THRESHOLDS: {
      estimatedMinutes: 1051920,
      // 2 years
      cancelAccountBtnMaxValue: 30000
    },
    // cancelAccountBtnMaxValue: 1000 # any installation with a LTV over this amount will be shown live chat instead of a cancel btn
    ALERT: {
      timeout: 5000,
      undoTimeout: 7000
    },
    DATES: {
      teamworkEpoch: function () {
        return moment({
          year: 2007,
          month: 10 - 1,
          day: 1
        });
      }
    },
    FONTFAMILY: '"Inter", sans-serif',
    ITEMBGCOLORS: {
      comment: '#95e1c1',
      company: '#e1e6ee',
      event: '#bba2ff',
      budget: '#3AC4FF',
      billing: '#C6C5EA',
      invoice: '#C6C5EA',
      file: '#a1e9f0',
      form: '#dfa275',
      integration: '#ffffff',
      link: '#ffe29b',
      message: '#ffa0dd',
      message_comment: '#ffa0dd',
      reply: '#ffa0dd',
      milestone: '#8f9fe7',
      notebook: '#ed818e',
      project: '#94c7fb',
      projectUpdate: '#94c7fb',
      status: '#e1e6ee',
      task: '#ffad8d',
      tasklist: '#FFC63C',
      team: '#007AC0',
      user: '#C7D9F3'
    },
    UICOLORS: {
      account: '#79E3AA',
      active: '#6cb3ff',
      activities: '#374674',
      activity: '#374674',
      added: '#4fc696',
      administrator: '#79E3AA',
      all: '#b1bac5',
      archived: '#b1bac5',
      attachment: '#85bd79',
      attachments: '#85bd79',
      billable: '#6076da',
      billed: '#138a5a',
      billing: '#a981d6',
      board: '#374674',
      budgethealthamber: '#FEB51F',
      budgethealthgreen: '#59c894',
      budgethealthred: '#C92B2B',
      calendar: '#1cb986',
      calendarevent: '#1cb986',
      client: '#1FCBDC',
      closed: '#b1bac5',
      closedrisk: '#55CB97',
      collaborator: '#895EF7',
      comment: '#1e9d4f',
      comments: '#1e9d4f',
      completed: '#138a5a',
      completedpercent: '#138a5a',
      contact: '#4DA2F8',
      current: '#6cb3ff',
      defaultbg: '#ffffff',
      deleted: '#ff6461',
      due: '#4fc696',
      edited: '#febe6f',
      event: '#1cb986',
      events: '#1cb986',
      file: '#85bd79',
      files: '#85bd79',
      filesversions: '#85bd79',
      fileversion: '#85bd79',
      form: '#555555',
      forms: '#555555',
      healthamber: '#FFC107',
      healthgreen: '#8BC34A',
      healthnone: '#CCCCCC',
      healthred: '#F44336',
      highlightbg: '#ffff9a',
      highpriority: '#ff6461',
      highrisk: '#ff6461',
      info: '#3b93f7',
      inwork: '#6cb3ff',
      late: '#ff6461',
      like: '#4ba1ff',
      link: '#ffaf4f',
      links: '#ffaf4f',
      lowpriority: '#52c175',
      lowrisk: '#52c175',
      mediumpriority: '#FFC300',
      mediumrisk: '#FFC300',
      message: '#de5094',
      messages: '#de5094',
      milestone: '#8f9fe7',
      milestones: '#8f9fe7',
      new: '#3b93f7',
      nodate: '#b1bac5',
      nonbillable: '#b1bac5',
      nopriority: '#b1bac5',
      notebook: '#ffa1b8',
      notebooks: '#ffa1b8',
      open: '#4fc696',
      openrisk: '#FF2F36',
      page: '#5a56fa',
      pending: '#febe6f',
      pendingrisk: '#FFA141',
      person: '#999',
      project: '#3b93f7',
      read: '#e6ebf1',
      reply: '#de5094',
      risk: '#ed581a',
      risks: '#ed581a',
      starred: '#febe6f',
      started: '#4fc696',
      task: '#374674',
      tasklist: '#374674',
      tasklists: '#374674',
      tasks: '#374674',
      time: '#6e1cc6',
      timelog: '#6e1cc6',
      timelogs: '#6e1cc6',
      today: '#6cb3ff',
      todo_items: '#374674',
      unbilled: '#febe6f',
      unread: '#3b93f7',
      upcoming: '#febe6f'
    },
    EVENTTYPECOLORS: ['FF7641', '4DA2F8', '4461D7', '4CD5E3', '895EF7', 'FFC63C', '4ECD97', 'FF61C7', 'E12D42', '969BB1', '646D7D', 'CA6419', '7B84C5', '397E48', '4251AE', 'D88177'],
    CATEGORYCOLORS: ['C3291D', 'D9506C', 'E15E34', 'F09A37', 'F5CE45', '5CC465', '397E48', '9FD9B5', '4CD5E3', '3478F5', '68A9ED', 'B4CCF4', '5756CE', '822DA4', 'B57AD6', '7B84C5', '998666', 'D0A8A0', '5D666F', '969BB1'],
    TAGCOLORS: ["#d84640", "#f78234", "#f4bd38", "#b1da34", "#53c944", "#37ced0", "#2f8de4", "#9b7cdb", "#f47fbe", "#a6a6a6", "#4d4d4d", "#9e6957"],
    TAGSLIMIT: 8,
    TEAMS_ICONS: ['archive', 'billing', 'bullseye', 'checklist', 'check-circle', 'circle-notch', 'clone', 'compress', 'delete', 'edit', 'event', 'expand', 'fax', 'file', 'filter', 'followers', 'globe', 'grid', 'landline-phone', 'list', 'mobile-phone', 'observer', 'owner', 'people', 'pin', 'project', 'quickview', 'reassign', 'save', 'search', 'shield', 'status', 'subtask', 'tasks', 'timer', 'play', 'stop', 'pause', 'copy', 'users', 'wrench', 'merge', 'bolt', 'bug', 'bullhorn', 'lightbulb', 'puzzle-piece', 'external-link', 'file-upload', 'file-download'],
    CUSTOM_FIELD_TYPES: {
      'text-short': 'Text',
      'number-integer': 'Whole number',
      'dropdown': 'Dropdown',
      'date': 'Date',
      'status': 'Status',
      'url': 'URL',
      'checkbox': 'Checkbox'
    },
    FILES: {
      // Microsoft Office Docs that can be edited online or previewed
      MICROSOFTOFFICEFILES: ["xlsx", "xls", "xlsb", "xlsm", "docx", "doc", "dotx", "pptx", "ppt", "ppsx", "pps", "potx", "ppsm", "one"],
      // These file types are blocked
      // for edit using Document Editor
      FORBIDDENFILETYPES: ['zip', 'exe', 'com', 'jar', 'webm', 'tar', 'pkg'],
      // All viewable file types
      VIEWABLEFILETYPES: ['txt', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf',
      // 'psd'
      'ai',
      // 'pages'
      'tiff', 'svg', 'ttf', 'xps', 'eps', 'ps', 'dxf', 'csv'],
      // videos playable in preview modal
      PLAYABLEVIDEO: ["mp4", "webm", "ogv", "mov"],
      // videos NOT playable in preview modal
      BLOCKEDVIDEOS: ["avc", "asf", "avi", "mpg", "mpeg", "flv", "divx", "wmv"],
      // Images displayable in preview modal
      DISPLAYABLEIMAGE: ["png", "jpg", "jpeg", "gif", "webp", "svg"],
      //Dropbox Files that can be "previewed"
      VIEWABLEDROPBOXFILETYPES: ["pdf"],
      // File types that for now are not previewable within TW from OneDrive Business
      ODB_BLOCKED_PREVIEW: ['pdf', 'mp4', 'xlsx', 'docx', 'pptx', 'mdbx', 'doc', 'xls', 'one', 'txt'],
      // sample files directory
      SAMPLE_DIR: "contentfiles/samples/"
    },
    EDITABLEFILESONDRIVEONLINE: ['xlsx', 'docx', 'pptx', 'mdbx', 'doc', 'xls', 'one', 'txt'],
    // Size Limits for File Preview
    FILEPREVIEWSIZELIMITS: {
      GOOGLE: 25 * 1024 * 1024,
      MICROSOFT: 5 * 1024 * 1024
    },
    STRIPEKEY: {
      LIVE: 'pk_live_ufuRcPPHE9yvdrMAopFtPrQu',
      DEV: 'pk_test_QHLdg6rPbjxSdhPjJn9TBrrk'
    },
    PRICEPLANTYPES: {
      MAXPROJECTS: 'maxProjects',
      PERUSER: 'perUser'
    },
    PRICEPLANS: {
      ENTERPRISE: 1,
      CORPORATE: 2,
      BUSINESS2: 3,
      BUSINESS1: 4,
      PERSONAL: 5,
      FREE: 6,
      ENTERPRISEPLUS: 7,
      STARTUP: 8,
      SMALLOFFICE: 9,
      PROFESSIONAL: 10,
      BUSINESS: 11,
      ENTERPRISECUSTOM: 12,
      OFFICE: 13,
      BUSINESSPLAN: 14,
      SMALLOFFICE010617: 15,
      PROFESSION010617: 16,
      BUSINESS010617: 17,
      PERUSER_FREE: 18,
      PERUSER_PRO2017: 19,
      PERUSER_ENTERPRISE: 20,
      PERUSER_PRO: 21,
      PERUSER_PREMIUM: 22,
      PERUSER_CORPORATE: 34,
      PERUSER_PRO_2020_07: 41,
      PERUSER_PREMIUM_2020_07: 42,
      PERUSER_TWONE_2020: 43,
      PERUSER_DELIVER_2021: 47,
      PERUSER_GROW_2021: 48,
      SPACES_ADDON: 49,
      SCALE_2021: 51
    },
    // used to store a list of installation IDs
    // that can be used for test features
    // !!!!!! ONLY FOR INTERNAL TW STAFF !!!!!!!!!!!!!!
    // Used with app.account.isTWInternal()
    TRUSTED_INTERNAL: [1,
    // digitalcrew / DC staging
    2,
    // digitalcrew AU / twtest staging
    371533,
    // digitalcrew EU
    115769,
    // twtest
    567452,
    // twtesteu
    3,
    // twtestau
    336629,
    // teamwork50
    301117,
    // theinitials (Tim)
    44261,
    // adriankerr
    10509,
    // Dan
    // 272513 # chtesting
    291850,
    // Gordon US
    81120,
    // Gordon EU
    134769,
    // juliesandboxtw
    306264,
    // theresetesting
    // 343429 # marccashman # remove marc for now as he's doing live webinars with this
    305602,
    // escireland (Frank)
    112527,
    // tcdev (Tim C)
    280478,
    // aodh US
    // 390993 # aodh EU # removed for now so I have "real" site
    207610,
    // niallchandler
    301408,
    // bluebirdsoftware (Jason test site)
    120509,
    // testcompany99 (internal testing site)
    206817,
    // teamworkvideo (Teamwork Video site)
    300684,
    // enterprise (Niall)
    340712,
    // escapetocork (Leanne test site)
    424670,
    // emmaross (Emma Ross test site)
    431237,
    // Shona/experts
    435174,
    // enterprisetraining (Ashling Creedon)
    469170,
    // danjr.eu.teamwork.com
    428488,
    // emerosullivan
    451545,
    // liamruiseals.eu
    661456,
    // clienttesting (Ciara)
    664086,
    // clienttesting (Ciara)
    664088,
    // clienttesting (Ciara)
    457681,
    // janeaston.teamwork.com
    425114,
    // conorcoyne
    644689,
    // Accountmanager (Melanie Donelon)
    482891,
    // osullivanciara
    820835 // Ruthdemo (Ruth O'Callaghan)
    ],

    // used to store a list of installation IDs
    // that can be used for test features
    // Used with app.account.isTrusted()
    TRUSTED_EXTERNAL: [332558,
    // scseaside (@saasjunkie)
    240904,
    // jsaycreative (JJ Sereday)
    261001,
    // reliablepsd (April Greer)
    84854,
    // madebycam (Campbell McGuiness)
    105976,
    // threeoeight (Campbell McGuiness)
    324033,
    // fieldworkprojects (Gerald Lock)
    323590,
    // aimco
    328947,
    // fcrie
    345903,
    // CITCO (contact Emmet F)
    339723,
    // fiuenterprise (Niall C: Enterprise Customer)
    363808,
    // hertz (Niall C)
    327948,
    // maxitechmedia (Tamar Crane)
    366074,
    // rrcu (Jason Enterprise - remove from beta post release)
    14517,
    // witwag (Gilles Lancrey)
    340457,
    // Orix (Enterprise - remove from beta post release)
    159202,
    // Voxpro (Enterprise - remove from beta post release)
    307704,
    // a3ls (Airbus Enterprise - remove from beta post release)
    308598,
    // a3le (Airbus Enterprise - remove from beta post release)
    336430,
    // bookmark (consider removing after release)
    100185,
    // N!N pro GmbH (Tim C)
    318143,
    // ideaplanet (Enterprise - remove after release)
    38735,
    // booknookbiz (Hitch - tread carefully)
    77343,
    // onemotion (Josh Hagan - Teamwork Expert)
    416889 // auslogics (Wrike account testing)
    ],

    EARLY_ACCESS: [612316,
    // taskreviewer.eu.teamwork.com
    45789 // cvcweb.teamwork.com
    ],

    FREE_ACCOUNTS: [6,
    // FREE
    18 // PERUSER_FREE
    ],

    STARTER_PLAN_IDS: [67],
    DELIVER_PLAN_IDS: [21, 41, 47, 64, 82],
    SVG: {
      options: "M0 1.5C0 .6716.6716 0 1.5 0S3 .6716 3 1.5 2.3284 3 1.5 3 0 2.3284 0 1.5zm0 5C0 5.6716.6716 5 1.5 5S3 5.6716 3 6.5 2.3284 8 1.5 8 0 7.3284 0 6.5zm0 5c0-.8284.6716-1.5 1.5-1.5s1.5.6716 1.5 1.5S2.3284 13 1.5 13 0 12.3284 0 11.5z",
      sliders: "M9.05 8c.2315-1.1413 1.2402-2 2.45-2 1.2098 0 2.2185.8587 2.45 2h1.55c.275 0 .5.225.5.5s-.225.5-.5.5h-1.55c-.2315 1.1413-1.2402 2-2.45 2-1.2098 0-2.2185-.8587-2.45-2H.5C.225 9 0 8.775 0 8.5S.225 8 .5 8h8.55zm-2.1-5C6.7185 4.1413 5.7098 5 4.5 5c-1.2098 0-2.2185-.8587-2.45-2H.5C.225 3 0 2.775 0 2.5S.225 2 .5 2h1.55C2.2815.8587 3.2902 0 4.5 0c1.2098 0 2.2185.8587 2.45 2h8.55c.275 0 .5.225.5.5s-.225.5-.5.5H6.95zM6 2.5C6 1.671 5.329 1 4.5 1S3 1.671 3 2.5 3.671 4 4.5 4 6 3.329 6 2.5zM11.5 7c-.829 0-1.5.671-1.5 1.5s.671 1.5 1.5 1.5S13 9.329 13 8.5 12.329 7 11.5 7z"
    },
    UIDIMENSIONS: {
      HEADERHEIGHT: 120,
      HEADERWITHALERTHEIGHT: 160,
      SUBNAVPOSITION: 170,
      SUBNAVPOSITIONFILTERED: 180,
      SUBNAVPOSITION_EXTERNAL: 210,
      SUBNAVPOSITIONFILTERED_EXTERNAL: 220
    },
    // HELPDOCS
    //prefix - URL
    //root - ROUTE
    //suffix - ARTICLE
    // usage: href="{{app.consts.HELPDOCS.URL+'/'+app.consts.HELPDOCS.BILLING.ROUTE+'/'+app.consts.HELPDOCS.BILLING.ARTICLE.EXPORT_QUICKBOOKS}}"
    HELPDOCS: {
      URL: 'https://support.teamwork.com/projects',
      ADDONS: {
        URL: 'https://integrations.teamwork.com/',
        ROUTE: 'addons-and-integrations',
        ARTICLE: {
          CAL_SYNC: 'using-the-google-calendar-sync-integration?preview=1'
        }
      },
      BETA: {
        ROUTE: 'teamwork-beta-program',
        ARTICLE: {
          BLOCK_FILE_UPLOAD: 'teamwork-beta-program-direct-file-upload-prevention?preview=true',
          MY_BOARDS: 'teamwork-beta-program-my-boards?preview=true',
          CLOCK_IN: 'teamwork-beta-program-clock-in-out?preview=true',
          COLLABORATIVE_EDITING: 'teamwork-beta-program-collaborative-editing?preview=true',
          TEAMS: 'teamwork-beta-program-teams?preview=true',
          SLACK: 'teamwork-beta-program-slack-integration?preview=true',
          VIEW_MANAGER: 'custom-table-views?preview=true',
          NEW_GANTT: 'gantt-chart-overhaul?preview=true',
          RESOURCE_SCHEDULING: 'resource-scheduling-beta?preview=true'
        }
      },
      BILLING: {
        ROUTE: 'billing',
        ARTICLE: {
          EXPORT_INVOICE: 'exporting-an-invoice',
          EXPORT_QUICKBOOKS: 'quickbooks-online-custom-invoice-ids'
        }
      },
      CALENDAR: {
        ROUTE: 'calendar',
        ARTICLE: {
          CREATE_EVENT: 'creating-an-event-in-the-calendar'
        }
      },
      CHROME_EXT: {
        ROUTE: 'chrome-extension',
        ARTICLE: {
          INSTALL: 'installing-teamwork-projects-for-chrome'
        }
      },
      COLLABORATION: {
        ROUTE: 'collaboration',
        ARTICLE: {
          ACTIVITY_UPDATES: 'viewing-project-activity-updates',
          ADD_MESSAGE: 'adding-a-message',
          CALENDAR: 'calendar-explained',
          DAILY_REPORT_EMAL: 'enabling-your-daily-report-email',
          ITEM_COMMENTS: 'commenting-on-items',
          NOTIFICATION_SETTINGS: 'project-notification-settings',
          STATUS_UPDATES: 'status-updates'
        }
      },
      EFFICIENCY: {
        ROUTE: 'efficiency',
        ARTICLE: {
          BULK_EDIT_SUB_TASKS: 'bulk-editing-sub-tasks',
          COPY_PROJECT: 'copying-a-project',
          IMPORT_TASK_EXCEL: 'importing-tasks-from-excel',
          QUICK_EVENT: 'quick-add-add-a-new-event',
          QUICK_MESSAGE: 'quick-add-new-message',
          QUICK_MILESTONE: 'add-a-new-milestone',
          QUICK_TASK: 'quick-add-new-task',
          QUICK_PROJECT: 'add-a-new-project',
          IMPORT_USERS: 'importing-users-and-contacts',
          SSO: 'print-what-is-single-sign-on-sso',
          SWITCH_PROJECT: 'switch-project-menu',
          TASK_LIST_TEMP: 'task-list-templates',
          CREATE_TASKS_VIA_EMAIL: 'creating-tasks-via-email'
        }
      },
      EVERYTHING: {
        ROUTE: 'everything',
        ARTICLE: {
          TIME_TOTALS: 'understanding-decimal-time-totals',
          TIME_LOGGED: 'everything-all-time'
        }
      },
      FILES: {
        ROUTE: 'files',
        ARTICLE: {
          TAGGING_ITEMS: 'tagging-items',
          VIDEO_FORMATS: 'supported-video-formats'
        }
      },
      GETTING_STARTED: {
        ROUTE: 'getting-started',
        ARTICLE: {
          ADD_MESSAGE: 'adding-a-message',
          ADD_PEOPLE: 'adding-people-to-a-project',
          ADD_PROJECT: 'adding-a-project',
          ADD_TASK: 'adding-tasks-within-the-tasks-area',
          CREATE_TASK_LIST: 'creating-a-task-list',
          COMPANIES: 'companies-owner-and-external',
          INVITE_PEOPLE: 'inviting-people-to-your-teamwork-projects-site',
          UPLOAD_FILES: 'uploading-files',
          TASK_LISTS: 'task-lists-overview',
          COMMENTS: 'comments-overview',
          TASKS: 'tasks-overview',
          PEOPLE: 'people-overview',
          CALENDAR: 'calendar-overview',
          TIME_TRACKING: 'time-tracking-overview',
          FILES: 'files-overview',
          MESSAGES: 'messages-overview',
          MILESTONES: 'milestones-overview',
          PROJECTS: 'projects-overview',
          WEBINAR: 'subscribing-to-a-webinar-through-teamwork-projects'
        }
      },
      IMPORT: {
        ROUTE: 'import',
        ARTICLE: {
          BASECAMP: 'importing-your-projects-from-basecamp',
          ASANA: 'importing-your-projects-from-asana',
          WRIKE: 'importing-your-projects-from-wrike',
          TRELLO: 'importing-your-projects-from-trello',
          MONDAY: 'importing-your-projects-from-monday',
          CLICKUP: 'clickup-importer'
        }
      },
      INTEGRATIONS: {
        ROUTE: 'integrations2',
        ARTICLE: {
          ENABLE_FRESHBOOKS: '',
          //temporary -> BILLING.ARTICLE.EXPORT_INVOICE
          ENABLE_HARVEST: 'enabling-harvest-integration-on-your-teamwork-projects-site',
          SALESFORCE: 'salesforce/salesforce-integration'
        }
      },
      OVERVIEW: {
        ROUTE: 'overview2',
        ARTICLE: {
          ACTIVE_PROJECTS: 'all-active-project',
          PROJECT_CHART: 'project-chart',
          PROFILE_ITEMS: 'viewing-all-your-items-in-your-profile',
          WORKLOAD: 'everything-workload',
          OVERVIEW_TABS: 'overview-tabs'
        }
      },
      PROJECTOVERVIEW: {
        ROUTE: 'overview',
        ARTICLE: {
          OVERVIEW_TABS: 'overview-tabs'
        }
      },
      PEOPLE: {
        ROUTE: 'project-people',
        ARTICLE: {
          ADD_OBSERVERS: 'adding-observers-to-a-project',
          ADD_EDIT_ROLES: 'creating-and-editing-roles-on-a-project'
        }
      },
      PLANNING: {
        ROUTE: 'planning-managing-work',
        ARTICLE: {
          ACTIVE_TASKS: 'everything-all-active-tasks',
          ADD_MILESTONE: 'adding-a-milestone',
          CALENDAR: 'calendar-explained',
          //duplicate -> COLLABORATION.ARTICLE.CALENDAR
          GANTT_CHART: 'viewing-your-project-in-a-gantt-chart',
          EST_TASK_TIME: 'estimated-time-on-tasks',
          PERMISSIONS: 'setting-user-permissions-on-a-project',
          TIME_LOGGED: 'everything-all-time',
          PROJECTS_AREA: 'projects-area2'
        }
      },
      PROFILE: {
        ROUTE: 'your-profile',
        ARTICLE: {
          ADD_EMAIL_ADDR: 'adding-extra-email-addresses',
          LINK_MULTIPLE_SITES: 'link-multiple-teamwork-projects-sites'
        }
      },
      SUBSCRIPTION: {
        ROUTE: 'subscription',
        ARTICLE: {
          CHANGE_EMAIL: 'changing-the-email-address-for-your-subscription-invoices',
          COMPANY_DETAILS: 'updating-the-company-details-on-your-invoice',
          INVOICE_PAYMENTS: 'viewing-your-subscription-invoices-and-payments',
          PAYING_ANNUALLY: 'paying-annually-by-invoice',
          PAYMENT_DETAILS: 'updating-your-payment-details',
          PAYMENT_METHODS: 'what-payment-methods-are-accepted'
        }
      },
      SETTINGS: {
        ROUTE: 'settings-administrator-only',
        ARTICLE: {
          VIEW_INVOICES: 'viewing-your-monthly-or-annual-invoices'
        }
      },
      EMAIL: {
        ROUTE: 'email',
        ARTICLE: {
          FORWARDED_EMAILS: 'managing-forwarded-emails'
        }
      },
      TASKS: {
        ROUTE: 'tasks',
        ARTICLE: {
          EMAIL_INTERACTION: 'interact-with-tasks-by-email',
          LOGGING_TIME: 'logging-time-on-tasks'
        }
      },
      USING_TEAMWORK: {
        ROUTE: 'using-teamwork',
        ARTICLE: {
          CLIENT_USERS: 'working-with-client-users?preview=true'
        }
      }
    },
    // WARNING: Don't use API_PREFIX's directly. Use app.prefixApi() instead.
    API_PREFIX_CF: "",
    API_PREFIX_V1: "projects/api/v1",
    API_PREFIX_V2: "projects/api/v2",
    API_PREFIX_V3: "projects/api/v3",
    // API_SELECTOR should be set to the most widely used API prefix across the app.
    // It can be selectively overridden via the overridePrefix param to individual app.prefixApi calls.
    API_SELECTOR: "API_PREFIX_V2",
    // Responsive breakpoints
    SCREEN_BREAKPOINTS: {
      LARGE: 1200
    },
    // General Permissions access points wr
    ACCESS_PERMISSION: {
      WRITE: 2,
      READ: 1,
      NONE: 0
    },
    PENDO_TARS_EVENT_CATEGORIES: {
      DISCOVERY: 'discovery_event',
      ADVANCED_DISCOVERY: 'advanced_discovery_event',
      ACTIVATION: 'activation_event',
      STANDARD_INTERACTION: 'standard_interaction_event',
      ERROR: 'error_event'
    },
    DEPRECATED_WEBHOOKS: ['CARD.CREATED', 'CARD.UPDATED', 'CARD.DELETED', 'COLUMN.CREATED', 'COLUMN.UPDATED', 'COLUMN.DELETED']
  };
});