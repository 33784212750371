import { getSocketUrl } from '@/scaffolding/socket';

export const watchers = [
  {
    getter: (state, rootState) => rootState.account,
    callback: ({ state, commit }, account) => {
      if (account) {
        commit('config/load', getSocketUrl(state.account));
      }
    },
  },
];

export default {
  namespaced: true,
  state: {
    socket: '',
    isReady: false,
  },
  mutations: {
    load(state, config) {
      Object.assign(state, config);
      state.isReady = true;
    },
  },
};
