import ko from 'knockout';

let lastHash = window.location.hash;

window.addEventListener('hashchange', () => {
  lastHash = window.location.hash;
});

export const vueRouter = ko.observable();
export const vueCurrentRoute = ko.observable();

export function initRouter(router) {
  vueRouter(router);
  vueCurrentRoute(vueRouter().currentRoute);

  vueRouter().afterEach(() => {
    vueCurrentRoute(vueRouter().currentRoute);

    // Wait until Vue Router updates the hash or
    // until the browser emits its own "hashchange" event.
    setTimeout(() => {
      // If the current route is updated programmatically,
      // the "hashchange" event is not emitted automaticcaly,
      // so we do it here to keep the tko router in sync.
      if (lastHash !== window.location.hash) {
        window.dispatchEvent(new (HashChangeEvent || Event)('hashchange'));
      }
    });
  });
}
