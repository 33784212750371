/* eslint-disable import/no-import-module-exports */
import ko from 'knockout';
import { cloneDeep, isEqual } from 'lodash-es';

// @vue/component
export default {
  name: 'TkoComponent',
  props: {
    name: {
      required: true,
      type: String,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    isTippedContainer: {
      type: Boolean,
      default: true,
    },
    modifier: {
      type: String,
      default: '',
    },
    cloneParams: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    component() {
      return {
        name: this.name,
        params: this.cloneParams ? cloneDeep(this.params) : this.params,
      };
    },
  },
  watch: {
    component(component) {
      if (!this.cloneParams && isEqual(this.componentKo(), component)) {
        return;
      }
      this.componentKo(component);
    },
  },
  mounted() {
    // Add HMR event listener
    if (module.hot) {
      window.addEventListener('message', this.handleHotComponent, false);
    }

    this.componentKo = ko.observable(this.component);
    ko.applyBindings({ componentKo: this.componentKo }, this.$el);
  },
  destroyed() {
    ko.cleanNode(this.$el);

    // Remove HMR event listener
    if (module.hot) {
      window.removeEventListener('message', this.handleHotComponent, false);
    }
  },
  methods: {
    // Handles HMR in development when using build-tko:hot-config
    async handleHotComponent(event) {
      if (event.origin !== window.location.origin) {
        return;
      }
      if (event.data.tkoHMRRefresh) {
        // Unregister and destroy KO component
        ko.cleanNode(this.$el);
        ko.applyBindings({ componentKo: this.componentKo }, this.$el);
      }
    },
  },
};
