import focus from './focus';
import imgLoadFail from './img-load-fail';
import scrollIntoView from './scroll-into-view';
import stickySidebar from './sticky-sidebar';

// NOTE: Directives must be registered in kebab-case.
export default {
  focus,
  'img-load-fail': imgLoadFail,
  'scroll-into-view': scrollIntoView,
  'sticky-sidebar': stickySidebar,
};
