/* eslint-disable no-param-reassign */

/**
 * Vue.assign is the Vue.set version of Object.assign
 *
 * Useful for updating a reactive object, it will permit new
 * properties and also efficiently update existing ones.
 */
export default {
  install(Vue) {
    Vue.assign = (target, ...sources) => {
      sources.forEach((src) => {
        Object.entries(src).forEach(([key, value]) => Vue.set(target, key, value));
      });
      return target;
    };
  },
};
