/**
 * This adds a body ID from TKO to support legacy styles
 *
 */

export default (store) => {
  store.watch(
    (state) => state.layout.bodyId,
    (bodyId) => {
      document.body.id = bodyId;
    },
  );
};
