import Vue from 'vue';
import { createRecordListLoader, ACTIONS, MUTATIONS, GETTERS, API } from '@/store/utils/loader';
import { mapActions, mapMutations } from '@/store/utils/record-mapper';

export const watchers = [
  // Watch for any local task modifications which might change whether a task is in the
  // current filtered view or not.
  {
    actions: ['changeName', 'progress', 'range', 'uncomplete', 'assign'].map((m) => `task/${m}`),
    callback: ({ dispatch, getters, state }, type, payload) => {
      if (getters['filter/current/active']) {
        dispatch('project/tasks/resultChangeNotification', {
          id: state.project.currentProjectId,
          payload: payload.id || payload,
        });
      }
    },
  },
];

export default createRecordListLoader({
  namespaced: true,
  modules: {
    // project/tasks/people
    people: createRecordListLoader({
      namespaced: true,
      config: {
        url: (id) => `projects/${id}/tasks/people.json`,
        module: 'people',
        pagination: true,
        updater: true,
      },
    }),
  },
  config: {
    id: 'project/tasks',
    url: (id) => `${API.v2}/projects/${id}/taskids.json`,
    params: (state, getters) => ({
      filter: 'anytime',
      priority: 'any',
      createdFilter: 'anytime',
      include: ['taskListNames', 'projectNames'],
      getSubTasks: true,
      includeCompletedSubtasks: false,
      matchAllTags: true,
      showCompletedLists: true,
      // When Filters are feature gated, completed tasks can still be toggled
      includeCompletedTasks: state.filter.includeCompletedTasks,
      ...getters['filter/current/apiParameters'],
    }),
    pagination: { initialPages: 4, pageSize: 250 },
    updater: true,
  },
  mutations: mapMutations({
    id2Remove(state, id2Remove) {
      Vue.set(state, 'id2Remove', id2Remove);
    },
  }),
  actions: mapActions({
    onLoad({ dispatch, commit, state, getters, rootGetters, id }, rs) {
      const ids = rs.data.taskids;
      // Defers to the task batch loader to load each record from the IDs
      const filtered = rootGetters['filter/current/active'];
      const updating = getters[GETTERS.UPDATING](id);
      const action = `task/batch/${(updating && 'reload') || (filtered && 'accessWithParents') || 'access'}`;
      dispatch(action, ids, { root: true, recordMap: false });
      // When new data is loaded we clear the `completedThisSession` flag which
      // is there to avoid removing a completed task from view immediately
      commit('task/clearSessionCompleted', null, {
        root: true,
        recordMap: false,
      });
      const list = state.additive ? [...new Set([...(state.list || []), ...ids])] : ids;
      // If running a filtered update and id2remove was specified but not returned
      if (state.id2Remove) {
        if (filtered && updating && !ids.includes(state.id2Remove)) {
          // remove it from the list
          const index = list.indexOf(state.id2Remove);
          if (index > -1) {
            list.splice(index, 1);
          }
        }
        commit('id2Remove'); // clear
      }
      commit(MUTATIONS.LIST, list);
      commit(MUTATIONS.ADDITIVE);
    },
    // If a single task change triggered the change, then if that task isn't
    // returned in the delta response, it should be removed
    resultChangeNotification({ dispatch, commit, state }, id2Remove) {
      if (id2Remove || state.id2Remove) {
        commit('id2Remove', id2Remove);
      }
      dispatch(ACTIONS.DATA_CHANGE);
    },
  }),
});
