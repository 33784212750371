import Vue from 'vue';

// this pairs with the @widgets/Popover component
// to purpose is to keep track of popovers so we can controls things like
// closing all other when one is opened
export default {
  namespaced: true,
  state: {
    instances: {},
    locked: [],
  },
  mutations: {
    addInstance(state, id) {
      state.instances = { ...state.instances, [id]: false };
    },

    removeInstance(state, id) {
      Vue.delete(state.instances, id);
    },

    show(state, { id, noCloseOther }) {
      if (!noCloseOther) {
        Object.keys(state.instances).forEach((instance) => {
          if (state.locked.includes(instance)) {
            // Preserve current state of any locked popovers until they're unlocked
            return;
          }

          state.instances[instance] = false;
        });
      }

      state.instances[id] = true;
    },

    hide(state, id) {
      if (state.locked.includes(Number(id))) {
        // Preserve current state of any locked popovers until they're unlocked
        return;
      }

      state.instances[id] = false;
    },

    hideAll(state) {
      const resetInstances = {};

      Object.keys(state.instances).forEach((id) => {
        // Preserve current state of any locked popovers until they're unlocked
        if (state.locked.includes(Number(id))) {
          resetInstances[id] = state.instances[id];
          return;
        }

        resetInstances[id] = false;
      });

      state.instances = resetInstances;
    },

    toggle(state, id) {
      if (state.locked.includes(Number(id))) {
        return;
      }

      state.instances[id] = !state.instances[id];
    },

    lock(state, id) {
      const index = state.locked.indexOf(id);
      if (index === -1) {
        state.locked.push(id);
      }
    },

    unlock(state, id) {
      const index = state.locked.indexOf(id);
      if (index !== -1) {
        state.locked.splice(index, 1);
      }
    },
  },
  getters: {
    instanceCount(state) {
      return Object.keys(state.instances).length;
    },
  },
  actions: {
    hideAll({ commit, dispatch }) {
      dispatch('hybridPopover/hide', {}, { root: true });
      commit('hideAll');
    },

    addInstance({ state, commit }) {
      const id = Object.keys(state.instances).length + 1;
      commit('addInstance', id);
      return id;
    },

    lock({ commit }, id) {
      commit('lock', id);
    },

    unlock({ commit }, id) {
      commit('unlock', id);
    },
  },
};
