/* eslint-disable no-param-reassign,no-return-assign,consistent-return,no-underscore-dangle */
import api from '@/services/api';

export default {
  namespaced: true,
  state: {
    progress: '',
    status: 'working',
    lastCreatedProjectId: 0,
    errors: [],
    lastUpdated: null,
  },
  getters: {
    progress: (state) => state.progress,
    lastCreatedProjectId: (state) => state.lastCreatedProjectId,
    errors: (state) => state.errors,
    status: (state) => state.status,
    lastUpdated: (state) => state.lastUpdated,
  },
  actions: {
    createProjectFromJSON(_, { json, debugMode = false }) {
      return api.post('/projects/json.json', {
        project: json,
        waitForResponse: true,
        debugMode,
      });
    },
    createTeam(_, json) {
      return api.post('/teams.json', json);
    },
    inviteUser(_, json) {
      return api.post('/people.json', json);
    },
  },
  mutations: {
    updateStatus(state, status) {
      state.status = status;
    },
    updateProgress(state, message) {
      state.progress = message;
    },
    updateLastCreatedProjectId(state, id) {
      state.lastCreatedProjectId = id;
    },
    addErrors(state, data) {
      state.errors.push(data);
    },
    resetErrors(state) {
      state.errors = [];
    },
    setLastUpdated(state, eventTime) {
      state.lastUpdated = eventTime;
    },
  },
};
