import { onScopeDispose } from 'vue-demi';
import assertHasScope from '@/utils/other/assertHasScope';
import { useAxios } from './useAxios';

/**
 * @returns Dispose function.
 */
export default function useAxiosResponseInterceptor(fulfilled, rejected) {
  assertHasScope();

  const axios = useAxios();
  const id = axios.interceptors.response.use(fulfilled, rejected);
  const stop = () => axios.interceptors.response.eject(id);

  onScopeDispose(stop);

  return stop;
}
