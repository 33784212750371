/* eslint-disable no-unused-vars */
import { MUTATIONS } from './constants';

// Standard behaviour here, they can be overwritten with other arrows,
// or even getters (by name)
export default {
  // but the URL can be also be a fixed value (if set here), or an arrow which
  // takes the record ID as a parameter.
  url: null,
  // N.B. params has global store scope, not module scope
  params: (state, getters) => ({}),
  reactiveParams: true,

  commit: 'record',
  listMutation: MUTATIONS.LIST,
};
