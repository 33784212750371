/* eslint-disable consistent-return */
import { watch, unwatch } from '@/services/vuex-dynamic-watches';
import { check } from '@/services/vuex-visible-data';
import { createListLoader } from '../index.js';
import { ACTIONS, GETTERS, MUTATIONS, STATUS } from '../constants';

/**
 * Filtered Loader - the need is to be able to switch
 * between filtered and unfiltered loader without a need
 * to requery the same results as were previously queried.
 *
 * Premise here is simply to encapsulate 2 loaders under
 * a proxy loader. Switching between the 2 based on the
 * `isFiltered` value.
 */

const moduleName = (filtered) => (filtered ? 'filtered' : 'loader');

// Map a public action to either loader
const proxyAction =
  (name) =>
  ({ dispatch, getters }, payload) =>
    dispatch(`${moduleName(getters.isFiltered)}/${name}`, payload);

const proxyActions = (...actions) => actions.reduce((acts, act) => ({ ...acts, [act]: proxyAction(act) }), {});

// Map a public getter to either loader
const proxyGetter = (name) => (state, getters) => getters[`${moduleName(getters.isFiltered)}/${name}`];

const proxyGetters = (...getters) =>
  getters.reduce((gettrs, getter) => ({ ...gettrs, [getter]: proxyGetter(getter) }), {});

// eslint-disable-next-line import/prefer-default-export
export const createFilteredListLoader = (opts = {}) => {
  const cfg = opts.config || {};

  const combinedParams = (state, getters) => ({
    ...(cfg.params ? cfg.params(state, getters) : {}),
    ...cfg.filterParams(state, getters),
  });

  return {
    namespaced: opts.namespaced,
    modules: {
      [moduleName(false)]: createListLoader({
        namespaced: true,
        ...opts,
      }),
      [moduleName(true)]: createListLoader({
        namespaced: true,
        ...opts,
        config: {
          ...(opts.config || {}),
          params: combinedParams,
          reactiveParams: false,
        },
      }),
    },
    state: { paramsJSON: '' },
    mutations: {
      updateParams: (state, paramsJSON) => {
        state.paramsJSON = paramsJSON;
      },
    },
    getters: {
      ...proxyGetters(
        GETTERS.LIST,
        GETTERS.STATUS_READY,
        GETTERS.PAGINATION.HAS_MORE,
        GETTERS.PAGINATION.TOTAL_RECORDS,
        ...Object.keys(opts.getters || {}),
      ),
      isFiltered: (state, getters, rootState, rootGetters) => cfg.isFiltered(rootState, rootGetters),
    },
    actions: {
      ...proxyActions(ACTIONS.LOAD_CANCEL, ACTIONS.PAGINATION.LOAD_NEXT, ...Object.keys(opts.actions || {})),
      /**
       * Access action is mapped like the others, but some
       * watches are setup and it is also triggered when switching between loaders
       */
      [ACTIONS.ACCESS]({ dispatch, getters, state, id }) {
        let action = () => dispatch(ACTIONS.FILTERED.SWITCH);
        watch({ state, id, getter: cfg.isFiltered, action });
        if (getters.isFiltered) {
          action = () => dispatch(ACTIONS.PARAM_CHANGE);
          watch({ state, id, getter: combinedParams, action, json: true });
          if (state.filtered.status === STATUS.loaded) {
            // if loaded, we check if the params have changed since last time.
            // as ACCESS would be a noop
            return dispatch(ACTIONS.PARAM_CHANGE);
          }
          dispatch(ACTIONS.FILTERED.INIT);
          return dispatch(`${moduleName(true)}/${ACTIONS.ACCESS}`);
        }
        unwatch({ state, getter: combinedParams });
        return dispatch(`${moduleName(false)}/${ACTIONS.ACCESS}`);
      },
      /**
       * When the Filtered loader switches from filtered-2-unfiltered or vice versa
       * Should only trigger an access if currently visible.
       */
      [ACTIONS.FILTERED.SWITCH]({ dispatch, id }) {
        if (!cfg.id || check(cfg.id, id)) {
          return dispatch(ACTIONS.ACCESS);
        }
      },
      /**
       * First time filtering, needs to record the params JSON, to compare for any changes
       */
      [ACTIONS.FILTERED.INIT]({ commit, rootState, rootGetters }) {
        commit('updateParams', JSON.stringify(combinedParams(rootState, rootGetters)));
      },
      /**
       * The combinedParams are watched for changes, but only changes while `isFiltered`
       * is true will be evaluated, and we need to keep track of the last value because
       * switching back/forward from unfiltered/filtered results shouldn't be seen as
       * a transition of the combinedParams.
       */
      [ACTIONS.PARAM_CHANGE]({ dispatch, commit, state, getters, rootState, rootGetters }) {
        if (getters.isFiltered) {
          const paramsJSON = JSON.stringify(combinedParams(rootState, rootGetters));
          if (state.paramsJSON !== paramsJSON) {
            commit('updateParams', paramsJSON);
            return dispatch(`${moduleName(true)}/${ACTIONS.PARAM_CHANGE}`);
          }
        }
      },
      /**
       * Data Change is mapped like the other mapped actions, however the
       * inactive loader also needs to be set to stale (if initialised).
       */
      [ACTIONS.DATA_CHANGE]({ dispatch, commit, getters }) {
        const [active, dormant] = [moduleName(getters.isFiltered), moduleName(!getters.isFiltered)];

        if (!getters[`${dormant}/${GETTERS.UNINIT}`]) {
          commit(`${dormant}/${MUTATIONS.STALE}`);
        }
        return dispatch(`${active}/${ACTIONS.DATA_CHANGE}`);
      },
    },
  };
};
