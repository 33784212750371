export default [
  {
    path: '/welcome',
    component: () => import('./RouteWelcome'),
    children: [
      {
        path: 'the-fundamentals',
        component: () => import('@/views/welcome/RouteWelcomeTheFundamentals'),
      },
      {
        path: 'set-up-teamwork',
        component: () => import('@/views/welcome/RouteWelcomeSetUpTeamwork'),
      },
      {
        path: 'educate',
        component: () => import('@/views/welcome/RouteWelcomeEducate'),
      },
      {
        path: 'integrations',
        component: () => import('@/views/welcome/RouteWelcomeIntegrations'),
      },
      {
        path: 'get-in-touch',
        component: () => import('@/views/welcome/RouteWelcomeGetInTouch'),
      },
      {
        path: '*',
        redirect: '/welcome/the-fundamentals',
      },
    ],
  },
];
