import { computed, shallowRef } from 'vue-demi';
import { useItemLoader } from '@/api';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItem(response) {
  return response.data.groups || {};
}

const SUPPORTED_GROUPS = Object.freeze(['duedate']);

/**
 * Loader for task group counts.
 *
 * @param {Object} options - Options for the loader.
 * @param {boolean} [options.active=true] - Whether the loader is active.
 * @param {string} options.group - The group type to load counts for.
 * @param {Object} options.params - Additional parameters for the request.
 * @returns {Object}
 */
export default function useTaskGroupCountsLoader({ active: _active = true, group: _group, params: _params }) {
  const active = shallowRef(_active);
  const group = shallowRef(_group);
  const params = shallowRef(_params);

  const { state, refresh } = useItemLoader({
    url: computed(() => {
      return active.value && SUPPORTED_GROUPS.includes(group.value)
        ? `/projects/api/v3/tasks/groups/${group.value}/counts.json`
        : '';
    }),
    params: computed(() => ({
      ...params.value,
      include: undefined,
    })),
    responseToItem,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'task' || event.type === 'projectTasks' || event.type === 'tasklistTasks') {
      refresh();
    }
  });

  return state;
}
