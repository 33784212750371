import { computed, shallowRef } from 'vue-demi';
import { v1Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  const tasklists = response.data.tasklists;

  for (let i = 0; i < tasklists.length; i += 1) {
    const tasklist = tasklists[i];
    tasklist.id = Number(tasklist.id);
    tasklist.completedCount = tasklist['completed-count'];
    tasklist.uncompletedCount = tasklist['uncompleted-count'];
    tasklist.projectId = Number(tasklist.projectId);
  }

  return tasklists;
}

function order(list1, list2) {
  return list1.position - list2.position;
}

/**
 * Loads a list of task lists from the Teamwork v1 API.
 * @param {Object} options
 * @param {string} options.projectId The project from which to load task lists.
 */
export default function useTasklistsV1Loader({ projectId: _projectId, params, count, pageSize = 50 }) {
  const projectId = shallowRef(_projectId);
  const url = computed(() => {
    if (projectId.value) {
      return v1Url(`projects/${projectId.value}/tasklists`);
    }

    return v1Url('tasklists');
  });

  const { state, refresh } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    order,
    pageSize,
  });

  useRealTimeUpdates((event) => {
    // If many tasks changed, reload all task lists.
    // The event name is misleading, as task modifications are not limited to a single project.
    if (event.type === 'projectTasks') {
      refresh();
      return;
    }

    // If filtering by projectId, it must match event.projectId or event.previousProjectId.
    if (projectId.value && projectId.value !== event.projectId && projectId.value !== event.previousProjectId) {
      return;
    }

    if (event.type === 'taskList' || event.type === 'taskListTasks') {
      refresh(event.taskListId);
    } else if (event.type === 'task') {
      if (
        event.action === 'reopened' ||
        event.action === 'completed' ||
        event.action === 'new' ||
        event.action === 'deleted'
      ) {
        // Task count changed as the result of creating, deleting, completing or reopening a task.
        refresh(event.taskListId);
      } else if (event.previousTaskListId && event.previousTaskListId !== event.taskListId) {
        // Task count changed as the result of moving a task between tasklists.
        refresh(event.taskListId);
        refresh(event.previousTaskListId);
      }
    }
  });

  return state;
}
