import { createListLoader } from '@/store/utils/loader';

export default createListLoader({
  namespaced: true,
  config: {
    url: '/projects/api/v3/companies.json',
    params: ({ company }) => ({
      searchTerm: company.filtered.searchTerm,
    }),
    module: 'company',
  },
  state: {
    searchTerm: '',
  },
  mutations: {
    search: (state, term) => {
      state.searchTerm = term;
    },
  },
});
