/* eslint-disable no-param-reassign,no-return-assign,consistent-return */
import placeholder from './placeholder';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import batch from './modules/batch';
import subtasks from './modules/subtasks';
import predecessors from './modules/predecessors';
import recursiveDependencies from './modules/recursiveDependencies';

export default {
  namespaced: true,
  modules: {
    batch,
    subtasks,
    predecessors,
    recursiveDependencies,
  },
  state: {
    records: {},
    // Note subtasks and tasksByTasklist (id => array)
    subtasks: {}, // order maintained
    // Only top level tasklist tasks (not subtasks)
    tasksByTasklist: {}, // order not maintained
  },
  getters: {
    ...placeholder.getters,
    ...getters,
  },
  mutations: {
    ...placeholder.mutations,
    ...mutations,
  },
  actions: {
    ...placeholder.actions,
    ...actions,
  },
};
