import { v1Url } from '@/utils/fetcher';
import { useAxios } from '../base/useAxios';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

export default function useDataViewActions() {
  const api = useAxios();
  const { socketId } = useRealTimeUpdates();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId.value,
        'Triggered-By': 'user',
        'Sent-By': 'composable',
      },
    };
  }

  return {
    createDataView(dataView) {
      return api.post(v1Url('me/dataviews'), { dataView, get: '' }, config());
    },
  };
}
