import { computed, inject, provide, shallowRef, watch } from 'vue-demi';
import { useCurrentAccount, useCurrentUser } from '@/api';
import usePreferenceActions from './usePreferenceActions';

const usePreferencesSymbol = Symbol('usePreferences');

/**
 * @template Type
 * @typedef {Object} PreferenceType
 * @property {function(string|Type): Type} parse
 * @property {function(Type): string} serialize
 */

/**
 * @param {boolean} defaultValue
 * @returns {PreferenceType<boolean>}
 */
function useBooleanType(defaultValue = false) {
  return {
    parse(value) {
      switch (typeof value) {
        case 'boolean': {
          return value;
        }
        case 'string': {
          switch (value.toLowerCase()) {
            case 'true':
            case 'yes': {
              return true;
            }
            case 'false':
            case 'no': {
              return false;
            }
            default: {
              return defaultValue;
            }
          }
        }
        default: {
          return defaultValue;
        }
      }
    },
    serialize(value) {
      return JSON.stringify(value);
    },
  };
}

/**
 * @param {string} defaultValue
 * @returns {PreferenceType<string>}
 */
function useStringType(defaultValue = '') {
  return {
    parse(value) {
      switch (typeof value) {
        case 'string': {
          return value;
        }
        default: {
          return defaultValue;
        }
      }
    },
    serialize(value) {
      return value.toString();
    },
  };
}

/**
 * @param {Object} defaultValue
 * @returns {PreferenceType<Object>}
 */
function useObjectType(defaultValue = {}) {
  return {
    parse(value) {
      switch (typeof value) {
        case 'object': {
          if (value !== null && !Array.isArray(value)) {
            return value;
          }
          return defaultValue;
        }
        case 'string': {
          try {
            const parsedValue = JSON.parse(value);
            if (typeof parsedValue === 'object' && parsedValue !== null && !Array.isArray(parsedValue)) {
              return { ...defaultValue, ...parsedValue };
            }
            return defaultValue;
          } catch (error) {
            return defaultValue;
          }
        }
        default: {
          return defaultValue;
        }
      }
    },
    serialize(value) {
      return JSON.stringify(value);
    },
  };
}

/**
 * @param {*[]} defaultValue
 * @returns {PreferenceType<*[]>}
 */
function useArrayType(defaultValue = {}) {
  return {
    parse(value) {
      if (Array.isArray(value)) {
        return value;
      }

      if (typeof value !== 'string' || !value) {
        return defaultValue;
      }
      try {
        const parsedValue = JSON.parse(value);

        if (Array.isArray(parsedValue)) {
          return parsedValue;
        }
      } catch (error) {
        //
      }

      return defaultValue;
    },
    serialize(value) {
      return JSON.stringify(value);
    },
  };
}

/**
 * Returns a writable computed which is automatically synced with the given account preference.
 * @template Type
 * @param {string} name
 * @param {PreferenceType<Type>} type
 * @returns {import('vue-demi').WritableComputedRef<Type>}
 */
function useAccountPreference(name, type) {
  const account = useCurrentAccount();
  const { saveAccountPreferences } = usePreferenceActions();
  return computed({
    get() {
      return type.parse(account.value?.preferences?.[name]);
    },
    set(value) {
      saveAccountPreferences({ [name]: type.serialize(value) });
    },
  });
}

/**
 * Returns a writable computed which is automatically synced with the given account preference.
 * @template Type
 * @param {string} name
 * @param {PreferenceType<Type>} type
 * @returns {import('vue-demi').WritableComputedRef<Type>}
 */
function useUserPreference(name, type) {
  const user = useCurrentUser();
  const { saveUserPreferences } = usePreferenceActions();
  return computed({
    get() {
      return type.parse(user.value?.preferences?.[name]);
    },
    set(value) {
      saveUserPreferences({ [name]: type.serialize(value) });
    },
  });
}

function Preferences({ accountInSync, userInSync }) {
  const initialized = shallowRef(false);
  const unwatch = watch(
    [accountInSync, userInSync],
    () => {
      if (accountInSync.value && userInSync.value) {
        initialized.value = true;
        // prevent potential undefined value on first call
        queueMicrotask(() => {
          unwatch();
        });
      }
    },
    { immediate: true },
  );

  return {
    initialized,
    projectsPanelActiveTab: useUserPreference('recentProjectsPanelActiveTab', useStringType('recent')),
    shouldMinimizeSidebar: useUserPreference('shouldMinimizeSidebar', useBooleanType()),
    shouldShowRedesignedSidebar: useUserPreference('shouldShowRedesignedSidebar', useBooleanType()),
    postponeSwitchEveryoneModalUntil: useUserPreference('postponeSwitchEveryoneModalUntil', useStringType()),
    sidebarPinnedItems: useUserPreference('sidebarPinnedItems', useObjectType()),
    // Used to store the name of the panel that is currently open in the sidebar.
    sidebarPinnedPanel: useUserPreference('sidebarPinnedPanel', useObjectType({ name: null, isOpen: false })),
    hotspots: useUserPreference('hotspots', useObjectType()),
    dismissedBanners: useUserPreference('dismissedBanners', useObjectType()),
    sampleProjectIds: useUserPreference('sampleProjectIds', useArrayType([])),
    sampleUserIds: useUserPreference('sampleUserIds', useArrayType([])),
    sampleClientIds: useUserPreference('sampleClientIds', useArrayType([])),
    sampleProjectsVisible: useUserPreference('sampleProjectsVisible', useArrayType([])),
    areNotificationsUnmuted: useUserPreference('webBNotifsOn', useBooleanType(true)),
    useDesktopNotifications: useUserPreference('useDesktopNotifications', useBooleanType(true)),
    vueListViewActive: useUserPreference('vueListViewActive', useBooleanType()),
    firstTimeExperienceVisibility: useUserPreference(
      'firstTimeExperienceVisibility',
      useObjectType({
        allTime: true,
        myWorkInbox: true,
        myWorkTasks: true,
        myWorkProjects: true,
        projectsActive: true,
        projectsTemplates: true,
        everythingTasks: true,
        planningWorkload: true,
        reportsProfitability: true,
        reportsProjectHealth: true,
        reportsPlannedVsActual: true,
        reportsTaskTime: true,
        reportsUtilization: true,
        reportsUserTime: true,
        reportsUserTaskCompletion: true,
        reportsProjectsTime: true,
        reportsTime: true,
      }),
    ),
    experiment42CallbackRequestedDate: useUserPreference('experiment42CallbackRequestedDate', useStringType()),
    shouldExpandWorkloadUserRow: useUserPreference('shouldShowPersonalizedExperience', useBooleanType(true)),
    featureTrialsBannersVisibility: useUserPreference(
      'featureTrialsBannersVisibility',
      useObjectType({
        tasklistbudgets: true,
        retainerbudgets: true,
        timereport: true,
        projectbudgetexpenses: true,
      }),
    ),
    onboardingSelectedIntegrations: useUserPreference('onboardingSelectedIntegrations', useArrayType([])),
    welcomeChecklistCompletions: useUserPreference('welcomeChecklistCompletions', useArrayType([])),
    projectWizardModalBudgetInfoBannerDismissed: useUserPreference(
      'projectWizardModalBudgetInfoBannerDismissed',
      useBooleanType(false),
    ),
    retainerBudgetTemplateBannerDismissed: useUserPreference(
      'retainerBudgetTemplateBannerDismissed',
      useBooleanType(false),
    ),
    onboardingProjectId: useAccountPreference('onboardingProjectId', useStringType()),
    onboardingTemplateCreatedIds: useUserPreference('onboardingTemplateCreatedIds', useArrayType([])),
    onboardingTeamAddedOrRatedProjectIds: useUserPreference('onboardingTeamAddedOrRatedProjectIds', useArrayType([])),
    onboardingTasklistGeneration: useUserPreference(
      'onboardingTasklistGeneration',
      useObjectType({ countByProjectId: {}, completedByProjectId: {} }),
    ),
  };
}

export function providePreferences(params) {
  provide(usePreferencesSymbol, Preferences(params));
}

/**
 * @type {Preferences}
 */
export function usePreferences() {
  return inject(usePreferencesSymbol);
}
