import { STATUS, GETTERS } from './constants';

// Note all these getters are record-mapped safe, they check that
// `state` is defined.

export default {
  // Keep in mind while data is updating, the previous version can still be
  // displayed, therefore it is 'ready'
  [GETTERS.STATUS_READY]: (state) => !!state && (state.status === STATUS.loaded || state.status === STATUS.updating),
  [GETTERS.LIST]: (state) => (!!state && state.list) || [],
  [GETTERS.WORKING]: (state) => !!state && [STATUS.loading, STATUS.updating].includes(state.status),
  [GETTERS.UNINIT]: (state) => !state || state.status === STATUS.uninit,
  [GETTERS.UPDATING]: (state) => !!state && state.status === STATUS.updating,
};
