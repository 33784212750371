import { mapActions, mapMutations } from '../../record-mapper';

import mutations from './mutations';
import actions from './actions';

const baseUpdater = () => ({
  state: { lastUpdated: null },
  mutations,
  actions,
});

const recordUpdater = () => ({
  state: { records: {}, isUpdater: true },
  mutations: mapMutations(mutations),
  actions: mapActions(actions),
});

const decorateWithConfig =
  (createModule) =>
  (opts = {}) => {
    const module = createModule();
    return {
      namespaced: opts.namespaced,
      modules: opts.modules,
      state: {
        ...module.state,
        ...(opts.state || {}),
      },
      mutations: {
        ...module.mutations,
        ...(opts.mutations || {}),
      },
      actions: {
        ...module.actions,
        ...(opts.actions || {}),
      },
    };
  };

export const createUpdater = decorateWithConfig(baseUpdater);
export const createRecordUpdater = decorateWithConfig(recordUpdater);
export const detectUpdater = (module) =>
  module.state && (module.state.lastUpdated !== undefined || module.state.isUpdater !== undefined);
