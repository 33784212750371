import { createRecordListLoader, API } from '@/store/utils/loader';
import { mapGetters } from '@/store/utils/record-mapper';

export default createRecordListLoader({
  namespaced: true,
  config: {
    url: (id) => `${API.v2}/projects/${id}/people.json`,
    params: () => ({
      type: 'all',
    }),
    module: 'people',
    pagination: { loadAll: true },
    updater: true,
  },
  getters: {
    ...mapGetters({
      records: (state, getters, { people }) => (!state ? [] : state.list.map((pid) => people.records[pid])),
    }),
  },
});
