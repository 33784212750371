import Vue from 'vue';
import { createRecordLoader, ACTIONS } from '@/store/utils/loader';
import typedDefaults from '@/utils/helpers/typed-defaults';
import all from './modules/all';
import filtered from './modules/filtered';
import { defaultTypes } from './recordDefault';

export default {
  namespaced: true,
  modules: {
    loader: createRecordLoader({
      config: {
        url: (id) => `/projects/api/v3/companies/${id}.json`,
        params: () => ({ fullprofile: false, include: 'countries' }),
        includedConfig: { countries: 'country' },
      },
    }),
    all,
    filtered,
  },
  state: {
    records: {},
    currentCompanyId: null,
  },
  getters: {
    current: ({ records, currentCompanyId }) => currentCompanyId && records[currentCompanyId],
  },
  mutations: {
    current(state, id) {
      state.currentCompanyId = id;
    },
    records(state, companies) {
      const hash = {};
      companies.forEach((company) => {
        const defsApplied = typedDefaults(defaultTypes, company);
        // First time saving the record, add all the defaults too.
        hash[company.id] = {
          ...(state.records[company.id] || defaultTypes),
          ...defsApplied,
        };
      });
      state.records = { ...state.records, ...hash };
    },
    record(state, company) {
      const defsApplied = typedDefaults(defaultTypes, company);
      // First time saving the record, add all the defaults too.
      const newRec = {
        ...(state.records[company.id] || defaultTypes),
        ...defsApplied,
      };
      Vue.set(state.records, newRec.id, newRec);
    },
  },
  actions: {
    current({ commit, dispatch }, id) {
      const change = () => commit('current', id);
      return id ? dispatch(ACTIONS.ACCESS, id).then(change) : change();
    },
  },
};
