import { render, staticRenderFns } from "./TkoComponent.vue?vue&type=template&id=961b2f6c&"
import script from "./TkoComponent.js?vue&type=script&lang=js&"
export * from "./TkoComponent.js?vue&type=script&lang=js&"
import style0 from "./TkoComponent.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.2_babel-core@7.0.0-bridge.0_@babel+core@7.23.2_supports-color@9.4.0____6d54ea40cd7d7662c5066ad69d355cc3/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports