import { computed, effectScope, onScopeDispose, shallowRef, triggerRef, unref, watch } from 'vue-demi';
import deterministicJsonReplacer from '@/utils/other/deterministicJsonReplacer';
import useWorkflowStagesV3Loader from './useWorkflowStagesV3Loader';

const cache = new Map();

function acquire(input) {
  const workflowId = unref(input.workflowId);
  const params = JSON.parse(JSON.stringify(unref(input.params) || {}));
  const cacheKey = JSON.stringify({ workflowId, params }, deterministicJsonReplacer);
  let cacheItem = cache.get(cacheKey);
  if (cacheItem) {
    cacheItem.inputs.value.add(input);
    triggerRef(cacheItem.inputs);
  } else {
    const inputs = shallowRef(new Set([input]));
    const scope = effectScope(true);
    const loader = scope.run(() => {
      const inputsArray = computed(() => Array.from(inputs.value));
      const count = computed(() =>
        inputsArray.value.reduce((maxCount, { count: currentCount }) => Math.max(maxCount, unref(currentCount)), -1),
      );
      const pageSize = computed(() =>
        inputsArray.value.reduce(
          (maxPageSize, { pageSize: currentPageSize }) => Math.max(maxPageSize, unref(currentPageSize)),
          0,
        ),
      );
      return useWorkflowStagesV3Loader({ workflowId, params, count, pageSize });
    });
    cacheItem = {
      inputs,
      scope,
      loader,
    };
    cache.set(cacheKey, cacheItem);
  }
  return {
    loader: cacheItem.loader,
    release() {
      if (cacheItem.inputs.value.delete(input)) {
        triggerRef(cacheItem.inputs);
        if (cacheItem.inputs.value.size === 0) {
          cacheItem.scope.stop();
          cache.delete(cacheKey);
        }
      }
    },
  };
}

/**
 * A shared version of `useWorkflowStagesV3Loader`.
 */
export default function useSharedWorkflowStagesV3Loader({ workflowId, params, count = 0, pageSize = 50 }) {
  const input = { workflowId, params, count, pageSize };
  const loaderRef = shallowRef();
  let release;
  function init() {
    ({ loader: loaderRef.value, release } = acquire(input));
  }
  function update() {
    release();
    init();
  }
  init();
  watch(shallowRef(workflowId), update);
  watch(shallowRef(params), update, { deep: true });
  onScopeDispose(release);
  return {
    items: computed(() => {
      const items = loaderRef.value.items.value;
      // eslint-disable-next-line no-bitwise
      const maxLength = Math.max(0, unref(count) | 0);
      return items.length <= maxLength ? items : items.slice(0, maxLength);
    }),
    totalCount: computed(() => loaderRef.value.totalCount.value),
    inSync: computed(() => loaderRef.value.inSync.value),
    itemInSync: (item) => loaderRef.value.itemInSync(item),
    loading: computed(() => loaderRef.value.loading.value),
    meta: computed(() => loaderRef.value.meta.value),
    response: computed(() => loaderRef.value.response.value),
    error: computed(() => loaderRef.value.error.value),
  };
}
