import { MUTATIONS, ACTIONS, GETTERS } from '../constants';

// Shorthand for recordMap getters
const recordMapped = (getter, id) => (id ? getter(id) : getter);

export default {
  /**
   * Distinguishes a stale data load vs a full load - overrides the same action in the loader
   */
  [ACTIONS.BEFORE_LOAD]: ({ state, commit, getters, id }) => {
    const { showDeleted, deletedAfterDate, updatedAfterDate } = state || {};
    if (recordMapped(getters[GETTERS.UPDATING], id)) {
      commit(MUTATIONS.PARAMS, {
        showDeleted,
        deletedAfterDate,
        updatedAfterDate,
      });
    } else {
      commit(MUTATIONS.PARAMS, {
        showDeleted: undefined,
        deletedAfterDate: undefined,
        updatedAfterDate: undefined,
      });
    }
  },
};
