import { useAxios } from '../base/useAxios';

export default function useUserRateActions() {
  const api = useAxios();

  /**
   * @param {Object} opts
   * @param {number} opts.userRate
   * @param {number[]} opts.userIds
   */
  function updateInstallationUserRates({ userRate, userIds } = {}) {
    return api.put('/projects/api/v3/rates/installation/users/bulk/update.json', {
      userRate,
      all: false,
      ids: userIds,
    });
  }

  /**
   *
   * @param {Object} opts
   * @param {Object[]} opts.userRates - An array of objects in the form of `{ user : { id: 1, type: 'users'}, userRate: 7000 }`
   * @param {Number} opts.projectRate - The rate to update everyone else on the project to.
   * @param {Number} opts.projectId - The id of the project to update the user rates for.
   */
  function updateProjectUserRates({ userRates, projectId, projectRate = undefined } = {}) {
    return api.put(`/projects/api/v3/rates/projects/${projectId}/actions/update.json`, {
      ...(userRates.length && { userRates }),
      projectRate,
    });
  }

  return {
    updateInstallationUserRates,
    updateProjectUserRates,
  };
}
