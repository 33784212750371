import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';

function responseToItems(response) {
  return response.data.recents;
}

/**
 * Loads a list of a users recent activity from the Teamwork API.
 * This may include views on entities as well as
 * CRUD actions on entities, similar to activity log actions
 */
export default function useRecentsLoader({ params, count, pageSize = 50, useElastic = false }) {
  const url = v3Url(`${useElastic ? 'recency' : 'recency/summary'}`);
  const { state } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    pageSize,
  });

  return state;
}
