import moment from 'moment';
import { API, createListLoader } from '@/store/utils/loader';
import { peopleIncluded, peopleIncludedConfig } from './constants';

export default createListLoader({
  namespaced: true,
  state: { assignedUserIds: null },
  mutations: {
    assignedUserIds(state, payload) {
      state.assignedUserIds = payload;
    },
    // prevent warning
    // we don't need pagination, we just need to update state accordingly in the people module
    // using the existing store architecture
    'loader-pagination': () => {},
  },
  config: {
    url: `${API.v3}/schedule/people.json`,
    pagination: true,
    params: ({ schedule }) => ({
      include: peopleIncluded,
      startDate: moment(schedule.startDate).format('YYYY-MM-DD'),
      endDate: moment(schedule.endDate).format('YYYY-MM-DD'),
      onlyWithAllocations: false,
      assignedUserIds: schedule.people.person.assignedUserIds,
    }),
    includedConfig: peopleIncludedConfig,
    mapResponse: (ctx, rs) => rs.data.userSchedules.map((sch) => ({ id: sch.userId, ...sch })),
    module: 'schedule/people',
  },
});
