/**
 * A `replacer` function for `JSON.stringify`,
 * which guarantees that the serialized output is deterministic,
 * in particular it is not affected by the order of properties in objects.
 *
 * Replaces objects like:
 * { 'any-key-2': 'any-value-2', 'any-key-1': 'any-value-1' }
 * with:
 * [ 'O', 'any-key-1', 'any-value-1', 'any-key-2', 'any-value-2' ]
 * by flattening objects into arrays, sorted by key and prefixed by "O".
 *
 * Replaces arrays like:
 * [ 'item-2', 'item-1' ]
 * with:
 * [ 'A', 'item-2', 'item-1' ]
 * by prefixing arrays with "A".
 */
export default function deterministicJsonReplacer(_key, value) {
  /* eslint-disable no-plusplus */
  if (Array.isArray(value)) {
    const { length } = value;
    const array = new Array(length + 1);
    let o = 0;
    array[o++] = 'A';
    for (let i = 0; i < length; i++) {
      array[o++] = value[i];
    }
    return array;
  }

  if (typeof value === 'object' && value !== null) {
    const keys = Object.keys(value).sort();
    const { length } = keys;
    const array = new Array(length * 2 + 1);
    let o = 0;
    array[o++] = 'O';
    for (let i = 0; i < length; i += 1) {
      const k = keys[i];
      const v = value[k];
      const t = typeof v;
      // Discard properties with unsupported values for compatibility with `JSON.stringify`.
      // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify
      if (t !== 'undefined' && t !== 'function' && t !== 'symbol') {
        array[o++] = k;
        array[o++] = v;
      }
    }
    if (array.length !== o) {
      // Reduce the array length in case any properties were omitted.
      array.length = o;
    }
    return array;
  }

  return value;
  /* eslint-enable no-plusplus */
}
