const play = (path) => {
  try {
    new Audio(path).play();
  } catch (e) {
    // ignore
  }
};

export const reminder = () => play('//twa-prod.teamwork.com/tko/public/assets/mp3/reminder.mp3');
export const like = () => play('//twa-prod.teamwork.com/tko/public/assets/mp3/like.mp3');
