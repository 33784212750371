import { mapValues } from 'lodash-es';
import { GETTERS } from '../constants';

const mapPaginators = (getters) => mapValues(getters, (getter) => (state) => state && getter(state[state.paginator]));

export default mapPaginators({
  [GETTERS.PAGINATION.URL_PARAMS]: (state) => ({
    page: state.page,
    pageSize: state.pageSize,
  }),
  [GETTERS.PAGINATION.HAS_MORE]: (state) => state.page * state.pageSize < state.totalRecords,
  [GETTERS.PAGINATION.LOADED_PERC]: (state) => Math.floor(((state.page * state.pageSize) / state.totalRecords) * 100),
  [GETTERS.PAGINATION.PAGES]: (state) => Math.ceil(state.totalRecords / state.pageSize),
  [GETTERS.PAGINATION.TOTAL_RECORDS]: (state) => state.totalRecords,
});
