import task from './task';

export default (store, { subType, itemId }) => {
  if (subType === 'task') {
    task(store, {
      actionType: 'edited',
      extraInfo: {},
      itemId,
    });
  }
};
