import Vue from 'vue';

/**
 * The concept of mapping a module aspect is so the same code can work on a record-keyed module,
 * as on a normal module. A record keyed module will have most of its aspects keyed on the ID.
 */
export const mapGetter = (getter) => (state, getters, rootState, rootGetters) => (id) =>
  getter(state.records[id], getters, rootState, rootGetters, id);

/**
 * Records will not exist initially, and cannot be created from inside
 * a mapped mutation, so if the record is not found, it is created
 * prior to the mutation.
 */
export const mapMutation = (mutation) => (state, payload) => {
  const id = payload.id || payload;
  if (!state.records[id]) {
    Vue.set(state.records, id, {});
  }
  return mutation(state.records[id], payload.payload);
};

/**
 * Preferable to check if the payload is undefined, but the
 * normal practice to skip a parameter is to set it to null,
 * so best expect that.
 * Default behaviour here is to recordMap.
 */
const mapCall = (mod, id) => (name, payload, opts) =>
  !opts || opts.recordMap !== false
    ? mod(name, payload == null ? id : { id, payload }, opts)
    : mod(name, payload, opts);

export const mapAction =
  (action) =>
  ({ dispatch, getters, state, commit, rootState, rootGetters }, arg) => {
    const id = arg.id || arg;

    const ctx = {
      dispatch: mapCall(dispatch, id),
      commit: mapCall(commit, id),
      getters,
      state: state.records[id],
      rootState,
      rootGetters,
      id,
    };

    return action(ctx, arg.payload);
  };

const multiple = (mapper) => (records) => {
  const mapped = {};
  Object.keys(records).forEach((key) => {
    mapped[key] = mapper(records[key]);
  });
  return mapped;
};

export const mapGetters = multiple(mapGetter);
export const mapMutations = multiple(mapMutation);
export const mapActions = multiple(mapAction);
