export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    open(state, { htmlElement, userId, userName, avatarHref, isDeleted }) {},

    close() {},

    closeIfInactive() {},

    // eslint-disable-next-line no-unused-vars
    openEmbeddedChatConversation(personId) {},
  },
};
