import moment from 'moment';
import { mapGetters } from '@/store/utils/record-mapper';
import typedDefaults from '@/utils/helpers/typed-defaults';

const defaults = {
  id: 0,
  avatarUrl: '',
  firstName: '',
  lastName: '',
  lastChangedOn: moment('', 'YYYY-MM-DDTHH:mm:ssZ'),
  postedOn: moment('', 'YYYY-MM-DDTHH:mm:ssZ'),
  neverUpdated: false,
  reset: false,
  status: '',
  userId: 0,
};

export default {
  namespaced: true,
  state: {
    records: {},
  },
  getters: mapGetters({
    hasStatus: (state) => !state.neverUpdated && !state.reset && !!state.status,
    isMine: (state, getters, rootState) => state.userId === rootState.user.id,
  }),
  mutations: {
    updateOrAddStatuses: (state, statuses) => {
      const hash = {};
      statuses.forEach((status) => {
        const existing = state.records[status.userId];
        const withDefaults = typedDefaults(defaults, status);
        hash[status.userId] = existing ? { ...existing, withDefaults } : withDefaults;
      });
      state.records = { ...state.records, ...hash };
    },
  },
};
