/* eslint-disable no-underscore-dangle */
// Safety catch for KO serialisation of Moment objects
// https://digitalcrew.teamwork.com/#/tasks/12891441
import moment from 'moment';

const original = moment.updateOffset;
moment.updateOffset = function updateOffset(inst, ...args) {
  if (inst._z && inst._z.name && !inst._z.offsets) {
    // Re-read the TZ and Locale shared objects (only the identifier fields are in the snapshot)
    inst.tz(inst.tz());
    inst.locale(inst.locale());
  }
  return original.apply(this, [inst, ...args]);
};
