import Vue from 'vue';
import { createRecordListLoader, ACTIONS, MUTATIONS } from '@/store/utils/loader';
import { mapActions, mapGetters, mapMutations } from '@/store/utils/record-mapper';
import api from '@/services/api';
import annex from '@/services/vuex-annex';

export const watchers = [
  // Rather than a reactive data loader, we listen to a watcher and trigger an action
  // This gives greater flexibility to avoid another request when hiding completed
  {
    getter: (state, { filter, project }, getters) =>
      project.currentProjectId &&
      (filter.includeCompletedTasks || getters['filter/current/apiParameters'].includeCompletedTasks),
    callback: ({ dispatch, state }, includeCompletedTasks) => {
      if (state.project.currentProjectId) {
        dispatch(
          `project/tasklists/${includeCompletedTasks ? 'show' : 'hide'}Completed`,
          state.project.currentProjectId,
        );
      }
    },
  },
];

export default createRecordListLoader({
  namespaced: true,
  config: {
    id: 'project/tasklists',
    url: (id) => `/projects/${id}/tasklists.json`,
    params: () => ({
      showMilestones: true,
      getCompletedCount: true,
      getNewTaskDefaults: true,
      getDLMs: true,
    }),
    pagination: { loadAll: true },
    updater: true,
    module: 'tasklist',
    mapToIncluded: (ctx, rs) => ({
      milestones: rs.data.tasklists.map(({ milestone }) => milestone).filter(Boolean),
    }),
    includedConfig: { milestones: 'milestone' },
  },
  getters: {
    ...mapGetters({
      ordered: (state, getters, { tasklist }, rootGetters, id) =>
        Object.values(tasklist.records)
          .filter(({ projectId }) => projectId === id)
          .filter(({ status }) => status !== 'deleted')
          .sort((a, b) => a.position - b.position),
    }),
  },
  mutations: {
    ...mapMutations({
      showCompleted: (state) => {
        state.params.showCompleted = true;
      },
      hideCompleted: (state) => {
        state.params.showCompleted = false;
      },
    }),
  },
  actions: {
    resetAllShowCompleted({ commit, state }) {
      Object.keys(state.records).forEach((recId) => {
        const rec = state.records[recId];
        if (rec.params.showCompleted) {
          commit('hideCompleted', Number(recId));
        }
      });
    },
    ...mapActions({
      // Change loader params and reload
      showCompleted({ commit, dispatch, state }) {
        if (state && !state.params.showCompleted) {
          // Equivalent to RELOAD (but with showCompleted added in the middle)
          dispatch(ACTIONS.LOAD_CANCEL);
          commit(MUTATIONS.INIT);
          commit('showCompleted');
          dispatch(ACTIONS.LOAD_WAIT);
        }
      },
      // Reduce loader scope and flag as stale
      // - the app only reverts showCompleted if leaving the area where it was applied
      hideCompleted({ commit, state }) {
        if (state && state.params.showCompleted) {
          commit('hideCompleted');
          commit(MUTATIONS.STALE);
        }
      },

      // Sort the tasklists within a project
      sort({ dispatch, getters, id, state }, sortBy) {
        dispatch('notifications/flashes/info', Vue.t('Sorting') + getters['user/ellipsis']);
        const working = api
          .put(`projects/${id}/tasklists/sort.json`, {
            sortBy,
            sortOrder: 'ASC',
          })
          .then(() => {
            dispatch(ACTIONS.DATA_CHANGE);
            dispatch('notifications/flashes/success', {
              title: Vue.t('Sorted'),
              opts: { timer: 500 },
            });
          });
        return annex.promises(state, { working });
      },
    }),
  },
});
