import { createListLoader } from '@/store/utils/loader';

export default createListLoader({
  namespaced: true,
  config: {
    url: '/boards.json',
    params: () => ({ getColumns: true }),
    module: 'board',
    pagination: { pageSize: 50 },
  },
});
