import Vue from 'vue';
import typedDefaults from '@/utils/helpers/typed-defaults';
import entries from './entries';

const defaultTypes = {
  id: 0,
  name: '',
  description: '',
  entries: [],
  entryIds: [],
  object: null,
  objectId: 0,
};

export default {
  namespaced: true,
  modules: {
    entries,
  },
  state: {
    records: {},
  },
  mutations: {
    records(state, workingHours) {
      const hash = {};
      workingHours.forEach((wh) => {
        const defsApplied = typedDefaults(defaultTypes, wh);
        hash[wh.id] = {
          ...(state.records[wh.id] || defaultTypes),
          ...defsApplied,
        };
      });
      state.records = { ...state.records, ...hash };
    },
    record(state, workingHour) {
      const defsApplied = typedDefaults(defaultTypes, workingHour);
      const newRec = {
        ...(state.records[workingHour.id] || defaultTypes),
        ...defsApplied,
      };
      Vue.set(state.records, newRec.id, newRec);
    },
  },
};
