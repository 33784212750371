export default [
  /**
   * Any flashes in Vue, should trigger TKO
   */
  (store) =>
    store.watch(
      (state) => state.notifications.flashes.flashes,
      (flashes) => {
        flashes.slice(0).forEach((flash) => {
          const { type, title, msg, opts } = flash;
          window.app.flash.Add(type, title, msg, opts);
          store.dispatch('notifications/flashes/close', flash);
          // TODO need to write code to handle undo in future
        });
      },
    ),
];
