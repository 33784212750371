export default [
  {
    path: '/projects/list',
    props: (route) => ({
      categoryId: route.query.catid ? Number(route.query.catid) : null,
    }),
    component: () => import('./RouteProjectsList'),
    meta: {
      sidebar: 'portal',
    },
    children: [
      {
        path: '*',
        redirect: '.',
      },
    ],
  },
];
