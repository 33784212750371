import { v3Url } from '@/utils/fetcher';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

const { hasOwnProperty } = Object.prototype;
/**
 * Generates temporary IDs for new budgets for the purpose of optimistic updates.
 */
const newProjectBudgetId = (() => {
  let id = 0;
  return () => {
    id -= 1;
    return id;
  };
})();

/**
 * Returns a transformed error for budget conflicts
 * Codes:
 * - `PBC1` Date overlaps with existing budget, user must fix themselves
 * - `PBC2` Existing open-ended budget started before this new budget startDate
 * - `PBC3` Budget dates overlap. Request the user selects an end date for conflicting budget.
 * - `PBC4` A budget with the same start date already exists
 * @param {Error} _error
 * @returns {Error} error
 * @returns {string} error.code
 * @returns {object} error.conflictingBudget
 * @returns {string} error.name
 */
function handleProjectBudgetConflictError(_error) {
  const error = _error?.response?.data?.errors?.[0];
  if (!error || !error.code.startsWith('PBC')) {
    return _error;
  }

  const {
    code,
    meta: { budget: conflictingBudget },
  } = error;

  const customError = new Error('Failed to upsert budget');
  customError.name = 'ProjectBudgetConflictError';
  customError.code = code;
  customError.conflictingBudget = conflictingBudget;
  return customError;
}

function handleProjectBudgetLimitError(error) {
  const errorData = error?.response?.data?.errors?.[0];
  if (errorData?.code !== 'PBL') {
    return error;
  }

  const { code, detail } = errorData;
  const customError = new Error('');
  customError.name = 'ProjectBudgetLimitError';
  customError.code = code;
  customError.detail = detail;
  return customError;
}

function handleProjectRetainerDateLimit(error) {
  const errorData = error?.response?.data?.errors?.[0];
  if (errorData?.code !== 'PBC5') {
    return error;
  }

  const { code, detail } = errorData;
  const customError = new Error('');
  customError.name = 'ProjectRetainerDateLimitError';
  customError.code = code;
  customError.detail = detail;
  return customError;
}

function createBudgetPayload(budget) {
  const budgetPayload = {};

  if (hasOwnProperty.call(budget, 'budgetCategory')) {
    let budgetCategory = budget.budgetCategory;
    // The legacy retainer category is now standard
    if (budgetCategory === 'retainer') {
      budgetCategory = 'standard';
    }
    budgetPayload.budgetCategory = budgetCategory.toUpperCase();
  }

  if (hasOwnProperty.call(budget, 'id')) {
    budgetPayload.id = Number(budget.id);
  }

  if (hasOwnProperty.call(budget, 'capacity')) {
    budgetPayload.capacity = Number(budget.capacity);
  }

  if (hasOwnProperty.call(budget, 'completedAt')) {
    budgetPayload.completedAt = budget.completedAt;
  }

  if (hasOwnProperty.call(budget, 'endDateTime')) {
    budgetPayload.endDateTime = budget.endDateTime;
  }

  if (hasOwnProperty.call(budget, 'isRepeating')) {
    budgetPayload.isRepeating = Boolean(budget.isRepeating);
  }

  if (hasOwnProperty.call(budget, 'notifications')) {
    budgetPayload.notifications = budget.notifications.map((notification) => ({
      notificationMedium: notification.notificationMedium.toUpperCase(),
      capacityThreshold: notification.capacityThreshold,
      userIds: notification.users?.map((user) => Number(user.id)) ?? [],
      teamIds: notification.teams?.map((team) => Number(team.id)) ?? [],
      companyIds: notification.companies?.map((company) => Number(company.id)) ?? [],
      id: notification.id,
    }));
  }

  if (hasOwnProperty.call(budget, 'projectId')) {
    budgetPayload.projectId = Number(budget.projectId);
  }

  if (budgetPayload.isRepeating && hasOwnProperty.call(budget, 'repeatPeriod') && Number(budget.repeatPeriod) > 0) {
    budgetPayload.repeatPeriod = Number(budget.repeatPeriod);
  }

  if (budgetPayload.isRepeating && hasOwnProperty.call(budget, 'repeatUnit') && Boolean(budget.repeatUnit)) {
    budgetPayload.repeatUnit = budget.repeatUnit.toUpperCase();
  }

  if (hasOwnProperty.call(budget, 'startDateTime')) {
    budgetPayload.startDateTime = budget.startDateTime;
  }

  if (hasOwnProperty.call(budget, 'status')) {
    budgetPayload.status = budget.status.toUpperCase();
  }

  if (hasOwnProperty.call(budget, 'type')) {
    budgetPayload.type = budget.type.toUpperCase();
  }

  if (hasOwnProperty.call(budget, 'timelogType')) {
    budgetPayload.timelogType = budget.timelogType.toUpperCase();
  }

  if (hasOwnProperty.call(budget, 'isRetainer')) {
    budgetPayload.isRetainer = budget.isRetainer;
  }

  if (hasOwnProperty.call(budget, 'projectRate')) {
    budgetPayload.projectRate = budget.projectRate;
  }

  if (budgetPayload.isRetainer && hasOwnProperty.call(budget, 'carryOverspend')) {
    budgetPayload.carryOverspend = budget.carryOverspend;
  }

  if (budgetPayload.isRetainer && hasOwnProperty.call(budget, 'carryUnderspend')) {
    budgetPayload.carryUnderspend = budget.carryUnderspend;
  }

  if (budgetPayload.budgetCategory === 'FIXEDFEE' && hasOwnProperty.call(budget, 'budgetProfitMargin')) {
    budgetPayload.budgetProfitMargin = budget.budgetProfitMargin;
  }

  return budgetPayload;
}

export default function useProjectBudgetActions() {
  const api = useAxios();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: _emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  const config = () => ({
    headers: {
      'Socket-ID': socketId.value,
      'Triggered-By': 'user',
      'Sent-By': 'composable',
    },
  });

  /**
   *
   * @param {Promise} promise
   * @param {('create'|'update'|'delete')} action
   * @param {object} budget
   */
  function emitOptimisticUpdate(promise, action, budget) {
    _emitOptimisticUpdate({
      promise,
      type: 'budget',
      action,
      budget,
    });
  }

  /**
   *
   * @param {'new'|'edited'|'deleted'} action
   * @param {object} budget
   * @param {object} oldBudget
   */
  function emitRealTimeUpdate(action, budget, oldBudget) {
    let originatorBudgetId = null;
    if (budget.isRepeating) {
      originatorBudgetId = budget.originatorBudgetId ?? budget.id;
    }

    _emitRealTimeUpdate({
      type: 'budget',
      action,
      budgetId: budget.id ?? oldBudget.id,
      projectId: budget.projectId ?? oldBudget.projectId,
      isRetainer: budget.isRetainer,
      originatorBudgetId,
    });
  }

  /**
   * Creates a new budget.
   * @param {object} budget
   */
  function createProjectBudget(budget) {
    const promise = api
      .post(v3Url(`projects/budgets`), { budget: createBudgetPayload(budget) }, config())
      .then(({ data: { budget: createdBudget } }) => {
        emitRealTimeUpdate('new', createdBudget);
        return createdBudget;
      })
      .catch((error) => {
        if (error.response.status === 409) {
          throw handleProjectBudgetConflictError(error);
        } else if (error.response.status === 403) {
          throw handleProjectBudgetLimitError(error);
        } else if (error.response.status === 400) {
          throw handleProjectRetainerDateLimit(error);
        }
        throw error;
      });
    emitOptimisticUpdate(promise, 'create', {
      ...budget,
      id: newProjectBudgetId(),
      capacityUsed: 0,
    });
    return promise;
  }

  /**
   * Updates an existing project budget.
   * @param {object} budget
   */
  function updateProjectBudget(budget, oldBudget) {
    const promise = api
      .patch(v3Url(`projects/budgets/${budget.id}`), { budget: createBudgetPayload(budget) }, config())
      .then(({ data: { budget: updatedBudget } }) => {
        emitRealTimeUpdate('edited', updatedBudget, oldBudget);
        return updatedBudget;
      })
      .catch((error) => {
        if (error.response.status === 409) {
          throw handleProjectBudgetConflictError(error);
        }
        throw error;
      });
    emitOptimisticUpdate(promise, 'update', budget);
    return promise;
  }

  /**
   * Deletes an existing budget.
   * @param {object} budget
   */
  function deleteProjectBudget(budget) {
    const promise = api.delete(v3Url(`projects/budgets/${budget.id}`), null, config()).then(() => {
      emitRealTimeUpdate('deleted', budget);
    });
    emitOptimisticUpdate(promise, 'delete', budget);
    return promise;
  }

  return {
    createProjectBudget,
    updateProjectBudget,
    deleteProjectBudget,
  };
}
