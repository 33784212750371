import moment from 'moment';
import { API } from '@/store/utils/loader';
import { createFilteredListLoader } from '@/store/utils/loader/filtered';
import { createPagination } from '@/store/utils/loader/pagination/v3';
import person from './person';
import { peopleIncluded, peopleIncludedConfig } from './constants';

export default {
  namespaced: true,

  state: {
    records: {},
    searchTerm: '',
  },

  mutations: {
    records(state, schedules) {
      const hash = {};
      schedules.forEach((schedule) => {
        hash[schedule.userId] = { ...schedule };
      });
      state.records = { ...state.records, ...hash };
    },

    searchTerm(state, searchTerm) {
      state.searchTerm = searchTerm;
    },
  },

  actions: {
    loadPersonSchedule({ commit }, id) {
      commit('person/assignedUserIds', id);
    },
  },

  modules: {
    person,
    loader: createFilteredListLoader({
      modules: {
        pagination: createPagination({
          config: { pageSize: 30 },
        }),
      },
      config: {
        sort: false,
        url: `${API.v3}/schedule/people.json`,
        params: ({ schedule }) => ({
          include: peopleIncluded,
          startDate: moment(schedule.startDate).format('YYYY-MM-DD'),
          endDate: moment(schedule.endDate).format('YYYY-MM-DD'),
          orderBy: 'assigneduser',
          onlyWithAllocations: false,
        }),
        // Filter Params contains all the params that apply to the filtered view only,
        // the params above will affect the default and filtered loaders.
        filterParams: ({ schedule }, getters) => {
          const { searchTerm = schedule.people.searchTerm, ...apiParams } = getters['filter/current/apiParameters'];

          return {
            searchTerm,
            ...apiParams,
          };
        },
        // Let's the loader know if we should be working with the filtered data or the full set
        isFiltered: (state, getters) => getters['filter/current/active'] || !!state.schedule.people.searchTerm,
        includedConfig: peopleIncludedConfig,
        // Need to map `userId` to `id`, as all records expected to have an ID field
        mapResponse: (ctx, rs) => rs.data.userSchedules.map((sch) => ({ id: sch.userId, ...sch })),
        module: 'schedule/people',
      },
    }),
  },
};
