import { shallowRef, computed } from 'vue-demi';
import useListLoader from '../base/useListLoader';

function responseToItems(response) {
  const { companies = {}, teams = {} } = response.data.included;
  const usersList = response.data.people || [];

  /* eslint-disable no-param-reassign */
  usersList.forEach((user) => {
    if (user.company && companies[user.company.id]) {
      user.company = companies[user.company.id];
    }

    user.teams?.forEach((team, index) => {
      if (teams[team.id]) {
        user.teams[index] = teams[team.id];
      }
    });

    /* eslint-enable no-param-reassign */
  });
  return usersList;
}

function responseToMeta({ data: { meta } }) {
  return { totalCount: meta.page?.count };
}

/**
 * Loads a list of people from the Teamwork v3 API.
 * Can fetch people generally or if a project id provided can target a project
 */
export default function usePeopleV3Loader({
  params,
  /*
   * optionally specify a projectId
   */
  projectId: _projectId,
  count,
  pageSize = 20,
}) {
  const projectId = shallowRef(_projectId);
  const url = computed(() =>
    projectId.value ? `/projects/api/v3/projects/${projectId.value}/people.json` : '/projects/api/v3/people.json',
  );

  const { state } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    responseToMeta,
    pageSize,
  });
  return state;
}
