/* eslint-disable no-param-reassign */

const imgMap = {
  person: '//cdn.teamwork.com/images/noPhoto2.png',
  userAvatar: '//cdn.teamwork.com/images/noPhoto2.png',
  companyLogo: '//cdn.teamwork.com/images/no_clogo2.png',
  projectLogo: '//cdn.teamwork.com/images/noProjectLogo.png',
  favicon: '//cdn.teamwork.com/favicon.ico',
  default: '//cdn.teamwork.com/images/newui/file-icons/large/raster.png',
};

export default {
  bind(el, { value }) {
    if (!(el instanceof HTMLImageElement)) {
      // eslint-disable-next-line no-console
      console.error('v-img-load-fail directive must be on an img element!');
      return;
    }
    el.addEventListener('error', () => {
      // Store away the current src into realSrc attribute
      if (!el.hasAttribute('realSrc')) {
        el.setAttribute('realSrc', el.src);
        el.classList.add('notFound');
      }
      el.src = imgMap[value] || imgMap.default;
      if (el.closest) {
        const thumb = el.closest('.fileThumb');
        if (thumb) {
          thumb.classList.add('failed');
        }
      }
    });
  },
};
