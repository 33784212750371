/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { STATUS, MUTATIONS } from './constants';
import loadingState from './state';

// Mutations part of the base of the loader mechanism
export const base = {
  [MUTATIONS.INIT](state) {
    Vue.assign(state, loadingState());
  },
  [MUTATIONS.PARAMS](state, params) {
    Vue.assign(state.params, params);
  },
  [MUTATIONS.LOADING](state) {
    state.status = STATUS.loading;
  },
  [MUTATIONS.UPDATING](state) {
    state.status = STATUS.updating;
  },
  [MUTATIONS.LOADED](state) {
    state.status = STATUS.loaded;
  },
  [MUTATIONS.LOADED_ERROR](state, err) {
    state.status = STATUS.error;
    if (err.response) {
      state.error.status = err.response.status;
    }
    state.error.text = err.message;
  },
  [MUTATIONS.STALE](state) {
    state.status = STATUS.stale;
  },
  [MUTATIONS.ADDITIVE](state) {
    state.additive = true;
  },
  [MUTATIONS.HEADERS]() {},
  [MUTATIONS.LIST](state, list) {
    state.list = list;
  },
};

export const optional = {
  record: (state, ent, id) => {
    id = id || ent.id;
    const curr = state.records[id];
    if (curr) {
      Vue.assign(state.records[id], ent);
    } else {
      Vue.set(state.records, id, ent);
    }
  },
  records: (state, ents) => {
    const hash = {};
    ents.forEach((rec) => {
      const existing = state.records[rec.id];
      hash[rec.id] = existing ? { ...existing, ...rec } : rec;
    });
    state.records = { ...state.records, ...hash };
  },
};
