import { darken } from '@/utils/helpers/color';

export default function buildThemeCSS(color) {
  const rawColor = color.replace('#', '');
  const darkTint = darken(rawColor, 0.75);

  return `
      .w-data-grid th, .w-time-grid th, table.timeGrid th, table.dataGrid th{
        background-color: ${darkTint} !important;
      }
    `;
}
