export default () => ({
  list: [],
  records: {},
  status: 0,
  additive: false,
  error: {
    status: null,
    text: null,
  },
  // Warning - changing these params will not trigger a load
  params: {},
});
