import { inject, provide, computed } from 'vue-demi';

import { usePreferences } from '@/platform/composables/usePreferences';

const appShellSymbol = Symbol('useAppShell');

export function provideAppShell() {
  const { initialized } = usePreferences();

  provide(appShellSymbol, {
    initialized,
    shouldUseAppShell: computed(() => {
      return true;
    }),
  });
}

export function useAppShell() {
  return inject(appShellSymbol);
}
