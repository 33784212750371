import { v1 as fetcher, v3 as v3Fetcher } from '@/utils/fetcher';
import mapById from '@/utils/helpers/mapById';

const defaultParams = {
  showMilestones: true,
  getCompletedCount: true,
  getNewTaskDefaults: true,
  getDLMs: true,
};

const normalize = ({ data: { tasklists: taskLists } }) => {
  const taskListsById = mapById(taskLists);
  return { taskLists, taskListsById };
};

const fetchAllByProject = async (id, params, pagination) =>
  fetcher(`projects/${id}/tasklists`, { ...defaultParams, ...params, ...pagination }, normalize);

const fetchAllTasklists = async (params, pagination) =>
  v3Fetcher('tasklists', { ...defaultParams, ...params, ...pagination }, ({ data }) => data);

const fetchSingle = async (id, params, pagination) =>
  fetcher(`tasklists/${id}`, { ...defaultParams, ...params, ...pagination }, normalize);

export default {
  fetchAllTasklists,
  fetchAllByProject,
  fetchSingle,
};
