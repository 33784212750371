import { v1Url } from '@/utils/fetcher';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

export default function usePortfolioActions() {
  const api = useAxios();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId.value,
        'Triggered-By': 'user',
        'Sent-By': 'composable',
      },
    };
  }

  return {
    movePortfolioCard(portfolioCard) {
      const { column, project } = portfolioCard;
      const promise = api
        .put(v1Url(`portfolio/cards/${portfolioCard.id}/move`), { columnId: column.id }, config())
        .then(() =>
          emitRealTimeUpdate({
            type: 'project',
            action: 'updated',
            projectId: project.id,
            categoryChanged: false,
          }),
        );
      emitOptimisticUpdate({
        promise,
        type: 'project',
        action: 'update',
        project: {
          id: project.id,
          portfolioCards: project.portfolioCards.map((card) => (card.id === portfolioCard.id ? portfolioCard : card)),
        },
      });
      return promise;
    },

    deletePortfolioCard(portfolioCard) {
      const { project } = portfolioCard;
      const promise = api.delete(v1Url(`portfolio/cards/${portfolioCard.id}`), config()).then(() =>
        emitRealTimeUpdate({
          type: 'project',
          action: 'updated',
          projectId: project.id,
          categoryChanged: false,
        }),
      );
      emitOptimisticUpdate({
        promise,
        type: 'project',
        action: 'update',
        project: {
          id: project.id,
          portfolioCards: project.portfolioCards.filter((card) => card.id !== portfolioCard.id),
        },
      });
      return promise;
    },
  };
}
