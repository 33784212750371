import Vue from 'vue';
import { createRecordLoader } from '@/store/utils/loader';
import typedDefaults from '@/utils/helpers/typed-defaults';
import api from '@/services/api';

const recordDefault = {
  id: 0,
  title: '',
  startDate: '',
  endDate: '',
  typeId: null,
  privacyType: '',
  allDay: false,
  location: '',
  description: '',
  showAsBusy: false,
  attendeesCanEdit: false,
  projectUsersCanEdit: false,
  monthlyRepeatType: '',
  dateLastUpdated: '',
  createdDateTime: '',
  deleted: false,
  dateDeleted: null,
  additionalProperties: null,
  currentUserAssociationType: null,
  sequenceId: null,
  projectId: null,
  ownerUserId: 0,
  deletedByUserId: null,
  createdByUserId: 0,
  updatedByUserId: 0,
  attendingUserIds: [],
};

export default {
  namespaced: true,

  state: {
    records: {},
  },

  modules: {
    loader: createRecordLoader({
      config: {
        url: (id) => `/calendarevents/${id}.json`,
      },
    }),
  },

  mutations: {
    record(state, event) {
      const defsApplied = typedDefaults(recordDefault, event);
      const newRec = { ...state.records[event.id], ...defsApplied };
      Vue.set(state.records, newRec.id, newRec);
    },

    records(state, list) {
      const hash = {};
      // if list is an object we need to convert to array
      if (typeof list === 'object' && list !== null) {
        // eslint-disable-next-line no-param-reassign
        list = Object.values(list);
      }
      list.forEach((rec) => {
        const existing = state.records[rec.id];
        const event = typedDefaults(recordDefault, rec);
        hash[event.id] = existing ? { ...existing, ...event } : event;
      });
      state.records = { ...state.records, ...hash };
    },

    delete(state, id) {
      Vue.delete(state.records, id);
    },
  },

  actions: {
    add({ dispatch }, itemData) {
      const payload = {
        event: {
          ...itemData,
          'attendees-can-edit': true,
        },
      };
      const url = '/calendarevents.json';

      return api
        .post(url, payload)
        .then(() => {
          dispatch('schedule/people/loadPersonSchedule', payload.event['attending-user-ids'], { root: true });
          dispatch('notifications/flashes/success', Vue.t('Unavailable Time Added'), { root: true });
          dispatch('modals/close', null, { root: true, recordMap: false });
        })
        .catch(() => {
          dispatch('notifications/flashes/error', Vue.t('Failed to add unavailable time.'), {
            root: true,
            recordMap: false,
          });
        });
    },

    update({ state, dispatch }, itemData) {
      const payload = {
        event: {
          ...itemData,
          'attendees-can-edit': true,
        },
      };
      const url = `/calendarevents/${itemData.id}.json`;
      const originalEvent = state.records[itemData.id];
      const peopleToUpdate = [
        ...new Set([payload.event['attending-user-ids'], ...originalEvent.attendingUserIds]),
      ].join(',');

      return api
        .put(url, payload)
        .then(() => {
          dispatch('schedule/people/loadPersonSchedule', peopleToUpdate, {
            root: true,
          });
          dispatch('notifications/flashes/success', Vue.t('Unavailable Time Updated'), { root: true });
          dispatch('modals/close', null, { root: true, recordMap: false });
        })
        .catch(() => {
          dispatch('notifications/flashes/error', Vue.t('Failed to update unavailable time.'), {
            root: true,
            recordMap: false,
          });
        });
    },

    async delete({ state, dispatch, commit }, id) {
      const event = state.records[id];

      await api.delete(`/calendarevents/${id}.json`);

      if (event) {
        dispatch('schedule/people/loadPersonSchedule', event.attendingUserIds || event['attending-user-ids'], {
          root: true,
          mapRecord: false,
        });
      }

      commit('delete', id);
    },
  },
};
