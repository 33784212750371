/**
 * Guard against Notification RTUs originating from current session triggering duplicate or redundant ui mutations
 */
export default function useRedundantRealtimeUpdateGuard() {
  let guard;

  function setRedundantRealtimeUpdateGuard({ notificationId, wasRead }) {
    guard = {
      notificationId,
      wasRead,
    };
  }

  function isRedundantRealTimeUpdate({ notificationId, wasRead }) {
    return Boolean(guard) && notificationId === guard.notificationId && wasRead === guard.wasRead;
  }

  function unsetRedundantRealtimeUpdateGuard() {
    guard = undefined;
  }

  return {
    setRedundantRealtimeUpdateGuard,
    isRedundantRealTimeUpdate,
    unsetRedundantRealtimeUpdateGuard,
  };
}
