/* eslint-disable import/no-import-module-exports */
// custom knockout loader for lazy loading TKO component chunks through webpack.
import ko from 'knockout';
import getComponentChunk from '@tko/config/component-chunk-map';

async function importComponentFromChunk(name) {
  const componentChunk = await getComponentChunk(name);
  if (!componentChunk) {
    return null;
  }
  const component = await componentChunk[name];
  return component;
}

export default () => {
  async function handleTkoHMR(event) {
    if (event.origin !== window.location.origin) {
      return;
    }
    if (event.data.tkoHMR) {
      ko.components.unregister(event.data.tkoHMR);
      ko.components.clearCachedDefinition(event.data.tkoHMR);
      // Load updated hot module
      const component = await importComponentFromChunk(event.data.tkoHMR);
      // Register and bind new ko component
      ko.components.register(event.data.tkoHMR, component);
      // LetkoComponent know to reload
      window.postMessage({ tkoHMRRefresh: true });
    }
  }

  if (module.hot) {
    // Stop TKO components from bubbling up HMR
    module.hot.accept(['@tko/config/component-chunk-map'], () => false);
    window.addEventListener('message', handleTkoHMR, false);
  }

  ko.components.loaders.unshift({
    getConfig: (name, callback) => {
      // this loads the chunk name from component-chunk-map.js
      // then the chunk file from ./chunks/{name}.js
      // and finally returns the component via callback
      importComponentFromChunk(name).then((component) => {
        // check if the component doesn't belong to the chunk
        if (!component || !ko.components.isRegistered(name)) {
          return;
        }

        // @TODO: Drop support for fragments
        const requireObject = {
          viewModel: component.default ? component.default.viewModel : component.viewModel,
          template: component.default ? component.default : component.template || component,
        };

        callback(requireObject);
      });
    },
    loadComponent(name, config, callback) {
      ko.components.defaultLoader.loadComponent(name, config, (definition) => {
        callback(definition);
      });
    },
  });
};
