import { createRecordListLoader, API } from '@/store/utils/loader';
import { mapGetters } from '@/store/utils/record-mapper';

export default createRecordListLoader({
  namespaced: true,
  modules: {},
  config: {
    url: (id) => `${API.v2}/projects.json?companyId=${id}`,
    params: () => ({
      orderby: 'lastActivityDate',
      orderMode: 'desc',
      dataset: 'basic',
    }),
    module: 'project',
    pagination: { pageSize: 10 },
    updater: true,
  },
  getters: {
    ...mapGetters({
      records: (state, getters, { project }) => (!state ? [] : state.list.map((pid) => project.records[pid])),
    }),
  },
});
