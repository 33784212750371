import lscache from 'lscache';

export default [
  {
    path: '/projects/templates/projects',
    component: () => import('./RouteProjectsTemplatesProjects'),
    children: [
      {
        path: '',
        redirect() {
          return lscache.get('twProjects-last-template-section') === 'custom' ? 'custom' : 'teamwork';
        },
      },
      {
        path: 'custom',
        beforeEnter(to, from, next) {
          lscache.set('twProjects-last-template-section', 'custom');
          next();
        },
        component: () => import('./RouteProjectsTemplatesProjectsCustom'),
        meta: { sidebar: 'project-templates-sidebar', noSpacing: true },
      },
      {
        path: 'teamwork',
        beforeEnter(to, from, next) {
          lscache.set('twProjects-last-template-section', 'teamwork');
          next();
        },
        component: () => import('./RouteProjectsTemplatesProjectsTeamwork'),
        meta: { sidebar: 'project-templates-sidebar' },
      },
      {
        path: 'upgrade',
        component: () => import('./RouteProjectsTemplatesProjectsUpgrade'),
      },
      {
        path: '*',
        redirect: '.',
      },
    ],
  },
  {
    path: '/projects/templates(.*)',
    redirect: '/projects/templates/projects',
  },
];
