import { shallowRef, inject, provide, readonly } from 'vue-demi';

const morePopoverMenuStateSymbol = Symbol('useMorePopoverMenuState');

export function provideMorePopoverMenuState() {
  const isOpen = shallowRef(false);
  const setIsOpen = (val) => {
    isOpen.value = val;
  };

  provide(morePopoverMenuStateSymbol, {
    isOpen: readonly(isOpen),
    setIsOpen,
  });
}

export function useMorePopoverMenuState() {
  return inject(morePopoverMenuStateSymbol);
}
