import { v1Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';

function responseToItems({ data: { tags } }) {
  for (let i = 0; i < tags.length; i += 1) {
    const tag = tags[i];
    tag.id = Number(tag.id);
    tag.projectId = Number(tag.projectId);
  }

  return tags;
}

/**
 * Loads a list of tags.
 */
export default function useTagsLoader({ params, pageSize, count }) {
  const { state } = useListLoader({
    url: v1Url('tags'),
    params,
    pageSize,
    count,
    responseToItems,
  });

  return state;
}
