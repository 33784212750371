import { v3Url } from '@/utils/fetcher';
import useItemLoader from '../base/useItemLoader';

function responseToItem(response, id) {
  const projectRate = response.data.projectRate;
  return { id, projectRate };
}

/**
 * Loads a list of user rates from the Teamwork v3 API.
 */
export default function useProjectRateLoader({
  /**
   * The id of project to loader the project rate from.
   */
  projectId,
  params,
}) {
  const { state } = useItemLoader({
    url: v3Url(`rates/projects/${projectId}`),
    params,
    responseToItem: (response) => responseToItem(response, projectId),
  });
  return state;
}
