import { v1Url } from '@/utils/fetcher';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

const newMilestoneId = (() => {
  let id = 0;
  return () => {
    id -= 1;
    return id;
  };
})();

export default function useMilestoneActions() {
  const api = useAxios();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: _emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  function emitOptimisticUpdate(promise, action, milestone) {
    _emitOptimisticUpdate({ promise, type: 'milestone', action, milestone });
  }

  function emitRealTimeUpdate(action, newMilestone, oldMilestone) {
    const milestoneId = newMilestone.id || oldMilestone.id;
    _emitRealTimeUpdate({
      type: 'milestone',
      action,
      milestoneId,
      projectId: newMilestone.projectId,
    });
  }

  function config() {
    return {
      headers: {
        'Socket-ID': socketId.value,
        'Triggered-By': 'user',
        'Sent-By': 'composable',
      },
    };
  }

  return {
    createMilestone(newMilestone) {
      const promise = api
        .post(v1Url(`projects/${newMilestone.milestoneListId}/milestones`), config())
        .then(({ data: { id } }) => emitRealTimeUpdate('new', { ...newMilestone, id: Number(id) }, {}));
      emitOptimisticUpdate(promise, 'create', {
        ...newMilestone,
        id: newMilestoneId(),
      });
      return promise;
    },

    updateMilestone(updatedMilestone, milestone, options) {
      const promise = api
        .put(
          v1Url(`milestones/${updatedMilestone.id}`),
          {
            milestone: updatedMilestone,
            ...options,
          },
          config(),
        )
        .then(
          ({
            data: {
              'affected-milestones': affectedMilestones,
              'affects-other-milestones': affectsOtherMilestones,
              isOffset,
            },
          }) => {
            emitRealTimeUpdate('edited', updatedMilestone, milestone || {});
            return {
              affectedMilestones,
              affectsOtherMilestones,
              isOffset,
            };
          },
        );
      emitOptimisticUpdate(promise, 'update', updatedMilestone);
      return promise;
    },

    deleteMilestone(milestone) {
      const deletedMilestone = { id: milestone.id };
      const promise = api
        .delete(v1Url(`milestones/${deletedMilestone.id}`), config())
        .then(() => emitRealTimeUpdate('deleted', deletedMilestone, milestone));
      emitOptimisticUpdate(promise, 'delete', deletedMilestone);
      return promise;
    },

    completeMilestone(milestone) {
      const updatedMilestone = {
        id: milestone.id,
        progress: 100,
        status: 'completed',
      };
      const promise = api
        .put(v1Url(`milestones/${updatedMilestone.id}/complete`), null, config())
        .then(() => emitRealTimeUpdate('completed', updatedMilestone, milestone));
      emitOptimisticUpdate(promise, 'update', updatedMilestone);
      return promise;
    },

    uncompleteMilestone(milestone) {
      const updatedMilestone = {
        id: milestone.id,
        progress: 0,
        status: 'reopened',
      };
      const promise = api
        .put(v1Url(`milestones/${updatedMilestone.id}/uncomplete`), null, config())
        .then(() => emitRealTimeUpdate('reopened', updatedMilestone, milestone));
      emitOptimisticUpdate(promise, 'update', updatedMilestone);
      return promise;
    },
  };
}
