// If any lower-level change notifications don't result in API calls,
// or if we are running a filter currently (as it will need to be reassessed)
// then we need to go up the hierarchy and mark stale/refresh the higher-level DLs
// https://digitalcrew.teamwork.com/#/tasks/13673822
export default ({ dispatch, state, getters }, itemId, taskListId) =>
  (results) => {
    const projectId = (
      (itemId && state.task.records[itemId]) ||
      (taskListId && state.tasklist.records[taskListId]) ||
      {}
    ).projectId;
    // If there is an active filter we need to update regardless of item updates,
    // here we pass
    const filterUpdate =
      projectId &&
      getters['filter/current/active'] &&
      dispatch('project/tasks/resultChangeNotification', {
        id: projectId,
        payload: Number(itemId),
      });

    // any false values from results (changeNotification that didnt result in ajax update)
    if (!results.every(Boolean)) {
      if (itemId) {
        dispatch('task/batch/changeNotification', itemId);
      } else if (taskListId) {
        // TODO - when data loader hierarchy gets centralised, don't forget about this use case
        // tasklist/tasks gets data change notif, but isn't visible, so it is reset, at the same
        // time, the tasks in question need to marked stale in the batch loader, this is inlined
        // here
        dispatch('task/batch/changeNotification', state.task.tasksByTasklist[taskListId] || []);
      }
      if (projectId) {
        if (!filterUpdate) {
          dispatch('project/tasks/changeNotification', projectId);
        }
        dispatch('project/dependencies/changeNotification', projectId);
      }
    }
  };
