import moment from 'moment';
import { mapValues } from 'lodash-es';
import { mapGetters } from '@/store/utils/record-mapper';
import workingWeek from '@/utils/helpers/workingWeek';

// Most permission checks consist of an activePage check and a permission check
const permissions = mapValues(
  {
    canAddMilestones: ['milestones', 'canAddMilestones'],
    canViewMilestones: ['milestones', 'viewTasksAndMilestones'],
    canAddTasks: ['tasks', 'canAddTasks'],
    canAddTaskLists: ['tasks', 'canAddTaskLists'],
    canViewTasks: ['tasks', 'viewTasksAndMilestones'],
    canAddMessages: ['messages', 'canAddMessages'],
    canViewMessages: ['messages', 'viewMessagesAndFiles'],
    canViewFiles: ['files', 'viewMessagesAndFiles'],
    canAddFiles: ['files', 'canAddFiles'],
    canLogTime: ['time', 'canLogTime', true],
    canViewTimeLog: ['time', 'viewTimeLog'],
    canViewNotebooks: ['notebooks', 'viewNotebook'],
    canAddNotebooks: ['notebooks', 'canAddNotebooks'],
    canViewRisks: ['riskRegister', 'viewRiskRegister'],
    canAddRisks: ['riskRegister', 'canAddRisks'],
    canViewLinks: ['links', 'viewLinks'],
    canAddLinks: ['links', 'canAddLinks'],
    canViewBilling: ['billing', 'canAccessInvoiceTracking', true],
    canViewForms: ['forms', 'canViewForms'],
    canAddForms: ['forms', 'canAddForms'],
    canAccessInvoiceTracking: ['billing', 'canAccessInvoiceTracking', true],
    canManageProjectBudget: ['budgets', 'canManageProjectBudget'],
    canViewProjectBudget: ['budgets', 'canViewProjectBudget'],
  },
  ([activePage, permission, cantBeTemplate]) =>
    (record) =>
      record.activePages[activePage] &&
      record.permissions[permission] &&
      (!cantBeTemplate || record.type !== 'projects-template'),
);

const isAdmin = (record, getters, { user }, rootGetters, id) =>
  (record && record.permissions.projectAdministrator) ||
  (getters.isTemplate(id) && rootGetters['permissions/canManageProjectTemplates']) ||
  user.administrator;

const adminFunctions = [
  'canViewProjectRoles',
  'canViewProjectDates',
  'canCompleteProject',
  'canArchiveProject',
  'canDeleteProject',
  'canAddPeopleToProject',
].reduce((getters, fnc) => ({ ...getters, [fnc]: isAdmin }), {});

// These will be record-mapped, but also applied within the 'current' namespace
const recordGetters = {
  ...permissions,
  isArchived: ({ status }) => status === 'archived',
  isActive: ({ status }) => status === 'active',
  isDeleted: ({ status }) => status === 'deleted',
  isCompleted: ({ subStatus }) => subStatus === 'completed',
  isIncomplete: (record, getters, rootState, rootGetters, id) => !getters.isCompleted(id),
  isTemplate: (record) => record && record.type === 'projects-template',
  isNonTemplate: (record, getters, rootState, rootGetters, id) => !getters.isTemplate(id),
  isAdmin,
  ...adminFunctions,
  canCopyProject: (record, getters, rootState, rootGetters) => rootGetters['permissions/canAddProjects'],
  canViewSettings: (record, getters, rootState, rootGetters, id) => getters.isAdmin(id) && getters.isActive(id),
  canViewComments: (record) => record.activePages.comments,
  canViewPeople: (record, getters, { user }) =>
    record.permissions.projectAdministrator || user.administrator || user.inOwnerCompany,
  canViewProjectReport: (record, getters, { user }) =>
    record.permissions.projectAdministrator || record.permissions.viewTasksAndMilestones || user.administrator,
  canAddEvents: (record, getters, { user }) =>
    record.type !== 'projects-template' && user.inOwnerCompany && user.userType === 'account',
  canManageProjectCustomFields: (record) => record.permissions.canManageCustomFields,
  canAccessInvoiceTracking: (record) => record.permissions.canAccessInvoiceTracking,
  canViewProjectBudget: (record) => record.permissions.canViewProjectBudget,
  canManageProjectBudget: (record) => record.permissions.canManageProjectBudget,
  tasklists: (state, getters, rootState, rootGetters, id) => getters['tasklists/ordered'](id),

  blackoutDays: (state) => (state.skipWeekends ? [0, 6] : []),
  workingWeek: (state, getters, rootState, rootGetters, id) => workingWeek(getters.blackoutDays(id)),

  canAddUpdate: (record, getters, rootState, rootGetters, id) =>
    rootGetters['account/features'].includes('projectupdates') &&
    (rootGetters['project/isAdmin'](id) || record.permissions.canAddProjectUpdate) &&
    record.type !== 'projects-template',

  subStatusFromDates: ({ startDate, endDate }) => {
    if (startDate && startDate.diff(moment(), 'days') > 0) {
      return 'upcoming';
    }
    if (endDate && endDate.diff(moment(), 'days') < 0) {
      return 'late';
    }
    return 'current';
  },
};

export default {
  current: (state) => state.currentProjectId && state.records[state.currentProjectId],

  // Create 'current/' prefixed getters for all recordGetters
  ...Object.keys(recordGetters).reduce(
    (currentGetters, key) => ({
      ...currentGetters,
      [`current/${key}`]: (state, getters, ...others) =>
        getters.current && recordGetters[key](getters.current, getters, ...others, getters.current.id),
    }),
    {},
  ),

  ...mapGetters({
    ...recordGetters,

    categoryColor: ({ category }) => (category.color ? `#${category.color}` : ''),
  }),
};
