import { h } from 'vue-demi';

export default [
  {
    path: '/projects/:project(\\d+)/notebooks',
    component: { render: () => h('RouterView') },
    children: [
      {
        path: '',
        component: () => import('./RouteProjectsNotebooks'),
        name: 'page-project-notebooks', // TODO find references and remove
        meta: {
          noSpacing: true,
        },
      },
      {
        path: 'create',
        component: () => import('./RouteProjectsNotebooksCreate'),
        meta: {
          noSpacing: true,
        },
      },
      {
        path: ':notebookId',
        redirect: (to) => `/notebooks/${to.params.notebookId}`,
      },
      {
        path: ':notebookId/edit',
        redirect: (to) => `/notebooks/${to.params.notebookId}/edit`,
      },
      {
        path: '*',
        redirect: '.',
      },
    ],
  },
  {
    path: '/notebooks/:notebookId(\\d+)',
    component: { render: () => h('RouterView') },
    children: [
      {
        path: '',
        component: () => import('./RouteProjectsNotebooksDetails'),
        props: (to) => ({
          notebookId: Number(to.params.notebookId),
        }),
        meta: {
          noSpacing: true,
        },
      },
      {
        path: 'edit',
        component: () => import('./RouteProjectsNotebooksEdit'),
        props: (to) => ({
          notebookId: Number(to.params.notebookId),
        }),
        meta: {
          noSpacing: true,
        },
      },
      {
        path: '*',
        redirect: '.',
      },
    ],
  },
];
