import moment from 'moment';
import { computed, shallowRef } from 'vue-demi';
import { v3Url } from '@/utils/fetcher';
import useItemLoader from '../base/useItemLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItem({ data: { reminders, included: { users = {} } = {} } }) {
  for (let i = 0; i < reminders.length; i += 1) {
    const reminder = reminders[i];

    if (reminder.user && users[reminder.user.id]) {
      reminder.user = users[reminder.user.id];
      reminder.remindAt = moment(reminder.remindAt);
    }
  }

  return reminders;
}

/**
 * Loads a list of reminders for a specific task.
 *
 * Note: The API does not support pagination for reminders.
 */
export default function useRemindersLoader({ taskId: _taskId, params, count: _count = 0 }) {
  const taskId = shallowRef(_taskId);
  const count = shallowRef(_count);
  const url = computed(() => Number.isInteger(taskId.value) && v3Url(`tasks/${taskId.value}/reminders`));
  const {
    state: { item, inSync, loading, meta, response, error },
    refresh,
  } = useItemLoader({
    url,
    params,
    responseToItem,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'reminder' && event.taskId === taskId.value) {
      refresh();
    }
  });

  return {
    items: computed(() => {
      if (item.value && count.value > 0) {
        if (item.value.length <= count.value) {
          return item.value;
        }
        return item.value.slice(0, count.value);
      }
      return [];
    }),
    totalCount: computed(() => item.value && item.value.length),
    itemInSync: () => inSync.value,
    inSync,
    loading,
    meta,
    response,
    error,
  };
}
