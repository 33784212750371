import { v1Url } from '@/utils/fetcher';
import useItemLoader from '../base/useItemLoader';

function responseToItem({ data: { branding } }) {
  return branding;
}

/**
 * Loads the branding information.
 */
export default function useBrandingLoader({ params } = {}) {
  const { state } = useItemLoader({
    url: v1Url('v/1/settings/branding'),
    params,
    responseToItem,
  });
  return state;
}
