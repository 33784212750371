import { computed, shallowRef } from 'vue-demi';
import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItems(response) {
  const {
    notifications = {},
    companies = {},
    projects = {},
    tasklistTaskStats = {},
    tasklistBudgets = {},
    teams = {},
    users = {},
  } = response.data.included;

  const tasklists = response.data.tasklists || [];

  /* eslint-disable no-param-reassign */
  tasklists.forEach((tasklist) => {
    tasklist.id = Number(tasklist.id);
    tasklist.projectId = Number(tasklist.projectId);

    if (projects[tasklist.projectId]) {
      tasklist.project = projects[tasklist.projectId];
    }

    if (tasklist.tasklistBudget && tasklistBudgets[tasklist.tasklistBudget.id]) {
      const tasklistBudget = tasklistBudgets[tasklist.tasklistBudget.id];
      tasklistBudget.type = tasklistBudget.type.toLowerCase();

      const budgetNotifications = [];
      tasklistBudget.notifications?.forEach((_notification) => {
        if (notifications[_notification.id]) {
          const notification = notifications[_notification.id];
          const { capacityThreshold, notificationMedium } = notification;
          // Find other notification with the same capacity threshold
          const existingIndex = budgetNotifications.findIndex(
            (n) =>
              n.capacityThreshold === capacityThreshold && n.notificationMedium === notificationMedium.toLowerCase(),
          );

          notification.notificationMedium = notification.notificationMedium.toLowerCase();
          const notificationUsers = notification.userId ? [users[notification.userId]] : [];
          const notificationTeams = notification.teamId ? [teams[notification.teamId]] : [];
          const notificationCompanies = notification.companyId ? [companies[notification.companyId]] : [];

          if (existingIndex > -1) {
            // There is already a notification with this capacity and medium
            // We need to merge it
            budgetNotifications[existingIndex].users.push(...notificationUsers);
            budgetNotifications[existingIndex].companies.push(...notificationCompanies);
            budgetNotifications[existingIndex].teams.push(...notificationTeams);
          } else {
            notification.users = notificationUsers;
            notification.companies = notificationCompanies;
            notification.teams = notificationTeams;
            budgetNotifications.push(notification);
          }
        }
      });
      tasklist.tasklistBudget = tasklistBudget;
      tasklist.tasklistBudget.notifications = budgetNotifications;
    }

    tasklist.tasklistTaskStats = tasklistTaskStats[tasklist.id] || {};
  });
  /* eslint-enable no-param-reassign */
  return tasklists;
}

function order(list1, list2) {
  return list1.position - list2.position;
}

/**
 * Loads a list of task lists from the Teamwork v3 API.
 * @param {Object} options
 * @param {string} options.projectId The project from which to load task lists.
 */
export default function useTasklistsV3Loader({ projectId: _projectId, params, count, pageSize = 50 }) {
  const projectId = shallowRef(_projectId);
  const url = computed(() => {
    if (projectId.value) {
      return v3Url(`projects/${projectId.value}/tasklists`);
    }
    return v3Url('tasklists');
  });

  const { state, refresh } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    order,
    pageSize,
  });

  useRealTimeUpdates((event) => {
    // If many tasks changed, reload all task lists.
    // The event name is misleading, as task modifications are not limited to a single project.
    if (event.type === 'projectTasks') {
      refresh();
      return;
    }

    // If filtering by projectId, it must match event.projectId.
    if (projectId.value && projectId.value !== event.projectId) {
      return;
    }

    // Only events of these types can affect task list properties.
    if (event.type !== 'task' && event.type !== 'taskList' && event.type !== 'taskListTasks') {
      return;
    }

    // Only specific task actions can affect a task list.
    if (
      event.type === 'task' &&
      event.action !== 'reopened' &&
      event.action !== 'completed' &&
      event.action !== 'new' &&
      event.action !== 'deleted'
    ) {
      return;
    }

    refresh(event.taskListId);
  });

  return state;
}
