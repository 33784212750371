import { computed, shallowRef } from 'vue-demi';
import { v1Url } from '@/utils/fetcher';
import useItemLoader from '../base/useItemLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItem(response) {
  return response.data.categories.map((category) => ({
    id: Number(category.id),
    type: category.type,
    parentId: Number(category['parent-id']) || 0,
    name: category.name,
    color: category.color,
    count: Number(category.count),
  }));
}

function responseToMeta(type, response) {
  return {
    totalItems: Number(response.headers[`x-total-${type.value}s`]),
    totalCategorizedItems: Number(response.headers[`x-total-${type.value}s-categorized`]),
    totalUncategorizedItems: Number(response.headers[`x-total-${type.value}s-uncategorized`]),
  };
}

/**
 * Loads a list of categories from the Teamwork v1 API.
 *
 * Note: The v1 categories endpoints do not support pagination.
 */
export default function useCategoriesLoader({
  /**
   * The projectId for which to load categories.
   */
  projectId: _projectId,
  /**
   * The type of categories to load: "project", "file", "notebook", "message", "link".
   */
  type: _type,
  params,
  count: _count = 0,
}) {
  const projectId = shallowRef(_projectId);
  const type = shallowRef(_type);
  const count = shallowRef(_count);
  const url = computed(() => {
    switch (type.value) {
      case 'project':
        return v1Url(`projectcategories`);
      case 'file':
      case 'notebook':
      case 'message':
      case 'link':
        return v1Url(`projects/${projectId.value}/${type.value}categories`);
      default:
        return undefined;
    }
  });
  // The v1 categories endpoints do not support pagination and always return all categories,
  // so we have to use `useItemLoader` instead of `useListLoader`.
  const {
    state: { item, inSync, loading, meta, response, error },
    refresh,
  } = useItemLoader({
    url,
    params,
    responseToItem,
    responseToMeta: responseToMeta.bind(null, type),
  });

  useRealTimeUpdates((event) => {
    // When receiving a category entity realtime update, check to ensure it is
    // for this category loader state.
    // Categories are found at a project level or at a project entity level e.g. file, notebook, message, link
    // So if we receive update events for these entities we need to refresh the category list also, as it will impact
    // upon category counts on their list view pages in realtime.
    if (event.type === 'category') {
      if (
        event.categoryType === type.value &&
        (event.categoryType === 'project' || event.projectId === projectId.value)
      ) {
        refresh();
      }
    } else if (event.type === type.value && (event.type === 'project' || event.projectId === projectId.value)) {
      if (
        event.categoryChanged ||
        event.action === 'added' ||
        event.action === 'archived' ||
        event.action === 'deleted' ||
        event.action === 'new' ||
        event.action === 'reopened' ||
        event.action === 'undelete'
      ) {
        refresh();
      }
    }
  });

  return {
    items: computed(() => {
      if (item.value && count.value > 0) {
        if (item.value.length <= count.value) {
          return item.value;
        }
        return item.value.slice(0, count.value);
      }
      return [];
    }),
    totalCount: computed(() => item.value && item.value.length),
    itemInSync: () => inSync.value,
    inSync,
    loading,
    meta,
    response,
    error,
  };
}
