import { computed, shallowRef } from 'vue-demi';
import { v1Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';

function responseToItems(response) {
  const { columns } = response.data;

  columns.forEach((column) => {
    // eslint-disable-next-line no-param-reassign
    column.id = Number(column.id);
  });

  return columns;
}

/**
 * Loads a list of board columns from the Teamwork v1 API.
 */
export default function useBoardColumnsLoader({
  /**
   * The projectId for which to load board columns.
   */
  projectId: _projectId,
  params,
  count,
  pageSize = 50,
}) {
  const projectId = shallowRef(_projectId);
  const url = computed(() => Number.isInteger(projectId.value) && v1Url(`projects/${projectId.value}/boards/columns`));
  const { state } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    pageSize,
  });
  return state;
}
