import { createRecordListLoader, API, MUTATIONS } from '@/store/utils/loader';
import { mapActions } from '@/store/utils/record-mapper';

export default createRecordListLoader({
  namespaced: true,
  config: {
    id: 'tasklist/tasks',
    url: (id) => `${API.v2}/tasklists/${id}/tasks.json`,
    params: ({ tasklist }) => ({
      getSubTasks: false,
      getFiles: true,
      sort: tasklist.sort,
      sortOrder: tasklist.sortOrder,
    }),
    pagination: { loadAll: true },
    updater: true,
  },
  actions: mapActions({
    /**
     * Custom list action because the `list` is maintained in `task.tasksByTasklist`
     * which means some custom behaviour.
     */
    onLoad({ commit, state, id, rootState: { task } }, rs) {
      const { tasks } = rs.data;
      const prevList = task.tasksByTasklist[id] || [];
      commit('task/records', tasks, { root: true, recordMap: false });
      const ids = tasks.map((t) => t.id);
      let list = state.additive ? [...new Set([...prevList, ...ids])] : ids;
      list = list
        .map((tid) => task.records[tid])
        // remove deleted needs to be after merging with existing,
        // as update might return some deleted entries
        .filter(({ status }) => !status || status !== 'deleted')
        .map((t) => t.id);
      commit('task/tasksByTasklist', list, { root: true });
      commit(MUTATIONS.ADDITIVE);
    },
  }),
});
