// Runs a map process over all routes, follows the nesting
export default (routes, process) => {
  const queue = [...routes.map((r) => [r, null])];
  const result = [];

  while (queue.length) {
    // Grab next node
    const next = queue.shift();
    // Process it
    const out = process(next[0]);
    // Wipe output children
    out.children = [];
    // Add all original children to queue
    (next[0].children || []).forEach((ch) => queue.push([ch, out.children]));
    // Add output to its parent
    (next[1] || result).push(out);
  }
  return result;
};
