import { shallowRef, inject, provide, readonly } from 'vue-demi';
import { isQuickAddOpen } from '@/scaffolding/tko-ui-state';

const quickAddMenuStateSymbol = Symbol('useQuickAddMenuState');

export function provideQuickAddMenuState() {
  const isOpen = shallowRef(false);
  const setIsOpen = (val) => {
    isOpen.value = val;
    isQuickAddOpen(val);
  };

  provide(quickAddMenuStateSymbol, {
    isOpen: readonly(isOpen),
    setIsOpen,
  });
}

export function useQuickAddMenuState() {
  return inject(quickAddMenuStateSymbol);
}
