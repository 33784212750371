import { computed, ref } from 'vue-demi';
import getUrlParam from '@/utils/helpers/getUrlParam';
import GetCookie from '@/utils/helpers/getcookie';

const BYPASS_EXPERIMENT = 'bypassExperiments';
const LOG_TRACK_EVENTS = 'logTrackEvents';
const bypassExperiments = ref(false);
const logTrackEvents = ref(false);

export default function useTrackingDebugQuery() {
  /**
   * Checks if experiment is to be bypassed via `bypassExperiments` query parameter and
   * tracks as stateful bypassExperiments ref.
   *
   * @return {Boolean} Returns `true` if experiment is to be bypassed, else `false`
   */
  const isBypassExperimentsQueryEnabled = computed(() => {
    const expDebugQueryParam = getUrlParam(BYPASS_EXPERIMENT);
    if (['true', '1'].includes(expDebugQueryParam)) {
      bypassExperiments.value = true;
    } else if (['false', '0', ''].includes(expDebugQueryParam)) {
      bypassExperiments.value = false;
    }
    return bypassExperiments.value;
  });

  /**
   * Checks if Track Events should be logged via `logTrackEvents` query parameter and
   * tracks as stateful logTrackEvents ref.
   *
   * @return {Boolean} Returns `true` if Track Events should be logged, else `false`
   */
  const isLogTrackEventsEnabled = computed(() => {
    const logTrackEventsQueryParam = getUrlParam(LOG_TRACK_EVENTS, window.location.hash);
    if (['true', '1'].includes(logTrackEventsQueryParam) || GetCookie(LOG_TRACK_EVENTS)) {
      logTrackEvents.value = true;
    } else if (['false', '0', ''].includes(logTrackEventsQueryParam)) {
      logTrackEvents.value = false;
    }
    return logTrackEvents.value;
  });

  return {
    isBypassExperimentsQueryEnabled,
    isLogTrackEventsEnabled,
  };
}
