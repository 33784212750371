import moment from 'moment';
import { computed } from 'vue-demi';
import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function filterWorkflowsByProjectId({ workflows, projectId }) {
  const workflowValues = Object.values(workflows);

  const filteredWorkflows = workflowValues.filter((workflow) => {
    return workflow.projectIds && workflow.projectIds.includes(projectId);
  });

  return filteredWorkflows;
}

function responseToItems(response) {
  const projects = {};
  const {
    activities = {},
    companies = {},
    workflows = {},
    customfieldProjects: customFieldProjects = {},
    customfields: customFields = {},
    portfolioBoards = {},
    portfolioCards = {},
    portfolioColumns = {},
    projectBudgets = {},
    projectCategories = {},
    projectTaskStats = {},
    projectUpdates = {},
    tags = {},
    users = {},
  } = response.data.included;
  const permissions = response.data.meta.permissions || {};
  const projectsList = response.data.projects || [];

  /* eslint-disable no-param-reassign */
  projectsList.forEach((project) => {
    projects[project.id] = project;

    project.startDate = project.startDate ? moment(project.startDate) : null;
    project.endDate = project.endDate ? moment(project.endDate) : null;
    project.createdAt = project.createdAt ? moment(project.createdAt) : null;
    project.updatedAt = project.updatedAt ? moment(project.updatedAt) : null;
    project.lastWorkedOn = project.lastWorkedOn ? moment(project.lastWorkedOn) : null;

    if (project.category && projectCategories[project.category.id]) {
      project.category = projectCategories[project.category.id];
    }

    if (project.company && companies[project.company.id]) {
      project.company = companies[project.company.id];
    }

    project.workflows = filterWorkflowsByProjectId({
      workflows,
      projectId: project.id,
    });

    if (project.customFieldValues) {
      project.customFieldValues.forEach((customFieldValue, index) => {
        if (customFieldProjects[customFieldValue.id]) {
          project.customFieldValues[index] = customFieldProjects[customFieldValue.id];
        }
      });
    }

    if (project.latestActivity && activities[project.latestActivity.id]) {
      project.latestActivity = activities[project.latestActivity.id];
    }

    if (project.ownerId && users[project.ownerId]) {
      project.owner = users[project.ownerId];
    }

    if (permissions[project.id]) {
      project.permissions = permissions[project.id];
    }

    if (project.portfolioCards) {
      project.portfolioCards.forEach((portfolioCard, index) => {
        if (portfolioCards[portfolioCard.id]) {
          project.portfolioCards[index] = portfolioCards[portfolioCard.id];
        }
      });
    }

    if (project.tags?.length) {
      project.tags.forEach((tag, index) => {
        if (tags[tag.id]) {
          project.tags[index] = tags[tag.id];
        }
      });
    }

    if (projectTaskStats[project.id]) {
      project.taskStats = projectTaskStats[project.id];
    }

    const budgetId = project.timeBudgetId || project.financialBudgetId;
    if (projectBudgets[budgetId]) {
      project.budget = projectBudgets[budgetId];
    }

    if (project.updateId && projectUpdates[project.updateId]) {
      project.update = projectUpdates[project.updateId];
    }
  });

  Object.values(activities).forEach((activity) => {
    activity.datetime = activity.datetime ? moment(activity.datetime) : null;

    if (activity.user && users[activity.user.id]) {
      activity.user = users[activity.user.id];
    }
  });

  Object.values(customFieldProjects).forEach((customFieldProject) => {
    if (customFieldProject.customfield) {
      customFieldProject.customField = customFieldProject.customfield;
      delete customFieldProject.customfield;
    }
    if (customFieldProject.customfieldId) {
      customFieldProject.customFieldId = customFieldProject.customfieldId;
      delete customFieldProject.customfieldId;
    }

    if (customFieldProject.customField && customFields[customFieldProject.customField.id]) {
      customFieldProject.customField = customFields[customFieldProject.customField.id];
    }

    if (customFieldProject.project && projects[customFieldProject.project.id]) {
      customFieldProject.project = projects[customFieldProject.project.id];
    }
  });

  Object.values(portfolioCards).forEach((portfolioCard) => {
    if (portfolioCard.project && projects[portfolioCard.project.id]) {
      portfolioCard.project = projects[portfolioCard.project.id];
    }

    if (portfolioCard.column && portfolioColumns[portfolioCard.column.id]) {
      portfolioCard.column = portfolioColumns[portfolioCard.column.id];
    }

    if (portfolioCard.board && portfolioBoards[portfolioCard.board.id]) {
      portfolioCard.board = portfolioBoards[portfolioCard.board.id];
    }
  });

  Object.values(projectCategories).forEach((projectCategory) => {
    if (projectCategory.parent && projectCategories[projectCategory.parent.id]) {
      projectCategory.parent = projectCategories[projectCategory.parent.id];
    }
  });

  Object.values(projectUpdates).forEach((projectUpdate) => {
    projectUpdate.createdAt = projectUpdate.createdAt ? moment(projectUpdate.createdAt) : null;
    projectUpdate.updatedAt = projectUpdate.updatedAt ? moment(projectUpdate.updatedAt) : null;

    if (users[projectUpdate.createdBy]) {
      projectUpdate.creator = users[projectUpdate.createdBy];
    }

    if (projectUpdate.project && projects[projectUpdate.project.id]) {
      projectUpdate.project = projects[projectUpdate.project.id];
    }
  });
  /* eslint-enable no-param-reassign */

  return projectsList;
}

// Gets projects metadata from an axios response.
function responseToMeta(response) {
  const { projects, user } = response.data.meta;
  return { projects, user };
}

/**
 * Loads a list of projects from the Teamwork v3 API.
 */
export default function useProjectsLoader({ params, count, pageSize } = {}) {
  const url = computed(() => v3Url('projects'));

  const { state, refresh, update } = useListLoader({
    url,
    params,
    count,
    pageSize,
    responseToItems,
    responseToMeta,
  });

  useOptimisticUpdates((event) => {
    if (event.type === 'project') {
      if (event.action === 'update') {
        update(
          (projects) =>
            projects.map((project) => (project.id === event.project.id ? { ...project, ...event.project } : project)),
          event.promise,
        );
      }
    }
  });

  useRealTimeUpdates((event) => {
    switch (event.type) {
      case 'project':
        if (event.action === 'permissions') {
          // Do nothing - existing pwa behaviour.
        } else if (event.action === 'progressNotice') {
          // UI issue, see https://digitalcrew.teamwork.com/app/tasks/21630540
          // Do nothing - avoid refreshing the whole list.
          // We get 'project.progressNotice' events but the projects do not actually change.
          // Update so that the loader will NOT call refresh when type is "project" and action is "progressNotice"
        } else if (event.action === 'starred' || event.action === 'unstarred') {
          // The server does not mark starred and unstarred projects as modified,
          // so we must trigger a full refresh to avoid projects disappearing from the list.
          // See https://digitalcrew.teamwork.com/#tasks/18655536?c=9133523
          refresh();
        } else {
          // Refresh only the modified projects.
          refresh(event.projectId);
        }
        break;
      case 'budget':
        // Update project budgets.
        if (
          params.value &&
          typeof params.value.include === 'string' &&
          /(^|,)projectBudgets($|,)/.test(params.value.include)
        ) {
          refresh(event.projectId);
        }
        break;
      case 'time':
        // Update project budgets.
        if (
          params.value &&
          typeof params.value.include === 'string' &&
          /(^|,)projectBudgets($|,)/.test(params.value.include)
        ) {
          refresh();
        }
        break;
      case 'task':
        // Update task stats.
        if (
          params.value &&
          typeof params.value.include === 'string' &&
          /(^|,)projectTaskStats($|,)/.test(params.value.include) &&
          (event.action === 'completed' ||
            event.action === 'reopened' ||
            event.action === 'deleted' ||
            event.action === 'new')
        ) {
          refresh();
        }
        break;
      default:
        break;
    }
  });

  return state;
}
