import Vue from 'vue';
import moment from 'moment';

const presets = {
  short: 'MMMM Do',
  relativeShort: 'dddd, MMMM Do',
  long: 'Do MMMM YYYY, h:mm:ssa',
};

const dayDiff = (date) => {
  const otherDay = date.clone().startOf('day');
  const today = moment.tz(moment.tz.guess()).startOf('day');
  switch (otherDay.diff(today, 'days')) {
    case 0:
      return Vue.t('Today');
    case 1:
      return Vue.t('Tomorrow');
    case -1:
      return Vue.t('Yesterday');
    default:
      return false;
  }
};

export default (value, format = 'short') =>
  (format.includes('relative') && dayDiff(moment(value))) || moment(value).format(presets[format] || format);
