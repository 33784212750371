/**
 * Converts an Array of objects to a Map of objects,
 * containing the same items indexed by `item.id`.
 */
export default function mapById(items) {
  const map = new Map();
  if (Array.isArray(items)) {
    items.forEach((item) => {
      map.set(item.id, item);
    });
  }
  return map;
}
