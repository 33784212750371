// NOTE This component MUST be kept compatible with [vue-resize](https://github.com/Akryum/vue-resize).
// It is implemented here only because the original `vue-resize` used by `v-popover` is inefficient.
// Please use the `v-resize` directive instead of this component.

// @vue/component
export default {
  mounted() {
    const parentNode = this.$el.parentNode;
    new ResizeObserver(() => {
      this.$emit('notify', {
        width: parentNode.offsetWidth,
        height: parentNode.offsetHeight,
      });
    }).observe(parentNode);
  },
};
