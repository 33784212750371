/**
 * Get a lockdown object with grantAccessTo mapped to users, teams, companies.
 * @returns the lockdown object with populated users, teams, companies
 */
// eslint-disable-next-line import/prefer-default-export
export function normalizeLockdown({ lockdown = {}, users = {}, teams = {}, companies = {} } = {}) {
  if (!lockdown.grantAccessTo) {
    return lockdown;
  }
  const normalized = structuredClone(lockdown);

  lockdown.grantAccessTo.users.forEach((user, index) => {
    normalized.grantAccessTo.users[index] = users[user.id];
    const company = companies[users[user.id].companyId];
    normalized.grantAccessTo.users[index].company = {
      name: company.name,
    };
  });

  lockdown.grantAccessTo.companies.forEach((company, index) => {
    normalized.grantAccessTo.companies[index] = companies[company.id];
  });

  lockdown.grantAccessTo.teams.forEach((team, index) => {
    normalized.grantAccessTo.teams[index] = teams[team.id];
  });

  normalized.grantAccessTo.everyone = Boolean(
    ![].concat(normalized.grantAccessTo.users, normalized.grantAccessTo.teams, normalized.grantAccessTo.companies)
      .length,
  );

  return normalized;
}
