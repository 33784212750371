import api from './helpers/api';

function paginate({ page }) {
  return {
    page: parseInt(page.pageOffset, 10) + 1,
    hasMore: page.hasMore,
    totalRecords: parseInt(page.count, 10),
  };
}

export default async (endpoint, params, normalize = (data) => data, options = {}) => {
  const resp = await api.get(`/projects/api/v3/${endpoint}.json`, { params, ...options });
  const { data } = resp;
  const response = {
    ...normalize(resp),
  };

  const pagination = data.meta ? paginate(data.meta) : null;
  if (pagination) {
    response.pagination = pagination;
  }

  return response;
};
