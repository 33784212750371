export default [
  {
    path: '/settings',
    component: () => import('./RouteSettings'),
    children: [
      {
        path: 'logo',
        component: () => import('./RouteSettingsLogo'),
      },
      {
        path: 'templates',
        component: () => import('./RouteSettingsTemplates'),
      },
      {
        path: 'customfields',
        component: () => import('./RouteSettingsCustomfields'),
      },
      {
        path: 'integrations/:category(.+)?',
        component: () => import('./RouteSettingsIntegrations'),
      },
      {
        path: 'beta',
        component: () => import('./RouteSettingsBeta'),
      },
      {
        path: 'referral/:section(.+)?',
        component: () => import('./RouteSettingsReferral'),
      },
      {
        path: 'import',
        component: () => import('./RouteSettingsImport'),
      },
      {
        path: 'export',
        component: () => import('./RouteSettingsExport'),
      },
      {
        path: 'email',
        component: () => import('./RouteSettingsEmail'),
      },
      {
        path: 'webhooks',
        component: () => import('./RouteSettingsWebhooks'),
      },
      {
        path: 'sso',
        component: () => import('./RouteSettingsSSO'),
      },
      {
        path: 'tags',
        component: () => import('./RouteSettingsTags'),
      },
      {
        path: 'subscription',
        component: () => import('./RouteSettingsSubscription'),
      },
      {
        path: 'invoicehistory',
        component: () => import('./RouteSettingsInvoicehistory'),
      },
      {
        path: 'forms',
        component: () => import('./RouteSettingsForms'),
      },
      {
        path: '*',
        redirect: '/settings',
      },
    ],
  },
  {
    path: '/referral/(.*)?',
    redirect: '/settings/referral',
  },
];
