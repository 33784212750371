export const defaultTypes = {
  id: 0,
  name: '',
  code: '',
  icon: '',
  description: '',
  categoryId: 0,
  imagePreview: '',
};

export const defaults = { ...defaultTypes, startDate: null, endDate: null };
