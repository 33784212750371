import { v3Url } from '@/utils/fetcher';
import { useAxios } from '../base/useAxios';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

const { hasOwnProperty } = Object.prototype;

function createTasklistBudgetPayload(tasklistBudget) {
  const budgetPayload = {};

  if (hasOwnProperty.call(tasklistBudget, 'id')) {
    budgetPayload.id = Number(tasklistBudget.id);
  } else if (hasOwnProperty.call(tasklistBudget, 'tasklist')) {
    budgetPayload.tasklist = tasklistBudget.tasklist;
  }

  if (hasOwnProperty.call(tasklistBudget, 'capacity')) {
    budgetPayload.capacity = Number(tasklistBudget.capacity);
  }

  if (hasOwnProperty.call(tasklistBudget, 'notifications') && tasklistBudget.notifications.length) {
    budgetPayload.notifications = tasklistBudget.notifications.map((notification) => ({
      notificationMedium: notification.notificationMedium.toUpperCase(),
      capacityThreshold: notification.capacityThreshold,
      users: notification.users?.map(
        (user) =>
          ({
            id: Number(user.id),
            type: 'users',
          }) ?? [],
      ),
      teams: notification.teams?.map(
        (team) =>
          ({
            id: Number(team.id),
            type: 'teams',
          }) ?? [],
      ),
      companies: notification.companies?.map(
        (company) =>
          ({
            id: Number(company.id),
            type: 'companies',
          }) ?? [],
      ),
      id: notification.id,
    }));
  }

  if (hasOwnProperty.call(tasklistBudget, 'taskListId')) {
    budgetPayload.tasklist = {
      id: Number(tasklistBudget.taskListId),
      type: 'tasklists',
    };
  }

  return budgetPayload;
}

function handleTasklistBudgetLimitError(error) {
  const errorData = error?.response?.data?.errors?.[0];
  if (errorData?.code !== 'TLBL') {
    return error;
  }

  const { code, detail } = errorData;
  const customError = new Error('');
  customError.name = 'TasklistBudgetLimitError';
  customError.code = code;
  customError.detail = detail;
  return customError;
}

export default function useTasklistBudgetActions() {
  const api = useAxios();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: _emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  const config = () => ({
    headers: {
      'Socket-ID': socketId.value,
      'Triggered-By': 'user',
      'Sent-By': 'composable',
    },
  });

  /**
   *
   * @param {Promise} promise
   * @param {'create'|'update'|'delete'} action
   * @param {object} tasklistBudget
   */
  function emitOptimisticUpdate(promise, action, tasklistBudget) {
    _emitOptimisticUpdate({
      promise,
      type: 'tasklistBudget',
      action,
      tasklistBudget,
    });
  }

  /**
   *
   * @param {'new'|'edited'|'deleted'} action
   * @param {object} tasklistBudget
   * @param {object} oldTasklistBudget
   */
  function emitRealTimeUpdate(action, tasklistBudget, oldTasklistBudget) {
    _emitRealTimeUpdate({
      type: 'tasklistBudget',
      action,
      tasklistBudgetId: tasklistBudget.id ?? oldTasklistBudget.id,
      projectBudgetId: tasklistBudget.projectBudgetId ?? oldTasklistBudget.projectBudgetId,
      tasklistId: tasklistBudget.tasklistId ?? oldTasklistBudget.tasklistId,
    });
  }

  /**
   * Bulk creates new tasklist budgets against an existing project budget.
   * @param {number} projectBudgetId
   * @param {Object[]} tasklistBudgets
   */
  function createTasklistBudgets(projectBudgetId, tasklistBudgets) {
    const url = v3Url(`projects/budgets/${projectBudgetId}/tasklists/budgets/bulk/add`);
    const promise = api.post(url, { tasklistBudgets: tasklistBudgets.map(createTasklistBudgetPayload) }, config());

    return promise.then((response) => response.data.tasklistBudgets);
  }

  /**
   * Update an existing tasklist budget.
   * @param {Object} tasklistBudget
   */
  function updateTasklistBudget(tasklistBudget, oldTasklistBudget) {
    const url = v3Url(`projects/budgets/tasklists/budgets/${tasklistBudget.id}`);
    const payload = {
      tasklistBudget: createTasklistBudgetPayload(tasklistBudget),
    };

    const promise = api
      .patch(url, payload, config())
      .then(({ data: { tasklistBudget: updatedTasklistBudget } }) => {
        emitRealTimeUpdate('edited', updatedTasklistBudget, oldTasklistBudget);
        return updatedTasklistBudget;
      })
      .catch((error) => {
        if (error.response.status === 403) {
          throw handleTasklistBudgetLimitError(error);
        }
        throw error;
      });

    emitOptimisticUpdate(promise, 'update', tasklistBudget);

    return promise;
  }

  /**
   * Bulk update tasklist budgets for a project budget.
   * @param {number} projectBudgetId
   * @param {Object[]} tasklistBudgets
   */
  function updateTasklistBudgets(projectBudgetId, tasklistBudgets) {
    const url = v3Url(`projects/budgets/${projectBudgetId}/tasklists/budgets`);
    const promise = api
      .put(url, { tasklistBudgets: tasklistBudgets.map(createTasklistBudgetPayload) }, config())
      .then(({ data: { tasklistBudgets: newTasklistBudgets } }) => {
        _emitRealTimeUpdate({
          type: 'tasklistBudget',
          action: 'bulkCreated',
          tasklistBudgets: newTasklistBudgets,
          projectBudgetId,
        });

        return newTasklistBudgets;
      })
      .catch((error) => {
        if (error.response.status === 403) {
          throw handleTasklistBudgetLimitError(error);
        }
        throw error;
      });

    return promise;
  }

  /**
   * Deletes a single tasklist budget.
   * @param {object} tasklistBudget
   */
  function deleteTasklistBudget(tasklistBudget) {
    const url = v3Url(`projects/budgets/tasklists/budgets/${tasklistBudget.id}`);
    const promise = api.delete(url, null, config()).then(() => {
      emitRealTimeUpdate('deleted', tasklistBudget);
    });
    emitOptimisticUpdate(promise, 'delete', tasklistBudget);
    return promise;
  }

  /**
   * Restore a single tasklist budget.
   * @param {object} tasklistBudget
   */
  function restoreTasklistBudget(tasklistBudget) {
    const url = v3Url(`/projects/budgets/tasklists/budgets/${tasklistBudget.id}/undelete`);
    const promise = api
      .put(url, null, config())
      .then(() => {
        emitRealTimeUpdate('undelete', tasklistBudget);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          throw handleTasklistBudgetLimitError(error);
        }
        throw error;
      });
    emitOptimisticUpdate(promise, 'undelete', tasklistBudget);
    return promise;
  }

  return {
    createTasklistBudgets,
    updateTasklistBudget,
    updateTasklistBudgets,
    deleteTasklistBudget,
    restoreTasklistBudget,
  };
}
