export default (store, { itemId, extraInfo }) => {
  let boardType = '';

  // We have different types of boards that use different api calls
  if (typeof extraInfo !== 'undefined' && extraInfo.data && extraInfo.data.boardType) {
    boardType = extraInfo.data.boardType || boardType;
  }
  // Portfolio boards haven't been implemented yet and use a different api call
  if (boardType !== 'project') {
    store.dispatch('board/changeNotification', Number(itemId));
  }
};
