import moment from 'moment';

const dateFormat = 'YYYYMMDD';

export default {
  id: 0,
  name: '',
  status: '',
  completedThisSession: false,
  taskListId: 0,
  canEdit: false,
  canComplete: false,
  numDependencies: 0,
  numEstMins: 0,
  parentTaskId: 0,
  projectId: 0,
  progress: 0,
  order: 0,
  priority: '',
  startDate: moment.tz('', dateFormat, moment.tz.guess()),
  dueDate: moment.tz('', dateFormat, moment.tz.guess()),
  dueDateFromMilestone: false,
  assignedTo: [],
  boardColumnId: 0,
  tagIds: [],
  // local data used to control whether this should show with a spinner,
  // can be set with a promise for chaining follow-up actions
  busy: false,
};
