import { createListLoader } from '@/store/utils/loader';

export default createListLoader({
  namespaced: true,

  state: {
    records: {
      // default columns
      name: {
        value: 'name',
        title: 'Task Name',
        scope: 'project',
        default: true,
        hasDirection: false,
      },
      tags: { value: 'tags', title: 'Tags' },
      startDate: {
        value: 'startDate',
        title: 'Start Date',
        hasDirection: true,
      },
      dueDate: { value: 'dueDate', title: 'Due Date', hasDirection: true },
      dateadded: { value: 'dateadded', title: 'Date Added' },
      project: { value: 'project', title: 'Project', scope: 'global' },
      priority: { value: 'priority', title: 'Priority' },
      company: { value: 'company', title: 'Company', scope: 'global' },
      createdby: { value: 'createdby', title: 'Created By' },
    },
  },

  config: {
    url: '/me/dataviews.json',
    module: 'dataview',
    params: () => ({
      dataType: 'tasks',
      getShareData: true,
    }),
    pagination: { pageSize: 5 },
  },
});
