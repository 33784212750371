import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';

function responseToItems(response) {
  const { users = {} } = response.data.included;

  const userRatesList = response.data.userRates || [];

  /* eslint-disable no-param-reassign */
  userRatesList.forEach((userRate) => {
    userRate.id = userRate.user.id;
    if (users[userRate.user.id]) {
      userRate.user = users[userRate.user.id];
    }
    /* eslint-enable no-param-reassign */
  });

  return userRatesList;
}

/**
 * Loads a list of project level user rates from the Teamwork v3 API.
 */
export default function useUserRatesLoader({
  /**
   * The id of project to loader the user rates from.
   */
  projectId,
  params,
  count,
  pageSize = 50,
}) {
  const url = projectId ? v3Url(`rates/projects/${projectId}/users`) : v3Url('rates/installation/users');
  const { state } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    pageSize,
  });
  return state;
}
