import io from 'socket.io-client';
import { inject, onUnmounted, provide, shallowRef, watch } from 'vue-demi';
import assertHasScope from '@/utils/other/assertHasScope';
import useBroadcast from '@/utils/browser/useBroadcast';
import { useCurrentAccount } from '../account/useCurrentAccount';
import { useCurrentUser } from '../user/useCurrentUser';

const useTWIMSocketSymbol = Symbol('useTWIMSocket');

// When the app runs on the default port number, it is able to receive the events from Lightspeed,
// so a fake socket is created to reduce the number of connections to our notification server.
function createFakeSocket() {
  // `crypto.randomUUID` is supported only since Safari 15.4
  // We can remove the fallback to `Math.random` once we stop supporting the older Safari versions.
  const socketId = crypto?.randomUUID?.() || `${Date.now()}-${Math.random()}`;
  const listeners = [];
  useBroadcast('useRealTimeUpdates/eventNotice', (eventNotice) => {
    listeners.forEach((listener) => {
      listener(eventNotice);
    });
  });

  return {
    io: {
      engine: {
        id: socketId,
      },
    },
    on(name, listener) {
      if (name === 'eventNotice' && typeof listener === 'function') {
        listeners.push(listener);
      }
    },
    off(name, listener) {
      if (name === 'eventNotice') {
        const index = listeners.lastIndexOf(listener);
        if (index >= 0) {
          listeners.splice(index, 1);
        }
      }
    },
  };
}

// Returns a promise which resolves to a new Socket.IO Socket,
// which is connected and authenticated to the TWIM service.
function createSocket(url, account, user) {
  return new Promise((resolve, reject) => {
    const installationId = account.id;
    const auth = {
      clientVersion: 1.1,
      installationId,
      userId: user.id,
      fname: user.firstName,
      lname: user.lastName,
      authToken: user.auth.token,
      timestamp: user.auth.timestamp,
    };
    const socket = io.connect(url, {
      path: `/socket.io/${installationId}`,
      timeout: 5000,
      // We use custom reconnection logic to avoid overloading the server.
      reconnection: false,
      forceNew: true,
      transports: ['websocket'],
    });

    function handleConnectionError() {
      // eslint-disable-next-line no-use-before-define
      done(new Error('useTWIMSocket: Failed to connect'));
    }

    function sendAuth() {
      socket.emit('auth', auth);
    }

    function handleAuthResult({ authenticated, serverVersion }) {
      // eslint-disable-next-line no-use-before-define
      done(authenticated && serverVersion != null ? null : new Error('useTWIMSocket: Failed to authenticate'));
    }

    socket.on('error', handleConnectionError);
    socket.on('connect_error', handleConnectionError);
    socket.on('disconnect', handleConnectionError);
    socket.on('connect', sendAuth);
    socket.on('authResult', handleAuthResult);

    function done(error) {
      socket.off('error', handleConnectionError);
      socket.off('connect_error', handleConnectionError);
      socket.off('disconnect', handleConnectionError);
      socket.off('connect', sendAuth);
      socket.off('authResult', handleAuthResult);

      if (error) {
        socket.disconnect();
        reject(error);
      } else {
        resolve(socket);
      }
    }
  });
}

/**
 * Returns a ref containing a Socket.IO socket connected and authenticated
 * to the Teamwork Instant Messaging service (TWIM).
 *
 * @param socketUrl (Account) => String
 */
export function provideTWIMSocket(socketUrl) {
  assertHasScope();

  if (window.location.port === '') {
    provide(useTWIMSocketSymbol, shallowRef(createFakeSocket()));
    return;
  }

  const account = useCurrentAccount();
  const user = useCurrentUser();
  let attempt = 0; // reconnection attempts
  let timeout; // represents a delayed socket connection
  const promise = shallowRef(); // resolves to a connected and authenticated socket
  const socket = shallowRef(); // connected and authenticated socket

  function initSocket(localSocket) {
    socket.value = localSocket;

    const localDisconnect = () => {
      localSocket.off('error', localDisconnect);
      localSocket.off('connect_error', localDisconnect);
      localSocket.off('disconnect', localDisconnect);

      localSocket.disconnect();

      if (socket.value === localSocket) {
        socket.value = undefined;
      }
    };

    localSocket.on('error', localDisconnect);
    localSocket.on('connect_error', localDisconnect);
    localSocket.on('disconnect', localDisconnect);
  }

  function disconnect() {
    clearTimeout(timeout);
    promise.value = undefined;
    if (socket.value) {
      socket.value.disconnect();
    }
    socket.value = undefined;
  }

  function connect() {
    if (promise.value) {
      return;
    } // already connecting
    if (socket.value) {
      return;
    } // already connected
    if (!account.value) {
      return;
    } // account not loaded
    if (!user.value) {
      return;
    } // user not loaded

    const localPromise = createSocket(socketUrl(account.value), account.value, user.value);
    promise.value = localPromise;

    localPromise
      .then((localSocket) => {
        if (promise.value === localPromise) {
          promise.value = undefined;
          initSocket(localSocket);
        } else {
          // connection canceled, so disconnect the new socket
          localSocket.disconnect();
        }
      })
      .catch(() => {
        if (promise.value === localPromise) {
          promise.value = undefined;
        }
        // else connection canceled
      });
  }

  watch([() => account.value && account.value.id, () => user.value && user.value.id], () => {
    attempt = 0;
    disconnect();
    connect();
  });
  watch([socket, promise], () => {
    if (promise.value) {
      return;
    } // already connecting
    if (socket.value) {
      return;
    } // already connected
    const delay = Math.min(attempt * 3000, 60000) + Math.random() * 5000;
    attempt += 1;
    clearTimeout(timeout);
    timeout = setTimeout(connect, delay);
  });

  onUnmounted(disconnect);
  connect();

  provide(useTWIMSocketSymbol, socket);
}

/**
 * Returns a ref containing a Socket.IO socket connected and authenticated
 * to the Teamwork Instant Messaging service (TWIM).
 */
export function useTWIMSocket() {
  return inject(useTWIMSocketSymbol);
}
