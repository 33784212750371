import { mapActions, mapMutations, mapGetters } from '../../record-mapper';

import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import { STATUS } from '../constants';

const basePagination = (cfg) => ({
  state: {
    paginator: STATUS.loading,
    [STATUS.loading]: {
      page: 1,
      pageSize: cfg.pageSize || 50,
    },
  },
  getters,
  mutations: mutations(cfg),
  actions: actions(cfg),
});

const recordPagination = (cfg) => ({
  state: { records: {} },
  getters: mapGetters(getters),
  mutations: mapMutations(mutations(cfg)),
  actions: mapActions(actions(cfg)),
});

const decorateWithConfig =
  (createModule) =>
  (opts = {}) => {
    const cfg = { loadAll: false, ...(opts.config || {}) };
    const module = createModule(cfg);
    return {
      namespaced: opts.namespaced,
      modules: opts.modules,
      state: {
        ...module.state,
        ...(opts.state || {}),
      },
      getters: {
        ...module.getters,
        ...(opts.getters || {}),
      },
      mutations: {
        ...module.mutations,
        ...(opts.mutations || {}),
      },
      actions: {
        ...module.actions,
        ...(opts.actions || {}),
      },
    };
  };

export const createPagination = decorateWithConfig(basePagination);
export const createRecordPagination = decorateWithConfig(recordPagination);
