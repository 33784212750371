import { useEventListener } from '@vueuse/core';
import { computed, inject, provide } from 'vue-demi';
import isInputFocused from '@/utils/helpers/isInputFocused';
import useStore from '@/platform/composables/useStore';
import { useLightspeedBridge } from '@/platform/composables/useLightspeedBridge';
import { postMessageToLightspeed } from '@/scaffolding/tko-lightspeed';

const quickViewsSymbol = Symbol('useQuickViews');

// NOTE
// For now we reuse the hybrid quick view system to get started quickly, however,
// eventually all quick views should be standardized and managed fully by this module.

/**
 * Normalizes a hybrid or tko quick view.
 */
function normalizeQuickView(quickView) {
  if (!quickView) {
    return undefined;
  }
  switch (quickView.name) {
    case 'task-details-quick-view':
      return { type: 'task', id: quickView.props.taskId };
    default:
      return undefined;
  }
}

export function provideQuickViews() {
  const store = useStore();
  const { shouldUseLightspeedTaskDetails, lightspeedState } = useLightspeedBridge();

  function openQuickView({ type, id, initialItem, viewType = 'panel', props = {} }) {
    switch (type) {
      case 'task':
        if (shouldUseLightspeedTaskDetails.value) {
          postMessageToLightspeed('twa:quick-view-route', {
            itemId: id,
            itemType: 'tasks',
          });
        } else {
          store.dispatch('quickViews/open', {
            id,
            name: 'task-details-quick-view',
            type: viewType,
            props: {
              taskId: id,
              initialItem,
              ...props,
            },
          });
        }
        break;
      default:
        throw new Error(`QuickView type not supported: "${type}"`);
    }
  }

  function replaceQuickView({ type, id, initialItem, viewType = 'panel', props = {} }) {
    switch (type) {
      case 'task':
        if (shouldUseLightspeedTaskDetails.value) {
          postMessageToLightspeed('twa:quick-view-route', {
            itemId: id,
            itemType: 'tasks',
          });
        } else {
          store.dispatch('quickViews/replace', {
            id,
            name: 'task-details-quick-view',
            type: viewType,
            props: {
              taskId: id,
              initialItem,
              ...props,
            },
          });
        }

        break;
      default:
        throw new Error(`QuickView type not supported: "${type}"`);
    }
  }

  function closeQuickView() {
    store.dispatch('quickViews/close');
  }

  function closeAllQuickViews() {
    store.dispatch('quickViews/reset');
  }

  const quickView = computed(() => normalizeQuickView(store.getters['quickViews/active']));

  useEventListener(window, 'keydown', (event) => {
    switch (event.key) {
      case 'ArrowDown': {
        if (
          ((!shouldUseLightspeedTaskDetails.value || !lightspeedState.value.activeTaskDetailsId) && !quickView.value) ||
          event.metaKey ||
          event.ctrlKey ||
          event.shiftKey ||
          event.altKey ||
          isInputFocused()
        ) {
          break;
        }
        const id = lightspeedState.value.activeTaskDetailsId || quickView.value.id;
        const elements = document.querySelectorAll(`[data-quick-view-type=task]`);
        for (let i = 0; i < elements.length; i += 1) {
          const element = elements[i];
          if (Number(element.dataset.quickViewId) === id) {
            if (i + 1 < elements.length) {
              const nextElement = elements[i + 1];
              nextElement.scrollIntoView({ block: 'nearest' });
              replaceQuickView({
                type: nextElement.dataset.quickViewType,
                id: Number(nextElement.dataset.quickViewId),
                initialItem: nextElement.quickViewInitialItem,
              });
            }
            event.preventDefault();
            break;
          }
        }
        break;
      }

      case 'ArrowUp': {
        if (
          ((!shouldUseLightspeedTaskDetails.value || !lightspeedState.value.activeTaskDetailsId) && !quickView.value) ||
          event.metaKey ||
          event.ctrlKey ||
          event.shiftKey ||
          event.altKey ||
          isInputFocused()
        ) {
          break;
        }
        const id = lightspeedState.value.activeTaskDetailsId || quickView.value.id;
        const elements = document.querySelectorAll(`[data-quick-view-type=task]`);
        for (let i = 0; i < elements.length; i += 1) {
          const element = elements[i];
          if (Number(element.dataset.quickViewId) === id) {
            if (i - 1 >= 0) {
              const previousElement = elements[i - 1];
              previousElement.scrollIntoView({ block: 'nearest' });
              replaceQuickView({
                type: previousElement.dataset.quickViewType,
                id: Number(previousElement.dataset.quickViewId),
                initialItem: previousElement.quickViewInitialItem,
              });
            }
            event.preventDefault();
            break;
          }
        }
        break;
      }

      default:
        break;
    }
  });

  provide(quickViewsSymbol, {
    /**
     * Opens a quick view.
     * @type ({ type: string, id: number, initialItem?: Object }) => void
     */
    openQuickView,
    /**
     * Replaces a quick view by a specific Id.
     * @type ({ type: string, id: number, initialItem?: Object }) => void
     */
    replaceQuickView,
    /**
     * Closes the active quick view.
     * @type () => void
     */
    closeQuickView,
    /**
     * Closes all quick views.
     * @type () => void
     */
    closeAllQuickViews,
    /**
     * A ref containing the currently active quick view or undefined.
     * @type Ref<{ type: string, id: number } | undefined>
     *
     * WARNING It does not support all quick views yet.
     */
    quickView,
  });
}

export function useQuickViews() {
  return inject(quickViewsSymbol);
}
