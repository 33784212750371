import { computed, shallowRef } from 'vue-demi';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import useItemLoader from '../base/useItemLoader';
import { normalizeLockdown } from './normalizeLockdown';

function responseToLockdown({ data }) {
  const { users = {}, companies = {}, teams = {} } = data.included || {};
  return normalizeLockdown({ lockdown: data.lockdown, users, teams, companies });
}

export default function useLockdownV3Loader({
  // The ID of the lockdown to load.
  lockdownId: _lockdownId,
  params,
}) {
  const lockdownId = shallowRef(_lockdownId);
  const url = computed(
    () => Number.isInteger(lockdownId.value) && `/projects/api/v3/lockdowns/${lockdownId.value}.json`,
  );

  const { state, refresh } = useItemLoader({
    url,
    params,
    responseToItem: responseToLockdown,
  });

  useRealTimeUpdates((event) => {
    if (event.lockdownId === lockdownId.value) {
      refresh();
    }
  });
  return state;
}
