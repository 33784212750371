import typedDefaults from '@/utils/helpers/typed-defaults';
import { createBatchLoader } from '@/store/utils/loader/batch';

const recordDefault = {
  id: 0,
  name: '',
  logoColor: '',
  logoIcon: '',
  logoUrl: '',
  teamLogo: '',
  teamLogoColor: '',
  teamLogoIcon: '',
  members: [],
};

export default {
  namespaced: true,
  modules: {
    batch: createBatchLoader({
      namespaced: true,
      config: {
        url: 'teams.json',
        param: 'teamIds',
        module: 'team',
        mapResponse: (ctx, rs) => rs.data.teams,
      },
    }),
  },
  state: {
    records: {},
  },
  mutations: {
    records(state, list) {
      const hash = {};
      list.forEach((rec) => {
        const existing = state.records[rec.id];
        const person = typedDefaults(recordDefault, rec);
        hash[person.id] = existing ? { ...existing, ...person } : person;
      });
      state.records = { ...state.records, ...hash };
    },
  },
};
