import { computed, provide, inject, ref } from 'vue-demi';

const notificationFilterSymbol = Symbol('notification-filter');

function notificationFilter() {
  const paramNameMapping = {
    projects: 'projectids',
    notificationTypes: 'entities',
  };

  const defaultParams = {
    projects: [],
    notificationTypes: [],
  };

  const filterMeta = {
    section: 'inbox',
    include: 'projects,notificationTypes',
  };

  const params = ref({ ...defaultParams });

  const activeNumber = computed(() => {
    let result = 0;

    result += params.value.projects.length;
    result += params.value.notificationTypes.length;

    return result;
  });

  const hasActiveFilter = computed(() => {
    return activeNumber.value > 0;
  });

  function clearAdvanced() {
    params.value = {
      ...params.value,
      projects: defaultParams.projects,
      notificationTypes: defaultParams.notificationTypes,
    };
  }

  const chips = computed(() => {
    const result = [];
    params.value.projects.forEach((project) => {
      result.push({
        type: 'projects',
        id: project.id,
        text: project.name,
        textColor: project.textColor,
        backgroundColor: project.color,
      });
    });

    params.value.notificationTypes.forEach((notificationType) => {
      result.push({
        type: 'notificationTypes',
        id: notificationType.id,
        text: notificationType.name,
        // chip color tbd for entity Types
        // textColor: '#f3c',
        // backgroundColor: '#2cdb1a',
        // borderColor: '#35f',
      });
    });

    return result;
  });

  function removeChip(id, type) {
    params.value[type] = params.value[type].filter((item) => item.id !== id);
  }

  return {
    params,
    defaultParams,
    paramNameMapping,
    clearAdvanced,
    activeNumber,
    hasActiveFilter,
    chips,
    removeChip,
    filterMeta,
  };
}

export function provideNotificationFilter() {
  const filters = notificationFilter();
  provide(notificationFilterSymbol, filters);
  return filters;
}

export function useNotificationFilter() {
  return inject(notificationFilterSymbol);
}
