import { useAxios, useCurrentAccount, useCurrentUser, useOptimisticUpdates, useRealTimeUpdates } from '@/api';

// All preferences are saved as one object in the database,
// so updating multiple preferences in parallel runs into a race condition.
// In order to avoid it we serialize the requests in the frontend.
let accountPromise = Promise.resolve();
let userPromise = Promise.resolve();

export default function usePreferenceActions() {
  const api = useAxios();
  const account = useCurrentAccount();
  const user = useCurrentUser();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId.value,
        'Triggered-By': 'user',
        'Sent-By': 'composable',
      },
    };
  }

  return {
    /**
     * @param {Object} preferences
     * @returns {Promise}
     */
    saveAccountPreferences(preferences) {
      accountPromise = accountPromise
        .catch(() => undefined)
        .then(() =>
          api.put('/account/prefs.json', { prefs: preferences }, config()).then(() => {
            emitRealTimeUpdate({ type: 'accountPreference', action: 'updated', accountId: account.value.id });
          }),
        );

      emitOptimisticUpdate({
        promise: accountPromise,
        type: 'account',
        action: 'update',
        account: { id: account.value.id, preferences: { ...account.value.preferences, ...preferences } },
      });

      return accountPromise;
    },

    /**
     * @param {Object} preferences
     * @returns {Promise}
     */
    saveUserPreferences(preferences) {
      userPromise = userPromise
        .catch(() => undefined)
        .then(() =>
          api.put('/me/prefs.json', { prefs: preferences }, config()).then(() => {
            emitRealTimeUpdate({ type: 'userPreference', action: 'updated', userId: user.value.id });
          }),
        );

      emitOptimisticUpdate({
        promise: userPromise,
        type: 'person',
        action: 'update',
        person: { id: user.value.id, preferences: { ...user.value.preferences, ...preferences } },
      });

      return userPromise;
    },
  };
}
