import { createRecordListLoader, API } from '@/store/utils/loader';
import { mapActions } from '@/store/utils/record-mapper';

export default createRecordListLoader({
  namespaced: true,
  config: {
    id: 'project/dependencies',
    url: (id) => `${API.v2}/projects/${id}/predecessors.json`,
    params: () => ({
      includeDependencies: true,
      onlyBasicFields: true,
      onlyActionable: true,
    }),
  },
  actions: mapActions({
    onLoad({ commit, state, id }, rs) {
      const deps = (rs.data.tasks || []).map((t) => ({ ...t, projectId: id }));
      if (!state.additive) {
        commit('dependencies/clearProject', id, {
          root: true,
          recordMap: false,
        });
      }
      commit('dependencies/records', deps, { root: true, recordMap: false });
    },
  }),
});
