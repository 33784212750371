import { createListLoader } from '@/store/utils/loader';

export default createListLoader({
  namespaced: true,
  config: {
    url: '/eventtypes.json',
    module: 'eventType',
    params: () => ({
      loadAll: true,
      includeDefaults: true,
    }),
  },
});
