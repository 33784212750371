import { mapState, mapActions, mapGetters } from 'vuex';
import QuickViews from '@sections/QuickViews';
import GlobalEvents from 'vue-global-events';
import { useLightspeedBridge } from '@/platform/composables/useLightspeedBridge';

// @vue/component
export default {
  components: {
    ...QuickViews,
    GlobalEvents,
  },
  props: {
    name: { type: String, default: '', required: false },
    props: { type: Object, required: false, default: () => {} },
    type: { type: String, required: false, default: 'modal' },
  },
  computed: {
    ...mapState('quickViews', ['quickViews']),
    ...mapGetters('quickViews', ['active']),
    modalQuickViews() {
      return this.quickViews.filter((quickView) => quickView.type !== 'panel');
    },
    panelQuickViews() {
      return this.quickViews.filter((quickView) => quickView.type === 'panel');
    },
  },
  methods: {
    ...mapActions('quickViews', ['close']),
    closeTopQuickView() {
      if (this.active.type === 'modal') {
        this.close();
      }
    },
  },
  setup() {
    const { isInLightspeed } = useLightspeedBridge();

    return { isInLightspeed };
  },
};
