import axios from 'axios';
import { stringify } from 'qs';
import fetchAdapter from './fetchAdapter';

export default axios.create({
  paramsSerializer(params) {
    return stringify(params, { arrayFormat: 'brackets' });
  },
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Teams-Enabled': 'true',
    'Referer-Path': window.location.hash.replace(/^#?\/*/, '/tko/'),
  },
  adapter: fetchAdapter,
});
