/* eslint-disable no-param-reassign */
import Translation from '@widgets/Translation';
import i18next from 'i18next';

// Until i18next is initialised, we get no reactive benefit, it will
// just return `undefined` without any reactive dependencies.
// So we need to create and log a dependency with some reactive data,
// then change the value to trigger a refresh when i18next is loaded
const reactivePlaceholder = { fire: false };
function alwaysReactiveT(...args) {
  if (!Object.keys(i18next.options).length) {
    // Register a reactive dependency
    // eslint-disable-next-line no-unused-expressions
    reactivePlaceholder.fire;
  }
  return i18next.t(...args);
}

/**
 * Vue i18next plugin
 * Inspired by:
 * https://github.com/rse/vue-i18next
 * https://github.com/i18next/jquery-i18next
 */
export default {
  install(Vue) {
    // Boom! Magical reactivity!
    // eslint-disable-next-line no-new
    new Vue({ data: reactivePlaceholder });
    i18next.on('initialized', () => {
      reactivePlaceholder.fire = true;
    });

    Vue.t = alwaysReactiveT;
    Vue.prototype.$t = Vue.t;

    // http://fitzgeraldnick.com/2014/01/13/hiding-implementation-details-with-e6-weakmaps.html
    const done = new WeakMap();
    const isDone = (el, binding) => done.has(el) && done.get(el)[binding.arg];
    const markDone = (el, binding) => {
      if (done.has(el)) {
        done.get(el)[binding.arg] = true;
      } else {
        done.set(el, { [binding.arg]: true });
      }
    };

    // DEPRECATED
    // Directive should only be used for no arg translations
    // otherwise use a bind with $t
    Vue.directive('t', (el, binding) => {
      if (isDone(el, binding)) {
        return;
      }

      console.warn('v-t directive is deprecated. Please use `Vue.t` or `$t` functions instead.');

      // default handler for bind/update lifecycle phases
      const defaultLang = i18next.language === i18next.options.fallbackLng;

      if (binding.arg) {
        // <div v-t:placeholder="'string to translate'"/> - note the literal string
        // if translation doesnt exist, default lang should be set to the attribute in the interim
        el.setAttribute(binding.arg, Vue.t(binding.value));
        if (i18next.exists(binding.value)) {
          markDone(el, binding);
        }
      } else if (!defaultLang) {
        // note that default lang is a noop
        // <div v-t>string to translate</div>
        if (i18next.exists(el.innerText)) {
          el.innerText = Vue.t(el.innerText);
          markDone(el, binding);
        }
      }
    });

    // DEPRECATED
    // Register global Translation component
    Vue.component('T', Translation);
  },
};
