import { createRecordListLoader, API } from '@/store/utils/loader';
import { mapActions } from '@/store/utils/record-mapper';

export default createRecordListLoader({
  namespaced: true,
  config: {
    id: 'task/predecessors',
    url: (id) => `${API.v2}/tasks/${id}/predecessors.json`,
    params: () => ({
      onlyBasicFields: true,
      onlyActionable: true,
    }),
    pagination: { loadAll: true },
  },
  actions: mapActions({
    onLoad({ commit, state, rootState, id }, rs) {
      const projectId = (rootState.task.records[id] || {}).projectId || 0;
      const deps = rs.data.tasks.map((t) => ({ ...t, projectId }));
      if (!state.additive) {
        commit('dependencies/clearPredecessors', id, {
          root: true,
          recordMap: false,
        });
      }
      commit('dependencies/records', deps, { root: true, recordMap: false });
    },
  }),
});
