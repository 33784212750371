/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import { isNumber, isPlainObject, isString } from 'lodash-es';
import moment from 'moment';
import isValidId from '@/utils/helpers/isValidId';

export const NOTIFICATION_ITEM_TYPES = Object.freeze({
  comment: 'comment',
  dashboard: 'dashboard',
  event: 'event',
  file: 'file',
  fileversion: 'fileversion',
  form: 'form',
  integration: 'integration',
  invoice: 'invoice',
  link: 'link',
  message: 'message',
  message_comment: 'message_comment',
  milestone: 'milestone',
  notebook: 'notebook',
  project: 'project',
  reply: 'reply',
  status: 'status',
  statusrequest: 'statusrequest',
  statusupdate: 'statusupdate',
  task: 'task',
  tasklist: 'tasklist',
});

export const NOTIFICATION_GROUPS = Object.freeze({
  comment: 'comment',
  event: 'event',
  file: 'file',
  form: 'form',
  integration: 'integration',
  invoice: 'invoice',
  link: 'link',
  message: 'message',
  message_comment: 'message_comment',
  milestone: 'milestone',
  notebook: 'notebook',
  project: 'project',
  reaction: 'reaction',
  status: 'status',
  task: 'task',
  tasklist: 'tasklist',
});

// @vue/component
export default function normalizeNotification(_notification, _userId, _legacy = false) {
  if (!isPlainObject(_notification)) {
    throw new Error(`'_notification' parameter is missing or invalid (expected Object)`);
  }

  if (!isValidId(_userId)) {
    throw new Error(`'_userId' parameter is missing or invalid (expected ID)`);
  }

  const id = (() => {
    if (!isPlainObject(_notification)) {
      return undefined;
    }

    const _id = parseInt(!_legacy ? _notification.id : _notification.twimEventId, 10);

    if (Number.isNaN(_id)) {
      return undefined;
    }

    return _id;
  })();

  const item = (() => {
    if (!isPlainObject(_notification)) {
      return undefined;
    }

    const _id = (() => {
      if (!_legacy && !isPlainObject(_notification.item)) {
        return undefined;
      }

      const __id = parseInt(!_legacy ? _notification.item.id : _notification.itemId, 10);

      if (Number.isNaN(__id)) {
        return undefined;
      }

      return __id;
    })();

    const _type = (() => {
      if (!_legacy) {
        if (!isPlainObject(_notification.item)) {
          return undefined;
        }

        if (!isString(_notification.item.type)) {
          return undefined;
        }

        if (isString(_notification.link) && _notification.link.toLowerCase().indexOf('message') !== -1) {
          return 'message_comment';
        }

        return _notification.item.type.toLowerCase();
      }

      if (!isString(_notification.itemType)) {
        return undefined;
      }

      return _notification.itemType.toLowerCase();
    })();

    const _link = (() => {
      if (!_legacy) {
        if (!isString(_notification.link)) {
          return undefined;
        }

        return _notification.link.toLowerCase();
      }

      if (!isString(_notification.itemLink)) {
        return undefined;
      }

      return _notification.itemLink.toLowerCase();
    })();

    return {
      id: _id,
      type: _type,
      link: _link,
    };
  })();

  const action = (() => {
    if (!isPlainObject(_notification)) {
      return undefined;
    }

    if (!_legacy) {
      if (!isString(_notification.action)) {
        return undefined;
      }

      return _notification.action.toLowerCase();
    }

    if (!isString(_notification.actionType)) {
      return undefined;
    }

    return _notification.actionType.toLowerCase();
  })();

  const extra = (() => {
    if (!isPlainObject(_notification)) {
      return undefined;
    }

    const _description = (() => {
      if (!_legacy) {
        return _notification.extraDescription;
      }

      return _notification.extraDesc;
    })();

    const _info = (() => {
      function getExtraInfo(_extraInfo) {
        function getData(_data) {
          // `extraInfo.data` is either an object or a JSON-encoded string
          if (!_data || (!isString(_data) && !isPlainObject(_data))) {
            return undefined;
          }

          try {
            return JSON.parse(_data);
          } catch (error) {
            return undefined;
          }
        }

        // `extraInfo` is either an object or a JSON-encoded string
        if (!_extraInfo || (!isString(_extraInfo) && !isPlainObject(_extraInfo))) {
          return undefined;
        }

        try {
          const extraInfo = isString(_extraInfo) ? JSON.parse(_extraInfo) : { ..._extraInfo };

          extraInfo.data = getData(extraInfo.data);

          return extraInfo;
        } catch (error) {
          return undefined;
        }
      }

      if (!_legacy) {
        return getExtraInfo(_notification.extraInfo);
      }

      return getExtraInfo(_notification.extraInfo);
    })();

    const _link = (() => {
      if (!_legacy) {
        if (!isString(_notification.extraLink)) {
          return undefined;
        }

        return _notification.extraLink.toLowerCase();
      }

      if (!isString(_notification.extraLink)) {
        return undefined;
      }

      return _notification.extraLink.toLowerCase();
    })();

    return {
      description: _description,
      info: _info,
      link: _link,
    };
  })();

  const group = (() => {
    if (!isPlainObject(_notification)) {
      return undefined;
    }

    if (!item || !isString(item.type)) {
      return undefined;
    }

    const itemTypeGroupMap = {
      [NOTIFICATION_ITEM_TYPES.comment]: NOTIFICATION_GROUPS.comment,
      [NOTIFICATION_ITEM_TYPES.event]: NOTIFICATION_GROUPS.event,
      [NOTIFICATION_ITEM_TYPES.file]: NOTIFICATION_GROUPS.file,
      [NOTIFICATION_ITEM_TYPES.fileVersion]: NOTIFICATION_GROUPS.file,
      [NOTIFICATION_ITEM_TYPES.form]: NOTIFICATION_GROUPS.form,
      [NOTIFICATION_ITEM_TYPES.integration]: NOTIFICATION_GROUPS.integration,
      [NOTIFICATION_ITEM_TYPES.invoice]: NOTIFICATION_GROUPS.invoice,
      [NOTIFICATION_ITEM_TYPES.link]: NOTIFICATION_GROUPS.link,
      [NOTIFICATION_ITEM_TYPES.message]: NOTIFICATION_GROUPS.message,
      [NOTIFICATION_ITEM_TYPES.message_comment]: NOTIFICATION_GROUPS.message_comment,
      [NOTIFICATION_ITEM_TYPES.milestone]: NOTIFICATION_GROUPS.milestone,
      [NOTIFICATION_ITEM_TYPES.notebook]: NOTIFICATION_GROUPS.notebook,
      [NOTIFICATION_ITEM_TYPES.project]: NOTIFICATION_GROUPS.project,
      [NOTIFICATION_ITEM_TYPES.reply]: NOTIFICATION_GROUPS.message,
      [NOTIFICATION_ITEM_TYPES.status]: NOTIFICATION_GROUPS.status,
      [NOTIFICATION_ITEM_TYPES.statusrequest]: NOTIFICATION_GROUPS.status,
      [NOTIFICATION_ITEM_TYPES.statusupdate]: NOTIFICATION_GROUPS.status,
      [NOTIFICATION_ITEM_TYPES.task]: NOTIFICATION_GROUPS.task,
      [NOTIFICATION_ITEM_TYPES.tasklist]: NOTIFICATION_GROUPS.tasklist,
    };

    // Special case for reactions
    if (action === 'reacted' && extra && extra.description && extra.description.length) {
      return NOTIFICATION_GROUPS.reaction;
    }

    if (item.type === 'message_comment') {
      return NOTIFICATION_GROUPS.message_comment;
    }

    if (item.type.includes('comment')) {
      return NOTIFICATION_GROUPS.comment;
    }

    if (item.type.includes('project')) {
      return NOTIFICATION_GROUPS.project;
    }

    return itemTypeGroupMap[item.type] || undefined;
  })();

  const user = (() => {
    if (!isPlainObject(_notification)) {
      return undefined;
    }

    const _id = (() => {
      if (!_legacy) {
        if (!isPlainObject(_notification.user)) {
          return undefined;
        }

        return _notification.user.id;
      }

      return _notification.userId;
    })();

    const _avatar = (() => {
      if (!_legacy) {
        if (!isPlainObject(_notification.user)) {
          return undefined;
        }

        return _notification.user.avatar_url;
      }

      return _notification.userImage;
    })();

    const _firstName = (() => {
      if (!_legacy) {
        if (!isPlainObject(_notification.user)) {
          return undefined;
        }

        return _notification.user.firstname;
      }

      return _notification.userFName;
    })();

    const _lastName = (() => {
      if (!_legacy) {
        if (!isPlainObject(_notification.user)) {
          return undefined;
        }

        return _notification.user.lastname;
      }

      return _notification.userLName;
    })();

    return {
      id: _id,
      avatar: _avatar,
      firstName: _firstName,
      lastName: _lastName,
    };
  })();

  const read = (() => {
    if (!isPlainObject(_notification)) {
      return undefined;
    }

    if (!_legacy) {
      return !!_notification.read;
    }

    return !!_notification.wasRead;
  })();

  const title = (() => {
    let fullActionText;

    if (!isPlainObject(_notification)) {
      return undefined;
    }

    if (!item || !isString(item.type)) {
      return undefined;
    }

    if (action === 'statusrequest') {
      return Vue.t('Request to update your status');
    }

    if (action === 'reminder') {
      switch (item.type) {
        case 'task':
          return Vue.t('Task Reminder');
        case 'event':
          return Vue.t('Event Reminder');
        case 'milestone':
          return Vue.t('Milestone Reminder');
        default:
          break;
      }
    }

    const _itemType = (() => {
      if (item.type.toLowerCase().indexOf('comment') !== -1) {
        if (isString(item.link) && item.link.toLowerCase().indexOf('message') !== -1) {
          return 'reply';
        }

        return 'comment';
      }

      if (item.type.toLowerCase() === 'fileversion') {
        return 'file';
      }

      if (item.type.toLowerCase() === 'statusupdate') {
        return 'status';
      }

      if (item.type.toLowerCase() === 'tasklist') {
        return 'task list';
      }

      if (item.type.toLowerCase() === 'projectupdate') {
        return 'project update';
      }

      return item.type;
    })();

    const _actionType = (() => {
      switch (action) {
        case 'like':
          return 'liked';
        case 'new':
          return 'added';
        case 'reacted':
          return 'reacted to';
        default:
          return action;
      }
    })();

    if (_itemType === 'integration') {
      fullActionText = `${_actionType} an ${_itemType}`;
    } else {
      fullActionText = `${_actionType} a ${_itemType}`;
    }

    if (_itemType === 'integration' && isString(item.link) && item.link.includes(':USER_ID_REPLACEMENT')) {
      // If the property of the notification called 'event' was named 'integration-enabled' we
      // link to the users personal integration page -> "/people/:USER_ID/integrations"
      // eslint-disable-next-line no-param-reassign
      _notification.link = _notification.link.replace(':USER_ID_REPLACEMENT', _userId);
    }

    const taskAssigneeIds = (() => {
      if (extra && isPlainObject(extra.data) && extra.data.taskAssignedToUserId) {
        const taskAssignedToUserId = extra.data.taskAssignedToUserId;

        if (isString(taskAssignedToUserId)) {
          return taskAssignedToUserId.split(',').map(Number);
        }

        if (isNumber(taskAssignedToUserId)) {
          return [taskAssignedToUserId];
        }
      }

      return [];
    })();

    if (taskAssigneeIds.includes(_userId)) {
      if (_actionType === 'added') {
        fullActionText = 'added a task for you';
      } else {
        fullActionText = 'assigned a task to you';
      }
    }

    const name = (() => {
      if (!user || !isString(user.firstName)) {
        return Vue.t('Someone');
      }

      return user.firstName;
    })();

    return `${name} ${fullActionText}`;
  })();

  const description = (() => {
    if (!isPlainObject(_notification) || !_notification.description) {
      return undefined;
    }

    return _notification.description.trim();
  })();

  const reaction = (() => {
    if (!isPlainObject(_notification)) {
      return undefined;
    }

    if (group !== NOTIFICATION_GROUPS.reaction) {
      return undefined;
    }

    const extraDescription = extra && isString(extra.description) && extra.description.toLowerCase();

    if (['frown', 'joy', 'heart', 'like', 'dislike'].includes(extraDescription)) {
      return extraDescription;
    }

    return undefined;
  })();

  const date = (() => {
    if (!isPlainObject(_notification)) {
      return undefined;
    }

    if (!_legacy) {
      return moment(_notification.date);
    }

    // `moment.utc(_notification.eventTimeUTC).local()` does not work as needed
    // because it uses the system timezone instead of the Teamwork timezone.
    return moment(moment.utc(_notification.eventTimeUTC).valueOf());
  })();

  const installation = (() => {
    if (!isPlainObject(_notification)) {
      return undefined;
    }

    const _id = (() => {
      function parse(__id) {
        const ___id = parseInt(__id, 10);

        if (Number.isNaN(___id)) {
          return undefined;
        }

        return ___id;
      }

      if (!_legacy) {
        if (!isPlainObject(_notification.installation)) {
          return undefined;
        }

        return parse(_notification.installation.id);
      }

      return parse(_notification.installationId);
    })();

    return {
      id: _id,
    };
  })();

  const project = (() => {
    if (!isPlainObject(_notification)) {
      return undefined;
    }

    const _id = (() => {
      function parse(__id) {
        const ___id = parseInt(__id, 10);

        if (Number.isNaN(___id)) {
          return undefined;
        }

        return ___id;
      }

      if (!_legacy) {
        if (!isPlainObject(_notification.project)) {
          return undefined;
        }

        return parse(_notification.project.id);
      }

      return parse(_notification.projectId);
    })();

    const _name = (() => {
      function parse(__name) {
        return isString(__name) ? __name.trim() : undefined;
      }

      if (!_legacy) {
        if (!isPlainObject(_notification.project)) {
          return undefined;
        }

        return parse(_notification.project.name);
      }

      return parse(_notification.projectName);
    })();

    return {
      id: _id,
      name: _name,
    };
  })();

  const privacy = (() => {
    if (!isPlainObject(_notification)) {
      return undefined;
    }

    const _private = (() => {
      if (!_legacy) {
        if (!isPlainObject(_notification.privacy)) {
          return undefined;
        }

        return _notification.privacy.private || undefined;
      }

      return _notification.isPrivate === '1';
    })();

    const _lockdownId = (() => {
      if (!_legacy) {
        if (!isPlainObject(_notification.privacy)) {
          return undefined;
        }

        return _notification.privacy.lockdownId || undefined;
      }

      return _notification.lockdownId || undefined;
    })();

    return {
      private: _private,
      lockdownId: _lockdownId,
    };
  })();

  const notifiedUserIdList = (() => {
    return _notification.notifiedUserIdList || undefined;
  })();

  return {
    _raw: _notification,
    action,
    date,
    description,
    extra,
    group,
    id,
    installation,
    item,
    privacy,
    project,
    reaction,
    read,
    title,
    user,
    notifiedUserIdList,
  };
}
