import { computed, shallowRef } from 'vue-demi';
import { v1Url } from '@/utils/fetcher';
import useItemLoader from '../base/useItemLoader';

function responseToItem({ data: { predecessors } }) {
  for (let i = 0; i < predecessors.length; i += 1) {
    const predecessor = predecessors[i];
    predecessor.id = Number(predecessor.id);
    predecessor.projectId = Number(predecessor.projectId);
  }

  return predecessors;
}

/**
 * Loads a list of task predecessors.
 *
 * Note: The API does not support pagination for task predecessors.
 */
export default function usePredecessorsLoader({ taskId: _taskId, params, count: _count }) {
  const taskId = shallowRef(_taskId);
  const count = shallowRef(_count);
  const url = computed(() => Number.isInteger(taskId.value) && v1Url(`tasks/${taskId.value}/predecessors`));
  const {
    state: { item, inSync, loading, meta, response, error },
  } = useItemLoader({ url, params, responseToItem });

  return {
    items: computed(() => {
      if (item.value && count.value > 0) {
        if (item.value.length <= count.value) {
          return item.value;
        }
        return item.value.slice(0, count.value);
      }
      return [];
    }),
    totalCount: computed(() => item.value && item.value.length),
    itemInSync: () => inSync.value,
    inSync,
    loading,
    meta,
    response,
    error,
  };
}
