import Vue from 'vue';
import { createRecordLoader } from '@/store/utils/loader';
import typedDefaults from '@/utils/helpers/typed-defaults';
import all from './modules/all';

export const defaultTypes = {
  id: 0,
  name: '',
  color: '',
  unavailable: false,
};

export default {
  namespaced: true,

  state: {
    records: {},
  },

  modules: {
    loader: createRecordLoader({
      config: {
        url: (id) => `/eventtypes/${id}.json`,
      },
    }),
    all,
  },

  mutations: {
    records(state, eventTypes) {
      const hash = {};
      eventTypes.forEach((eventType) => {
        hash[eventType.id] = typedDefaults(defaultTypes, eventType);
      });
      state.records = { ...state.records, ...hash };
    },

    record(state, eventType) {
      const defsApplied = typedDefaults(defaultTypes, eventType);
      const newRec = {
        ...(state.records[eventType.id] || defaultTypes),
        ...defsApplied,
      };
      Vue.set(state.records, newRec.id, newRec);
    },
  },

  getters: {
    unavailableTypes(state) {
      return Object.values(state.records).filter((type) => type.unavailable);
    },
  },
};
