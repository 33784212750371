import moment from 'moment';
import { computed, shallowRef } from 'vue-demi';
import { v1Url } from '@/utils/fetcher';
import useItemLoader from '../base/useItemLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItem(response) {
  const datesSet = response.data['dates-set'];
  Object.keys(datesSet).forEach((key) => {
    datesSet[key] = Number(datesSet[key]);
  });
  return {
    datesSet,
    outOfSequence: response.data['out-of-sequence'],
    recurringDates: response.data['recurring-dates'].map((date) => moment.utc(date)),
  };
}

/**
 * Loads the recurring dates for a task.
 */
export default function useTaskRecurringLoader({
  // The ID of the task for which to load the recurring dates.
  taskId: _taskId,
  // The ID of the task list containing the task for which to load the recurring dates.
  // It is used for filtering real-time update events.
  taskListId: _taskListId,
  // The ID of the project containing the task for which to load the recurring dates.
  // It is used for filtering real-time update events.
  projectId: _projectId,
  params,
}) {
  const taskId = shallowRef(_taskId);
  const taskListId = shallowRef(_taskListId);
  const projectId = shallowRef(_projectId);
  const url = computed(() => Number.isInteger(taskId.value) && v1Url(`tasks/${taskId.value}/recurring`));
  const { state, refresh } = useItemLoader({ url, params, responseToItem });

  useRealTimeUpdates((event) => {
    if (
      event.type === 'task' &&
      event.taskListId === taskListId.value &&
      event.projectId === projectId.value &&
      (event.action === 'added' || event.action === 'deleted')
    ) {
      refresh();
    }
  });

  return state;
}
