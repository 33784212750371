/**
 * We want to be able to trigger TKO modals from Vue components
 * we should use 'modals/tko/open' so we are consious about using a TKO component.
 */

export default {
  tko: {
    namespaced: true,
    actions: {
      open(_, { id, args }) {
        const realArgs = { ...args };
        // Replace project ID with cached ProjectModel
        if (realArgs.project) {
          realArgs.project = window.app.cachedProjects[realArgs.project.id || realArgs.project];
        }

        if (realArgs.item && window.app.cachedProjects[realArgs.item]) {
          realArgs.item = window.app.cachedProjects[realArgs.item];
        }

        if (realArgs.template) {
          realArgs.template = window.app.cachedProjects[realArgs.template.id];
        }

        window.app.modal.Show(id, realArgs);
      },
    },
  },
};
